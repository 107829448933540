import React from 'react';

const AttachmentThumbnail = (className = '') => (
  <svg
    width="50"
    height="36"
    viewBox="0 0 50 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M20.3312 1.18997L23.0311 4.42325C23.5219 5.01102 24.2651 5.32619 25.0278 5.26986L37.0187 4.38501C38.8816 4.24749 40.503 5.64965 40.6402 7.51675L42.271 29.7136C42.4082 31.5807 41.0092 33.2057 39.1463 33.3433L5.90056 35.7968C4.03766 35.9343 2.41629 34.5322 2.27908 32.6651L0.178405 4.07135C0.08198 2.75961 1.06484 1.61799 2.37362 1.52134L18.3343 0.343363C19.0972 0.287036 19.8402 0.602207 20.3312 1.18997Z"
      fill="#E48642"
    />
    <g opacity="0.3">
      <path
        d="M20.3312 1.18997L23.0311 4.42325C23.5219 5.01102 24.2651 5.32619 25.0278 5.26986L37.0187 4.38501C38.8816 4.24749 40.503 5.64965 40.6402 7.51675L42.271 29.7136C42.4082 31.5807 41.0092 33.2057 39.1463 33.3433L5.90056 35.7968C4.03766 35.9343 2.41629 34.5322 2.27908 32.6651L0.178405 4.07135C0.08198 2.75961 1.06484 1.61799 2.37362 1.52134L18.3343 0.343363C19.0972 0.287036 19.8402 0.602207 20.3312 1.18997Z"
        fill="black"
      />
    </g>
    <path
      d="M14.3844 3.2221L3.00237 4.06211C2.74617 4.08095 2.52099 3.88635 2.5022 3.62958L2.50126 3.61601C2.48246 3.35924 2.67663 3.13355 2.93282 3.11471L14.3149 2.2747C14.5711 2.25586 14.7963 2.45046 14.8151 2.70723L14.816 2.7208C14.8348 2.97757 14.6406 3.20307 14.3844 3.2221Z"
      fill="white"
    />
    <path
      d="M12.807 5.24651L3.13518 5.9603C2.87899 5.97914 2.65381 5.78454 2.63501 5.52777L2.63407 5.51421C2.61527 5.25743 2.80944 5.03175 3.06563 5.01291L12.7374 4.29911C12.9936 4.28027 13.2188 4.47488 13.2376 4.73165L13.2386 4.74521C13.2573 5.00217 13.0632 5.22767 12.807 5.24651Z"
      fill="white"
    />
    <path
      d="M40.2472 32.079L5.32256 34.6563C4.44684 34.7209 3.6777 34.0557 3.61323 33.178L2.02776 11.5976C1.96329 10.7199 2.62699 9.94901 3.50271 9.88439L38.4273 7.30707C39.303 7.24245 40.0722 7.90765 40.1366 8.78534L41.7221 30.3658C41.7866 31.2433 41.1229 32.0144 40.2472 32.079Z"
      fill="#E0E0E0"
    />
    <path
      d="M40.2466 32.0788L5.82256 34.6192C4.94684 34.6838 4.1777 34.0186 4.11323 33.1409L2.52776 11.5607C2.46329 10.683 3.12699 9.91208 4.00271 9.84747L38.4268 7.30707C39.3025 7.24245 40.0716 7.90765 40.1361 8.78534L41.7216 30.3658C41.786 31.2431 41.1223 32.0142 40.2466 32.0788Z"
      fill="white"
    />
    <path
      d="M6.94531 34.5378L14.1518 34.006L7.6278 12.5L6.94531 34.5378Z"
      fill="#F5F5F5"
    />
    <path
      d="M47.0338 26.0547L12.5007 31.8817C11.6348 32.0279 10.8072 31.4373 10.6613 30.5694L7.07686 9.23085C6.931 8.36295 7.52027 7.53349 8.38621 7.3873L42.9193 1.56031C43.7852 1.41412 44.6128 2.00472 44.7587 2.87261L48.3431 24.2112C48.4888 25.0791 47.8997 25.9085 47.0338 26.0547Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M47.0467 26.0539L13.0085 31.7973C12.1426 31.9435 11.315 31.3529 11.1691 30.485L7.58468 9.14642C7.43882 8.27852 8.02808 7.44906 8.89402 7.30287L42.9322 1.55933C43.7981 1.41315 44.6257 2.00374 44.7716 2.87164L48.356 24.2102C48.5017 25.0783 47.9126 25.9078 47.0467 26.0539Z"
      fill="#E48642"
    />
    <path
      opacity="0.2"
      d="M42.4837 6.1538L10.7883 11.5019C10.4063 11.5663 10.0413 11.306 9.97702 10.9232L9.79883 9.86239C9.73455 9.47958 9.99432 9.11374 10.3763 9.04931L42.0717 3.7012C42.4536 3.63677 42.8186 3.89712 42.8829 4.27992L43.0611 5.34073C43.1254 5.72353 42.8654 6.08938 42.4837 6.1538Z"
      fill="#E48642"
    />
    <path
      opacity="0.2"
      d="M42.8393 8.06026L11.0997 13.4159C10.73 13.4783 10.4785 13.8324 10.5407 14.203L10.6977 15.1376C10.7599 15.5081 11.1132 15.7602 11.483 15.6978L43.2225 10.3422C43.5923 10.2798 43.8438 9.92566 43.7815 9.5551L43.6246 8.62033C43.5624 8.24996 43.209 7.9979 42.8393 8.06026Z"
      fill="#E48642"
    />
    <path
      d="M47.0824 33.2465L47.0156 33.0704C47.4504 32.9048 47.8487 32.6638 48.2 32.3545L48.324 32.496C47.9558 32.8204 47.538 33.0728 47.0824 33.2465Z"
      fill="#E48642"
    />
    <path
      d="M49.2928 31.2523L49.125 31.1677C49.4398 30.5399 49.5789 29.8364 49.5274 29.1334L49.3201 26.3145L49.5077 26.3008L49.715 29.1196C49.7691 29.8566 49.6231 30.5941 49.2928 31.2523Z"
      fill="#E48642"
    />
    <path
      d="M45.8867 32.8451L9.32341 35.5434V35.544L5.90625 35.7962C7.79358 35.657 9.21082 34.0107 9.07173 32.1191L7.96783 17.0932C7.8761 15.844 8.81215 14.7566 10.0585 14.6647L45.5247 12.0472C46.771 11.9553 47.856 12.8935 47.9477 14.1427L49.0516 29.1688C49.1905 31.0598 47.7735 32.7059 45.8867 32.8451Z"
      fill="#E48642"
    />
  </svg>
);

export default AttachmentThumbnail;
