import { Loader } from 'components/Svg';
import { minutesToTimeString } from 'pages/people/peopleDetails';
import CategoriesDropdown from 'pages/transactions/CategoriesDropdown';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { dateCategoryListWithWeeks } from 'utils/Utils';

const PeopleHoursTable = ({
  overviewLoadings,
  peopleHoursData,
  peopleHourTimePeriod,
  handlePeopleTimePeriodChange,
}) => {
  const navigate = useNavigate();
  const getProjectTotalHours = (hours) => {
    if (hours?.length > 0) {
      let total = 0;
      // eslint-disable-next-line no-return-assign
      hours?.forEach((h) => (total += h?.minutes));
      return minutesToTimeString(total);
    }
    return '--';
  };

  return (
    <div className="p-6 border border-[#D0D5DD] rounded-[12px]">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-[22px] font-medium text-[#666]  ">Total Hours</h3>
        <div className="w-[130px] max-w-[200px] sm:max-w-[300px]">
          <CategoriesDropdown
            allCategories={dateCategoryListWithWeeks()}
            selectedCategoryId={peopleHourTimePeriod}
            setSelectedCategoryId={handlePeopleTimePeriodChange}
            isSetCategoryStyle
            height="h-10"
            buttonTextClassSub="!text-[#667085]"
          />
        </div>
      </div>
      {overviewLoadings?.peopleHours ? (
        <div className="flex flex-col h-[100px] justify-center items-center">
          <Loader height="h-8 w-8" />
          <p>Fetching data please wait...</p>
        </div>
      ) : (
        <>
          {peopleHoursData?.grouped?.length > 0 ? (
            <div className="overflow-auto scrollbar">
              <table className="w-full table-auto">
                <tbody className="divide-y divide-[#D0D5DD]">
                  {peopleHoursData?.grouped?.map(
                    (group, i) =>
                      i < 3 && (
                        <tr key={group?.id} className="">
                          <td className="py-6 pr-2 min-w-[10rem]">
                            <h4 className="text-[18px] my-0 leading-normal text-indigo-500">
                              {`${group?.firstName} ${group?.lastName}`}
                            </h4>
                          </td>

                          <td className="py-6 px-2 min-w-[5rem] ">
                            <div className="text-[18px] font-bold text-[#667085] text-right">
                              {getProjectTotalHours(group?.dates)}
                            </div>
                          </td>
                        </tr>
                      ),
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex w-full items-center justify-center flex-col my-4">
              <p className="text-slate-600 text-[20px] text-center">
                No Data Found
              </p>
            </div>
          )}
          <button
            type="button"
            className="w-full h-[38px] border border-indigo-500 mt-4 rounded-[6px] text-indigo-500"
            onClick={() => navigate('/reports/time-sheets')}
          >
            View Timesheet Report
          </button>
        </>
      )}
    </div>
  );
};

export default PeopleHoursTable;
