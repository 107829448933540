/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  TEAM_DATE_FORMAT,
  VIEWER,
  getFormattedDate,
  transformAccounts,
} from '../../../utils/Utils';
import { BackBtn } from '../../accounts/reconcileAccounts/Svg';
import ActionsDropdown from '../../transactions/ActionsDropdown';
import { Loader } from '../../../components/Svg';
import Transition from '../../../utils/Transition';
import { StatusDropdownArrow } from '../../inbox/Svg';
import {
  getAllBills,
  getInvoices,
  getPayableBills,
  getReceivableInvoices,
  getTransactions,
  getAccounts,
  getTags,
  getMerchants,
  getSingleTransaction,
  deleteTransactionsByIDs,
  assignInvoiceToBill,
  assignTransactionToBill,
  unassignTransactionToBill,
  deAssignTransToInvoice,
  getProducts,
  getPeople,
  createTimeEntry,
  getTimeInsights,
  getAllTimeEntries,
  updateTimeEntry,
  deleteBillById,
  updateInstanceById,
  getTaxes,
  createProduct,
  createPeople,
  getProjectTransactionSummary,
  createTax,
  getSingleProject,
  getCustomers,
  updateProject,
  deleteProject,
  getSingleProjectStats,
  getJournalEntries,
  getAllProjects,
} from '../../../API/backend_helper';
import useClickOutside from '../../../utils/useClickOutside';
import OverviewTab from './OverviewTab';
import TransactionDetails from '../../transactions/TransactionPanel';
import DeleteTransactionModal from '../../transactions/DeleteTransactionModal';
import AddTaskModal from '../../inbox/AddTaskModal';
import ReceiptModal from '../../transactions/ReceiptModal';
// import CreateRuleModal from "../../components/CreateRuleModal";
import CreateRulePopup from '../../../components/CreateRulePopup';
import InvoiceTab from './invoiceTab';
import AddTimeModal from '../AddTimeModal';
import TimeTab from './TimeTab';
import InstancesModal from '../../bills/InstancesModal';
import TransactionsModal from '../../bills/TransactionsModal';
import BillDetails from '../../bills/BillDetails';

import { EDIT_INVOICE, CREATE_INVOICE } from '../../invoices';
import ModalBasic from '../../../components/ModalBasic';
import ProductForm from '../../products/ProductForm';
import PeopleForm from '../../people/PeopleForm';
import { statusOptions } from '..';
import AddProjectModal from '../AddProjectModal';
import AddCustomerModal from '../../invoices/AddCustomerModal';
import CreateRuleModal from '../../../components/CreateRuleModal';
import AppLayout from '../../../components/AppLayout';
import DeleteModal from '../DeleteModal';
import ProjectExpense from './ProjectExpense';

const ProjectDetails = () => {
  const statusDropdownRef = useRef(null);
  const trigger = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mainLoading, setMainLoading] = useState(true);
  const [project, setProject] = useState(null);
  const { accessType, localUser } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') || '';

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('Not started');
  const [deleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const [projects, setProjects] = useState([]);

  // Invoices States
  const [invoices, setInvoices] = useState([]);
  const [estimates, setEstimates] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  const [allEstimates, setAllEstimates] = useState([]);
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [activeTab, setActiveTab] = useState('Overview');
  const [allCategories, setAllCategories] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [peopleModalOpen, setPeopleModalOpen] = useState(false);

  // Overview States
  const [selectedReceivableRange, setSelectedReceivableRange] = useState('all');
  const [selectedPayableRange, setSelectedPayableRange] = useState('all');
  const [invoiceBalance, setInvoiceBalance] = useState(null);
  const [billBalance, setBillBalance] = useState(null);

  // Loading States
  const [overviewLoading, setOverviewLoading] = useState(true);
  const [timeLoading, setTimeLoading] = useState(false);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [billsLoading, setBillsLoading] = useState(false);
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  // Overview States
  const [projectStat, setProjectStat] = useState(null);
  const [noteEditing, setNoteEditing] = useState(false);
  const [timeTrackingModalOpen, setTimeTrackingModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [people, setPeople] = useState([]);
  const [billableHours, setBillableHours] = useState(0);
  const [nonBillableHours, setNonBillableHours] = useState(0);
  const [taxes, setTaxes] = useState([]);
  const [overviewTransactionData, setOverviewTransactionData] = useState({});

  // Transaction States
  const [transactions, setTransactions] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [allExpenseCategories, setAllExpenseCategories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [deleteTransactionModal, setDeleteTransactionModal] = useState('');
  const [fromDropdown, setFromDropdown] = useState(false);
  const [splitTransactionModalOpen, setSplitTransactionModalOpen] =
    useState(false);
  const [allBills, setAllBills] = useState([]);
  const [transactionPanelOpen, setTransactionPanelOpen] = useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [currentTransDetailTab, setCurrentTransDetailTab] = useState('details');
  const [vendors, setVendors] = useState([]);
  const [tags, setTags] = useState([]);
  const [ruleModalOpen, setRuleModalOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupCategory, setPopupCategory] = useState('');
  const [popupTransaction, setPopupTransaction] = useState('');
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [deleteModalParent, setDeleteModalParent] = useState(null);
  const [selectedTaskOption, setSelectedTaskOption] = useState(null);
  const [categoryLoading, setCategoryLoading] = useState({});

  // Time States
  const [timeEntries, setTimeEntries] = useState([]);
  const [activeEntry, setActiveEntry] = useState(null);
  const [createdProduct, setCreatedProduct] = useState(null);
  const [createdPeople, setCreatedPeople] = useState(null);

  // Bills States
  const [bills, setBills] = useState([]);
  const [activeBill, setActiveBill] = useState(null);
  const [activeBillId, setActiveBillId] = useState(null);
  const [instancesModalOpen, setInstancesModalOpen] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [billTaskModal, setBillTaskModal] = useState(false);
  const [transactionsModalOpen, setTransactionsModalOpen] = useState(false);
  const [activeInstance, setActiveInstance] = useState(null);
  const [salesRep, setSalesRep] = useState([]);

  //Journal Entries Filter
  const [journalentries, setJournalEntries] = useState([]);
  const [localLimit, setLocalLimit] = useState(50);
  const [searchValue, setSearchValue] = useState('');
  const [journalentriesLoading, setJournalEntriesLoading] = useState(false);
  // Filters
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');

  useEffect(() => {
    if (tab) {
      if (tab === 'overview') {
        setActiveTab('Overview');
      } else if (tab === 'time') {
        setActiveTab('Time');
      } else if (tab === 'expenses') {
        setActiveTab('Expenses');
      } else if (tab === 'invoices') {
        setActiveTab('Invoices');
      }
    }
  }, [tab]);

  const tabs = ['Overview', 'Time', 'Expenses', 'Invoices'];

  useClickOutside(statusDropdownRef, (event) => {
    if (!trigger.current.contains(event.target)) setStatusDropdownOpen(false);
  });

  useEffect(() => {
    if (project) {
      loadData();
    }
  }, [project]);

  const getProjectByIdApi = async () => {
    setMainLoading(true);
    try {
      const res = await getSingleProject(id);
      setProject(res);
    } catch (e) {
      console.log('error', e);
    } finally {
      setMainLoading(false);
    }
  };

  const getProjectStatsByIdApi = async () => {
    // setMainLoading(true);
    try {
      const res = await getSingleProjectStats(id);
      setProjectStat(res?.data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setMainLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getProjectByIdApi();
      getProjectStatsByIdApi();
      getJournalEntriesApi({});
    }
  }, [id]);

  useEffect(() => {
    getBillBalance();
  }, [selectedPayableRange]);

  useEffect(() => {
    getInvoiceBalance();
  }, [selectedReceivableRange]);

  const loadCustomers = async () => {
    try {
      const res = await getCustomers({});
      setCustomers(res?.data?.records);
    } catch (error) {
      console.error(error);
    }
  };

  const loadAllProjects = async () => {
    try {
      const res = await getAllProjects({});
      setProjects(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getInvoicesApi = async (reload = true) => {
    try {
      const res = await getInvoices({});
      setAllInvoices(res?.data?.invoices);
      if (project) {
        const projectInvoices = res?.data?.invoices.filter(
          (invoice) => invoice.projectId === project?.id,
        );
        setInvoices(projectInvoices);
      }
      if (reload) {
        getInvoiceBalance();
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const getEstimatesApi = async (reload = true) => {
    try {
      const res = await getInvoices({ type: 'estimate' });
      setAllEstimates(res?.data?.invoices);
      if (project) {
        const projectEstimates = res?.data?.invoices.filter(
          (estimate) => estimate.projectId === project?.id,
        );
        setEstimates(projectEstimates);
      }
      if (reload) {
        getInvoiceBalance();
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const getSalesRepApi = async () => {
    try {
      const res = await getPeople({
        limit: 1000000,
        roles: ['sales rep'],
      });
      setSalesRep(res?.people);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadData = async () => {
    if (!firstLoad) {
      setOverviewLoading(true);
      setTransactionsLoading(true);
      setInvoicesLoading(true);
      setBillsLoading(true);
      setTimeLoading(true);
    }
    setFirstLoad(true);
    loadCustomers();
    loadAllProjects();
    getSalesRepApi();
    await Promise.all([
      getInvoiceBalance(),
      getBillBalance(),
      getProductsApi(),
      getPeopleApi(),
      getTimeInsightsApi(),
      getTaxesApi(),
      getOverviewTransactionsApi(),
      getInvoicesApi(false),
      getEstimatesApi(false),
      getAllTimeEntriesApi(),
      getTransactionsApi({ reload: false }),
      getAccountsApi(),
      getTagsApi(),
      getVendorsApi(),
      getBillsApi(false),
    ]);
    setOverviewLoading(false);
    setInvoicesLoading(false);
    setTimeLoading(false);
    setTransactionsLoading(false);
    setBillsLoading(false);
  };

  // Invoice and Bill Balances
  const getDatesForRange = (range) => {
    let startDate = '';
    let endDate = '';

    switch (range) {
      case 'next30':
        startDate = moment().format('YYYY-MM-DD');
        endDate = moment().add(30, 'days').format('YYYY-MM-DD');
        break;
      case 'next60':
        startDate = moment().format('YYYY-MM-DD');
        endDate = moment().add(60, 'days').format('YYYY-MM-DD');
        break;
      case 'next90':
        startDate = moment().format('YYYY-MM-DD');
        endDate = moment().add(90, 'days').format('YYYY-MM-DD');
        break;
      case 'year':
        startDate = moment().startOf('year').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
        break;
      case 'all':
        startDate = '';
        endDate = '';
        break;
      default:
    }

    return { startDate, endDate };
  };

  const getBillBalance = async () => {
    try {
      const { startDate, endDate } = getDatesForRange(selectedPayableRange);
      const res = await getPayableBills({
        startDate,
        endDate,
        project: project?.id,
      });
      setBillBalance(res?.data);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getInvoiceBalance = async () => {
    try {
      const { startDate, endDate } = getDatesForRange(selectedReceivableRange);
      const res = await getReceivableInvoices({
        startDate,
        endDate,
        project: project?.id,
      });
      setInvoiceBalance(res?.data);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getTransactionsApi = async ({ page = 1, reload = true }) => {
    try {
      const res = await getTransactions({ page, projects: project.id });
      if (reload) {
        getOverviewTransactionsApi();
      }
      setTransactions(res);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getBillsApi = async (reload = true) => {
    try {
      const res = await getAllBills({});
      setAllBills(res?.bills);
      const projectBills = res?.bills.filter(
        (bill) => bill.projectId === project.id,
      );
      setBills(projectBills);
      if (reload) {
        getBillBalance();
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const getAccountsApi = async () => {
    try {
      const res = await getAccounts();
      setAccounts(transformAccounts(res, 'PHYSICAL_ACCOUNT'));
      setAllCategories(transformAccounts(res, 'CATEGORY_PHYSICAL_ACCOUNT'));
      setAllExpenseCategories(
        transformAccounts(res, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
    } catch (e) {
      console.log('error', e);
    }
  };

  const getTagsApi = async () => {
    try {
      const res = await getTags();
      setTags(res);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getOverviewTransactionsApi = async () => {
    try {
      const res = await getProjectTransactionSummary(project?.id);
      setOverviewTransactionData(res?.data);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getVendorsApi = async () => {
    try {
      const res = await getMerchants({});
      setVendors(res?.vendors);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getProductsApi = async () => {
    try {
      const res = await getProducts({ limit: 10000 });
      const filteredProducts = res?.message?.products.filter(
        (product) => product?.timeEntryTask,
      );
      setProducts(filteredProducts);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getPeopleApi = async () => {
    try {
      const res = await getPeople({
        enabled: true,
        roles: 'time tracking',
        limit: 1000000,
      });
      setPeople(res?.people);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getTimeInsightsApi = async () => {
    try {
      const res = await getTimeInsights(project?.id);
      const billable = parseFloat(res?.data?.billableMinutes);
      const nonBillable = parseFloat(res?.data?.nonBillableMinutes);
      setBillableHours(billable);
      setNonBillableHours(nonBillable);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getAllTimeEntriesApi = async () => {
    try {
      const res = await getAllTimeEntries(project?.id);
      getProjectStatsByIdApi();
      const groupedEntries = groupByDate(res?.timeEntries);
      setTimeEntries(groupedEntries);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getTaxesApi = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleAddTax = async (tax) => {
    await createTax(tax);
    toast.success('Tax added successfully');
    getTaxesApi();
  };

  const handleUpdateTimeEntry = async (entry) => {
    try {
      await updateTimeEntry(entry?.id, entry);
      await getAllTimeEntriesApi();
      await getTimeInsightsApi();
      toast.success('Time entry updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateTimeEntry = async (data) => {
    try {
      const res = await createTimeEntry(data);
      getTimeInsightsApi();
      getAllTimeEntriesApi();
      toast.success('Time entry added successfully');
    } catch (e) {
      console.log('error', e);
    }
  };

  const groupByDate = (totalEntries) => {
    const grouped = totalEntries.reduce((acc, entry) => {
      const dateKey = moment
        .utc(entry.date)
        .startOf('day')
        .format('YYYY-MM-DD');

      if (!acc[dateKey]) {
        acc[dateKey] = {
          date: dateKey,
          totalMinutes: 0,
          entries: [],
          totalBillableAmount: 0,
        };
      }

      acc[dateKey].totalMinutes += entry.minutes;
      acc[dateKey].entries.push(entry);

      if (entry?.isBillable && entry?.productService?.unitPrice) {
        acc[dateKey].totalBillableAmount +=
          parseFloat(entry.productService.unitPrice) * (entry.minutes / 60);
      }

      return acc;
    }, {});

    const today = moment.utc().startOf('day').format('YYYY-MM-DD');
    const result = Object.values(grouped).sort((a, b) =>
      moment.utc(a.date).diff(moment.utc(b.date)),
    );
    result.reverse();

    result.forEach((group) => {
      if (group.date === today) {
        group.date = 'Today';
      }
      group.entries.sort((a, b) =>
        moment.utc(b.createdAt).diff(moment.utc(a.createdAt)),
      );
    });

    return result;
  };

  const handlePopupLogic = (category, transaction) => {
    setPopupCategory(category);
    setPopupTransaction(transaction);
    setPopupOpen(true);
  };

  const findSplitParent = async (transaction) => {
    const res = await getSingleTransaction(transaction?.splitParentId);
    setSelectedRow(res);
    return res;
  };

  const deleteTransactions = async () => {
    const data = {
      ids: deleteTransactionModal,
    };
    try {
      setDeleteModalLoading(true);
      await deleteTransactionsByIDs(data);
      await getTransactionsApi({ scrollToTop: false });
      toast.success('Transactions deleted successfully.');
      setDeleteTransactionModal('');
      setDeleteModalLoading(false);
    } catch (err) {
      if (err && err.includes('split transaction')) {
        const splitTran = await getSingleTransaction(data.ids[0]);
        if (splitTran?.splitParentId) {
          const parent = await findSplitParent(splitTran);
          setDeleteModalParent(parent);
        } else {
          setDeleteModalParent(splitTran);
        }
        setDeleteTransactionModal('split');
      } else {
        setDeleteTransactionModal('error');
      }
      setDeleteModalLoading(false);
      console.log('err', err);
    }
  };

  const assignTransactionToInvoice = async (invoiceId, transactionId) => {
    setCategoryLoading((prevState) => ({
      ...prevState,
      [transactionId]: true,
    }));
    try {
      await assignInvoiceToBill(invoiceId, { transactions: [transactionId] });
      getInvoicesApi();
      getEstimatesApi();
      await getTransactionsApi({ scrollToTop: false });
      toast.success('Transaction assigned to invoice successfully.');
    } catch (e) {
      toast.error('An error occurred while assigning transaction to invoice.');
    }
    setCategoryLoading((prevState) => ({
      ...prevState,
      [transactionId]: false,
    }));
  };

  const removeTransactionFromInvoice = async (invoiceId, transactionId) => {
    try {
      await deAssignTransToInvoice(invoiceId, { transactionId });
      toast.success('Transaction removed from invoice successfully.');
      getInvoicesApi();
      getEstimatesApi();
      await getTransactionsApi({ scrollToTop: false });
    } catch (e) {
      toast.error('An error occurred while removing transaction from invoice.');
    }
  };

  const removeTransactionFromBill = async (billId, instanceId, data) => {
    try {
      await unassignTransactionToBill(billId, instanceId, data);
      toast.success('Transaction removed from bill successfully.');
      getBillsApi();
      await getTransactionsApi({ scrollToTop: false });
    } catch (e) {
      toast.error('An error occurred while removing transaction from bill.');
      console.log('error', e);
    }
  };

  const assignTransactionToBillApi = async (
    billId,
    instance,
    transactionId,
  ) => {
    try {
      const res = await assignTransactionToBill(billId, instance, {
        transactionId,
      });
      await getBillsApi();
      await getTransactionsApi({ scrollToTop: false });
      if (res?.id) {
        toast.success('Transaction assigned to bill successfully.');
      }
    } catch (e) {
      toast.error('An error occurred while assigning transaction to bill.');
    }
  };

  const handleDeleteBill = async (id) => {
    try {
      await deleteBillById(id);
      await getBillsApi();
      toast.success('Bill deleted successfully');
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleBillTransactionAssignment = async (
    transactionId = activeTransaction?.id,
  ) => {
    try {
      const res = await assignTransactionToBill(
        activeBill?.id,
        activeInstance?.id,
        {
          transactionId,
        },
      );
      getBillsApi();
      getTransactionsApi({});
      return res;
    } catch (err) {
      console.log('err', err);
    }
  };

  const updateInstanceTotal = async (total) => {
    const data = {
      total,
    };

    try {
      const res = await updateInstanceById(
        activeBill?.id,
        activeInstance?.id,
        data,
      );
      return res;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleAddProduct = async (data) => {
    try {
      const response = await createProduct(data);
      if (timeTrackingModalOpen) {
        setCreatedProduct(response?.data?.productService);
      }
      getProductsApi();
      return response;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleAddPeople = async (data) => {
    try {
      const response = await createPeople(data);
      if (timeTrackingModalOpen) {
        setCreatedPeople(response?.data?.people);
      }
      getPeopleApi();
      return response;
    } catch (err) {
      console.log('err', err);
    }
  };

  const formatMinutes = (totalMinutes) => {
    if (!totalMinutes) return '00:00';
    const hours = String(Math.floor(totalMinutes / 60)).padStart(2, '0');
    const minutes = String(totalMinutes % 60).padStart(2, '0');

    return `${hours}:${minutes}`;
  };

  const handleUpdateProject = async (values, status = false, notes = false) => {
    try {
      const { startDate, endDate, customId, ...rest } = values;
      const data = { ...rest };
      if (startDate) {
        data.startDate = getFormattedDate(startDate, teamDateFormat);
      } else {
        data.startDate = null;
      }

      if (endDate) {
        data.endDate = getFormattedDate(endDate, teamDateFormat);
      } else {
        data.endDate = null;
      }
      if (status === true) {
        await updateProject(project?.id, data);
        await getProjectByIdApi();
      } else if (notes === true) {
        await updateProject(project?.id, data);
        await getProjectByIdApi();
      } else {
        setModalLoading(true);

        if (customId) {
          data.customId = customId?.toUpperCase();
        } else {
          data.customId = null;
        }

        await updateProject(project?.id, data);
        await getProjectByIdApi();
        setModalLoading(false);
        setCreateModalOpen(false);
      }
      toast.success('Project updated successfully');
    } catch (error) {
      console.error(error);
    } finally {
      setModalLoading(false);
    }
  };

  const handleDeleteProject = async () => {
    try {
      const res = await deleteProject(project?.id);
      if (res?.data?.data?.result?.success) {
        toast.success('Project deleted successfully');
        navigate('/projects');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getJournalEntriesApi = async ({
    limit = localLimit,
    page = journalentries?.page,
    search = searchValue || '',
    loading = false,
    accounts = selectedAccount || [],
    accountTypes = ['Expenses'],
    startDate = startDateFilter
      ? getFormattedDate(startDateFilter, teamDateFormat)
      : '',
    endDate = endDateFilter
      ? getFormattedDate(endDateFilter, teamDateFormat)
      : '',
  }) => {
    if (journalentriesLoading) {
      setJournalEntriesLoading(true);
    }
    try {
      const res = await getJournalEntries({
        page,
        search,
        limit,
        accounts,
        startDate,
        endDate,
        accountType: accountTypes,
        projects: [id],
      });
      const temp = [];
      if (res?.data?.results?.length > 0) {
        const groupedObjects = res?.data?.results.reduce((acc, obj) => {
          const key = obj.journalLinkUuid;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});

        // Convert the object of arrays to an array of arrays
        const groupedArrays = Object.values(groupedObjects).map((group) => {
          const debits = group.filter((obj) => obj.amountDebit !== null);
          const credits = group.filter((obj) => obj.amountDebit === null);
          const groupObj = { data: debits.concat(credits) };
          groupObj.debitTotal =
            debits?.reduce?.(
              (acc, obj) => acc + parseFloat(obj?.amountDebit),
              0,
            ) || 0;
          groupObj.creditTotal =
            credits?.reduce?.(
              (acc, obj) => acc + parseFloat(obj?.amountCredit),
              0,
            ) || 0;
          return groupObj;
        });
        res.data.results = groupedArrays;
      }
      setJournalEntries(res?.data);
      setIsReset(false);
      if (journalentriesLoading) {
        setJournalEntriesLoading(false);
      }
    } catch (e) {
      if (journalentriesLoading) {
        setJournalEntriesLoading(false);
      }
      console.log('error', e);
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteProjectModalOpen}
        setIsOpen={setDeleteProjectModalOpen}
        projectName={project?.name || ''}
        handleDone={handleDeleteProject}
      />
      <AddProjectModal
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
        customers={customers}
        setCustomers={setCustomers}
        customerModalOpen={customerModalOpen}
        setCustomerModalOpen={setCustomerModalOpen}
        handleSubmit={handleUpdateProject}
        selectedCustomer={selectedCustomer}
        modalLoading={modalLoading}
        selectedStatus={selectedStatus}
        statusOptions={statusOptions}
        project={project}
        setDeleteModalOpen={setDeleteProjectModalOpen}
      />
      <AddCustomerModal
        isOpen={customerModalOpen}
        setIsOpen={setCustomerModalOpen}
        setSelectedCustomer={(newCustomer) => {
          setCustomers((prev) => [newCustomer, ...prev]);
          setSelectedCustomer(newCustomer);
        }}
        salesRep={salesRep}
      />

      <AddTimeModal
        modalOpen={timeTrackingModalOpen}
        setModalOpen={setTimeTrackingModalOpen}
        project={project}
        projects={project?.id ? [project] : []}
        products={products}
        people={people}
        handleCreateTimeEntry={handleCreateTimeEntry}
        activeEntry={activeEntry}
        setActiveEntry={setActiveEntry}
        handleUpdateTimeEntry={handleUpdateTimeEntry}
        productModalOpen={productModalOpen}
        setProductModalOpen={setProductModalOpen}
        peopleModalOpen={peopleModalOpen}
        setPeopleModalOpen={setPeopleModalOpen}
        currencies={currencies}
        createdProduct={createdProduct}
        createdPeople={createdPeople}
        setCreatedProduct={setCreatedProduct}
        setCreatedPeople={setCreatedPeople}
      />
      <DeleteTransactionModal
        isOpen={deleteTransactionModal}
        setIsOpen={setDeleteTransactionModal}
        deleteTransactionModal={deleteTransactionModal}
        deleteModalLoading={deleteModalLoading}
        setDeleteTransactionModal={setDeleteTransactionModal}
        deleteTransactions={deleteTransactions}
        deleteModalParent={deleteModalParent}
        setDeleteModalParent={setDeleteModalParent}
      />
      <AddTaskModal
        modalOpen={taskModalOpen || billTaskModal}
        setModalOpen={taskModalOpen ? setTaskModalOpen : setBillTaskModal}
        transactions={!!taskModalOpen}
        bills={!!billTaskModal}
        selectedOption={selectedTaskOption}
        setSelectedOption={setSelectedTaskOption}
        selectedTransaction={selectedRow}
        projects={projects}
      />
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt}
      />
      <CreateRuleModal
        open={ruleModalOpen}
        setOpen={setRuleModalOpen}
        category={popupCategory}
        transaction={popupTransaction}
        reloadTransactions={getTransactionsApi}
      />
      <InstancesModal
        isOpen={instancesModalOpen}
        setIsOpen={setInstancesModalOpen}
        instances={activeBill?.id > -1 ? activeBill?.instances : []}
        setActiveInstance={setActiveInstance}
        setTransactionsModalOpen={setTransactionsModalOpen}
        billId={activeBill?.id}
        reloadData={() => {
          getBillsApi();
          getTransactionsApi({});
        }}
        activeBill={activeBill || {}}
        currencies={currencies}
        activeInstance={activeInstance}
      />
      <TransactionsModal
        isOpen={transactionsModalOpen}
        setIsOpen={setTransactionsModalOpen}
        activeTransaction={activeTransaction}
        activeInstance={activeInstance}
        setActiveTransaction={setActiveTransaction}
        handleTransactionAssignment={handleBillTransactionAssignment}
        updateInstanceTotal={updateInstanceTotal}
        allCategories={allCategories}
        currencies={currencies}
        activeBill={activeBill || {}}
        setActiveBill={setActiveBill}
        loadAllBills={getBillsApi}
      />
      <BillDetails
        allCategories={allCategories}
        vendors={vendors}
        setVendors={setVendors}
        activeBillId={activeBillId}
        setActiveBillId={setActiveBillId}
        loadAllBills={getBillsApi}
        setIsActive={setIsActive}
        currencies={currencies}
        team={team}
        expenseCategories={allExpenseCategories}
        isOpen={billDetails}
        setIsOpen={setBillDetails}
      />
      <ModalBasic
        modalOpen={productModalOpen}
        setModalOpen={setProductModalOpen}
        title="Create Product or Service"
        width="!w-[1088px]"
      >
        <ProductForm
          setFormOpen={setProductModalOpen}
          setTitle={() => {}}
          currencies={currencies}
          taxes={taxes}
          setTaxes={setTaxes}
          incomeCategories={allCategories}
          expenseCategories={allExpenseCategories}
          handleAddProduct={handleAddProduct}
          isModalView
          team={team}
          handleAddTax={handleAddTax}
          projectsPage
        />
      </ModalBasic>
      <ModalBasic
        modalOpen={peopleModalOpen}
        setModalOpen={setPeopleModalOpen}
        title="Create Person"
        width="!w-[1200px]"
      >
        <PeopleForm
          isModalView
          handleAddPeople={handleAddPeople}
          setFormOpen={setPeopleModalOpen}
          team={team}
        />
      </ModalBasic>
      {popupOpen && (
        <CreateRulePopup
          open={popupOpen}
          setOpen={setPopupOpen}
          category={popupCategory}
          transaction={popupTransaction}
          openModal={setRuleModalOpen}
        />
      )}

      <AppLayout pageId="projectDetailsContainer">
        <main className="relative grow ">
          {!mainLoading &&
            (selectedRow?.id || selectedRow?.transaction?.id) && (
              <TransactionDetails
                transactionPanelOpen={transactionPanelOpen}
                setTransactionPanelOpen={setTransactionPanelOpen}
                transaction={
                  selectedRow?.transaction?.id
                    ? selectedRow?.transaction
                    : selectedRow
                }
                setTransaction={setSelectedRow}
                reloadTransaction={
                  selectedRow?.transaction?.id
                    ? getJournalEntriesApi
                    : getTransactionsApi
                }
                tags={tags}
                allCategories={allCategories}
                setAllCategories={setAllCategories}
                setAllExpenseCategories={setAllExpenseCategories}
                vendors={vendors || []}
                setVendors={setVendors}
                accessType={accessType}
                setSplitTransactionModalOpen={setSplitTransactionModalOpen}
                receiptModalOpen={receiptModalOpen}
                setReceiptModalOpen={setReceiptModalOpen}
                setActiveReceipt={setActiveReceipt}
                currentTab={currentTransDetailTab}
                setCurrentTab={setCurrentTransDetailTab}
                accounts={accounts}
                setFromDropdown={setFromDropdown}
                fromDropdown={fromDropdown}
                findSplitParent={findSplitParent}
                allExpenseCategories={allExpenseCategories}
                localUser={localUser}
                customers={customers}
                setCustomers={setCustomers}
                ruleModalOpen={ruleModalOpen}
                setRuleModalOpen={setRuleModalOpen}
                setPopupOpen={setPopupOpen}
                setPopupCategory={setPopupCategory}
                setPopupTransaction={setPopupTransaction}
                handlePopupLogic={handlePopupLogic}
                taskModalOpen={taskModalOpen}
                setTaskModalOpen={setTaskModalOpen}
                invoices={invoices}
                assignTransactionToInvoice={assignTransactionToInvoice}
                currencies={currencies}
                removeTransactionFromInvoice={removeTransactionFromInvoice}
                allInvoices={allInvoices}
                allBills={allBills}
                bills={bills}
                assignTransactionToBillApi={assignTransactionToBillApi}
                removeTransactionFromBill={removeTransactionFromBill}
                projects={project?.id ? [project] : []}
                projectPage
                team={team}
                taxes={taxes}
                loadTaxes={getTaxesApi}
              />
            )}
          {/* Content */}
          {!(isActive === CREATE_INVOICE || isActive === EDIT_INVOICE) && (
            <div className="relative px-4 sm:px-6 lg:px-6 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
              {mainLoading ? (
                <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                  <Loader />
                  <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                    Fetching data, please wait...
                  </p>
                </div>
              ) : (
                <>
                  <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between">
                    <div className="flex items-center gap-5">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigate('/projects');
                        }}
                      >
                        <BackBtn />
                      </div>
                      <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                        {project?.name}
                      </h1>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 items-start sm:items-center">
                      {false ? (
                        <div className="w-full flex">
                          <Loader width="w-6" height="h-6" />
                        </div>
                      ) : (
                        <div
                          className="flex p-2 justify-center w-[8rem] items-center gap-2.5 rounded-[22px] text-[14px] leading-6 relative cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (accessType !== VIEWER) {
                              setStatusDropdownOpen(!statusDropdownOpen);
                            }
                          }}
                          style={{
                            backgroundColor: statusOptions.find(
                              (option) => option.id === project?.status,
                            )?.background,
                            color: statusOptions.find(
                              (option) => option.id === project?.status,
                            )?.textColor,
                          }}
                          ref={trigger}
                        >
                          <div className="flex items-center gap-2">
                            <p>
                              {
                                statusOptions.find(
                                  (option) => option.id === project?.status,
                                )?.name
                              }
                            </p>
                            {accessType !== VIEWER && (
                              <StatusDropdownArrow
                                fill={
                                  statusOptions.find(
                                    (option) => option.id === project?.status,
                                  )?.textColor
                                }
                              />
                            )}
                            <Transition
                              show={statusDropdownOpen}
                              tag="div"
                              className="z-60 absolute top-10 left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-visible mt-1"
                              enter="transition ease-out duration-100 transform"
                              enterStart="opacity-0 -translate-y-2"
                              enterEnd="opacity-100 translate-y-0"
                              leave="transition ease-out duration-100"
                              leaveStart="opacity-100"
                              leaveEnd="opacity-0"
                            >
                              {statusDropdownOpen && (
                                <div
                                  ref={statusDropdownRef}
                                  className="divide-y divide-slate-200 dark:divide-slate-700 z-60 "
                                >
                                  <div className="max-h-48 overflow-auto scrollbar rounded-[22px] z-60">
                                    {statusOptions.map((option, index) => (
                                      <button
                                        type="button"
                                        key={index}
                                        className="flex items-center justify-between w-[80%] rounded-[22px] px-2 my-2 mx-auto cursor-pointer"
                                        style={{
                                          backgroundColor: option.background,
                                          color: option.textColor,
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleUpdateProject(
                                            {
                                              ...project,
                                              status: option?.id,
                                            },
                                            true,
                                          );
                                          setStatusDropdownOpen(null);
                                        }}
                                      >
                                        {option?.name}
                                      </button>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </Transition>
                          </div>
                        </div>
                      )}
                      {accessType !== VIEWER && (
                        <ActionsDropdown
                          project
                          setProjectModalOpen={setCreateModalOpen}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between mb-10 mt-1">
                    <div className="flex items-center gap-2">
                      <div className="w-[33px] mr-3" />
                      <p className="text-slate-600 text-lg font-normal">
                        {project?.customer?.customer}
                        {project?.startDate || project?.endDate ? ' | ' : ''}
                      </p>
                      {(project?.startDate || project?.endDate) && (
                        <p className="text-slate-600 text-lg font-normal -ml-1">
                          {project?.startDate &&
                            moment
                              .utc(project.startDate)
                              .format('MMM DD, YYYY')}
                          {project?.startDate && project?.endDate && ' - '}
                          {project?.endDate &&
                            moment.utc(project.endDate).format('MMM DD, YYYY')}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex items-start justify-between mt-2.5">
                    <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-4 flex-1">
                      {tabs?.map((tab) => (
                        <div
                          onClick={() => {
                            setActiveTab(tab);
                            setSearchParams({ tab: tab?.toLowerCase() });
                          }}
                          role="button"
                          tabIndex="0"
                          key={tab}
                          className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === tab &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          } `}
                        >
                          {tab}
                        </div>
                      ))}
                    </div>
                  </div>

                  {activeTab === 'Overview' &&
                    (overviewLoading ? (
                      <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                        <Loader />
                        <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                          Fetching data, please wait...
                        </p>
                      </div>
                    ) : (
                      <OverviewTab
                        selectedPayableRange={selectedPayableRange}
                        setSelectedPayableRange={setSelectedPayableRange}
                        selectedReceivableRange={selectedReceivableRange}
                        setSelectedReceivableRange={setSelectedReceivableRange}
                        invoiceBalance={invoiceBalance}
                        billBalance={billBalance}
                        noteEditing={noteEditing}
                        setNoteEditing={setNoteEditing}
                        project={project}
                        team={team}
                        currencies={currencies}
                        accessType={accessType}
                        handleUpdateProject={handleUpdateProject}
                        setTimeTrackingModalOpen={setTimeTrackingModalOpen}
                        billableHours={billableHours}
                        nonBillableHours={nonBillableHours}
                        formatMinutes={formatMinutes}
                        overviewTransactionData={overviewTransactionData}
                      />
                    ))}

                  {activeTab === 'Time' &&
                    (timeLoading ? (
                      <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                        <Loader />
                        <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                          Fetching data, please wait...
                        </p>
                      </div>
                    ) : (
                      <TimeTab
                        timeEntries={timeEntries}
                        project={project}
                        billableHours={billableHours}
                        nonBillableHours={nonBillableHours}
                        accessType={accessType}
                        setTimeTrackingModalOpen={setTimeTrackingModalOpen}
                        handleUpdateTimeEntry={handleUpdateTimeEntry}
                        activeEntry={activeEntry}
                        setActiveEntry={setActiveEntry}
                        formatMinutes={formatMinutes}
                        timeTrackingModalOpen={timeTrackingModalOpen}
                        team={team}
                        currencies={currencies}
                        projectStat={projectStat}
                      />
                    ))}
                  {activeTab === 'Expenses' && (
                    <ProjectExpense
                      allCategories={allCategories}
                      setSelectedRow={setSelectedRow}
                      selectedAccount={selectedAccount}
                      setSelectedAccount={setSelectedAccount}
                      isReset={isReset}
                      setIsReset={setIsReset}
                      startDateFilter={startDateFilter}
                      setStartDateFilter={setStartDateFilter}
                      endDateFilter={endDateFilter}
                      setEndDateFilter={setEndDateFilter}
                      getData={getJournalEntriesApi}
                      journalentries={journalentries}
                      setJournalEntries={setJournalEntries}
                      isLoading={journalentriesLoading}
                      setIsLoading={setJournalEntriesLoading}
                      setFlyoutOpen={setTransactionPanelOpen}
                      flyoutOpen={transactionPanelOpen}
                      localLimit={localLimit}
                      setLocalLimit={setLocalLimit}
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                    />
                  )}
                  {activeTab === 'Invoices' &&
                    (invoicesLoading ? (
                      <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                        <Loader />
                        <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                          Fetching data, please wait...
                        </p>
                      </div>
                    ) : (
                      <InvoiceTab invoices={invoices} estimates={estimates} />
                    ))}
                </>
              )}
            </div>
          )}
        </main>
      </AppLayout>
    </>
  );
};

export default ProjectDetails;
