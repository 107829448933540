import {
  generateTimeSheetReports,
  getAccounts,
  getAccountSystemBalance,
  getAllProjects,
  getConnections,
  getCustomers,
  getDashboard,
  getExpenseReporterTrans,
  getMerchants,
  getPayableBills,
  getReceivableInvoices,
  getRecurringTransactions,
  getTags,
  getTasks,
  getTaxes,
  getTeamStats,
  getTransactions,
} from 'API/backend_helper';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  dateCategoryListWithWeeks,
  getFormattedDate,
  TEAM_DATE_FORMAT,
  TEAM_ID,
  transformAccounts,
} from 'utils/Utils';

const useOverviewState = () => {
  const ChartColors = [
    '#F68D2B',
    '#F4A79D',
    '#FFD200',
    '#344BFD',
    '#4B5D67',
    '#92A8D1',
    '#88B04B',
    '#F7CAC9',
    '#6B5B95',
    '#E3E3E3',
    '#9E9E9E',
    '#AAB6A2',
  ];
  const { team } = useSelector((state) => state.Team);

  const teamId = team?.id || localStorage.getItem(TEAM_ID);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const DefaultYear = moment().format('MMMM,YYYY');

  const [overviewLoadings, setOverviewLoadings] = useState({
    connectedAccounts: false,
    countBalances: false,
    recurringTransactions: false,
    tasks: false,
    projects: false,
    peopleHours: false,
    expenseClaims: false,
    invoiceBalance: false,
    billBalance: false,
    expenses: false,
    income: false,
    transactions: false,
    cashFlow: false,
    cashStat: false,
  });
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [accountsCountBalances, setAccountsCountBalances] = useState([]);
  const [recurringTransactions, setRecurringTransactions] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [peopleHoursData, setPeopleHoursData] = useState([]);
  const [peopleHourTimePeriod, setPeopleHourTimePeriod] = useState('this_week');
  const [expenseClaims, setExpenseClaims] = useState([]);
  const [invoiceBalance, setInvoiceBalance] = useState({});
  const [billBalance, setBillBalance] = useState({});
  const [selectedExpensesYear, setSelectedExpensesYear] = useState(DefaultYear);
  const [expensesData, setExpensesData] = useState({});
  const [selectedIncomeYear, setSelectedIncomeYear] = useState(DefaultYear);
  const [incomeData, setIncomeData] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [activeTransaction, setActiveTransaction] = useState({});
  const [transactionPanelOpen, setTransactionPanelOpen] = useState(false);

  const [cashFlowData, setCashFlowData] = useState({});
  const [cashStat, setCashStat] = useState({});

  // Transactions Panel Data
  const [tags, setTags] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [fromDropdown, setFromDropdown] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allExpenseCategories, setAllExpenseCategories] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [currentTransDetailTab, setCurrentTransDetailTab] = useState('details');

  const getAccountCountBalance = async (accountIds) => {
    setOverviewLoadings((prev) => ({
      ...prev,
      countBalances: true,
    }));
    try {
      const response = await getAccountSystemBalance(accountIds);
      setAccountsCountBalances(response);
    } catch (error) {
      console.error(`Error fetching account system balance from :`, error);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        countBalances: false,
      }));
    }
  };

  const getConnectionsApi = async (loading = false) => {
    if (loading) {
      setOverviewLoadings((prev) => ({
        ...prev,
        connectedAccounts: true,
      }));
    }

    try {
      const response = await getConnections();
      setConnectedAccounts(response?.reverse?.());
      const Ids = [];
      response?.forEach((connection) =>
        connection?.accounts?.forEach(
          (acc) => acc?.status !== 'inactive' && Ids?.push(acc?.id),
        ),
      );
      getAccountCountBalance(Ids);
      return response;
    } catch (err) {
      console.log('err', err);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        connectedAccounts: false,
      }));
    }
  };

  const fetchRecurringTransactions = async (loading = false) => {
    if (loading) {
      setOverviewLoadings((prev) => ({
        ...prev,
        recurringTransactions: true,
      }));
    }
    try {
      const res = await getRecurringTransactions();
      setRecurringTransactions(res.data);
    } catch (error) {
      console.error('Error', error);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        recurringTransactions: false,
      }));
    }
  };

  const loadTasks = async (loading = false) => {
    if (loading) {
      setOverviewLoadings((prev) => ({
        ...prev,
        tasks: true,
      }));
    }
    try {
      const res = await getTasks({
        page: 1,
        limit: 3,
        status: 'inProgress,review,notStarted',
      });
      setTasks(res?.tasks);
    } catch (err) {
      console.log('err', err);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        tasks: false,
      }));
    }
  };

  const loadProjects = async (loading = false) => {
    if (loading) {
      setOverviewLoadings((prev) => ({
        ...prev,
        projects: true,
      }));
    }
    try {
      const res = await getAllProjects({});
      setProjects(res);
    } catch (err) {
      console.log('err', err);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        projects: false,
      }));
    }
  };

  const findDateType = (data, searchId) =>
    data.reduce((found, item) => {
      if (found) return found;
      return (
        item.categories.find((category) => category.id === searchId) || null
      );
    }, null);

  const loadPeopleHourData = async (startDate, endDate) => {
    setOverviewLoadings((prev) => ({
      ...prev,
      peopleHours: true,
    }));
    try {
      const response = await generateTimeSheetReports({
        startDate: getFormattedDate(startDate, teamDateFormat),
        endDate: getFormattedDate(endDate, teamDateFormat),
      });
      setPeopleHoursData(response?.data);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        peopleHours: false,
      }));
    }
  };

  const handlePeopleTimePeriodChange = (value) => {
    setPeopleHourTimePeriod(value);

    const obj = findDateType(dateCategoryListWithWeeks(), value);
    if (obj) {
      loadPeopleHourData(obj?.startDate, obj?.endDate);
    } else {
      loadPeopleHourData(null, null);
    }
  };

  const loadExpenseClaims = async (loading = false) => {
    if (loading) {
      setOverviewLoadings((prev) => ({
        ...prev,
        expenseClaims: true,
      }));
    }

    try {
      const res = await getExpenseReporterTrans({
        page: 1,
        limit: 3,
        type: 'Submitted',
      });
      setExpenseClaims(res?.data?.expenseReport);
    } catch (err) {
      console.log('err', err);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        expenseClaims: false,
      }));
    }
  };

  const loadInvoiceBalance = async (loading = false) => {
    if (loading) {
      setOverviewLoadings((prev) => ({
        ...prev,
        invoiceBalance: true,
      }));
    }
    try {
      const res = await getReceivableInvoices({});
      setInvoiceBalance(res?.data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        invoiceBalance: false,
      }));
    }
  };

  const loadBillBalance = async (loading = false) => {
    if (loading) {
      setOverviewLoadings((prev) => ({
        ...prev,
        billBalance: true,
      }));
    }

    try {
      const res = await getPayableBills({});
      setBillBalance(res?.data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        billBalance: false,
      }));
    }
  };

  const getExpensesData = async ({
    year = selectedExpensesYear,
    loading = true,
  }) => {
    if (loading) {
      setOverviewLoadings((prev) => ({
        ...prev,
        expenses: true,
      }));
    }
    try {
      const res = await getDashboard(
        year?.split(',')?.[1],
        year?.split(',')?.[0]
          ? moment(year?.split(',')?.[0], 'MMMM').format('M')
          : '',
      );
      setExpensesData(res?.data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        expenses: false,
      }));
    }
  };

  const handleExpenseMonthChange = (value) => {
    setSelectedExpensesYear(value);
    getExpensesData({ year: value });
  };

  const getIncomeData = async ({
    year = selectedIncomeYear,
    loading = true,
  }) => {
    if (loading) {
      setOverviewLoadings((prev) => ({
        ...prev,
        income: true,
      }));
    }
    try {
      const res = await getDashboard(
        year?.split(',')?.[1],
        year?.split(',')?.[0]
          ? moment(year?.split(',')?.[0], 'MMMM').format('M')
          : '',
      );
      setIncomeData(res?.data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        income: false,
      }));
    }
  };

  const handleIncomeMonthChange = (value) => {
    setSelectedIncomeYear(value);
    getIncomeData({ year: value });
  };

  const loadTransactions = async ({ loading = false }) => {
    if (loading) {
      setOverviewLoadings((prev) => ({
        ...prev,
        transactions: true,
      }));
    }
    try {
      const transRes = await getTransactions({
        limit: 5,
        orderBy: 'date',
      });
      setTransactions(transRes?.transactions);
    } catch (e) {
      console.log('error', e);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        transactions: false,
      }));
    }
  };

  // Transactions panel Data
  const loadTags = async () => {
    try {
      const resTags = await getTags();
      setTags(resTags);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadVendors = async () => {
    try {
      const res = await getMerchants({});
      setVendors(res?.vendors);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadCustomers = async () => {
    try {
      const res = await getCustomers({});
      setCustomers(res?.data?.records);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadCategories = async () => {
    try {
      const accountsRes = await getAccounts();
      setAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
      setAllCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
      );
      setAllExpenseCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadTransactionPanelData = async () => {
    try {
      await Promise.all([
        loadTags(),
        loadVendors(),
        loadCustomers(),
        loadTaxes(),
        loadCategories(),
      ]);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadCashFlow = async ({
    year = selectedIncomeYear,
    loading = true,
  }) => {
    if (loading) {
      setOverviewLoadings((prev) => ({
        ...prev,
        cashFlow: true,
      }));
    }
    try {
      const res = await getDashboard(year?.split(',')?.[1], '');
      setCashFlowData(res?.data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        cashFlow: false,
      }));
    }
  };

  const loadStats = async () => {
    setOverviewLoadings((prev) => ({
      ...prev,
      cashStat: true,
    }));

    try {
      const res = await getTeamStats(teamId);
      setCashStat(res?.data?.stat);
    } catch (err) {
      console.log('err', err);
    } finally {
      setOverviewLoadings((prev) => ({
        ...prev,
        cashStat: false,
      }));
    }
  };

  const loadData = async () => {
    const obj = findDateType(dateCategoryListWithWeeks(), 'this_week');
    try {
      const responses = await Promise.all([
        getConnectionsApi(true),
        fetchRecurringTransactions(true),
        loadTasks(true),
        loadProjects(true),
        loadPeopleHourData(obj?.startDate, obj?.endDate),
        loadExpenseClaims(true),
        loadInvoiceBalance(true),
        loadBillBalance(true),
        getExpensesData({}),
        getIncomeData({}),
        loadTransactions({ loading: true }),
        loadCashFlow({ loading: true }),
        loadStats(),
      ]);
      loadTransactionPanelData();
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return {
    overviewLoadings,
    setOverviewLoadings,
    getConnectionsApi,
    connectedAccounts,
    accountsCountBalances,
    recurringTransactions,
    tasks,
    projects,
    peopleHoursData,
    peopleHourTimePeriod,
    handlePeopleTimePeriodChange,
    expenseClaims,
    loadExpenseClaims,
    invoiceBalance,
    billBalance,
    expensesData,
    selectedExpensesYear,
    handleExpenseMonthChange,
    ChartColors,
    selectedIncomeYear,
    incomeData,
    handleIncomeMonthChange,
    transactions,
    activeTransaction,
    setActiveTransaction,
    transactionPanelOpen,
    setTransactionPanelOpen,
    loadTransactions,
    cashFlowData,
    cashStat,
    // Transaction Panel Data
    activeReceipt,
    receiptModalOpen,
    tags,
    allCategories,
    vendors,
    setVendors,
    setReceiptModalOpen,
    setActiveReceipt,
    currentTransDetailTab,
    setCurrentTransDetailTab,
    accounts,
    setFromDropdown,
    fromDropdown,
    allExpenseCategories,
    customers,
    taxes,
    loadTaxes,
  };
};

export default useOverviewState;
