/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState, useMemo, useContext } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import CategoriesDropdown from '../../../transactions/CategoriesDropdown';
import {
  generateReports,
  getAccounts,
  getTags,
} from '../../../../API/backend_helper';
import {
  dateCategoryList,
  formatReportData,
  transformAccounts,
  formatValueWithSign,
  getFormattedDate,
  TEAM_CURRENCY,
  TEAM_DATE_FORMAT,
  formatDateLocally,
  DevEnv,
  PnlNoTagValue,
} from '../../../../utils/Utils';
import BasicCheckboxDropdown from '../../../component/BasicCheckboxDropdown';
import { TableHeader } from '../TableHeader';
import { CalendarIcon } from '../CalendarIcon';
import { DecreaseIcon } from '../DecreaseIcon';
import { IncreaseIcon } from '../IncreaseIcon';
import DateFilter from '../../../transactions/DateFilter';
import CategoriesFilter from '../../../transactions/Filters/CategoriesFilter';
import ReportTitleModal from '../../ReportTitleModal';
import Tooltip from '../../../../components/Tooltip';
import DropdownFull from '../../../../components/DropdownFull';
import AppLayout from '../../../../components/AppLayout';

const tableHeaders = {
  income: 'Income',
  cost_of_goods_sold: 'Cost of Goods Sold',
  operating_expenses: 'Operating Expenses',
  net_margin: 'Net Margin',
  net_profit: 'Net Profit',
  gross_proft: 'Gross Profit',
  gross_margin: 'Gross Margin',
};

const ProfitAndLossReport = () => {
  const formikRef = useRef();

  const navigate = useNavigate();
  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);
  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [loading, setLoading] = useState(false);
  const [accountConnections, setAccountConnections] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [values, setValues] = useState({});
  const [tags, setTags] = useState([]);
  const [generatedReport, setGeneratedReport] = useState([]);
  const [filter, setFilter] = useState({});
  const [activeTab, setActiveTab] = useState('summary');
  const [generating, setGenerating] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [reportTitle, setReportTitle] = useState('');
  const [selectedType, setSelectedType] = useState('accrual');
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [hasGenerated, setHasGenerated] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(teamDefaultCurrency);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const dateFormats = useMemo(() => dateCategoryList(), []);
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accounts');
  const startDate = searchParams.get('startDate');
  const secondStartDate = searchParams.get('secondStartDate');
  const endDate = searchParams.get('endDate');
  const secondEndDate = searchParams.get('secondEndDate');
  const typeParam = searchParams.get('type');
  const currency = searchParams.get('currency');
  const tag = searchParams.get('tags') || [];
  const from = searchParams.get('from');
  const reportType = searchParams.get('reportType');
  const compare = searchParams.get('compare');
  const [currencySymbol, setCurrencySymbol] = useState('');

  const AllDateFormats = useMemo(() => {
    const dates = dateFormats.flatMap((date) => date.categories);
    return dates;
  }, [dateFormats]);

  useEffect(() => {
    if (selectedCurrency && currencies) {
      setCurrencySymbol(
        currencies?.find?.((c) => c.isoCode === selectedCurrency)?.symbol,
      );
    }
  }, [generatedReport]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      setDataLoaded(false);
      const res = await getTags();
      setTags(res);
      const accountsRes = await getAccounts({ inactive: true });
      setAccountConnections(transformAccounts(accountsRes, 'allAccounts'));
      if (!currency) {
        setSelectedCurrency(teamDefaultCurrency);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const mergeArrays = (arr1, arr2) => {
    // Merge arrays based on accountLabel
    const merged = arr1.map((item1) => {
      const item2 = arr2.find(
        (item) => item.accountLabel === item1.accountLabel,
      );
      return {
        accountLabel: item1.accountLabel,
        firstDateRange: item1.firstDateRange,
        secondDateRange: item2.secondDateRange,
      };
    });

    return merged;
  };

  const calculateDifferenceAndPercent = (arr = []) =>
    arr.map((data) => {
      if (data?.secondDateRange) {
        const oldIncome = Number(data.secondDateRange?.total);
        const newIncome = Number(data.firstDateRange?.total);
        const difference = newIncome - oldIncome;

        const percentage = (difference / (Math.abs(oldIncome) || 1)) * 100;

        Object.assign(data, { difference, percentage });
      }

      data?.firstDateRange?.details?.forEach((elem) => {
        if (data?.secondDateRange) {
          const oldIncome = Number(
            data.secondDateRange?.details.find(
              (val) => val?.account?.id === elem?.account?.id,
            )?.total || 0,
          );
          const newIncome = Number(elem?.total || 0);

          const detailDifference = newIncome - oldIncome;

          const detailPercentage =
            (detailDifference / (Math.abs(oldIncome) || 1)) * 100;

          Object.assign(elem, { detailDifference, detailPercentage });
        }
      });

      return data;
    });

  const handleSubmit = async (paramValues = {}) => {
    setHasGenerated(true);
    let _tag = [];
    if (!paramValues?.tags) {
      _tag =
        typeof selectedTags === 'string'
          ? selectedTags?.length > 2
            ? selectedTags?.split?.(',')?.map?.(Number)
            : []
          : selectedTags || [];
    } else {
      _tag =
        typeof paramValues?.tags === 'string'
          ? paramValues?.tags?.length > 2
            ? paramValues?.tags?.split?.(',')?.map?.(Number)
            : []
          : paramValues?.tags || [];
    }

    try {
      const payloadData = {
        accounts: paramValues?.accounts || values?.accounts || [],
        tags: _tag?.filter?.((tag) => tag !== PnlNoTagValue),
        noTags: _tag?.includes(PnlNoTagValue),
        save: !!values?.save,
        reportTitle,
        reportType: paramValues?.reportType || selectedType || 'accrual',
        ...(values?.secondStartDate && {
          compareWith: {
            startDate: getFormattedDate(
              paramValues?.secondStartDate || values?.secondStartDate,
              teamDateFormat,
            ),
            endDate: getFormattedDate(
              paramValues?.secondEndDate || values?.secondEndDate,
              teamDateFormat,
            ),
          },
        }),
        currency: paramValues?.currency || selectedCurrency || currency || '',
      };
      if (values?.firstStartDate || paramValues?.startDate) {
        payloadData.startDate = getFormattedDate(
          paramValues?.startDate || values?.firstStartDate,
          teamDateFormat,
        );
      }
      if (values?.firstEndDate || paramValues?.endDate) {
        payloadData.endDate = getFormattedDate(
          paramValues?.endDate || values?.firstEndDate,
          teamDateFormat,
        );
      }
      const response = await generateReports(payloadData);
      setFilter(response?.data?.filters);
      const data = response?.data?.categorized;
      const formattedData =
        Object.keys(data).length && formatReportData(data, 'firstDateRange');

      if (
        ((paramValues?.secondStartDate && paramValues?.secondEndDate) ||
          values.compare) &&
        ((values?.secondStartDate && values?.secondEndDate) ||
          (paramValues?.secondStartDate && paramValues?.secondEndDate))
      ) {
        const payloadData1 = {
          accounts: paramValues?.accounts || values?.accounts || [],
          tags: _tag?.filter?.((tag) => tag !== PnlNoTagValue),
          noTags: _tag?.includes(PnlNoTagValue),
          reportType: paramValues?.reportType || selectedType || 'accrual',
          currency: paramValues?.currency || selectedCurrency || currency || '',
        };
        if (values.secondStartDate || paramValues?.secondStartDate) {
          payloadData1.startDate = getFormattedDate(
            paramValues?.secondStartDate || values.secondStartDate,
            teamDateFormat,
          );
        }
        if (values.secondEndDate || paramValues?.secondEndDate) {
          payloadData1.endDate = getFormattedDate(
            paramValues?.secondEndDate || values.secondEndDate,
            teamDateFormat,
          );
        }
        const response2 = await generateReports(payloadData1);
        const data2 = response2?.data?.categorized;
        const formattedData2 =
          Object.keys(data2).length &&
          formatReportData(data2, 'secondDateRange');
        const mergedData = calculateDifferenceAndPercent(
          mergeArrays(formattedData, formattedData2),
        );
        setGeneratedReport(mergedData);
      } else {
        setGeneratedReport(formattedData);
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setModalOpen(false);
      setGenerating(false);
      setIsSubmit(false);
      setSubmitTrigger(false);
    }
  };

  const formikSubmit = async (values) => {
    setGenerating(true);
    setSubmitTrigger(!submitTrigger);
    setValues(values);
  };

  const handleClose = () => {
    setModalOpen(false);
    setGenerating(false);
    setIsSubmit(false);
  };

  const findDateType = (searchId) => {
    const find = AllDateFormats.find((d) => d?.id === searchId);
    return find;
  };

  const downloadCSV = (csv, filename) => {
    const csvFile = new Blob([csv], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const exportToCSV = () => {
    const formatDate = (date) => moment(date).format('MMM D, YYYY');

    // Header information
    let csvContent = `"Profit and Loss"\r\n"${team?.name}"\r\n`;
    csvContent += `"Date Range: ${formatDate(
      getFormattedDate(values.firstStartDate, teamDateFormat),
    )} to ${formatDate(getFormattedDate(values.firstEndDate, teamDateFormat))}"\r\n`;
    if (values.compare) {
      csvContent += `"Comparison Date Range: ${formatDate(
        getFormattedDate(values.secondStartDate, teamDateFormat),
      )} to ${formatDate(getFormattedDate(values.secondEndDate, teamDateFormat))}"\r\n`;
    }
    csvContent += '\r\n';

    // Column headers
    csvContent += `"ACCOUNTS",,"${formatDate(
      getFormattedDate(values.firstStartDate, teamDateFormat),
    )} to ${formatDate(getFormattedDate(values.firstEndDate, teamDateFormat))}"`;
    if (values.compare) {
      csvContent += `,"${formatDate(getFormattedDate(values.secondStartDate, teamDateFormat))} to ${formatDate(
        getFormattedDate(values.secondEndDate, teamDateFormat),
      )}"`;
    }
    csvContent += '\r\n';

    // Income Section
    csvContent += `"Income"\r\n`;
    generatedReport.forEach((item) => {
      if (item.accountLabel === 'Income') {
        item.firstDateRange?.details.forEach((detail) => {
          csvContent += `,"${detail.account.name}","${currencySymbol}${parseFloat(detail?.total ?? 0)?.toFixed(2)}"`;
          if (values.compare && item.secondDateRange) {
            const secondDetail = item.secondDateRange.details.find(
              (d) => d.account.id === detail.account.id,
            );
            csvContent += `,"${
              secondDetail
                ? `${currencySymbol}${secondDetail?.total}`
                : `${currencySymbol}0.00`
            }"`;
          }
          csvContent += '\r\n';
        });
      }
    });

    // Total Income
    let totalIncomeFirst = 0;
    let totalIncomeSecond = 0;
    generatedReport.forEach((item) => {
      if (item.accountLabel === 'Income') {
        totalIncomeFirst += parseFloat(item.firstDateRange.total);
        if (values.compare && item.secondDateRange) {
          totalIncomeSecond += parseFloat(item.secondDateRange.total);
        }
      }
    });
    csvContent += `"Total Income",,"${currencySymbol}${parseFloat(totalIncomeFirst ?? 0)?.toFixed(2)}"`;
    if (values.compare) {
      csvContent += `,"${currencySymbol}${parseFloat(totalIncomeSecond ?? 0)?.toFixed(2)}"`;
    }
    csvContent += '\r\n';

    // COGS Section
    generatedReport.forEach((item) => {
      if (item.accountLabel === 'Cost of Goods Sold') {
        item.firstDateRange?.details.forEach((detail) => {
          csvContent += `,"${detail.account.name}","${currencySymbol}${parseFloat(detail?.total ?? 0)?.toFixed(2)}"`;
          if (values.compare && item.secondDateRange) {
            const secondDetail = item.secondDateRange.details.find(
              (d) => d.account.id === detail.account.id,
            );
            csvContent += `,"${
              secondDetail
                ? `${currencySymbol}${parseFloat(secondDetail?.total ?? 0)?.toFixed(2)}`
                : `${currencySymbol}0.00`
            }"`;
          }
          csvContent += '\r\n';
        });
      }
    });

    // Total Cost of Goods Sold
    let totalCOGSFirst = 0;
    let totalCOGSSecond = 0;
    generatedReport.forEach((item) => {
      if (item.accountLabel === 'Cost of Goods Sold') {
        totalCOGSFirst += parseFloat(item.firstDateRange.total);
        if (values.compare && item.secondDateRange) {
          totalCOGSSecond += parseFloat(item.secondDateRange.total);
        }
      }
    });
    csvContent += `"Total Cost of Goods Sold",,"${currencySymbol}${parseFloat(totalCOGSFirst ?? 0)?.toFixed(2)}"`;
    if (values.compare) {
      csvContent += `,"${currencySymbol}${parseFloat(totalCOGSSecond ?? 0)?.toFixed(2)}"`;
    }
    csvContent += '\r\n';

    // Gross Profit
    generatedReport.forEach((item) => {
      if (item.accountLabel === 'Gross Profit') {
        const firstTotal = `${currencySymbol}${parseFloat(item?.firstDateRange?.total ?? 0)?.toFixed(2)}`;
        csvContent += `"Gross Profit",,"${firstTotal}"`;
        if (values.compare && item.secondDateRange) {
          const secondTotal = `${currencySymbol}${parseFloat(item?.secondDateRange?.total ?? 0)?.toFixed(2)}`;
          csvContent += `,"${secondTotal}"`;
        }
        csvContent += '\r\n';
      }
    });

    // Operating Expenses
    csvContent += `"Operating Expenses"\r\n`;
    generatedReport.forEach((item) => {
      if (item.accountLabel === 'Operating Expenses') {
        item.firstDateRange?.details.forEach((detail) => {
          csvContent += `,"${detail.account.name}","${currencySymbol}${parseFloat(detail?.total ?? 0)?.toFixed(2)}"`;
          if (values.compare && item.secondDateRange) {
            const secondDetail = item.secondDateRange.details.find(
              (d) => d.account.id === detail.account.id,
            );
            csvContent += `,"${
              secondDetail
                ? `${currencySymbol}${parseFloat(secondDetail?.total ?? 0)?.toFixed(2)}`
                : `${currencySymbol}0.00`
            }"`;
          }
          csvContent += '\r\n';
        });
      }
    });

    // Operating Expenses Totals
    let totalExpensesFirst = 0;
    let totalExpensesSecond = 0;
    generatedReport.forEach((item) => {
      if (item.accountLabel === 'Operating Expenses') {
        totalExpensesFirst += parseFloat(item.firstDateRange.total);
        if (values.compare && item.secondDateRange) {
          totalExpensesSecond += parseFloat(item.secondDateRange.total);
        }
      }
    });
    csvContent += `"Total Operating Expenses",,"${currencySymbol}${parseFloat(totalExpensesFirst ?? 0)?.toFixed(2)}"`;
    if (values.compare) {
      csvContent += `,"${currencySymbol}${parseFloat(totalExpensesSecond ?? 0)?.toFixed(2)}"`;
    }
    csvContent += '\r\n';

    // Net Profit
    generatedReport.forEach((item) => {
      if (item.accountLabel === 'Net Profit') {
        const firstTotal = `${currencySymbol}${parseFloat(item?.firstDateRange?.total ?? 0)?.toFixed(2)}`;
        csvContent += `"Net Profit",,"${firstTotal}"`;
        if (values.compare && item.secondDateRange) {
          const secondTotal = `${currencySymbol}${parseFloat(item?.secondDateRange?.total ?? 0)?.toFixed(2)}`;
          csvContent += `,"${secondTotal}"`;
        }
        csvContent += '\r\n';
      }
    });

    const filename = 'Profit_and_Loss_Report.csv';
    downloadCSV(csvContent, filename);
  };

  useEffect(() => {
    if (Object.keys(values).length > 0 && submitTrigger) {
      if (values?.save) {
        let tagsName = '';
        if (selectedTags.length > 0) {
          const filteredTags = tags
            .filter((tag) => selectedTags.includes(tag.id))
            .map((tag) => tag?.name);

          tagsName = filteredTags.join('-');
        }
        setReportTitle(
          !values?.compare
            ? `Profit & Loss - ${
                formikRef?.current.values.firstStartDate &&
                `${moment(
                  getFormattedDate(
                    formikRef?.current.values.firstStartDate,
                    teamDateFormat,
                  ),
                ).format('MMM DD, YYYY')} to`
              } ${
                formikRef?.current?.values?.firstEndDate
                  ? moment(
                      getFormattedDate(
                        formikRef?.current?.values?.firstEndDate,
                        teamDateFormat,
                      ),
                    ).format('MMM DD, YYYY')
                  : ''
              }${tagsName} (${selectedCurrency || teamDefaultCurrency})`
            : `Profit & Loss - Compare ${moment(
                getFormattedDate(
                  formikRef?.current?.values?.firstStartDate,
                  teamDateFormat,
                ),
              ).format('MMM DD, YYYY')}-${
                formikRef?.current?.values?.firstEndDate
                  ? moment(
                      getFormattedDate(
                        formikRef?.current?.values?.firstEndDate,
                        teamDateFormat,
                      ),
                    ).format('MMM DD, YYYY')
                  : ''
              } to ${moment(
                getFormattedDate(
                  formikRef?.current?.values?.secondStartDate,
                  teamDateFormat,
                ),
              ).format('MMM DD, YYYY')}-${
                formikRef?.current?.values?.secondEndDate
                  ? moment(
                      getFormattedDate(
                        formikRef?.current?.values?.secondEndDate,
                        teamDateFormat,
                      ),
                    ).format('MMM DD, YYYY')
                  : ''
              }${tagsName} (${selectedCurrency || teamDefaultCurrency})`,
        );
        setModalOpen(true);
      } else {
        handleSubmit();
      }
    }
  }, [values, submitTrigger]);

  useEffect(() => {
    setHasGenerated(false);
    loadData();
  }, []);

  useEffect(() => {
    if (
      (startDate || endDate || secondEndDate || secondStartDate || accountId) &&
      !hasGenerated
    ) {
      const _tag =
        typeof tag === 'string'
          ? tag?.length > 2
            ? tag?.split?.(',')?.map?.(Number)
            : []
          : tag || [];
      const tags = _tag === '[]' ? [] : _tag;

      handleSubmit({
        startDate: startDate
          ? formatDateLocally(startDate, teamDateFormat)
          : null,
        endDate: endDate ? formatDateLocally(endDate, teamDateFormat) : null,
        secondStartDate: secondStartDate
          ? formatDateLocally(secondStartDate, teamDateFormat)
          : null,
        secondEndDate: secondEndDate
          ? formatDateLocally(secondEndDate, teamDateFormat)
          : null,
        accounts: accountId ? accountId.split(',').map(Number) : [],
        reportType,
        currency,
        tags,
      });
    }
  }, [
    startDate,
    endDate,
    secondStartDate,
    secondEndDate,
    accountId,
    currency,
    reportType,
    tag,
  ]);

  useEffect(() => {
    // Ensure Formik's `setFieldValue` is ready before running any logic
    if (!formikRef?.current?.setFieldValue || loading) return;
    const val = {};
    // Set selected currency if currency value exists
    if (currency) {
      setSelectedCurrency(currency);
    }

    // Set selected tags if tag is valid
    if (tag && tag.length > 0) {
      const _tag =
        typeof tag === 'string'
          ? tag.length > 2
            ? tag.split(',').map(Number)
            : []
          : tag || [];

      setSelectedTags(_tag);
      formikRef.current.setFieldValue('tags', _tag);
    }

    // Set accounts field if accountId is provided
    if (accountId) {
      val.accounts = accountId.split(',').map(Number);
      formikRef.current.setFieldValue(
        'accounts',
        accountId.split(',').map(Number),
      );
    }

    // Set report type if reportType is provided
    if (reportType) {
      formikRef.current.setFieldValue('reportType', reportType);
      setSelectedType(reportType);
    }

    // Set dates if startDate, endDate, and AllDateFormats are available
    if (startDate && endDate) {
      const _startDate = startDate
        ? formatDateLocally(startDate, teamDateFormat)
        : '';
      const _endDate = endDate
        ? formatDateLocally(endDate, teamDateFormat)
        : '';
      val.firstStartDate = _startDate;
      val.firstEndDate = _endDate;

      formikRef.current.setFieldValue('firstStartDate', _startDate);
      formikRef.current.setFieldValue('firstEndDate', _endDate);

      if (_startDate && _endDate) {
        const dateRange1 = AllDateFormats?.find?.(
          (d) =>
            formatDateLocally(d.startDate, teamDateFormat) === _startDate &&
            formatDateLocally(d.endDate, teamDateFormat) === _endDate,
        );
        formikRef.current.setFieldValue(
          'dateRange1',
          dateRange1 ? dateRange1.id : 'custom',
        );
      }
    }
    if (secondStartDate && secondEndDate) {
      const _secondStartDate = secondStartDate
        ? formatDateLocally(secondStartDate, teamDateFormat)
        : '';
      const _secondEndDate = secondEndDate
        ? formatDateLocally(secondEndDate, teamDateFormat)
        : '';
      if (_secondStartDate || _secondEndDate) {
        formikRef.current.setFieldValue('compare', true);
        val.secondStartDate = _secondStartDate;
        val.secondEndDate = _secondEndDate;
        val.compare = true;
        formikRef.current.setFieldValue('secondStartDate', _secondStartDate);
        formikRef.current.setFieldValue('secondEndDate', _secondEndDate);

        const dateRange2 = AllDateFormats?.find?.(
          (d) =>
            formatDateLocally(d.startDate, teamDateFormat) ===
              _secondStartDate &&
            formatDateLocally(d.endDate, teamDateFormat) === _secondEndDate,
        );
        formikRef.current.setFieldValue(
          'dateRange2',
          dateRange2 ? dateRange2.id : 'custom',
        );
      }
    }
    if (startDate && endDate) {
      setValues(val);
    }
  }, [
    currency,
    tag,
    accountId,
    reportType,
    startDate,
    endDate,
    secondStartDate,
    secondEndDate,
    AllDateFormats,
    formikRef?.current?.setFieldValue, // Ensure formikRef is ready before running
    loading,
  ]);

  useEffect(() => {
    if (
      formikRef?.current?.setFieldValue &&
      AllDateFormats?.length > 0 &&
      !startDate &&
      !endDate &&
      !loading
    ) {
      formikRef?.current.setFieldValue('dateRange1', AllDateFormats?.[0]?.id);
      formikRef?.current.setFieldValue(
        'firstStartDate',
        AllDateFormats?.[0]?.startDate,
      );
      formikRef?.current.setFieldValue(
        'firstEndDate',
        AllDateFormats?.[0]?.endDate,
      );
    }
  }, [
    formikRef?.current?.setFieldValue,
    AllDateFormats,
    startDate,
    endDate,
    loading,
  ]);

  return (
    <>
      <ReportTitleModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        reportTitle={reportTitle}
        setReportTitle={setReportTitle}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
      <AppLayout>
        <main className="relative grow">
          <div className="px-4 sm:px-6 lg:px-8 py-5 w-full max-w-9xl mx-auto flex flex-col gap-5">
            <div className="flex  gap-5 flex-wrap items-center">
              <div
                onClick={() => {
                  if (from === 'balanceSheetaccrual') {
                    navigate(
                      `/reports/balance-sheet?endDate=${getFormattedDate(values?.firstEndDate, teamDateFormat)}&currency=${selectedCurrency}&type=accrual`,
                    );
                  } else if (from === 'balanceSheetcash') {
                    navigate(
                      `/reports/balance-sheet?endDate=${getFormattedDate(values?.firstEndDate, teamDateFormat)}&currency=${selectedCurrency}&type=cash`,
                    );
                  } else {
                    navigate('/reports');
                  }
                }}
                className="cursor-pointer"
              >
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="32"
                    height="33"
                    transform="translate(33) rotate(90)"
                    fill="#FBF3ED"
                  />
                  <path
                    d="M19 10.5L13 16.5L19 22.5"
                    stroke="#E48642"
                    strokeWidth="3.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h1 className="text-3xl md:text-4xl text-black opacity-60  font-medium">
                Profit & Loss Statement
              </h1>
            </div>
            {loading ? (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin w-10 h-10 fill-current shrink-0"
                >
                  <path
                    d="M14.5 2.41699V7.25033"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5 21.749V26.5824"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 5.95703L9.37662 9.37662"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 19.6211L23.0426 23.0407"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.41699 14.5H7.25033"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.749 14.5H26.5824"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 23.0407L9.37662 19.6211"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 9.37662L23.0426 5.95703"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            ) : (
              <>
                <div className="flex py-5 px-7  flex-col  gap-1 flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
                  <Formik
                    innerRef={formikRef}
                    // enableReinitialize
                    initialValues={{
                      firstStartDate: null,
                      firstEndDate: null,
                      compare: false,
                      dateRange1: '',
                      secondStartDate: null,
                      secondEndDate: null,
                      dateRange2: '',
                      accounts: [],
                      title: reportTitle,
                      save: false,
                      tags: [],
                      reportType: '',
                    }}
                    validationSchema={Yup.object({
                      firstStartDate: Yup.string().required(
                        'Please Select Start Date',
                      ),
                      firstEndDate: Yup.string().required(
                        'Please Select End Date',
                      ),
                      ...(formikRef.current?.values?.compare && {
                        secondStartDate: Yup.string().required(
                          'Please Select Start Date',
                        ),
                        secondEndDate: Yup.string().required(
                          'Please Select End Date',
                        ),
                      }),
                    })}
                    onSubmit={isSubmit && formikSubmit}
                  >
                    {(validation) => (
                      <Form>
                        <div className="grid gap-1 xl:w-3/5 lg:w-10/12 sm:w-11/12">
                          <div>
                            <div className="">
                              <p className="py-2">
                                Date Range {validation.values.compare && '1'}
                                <span className="text-rose-500 pl-1">*</span>
                              </p>
                              <div className="sm:grid sm:grid-cols-5 gap-3 gap-y-0">
                                <div className="flex flex-wrap gap-y-0 gap-4">
                                  <div className="flex-1 max-w-[200px] sm:max-w-[300px] mb-3 sm:mb-0">
                                    <CategoriesDropdown
                                      allCategories={[
                                        ...dateFormats,
                                        {
                                          name: 'Custom',
                                          categories: [
                                            {
                                              name: 'Custom',
                                              id: 'custom',
                                            },
                                          ],
                                        },
                                      ]}
                                      selectedCategoryId={
                                        validation.values.dateRange1
                                      }
                                      setSelectedCategoryId={(value) => {
                                        validation.setFieldValue(
                                          'dateRange1',
                                          value,
                                        );
                                        const _date = findDateType(value);
                                        if (_date) {
                                          if (_date?.startDate) {
                                            validation.setFieldValue(
                                              'firstStartDate',
                                              _date?.startDate,
                                            );
                                          }
                                          if (_date?.endDate) {
                                            validation.setFieldValue(
                                              'firstEndDate',
                                              _date?.endDate,
                                            );
                                          }
                                        } else {
                                          validation.setFieldValue(
                                            'firstStartDate',
                                            null,
                                          );
                                          validation.setFieldValue(
                                            'firstEndDate',
                                            null,
                                          );
                                          validation.setFieldTouched(
                                            'firstStartDate',
                                            true,
                                          );
                                          validation.setFieldTouched(
                                            'firstEndDate',
                                            true,
                                          );
                                        }
                                      }}
                                      isSetCategoryStyle
                                      height="h-10"
                                    />
                                    {validation.touched.dateRange1 &&
                                    validation.errors.dateRange1 ? (
                                      <div className="text-xs mt-1 text-rose-500">
                                        {validation.errors.dateRange1}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="flex flex-wrap gap-y-0 col-span-2">
                                  <div className="flex-1 max-w-[200px] sm:max-w-[300px] mb-3 sm:mb-0">
                                    <DateFilter
                                      startDateFilter={
                                        validation.values.firstStartDate
                                      }
                                      endDateFilter={
                                        validation.values.firstEndDate
                                      }
                                      setStartDateFilter={(value) => {
                                        validation.setFieldValue(
                                          'firstStartDate',
                                          value,
                                        );
                                      }}
                                      setEndDateFilter={(value) => {
                                        validation.setFieldValue(
                                          'firstEndDate',
                                          value,
                                        );
                                      }}
                                      onDateChange={() => {
                                        validation.setFieldValue(
                                          'dateRange1',
                                          'custom',
                                        );
                                      }}
                                      align="left"
                                      report
                                    />
                                    {(validation.touched.firstStartDate ||
                                      validation.touched.firstEndDate) &&
                                    (validation.errors.firstStartDate ||
                                      validation.errors.firstEndDate) ? (
                                      <div className="text-xs mt-1 text-rose-500">
                                        {validation.errors.firstStartDate ||
                                          validation.errors.firstEndDate}{' '}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                {checkPermission(
                                  PermissionKeys.VIEW_REPORTS,
                                  accessType,
                                  permissions,
                                ) && (
                                  <button
                                    type="button"
                                    className={`${
                                      !validation.values.compare
                                        ? 'block'
                                        : 'hidden'
                                    } col-span-2 max-w-[200px] sm:max-w-full text-[14px] sm:text-[16px] text-center sm:text-left flex cursor-pointer items-center justify-center py-5 rounded-[0.313rem] shadow-sm leading-6  h-6  border border-[#E48642]  text-[#E48642]`}
                                    onClick={() => {
                                      validation.setFieldValue('compare', true);
                                      validation.setFieldValue(
                                        'dateRange2',
                                        `${parseInt(moment().format('YY')) - 1}`,
                                      );
                                      const _date = findDateType(
                                        `${parseInt(moment().format('YY')) - 1}`,
                                      );
                                      if (_date) {
                                        if (_date?.startDate) {
                                          validation.setFieldValue(
                                            'secondStartDate',
                                            _date?.startDate,
                                          );
                                        }
                                        if (_date?.endDate) {
                                          validation.setFieldValue(
                                            'secondEndDate',
                                            _date?.endDate,
                                          );
                                        }
                                      } else {
                                        validation.setFieldValue(
                                          'secondStartDate',
                                          null,
                                        );
                                        validation.setFieldValue(
                                          'secondEndDate',
                                          null,
                                        );
                                        validation.setFieldTouched(
                                          'secondStartDate',
                                          true,
                                        );
                                        validation.setFieldTouched(
                                          'secondEndDate',
                                          true,
                                        );
                                      }
                                    }}
                                  >
                                    Compare with a Prior Date Range
                                  </button>
                                )}
                              </div>
                            </div>

                            <div
                              className={
                                !validation.values.compare ? 'hidden' : 'block'
                              }
                            >
                              <p className="py-2">
                                Date Range 2
                                <span className="text-rose-500 pl-1">*</span>
                              </p>
                              <div className="sm:grid sm:grid-cols-5 gap-3 gap-y-0">
                                <div className="flex flex-wrap gap-y-0 gap-4">
                                  <div className="flex-1 max-w-[200px] sm:max-w-[300px] mb-3 sm:mb-0">
                                    <CategoriesDropdown
                                      allCategories={[
                                        ...dateFormats,
                                        {
                                          name: 'Custom',
                                          categories: [
                                            {
                                              name: 'Custom',
                                              id: 'custom',
                                            },
                                          ],
                                        },
                                      ]}
                                      selectedCategoryId={
                                        validation.values.dateRange2 ||
                                        dateFormats[0].categories[1].id
                                      }
                                      setSelectedCategoryId={(value) => {
                                        validation.setFieldValue(
                                          'dateRange2',
                                          value,
                                        );
                                        const _date = findDateType(value);
                                        if (_date) {
                                          if (_date?.startDate) {
                                            validation.setFieldValue(
                                              'secondStartDate',
                                              _date?.startDate,
                                            );
                                          }
                                          if (_date?.endDate) {
                                            validation.setFieldValue(
                                              'secondEndDate',
                                              _date?.endDate,
                                            );
                                          }
                                        } else {
                                          validation.setFieldValue(
                                            'secondStartDate',
                                            null,
                                          );
                                          validation.setFieldValue(
                                            'secondEndDate',
                                            null,
                                          );
                                          validation.setFieldTouched(
                                            'secondStartDate',
                                            true,
                                          );
                                          validation.setFieldTouched(
                                            'secondEndDate',
                                            true,
                                          );
                                        }
                                      }}
                                      isSetCategoryStyle
                                      height="h-10"
                                      isReset
                                    />
                                    {validation.touched.dateRange2 &&
                                    validation.errors.dateRange2 ? (
                                      <div className="text-xs mt-1 text-rose-500">
                                        {validation.errors.dateRange2}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="flex flex-wrap gap-y-0 col-span-2">
                                  <div className="flex-1 max-w-[200px] sm:max-w-[300px] mb-3 sm:mb-0">
                                    <DateFilter
                                      startDateFilter={
                                        validation.values.secondStartDate
                                      }
                                      endDateFilter={
                                        validation.values.secondEndDate
                                      }
                                      setStartDateFilter={(value) => {
                                        validation.setFieldValue(
                                          'secondStartDate',
                                          value,
                                        );
                                      }}
                                      setEndDateFilter={(value) => {
                                        validation.setFieldValue(
                                          'secondEndDate',
                                          value,
                                        );
                                      }}
                                      onDateChange={() => {
                                        validation.setFieldValue(
                                          'dateRange2',
                                          'custom',
                                        );
                                      }}
                                      align="left"
                                    />
                                    {(validation.touched.secondStartDate ||
                                      validation.touched.secondEndDate) &&
                                    (validation.errors.secondStartDate ||
                                      validation.errors.secondEndDate) ? (
                                      <div className="text-xs mt-1 text-rose-500">
                                        {validation.errors.secondStartDate ||
                                          validation.errors.secondEndDate}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="col-span-2 flex max-w-[200px] sm:max-w-full cursor-pointer items-center justify-center py-5 rounded-[0.313rem] shadow-sm leading-6  h-6 whitespace-nowrap  border border-[#E48642]  text-[#E48642]"
                                  onClick={() => {
                                    validation.setFieldValue('compare', false);
                                    validation.setFieldValue(
                                      'secondStartDate',
                                      '',
                                    );
                                    validation.setFieldValue(
                                      'secondEndDate',
                                      '',
                                    );
                                  }}
                                >
                                  Close Comparison
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-2 gap-3">
                            <div className="">
                              <p className="py-2">Account</p>
                              <div>
                                <div className="flex items-center max-w-[200px] sm:max-w-full py-5 rounded-[0.313rem] shadow-sm leading-6  h-8 whitespace-nowrap">
                                  <CategoriesFilter
                                    allCategories={accountConnections}
                                    selectedCategoryId={
                                      validation.values.accounts
                                    }
                                    setSelectedCategoryId={(value) => {
                                      validation.setFieldValue(
                                        'accounts',
                                        value,
                                      );
                                    }}
                                    getTransactionsApi={() => {}}
                                    apiFilter={validation.values.accounts}
                                    allAccount
                                    type="account"
                                    report
                                  />
                                </div>
                                {validation.touched.accounts &&
                                validation.errors.accounts ? (
                                  <div className="text-xs mt-1 text-rose-500">
                                    {validation.errors.accounts}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="">
                              <p className="py-2">Tags</p>
                              <div>
                                <div className="flex items-center max-w-[200px] sm:max-w-full py-5 rounded-[0.313rem] shadow-sm leading-6  h-8 whitespace-nowrap ">
                                  <BasicCheckboxDropdown
                                    selectedOptions={selectedTags}
                                    setSelectedOptions={setSelectedTags}
                                    apiFunc={() => {}}
                                    options={[
                                      {
                                        label:
                                          'No Tags (entries with no Tags only)',
                                        value: PnlNoTagValue,
                                      },
                                      ...(tags?.map((t) => ({
                                        label: t?.name,
                                        value: t?.id,
                                      })) || []),
                                    ]}
                                    onClear={() => {
                                      setSelectedTags([]);
                                    }}
                                    label="Select Tags"
                                    type="tags"
                                    tags
                                    apiFilter={[]}
                                    report
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col sm:flex-row gap-3">
                            <div className="">
                              <div className="flex items-center  gap-2">
                                <p className="py-2">Report Types</p>
                                <Tooltip
                                  content={
                                    <>
                                      <p className="font-medium">
                                        Accrual (Paid & Unpaid)
                                      </p>
                                      <p className="ml-1">
                                        All transactions,{' '}
                                        <span className="underline">
                                          including
                                        </span>{' '}
                                        unpaid invoices and bills.
                                      </p>
                                      <p className="font-medium">
                                        Cash Basis (Paid)
                                      </p>
                                      <p className="ml-1">
                                        All transactions,{' '}
                                        <span className="underline">
                                          except
                                        </span>{' '}
                                        unpaid invoices and bills.
                                      </p>
                                    </>
                                  }
                                  contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative left-[5rem]"
                                >
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="8.60522"
                                      cy="8.99976"
                                      r="8.60522"
                                      fill="#BDBDBD"
                                    />
                                    <rect
                                      x="7.17041"
                                      y="7.08984"
                                      width="2.8684"
                                      height="7.64908"
                                      rx="1.4342"
                                      fill="white"
                                    />
                                    <rect
                                      x="7.17041"
                                      y="3.26172"
                                      width="2.8684"
                                      height="2.8684"
                                      rx="1.4342"
                                      fill="white"
                                    />
                                  </svg>
                                </Tooltip>
                              </div>
                              <div>
                                <div className="flex items-center rounded-[0.313rem] max-w-[200px] sm:max-w-full shadow-sm leading-6  h-10 whitespace-nowrap w-full">
                                  <DropdownFull
                                    selected={selectedType}
                                    setSelected={setSelectedType}
                                    apiFunc={{}}
                                    options={[
                                      {
                                        label: 'Accrual',
                                        id: 'accrual',
                                        value: 'accrual',
                                      },
                                      {
                                        label: 'Cash Basis',
                                        id: 'cash',
                                        value: 'cash',
                                      },
                                    ]}
                                    name="label"
                                    onClear={() => {
                                      setSelectedType('accrual');
                                    }}
                                    label="Select Type"
                                    type="tags"
                                    flyout
                                    tags
                                    apiFilter=""
                                    scrollIntoView={false}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <p className="py-2">Currency</p>
                              <div>
                                <div className="flex items-center rounded-[0.313rem] max-w-[200px] sm:max-w-full shadow-sm leading-6  h-10 whitespace-nowrap w-full">
                                  <DropdownFull
                                    selected={selectedCurrency}
                                    setSelected={setSelectedCurrency}
                                    apiFunc={{}}
                                    options={currencies.map((c) => ({
                                      label: c?.name,
                                      id: c?.isoCode,
                                      value: c?.isoCode,
                                      symbol: c?.symbol,
                                    }))}
                                    name="label"
                                    onClear={() => {
                                      setSelectedCurrency(teamDefaultCurrency);
                                    }}
                                    label="Select Currency"
                                    type="tags"
                                    flyout
                                    tags
                                    apiFilter=""
                                    scrollIntoView={false}
                                    currency
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-4 gap-3 py-3 flex-wrap items-center">
                            {checkPermission(
                              PermissionKeys.VIEW_REPORTS,
                              accessType,
                              permissions,
                            ) && (
                              <div className="col-span-3 sm:col-span-2 flex flex-col sm:flex-row gap-2 pt-3">
                                <button
                                  disabled={generating}
                                  onClick={(e) => {
                                    validation.setFieldValue('save', false);
                                    setIsSubmit(true);
                                  }}
                                  className="flex items-center cursor-pointer justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap  bg-indigo-500 text-white"
                                >
                                  Generate Report
                                </button>
                                {process.env.REACT_APP_ENV === DevEnv && (
                                  <button
                                    disabled={generating}
                                    onClick={(e) => {
                                      validation.setFieldValue('save', true);
                                      setIsSubmit(true);
                                    }}
                                    className="flex items-center cursor-pointer justify-center px-2 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap border border-indigo-500  text-indigo-500"
                                  >
                                    Generate & Save Report
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                {!generatedReport?.length > 0 || generating ? (
                  <div className="flex py-16 px-7 flex-col gap-1 text-center flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
                    {generating && (
                      <div className="flex items-center pb-2 justify-center mt-8">
                        <svg
                          className="animate-spin w-10 h-10 fill-current shrink-0"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                      </div>
                    )}
                    <div className="flex justify-center">
                      <svg
                        width="177"
                        height="155"
                        viewBox="0 0 177 155"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2823_64411)">
                          <path
                            d="M48.9475 87.1651C72.3444 87.1651 91.3114 68.1982 91.3114 44.8013C91.3114 21.4044 72.3444 2.4375 48.9475 2.4375C25.5507 2.4375 6.58374 21.4044 6.58374 44.8013C6.58374 68.1982 25.5507 87.1651 48.9475 87.1651Z"
                            fill="#F9EAE0"
                          />
                          <path
                            d="M51.6384 141.316C51.0285 141.316 50.4276 141.169 49.8865 140.887C49.3453 140.606 48.8798 140.199 48.529 139.7L43.6594 132.732L162.034 58.0156L167.658 61.7104C168.187 62.0575 168.62 62.5314 168.918 63.0888C169.216 63.6462 169.37 64.2693 169.365 64.9014C169.361 65.5335 169.198 66.1544 168.892 66.7076C168.586 67.2607 168.147 67.7285 167.614 68.0682L53.6699 140.723C53.0631 141.11 52.3582 141.316 51.6384 141.316Z"
                            fill="white"
                          />
                          <path
                            d="M162.026 58.5765L167.399 62.1059C167.864 62.4057 168.246 62.8191 168.507 63.3072C168.769 63.7954 168.901 64.3421 168.893 64.8957C168.891 65.4501 168.749 65.9949 168.481 66.4801C168.213 66.9653 167.827 67.3753 167.359 67.6724L53.4176 140.33C52.6961 140.789 51.8253 140.952 50.9864 140.785C50.1476 140.619 49.4054 140.135 48.9144 139.435L44.3243 132.87L162.026 58.5765ZM162.036 57.4606L42.9897 132.599L48.1444 139.971C48.7741 140.87 49.7263 141.491 50.8027 141.705C51.8791 141.919 52.9966 141.71 53.9224 141.12L167.864 68.4647C168.463 68.0829 168.957 67.5572 169.301 66.9355C169.645 66.3138 169.828 65.6159 169.833 64.9055C169.838 64.195 169.665 63.4946 169.33 62.8681C168.995 62.2416 168.509 61.7089 167.915 61.3187L162.037 57.457L162.036 57.4606Z"
                            fill="#081F2D"
                          />
                          <path
                            d="M94.4027 10.1219C97.252 8.28492 100.657 7.51002 104.021 7.93316C107.384 8.35631 110.491 9.95048 112.797 12.4359L116.492 16.4188L25.6196 54.4691L94.4027 10.1219Z"
                            fill="white"
                          />
                          <path
                            d="M102.215 8.29063C104.137 8.29253 106.037 8.68979 107.799 9.45771C109.561 10.2256 111.145 11.3478 112.455 12.7544L115.692 16.2447L30.3452 51.9825L94.657 10.5179C96.9092 9.06308 99.5337 8.28966 102.215 8.29063ZM102.215 7.3507C99.3537 7.34996 96.5531 8.17552 94.1498 9.72815L20.8943 56.9585L117.296 16.5916L113.143 12.1157C111.749 10.6106 110.058 9.41004 108.178 8.58951C106.297 7.76897 104.267 7.34617 102.215 7.34766V7.3507Z"
                            fill="#081F2D"
                          />
                          <path
                            d="M42.9896 132.596L38.29 125.876C37.9543 125.396 37.7213 124.852 37.6055 124.278C37.4897 123.703 37.4937 123.111 37.6171 122.539C37.7406 121.966 37.9809 121.425 38.323 120.95C38.6651 120.474 39.1016 120.075 39.6053 119.776L145.216 57.0983C147.051 56.0093 149.098 55.3271 151.219 55.0979C153.34 54.8687 155.486 55.0978 157.511 55.7698L160.918 56.8999C161.683 57.1537 162.36 57.6194 162.871 58.2429C163.381 58.8663 163.704 59.6219 163.803 60.4218C163.901 61.2217 163.77 62.033 163.425 62.7614C163.08 63.4898 162.536 64.1053 161.855 64.5365L51.6376 134.333C50.2494 135.212 48.576 135.521 46.9654 135.198C45.3548 134.874 43.9308 133.942 42.9896 132.596Z"
                            fill="#78BD4F"
                          />
                          <path
                            d="M39.6054 119.778L15.239 77.3915C14.256 75.6816 13.6223 73.7935 13.3747 71.8368C13.1271 69.8801 13.2705 67.8937 13.7966 65.9929C14.3227 64.092 15.2209 62.3145 16.4393 60.7636C17.6577 59.2126 19.172 57.919 20.8942 56.9578L98.8604 13.4404C101.831 11.7821 105.28 11.1905 108.634 11.764C111.988 12.3375 115.045 14.0416 117.296 16.5929C128.319 29.0817 135.999 44.1592 139.62 60.4187L39.6054 119.778Z"
                            fill="#78BD4F"
                          />
                          <path
                            d="M130.107 66.0703C124.726 50.5333 116.459 36.1524 105.74 23.6836"
                            stroke="#081F2D"
                            strokeWidth="0.939413"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M124.152 69.6016C118.77 54.0647 110.503 39.6839 99.7852 27.2148"
                            stroke="#081F2D"
                            strokeWidth="0.939413"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M119.188 72.5469C113.807 57.0101 105.54 42.6293 94.8218 30.1602"
                            stroke="#081F2D"
                            strokeWidth="0.939413"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M77.843 97.0865C72.4613 81.5495 64.1942 67.1685 53.4761 54.6992"
                            stroke="#081F2D"
                            strokeWidth="0.939413"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M52.7817 117.98L57.4813 124.7"
                            stroke="#081F2D"
                            strokeWidth="0.939413"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M135.003 63.1602C138.493 64.4051 141.5 66.7228 143.592 69.7812"
                            stroke="#081F2D"
                            strokeWidth="0.939413"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M39.6055 119.779L139.62 60.4219"
                            stroke="#081F2D"
                            strokeWidth="0.939413"
                            strokeMiterlimit="10"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2823_64411">
                            <rect
                              width="176.013"
                              height="154.709"
                              fill="white"
                              transform="translate(0.493408)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                    <div className="self-stretch flex mt-6 flex-col justify-center gap-2  text-center text-slate-600 text-[1rem] leading-6 font-normal h-16">
                      <p className=" text-[#101828] ">
                        Your report will appear here
                      </p>
                      <p className="text-[#667085]">
                        Simply, select date range, account and tags above to
                        generate report!
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="flex p-7 flex-col gap-1 flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
                    <div className="flex justify-between ">
                      <h1 className="text-2xl md:text-2xl text-black opacity-60  font-medium">
                        Report
                      </h1>
                      <div className="flex gap-3">
                        {/*
                          <button className="flex items-center justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap  border border-[#E48642]  text-[#E48642]">
                            Export as PDF
                          </button> */}
                        {checkPermission(
                          PermissionKeys.VIEW_REPORTS,
                          accessType,
                          permissions,
                        ) && (
                          <button
                            className="flex items-center justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap  border border-[#E48642]  text-[#E48642]"
                            onClick={() => exportToCSV()}
                          >
                            Export as CSV
                          </button>
                        )}
                      </div>
                    </div>
                    <div
                      className={`grid grid-cols-7 text-center py-8  ${
                        (values.secondStartDate || isMobile) && 'hidden'
                      }`}
                    >
                      <TableHeader
                        price={
                          generatedReport.find(
                            (obj) => obj.accountLabel === tableHeaders.income,
                          ).firstDateRange?.total
                        }
                        label={tableHeaders.income}
                        sign={currencySymbol}
                      />

                      <div className="text-[#101828] self-center">
                        <p className="font-semibold"> -</p>
                      </div>
                      <TableHeader
                        price={
                          generatedReport.find(
                            (obj) =>
                              obj.accountLabel ===
                              tableHeaders.cost_of_goods_sold,
                          ).firstDateRange?.total
                        }
                        label={tableHeaders.cost_of_goods_sold}
                        sign={currencySymbol}
                      />

                      <div className="text-[#101828] self-center">
                        <p className="font-semibold"> -</p>
                      </div>
                      <TableHeader
                        price={
                          generatedReport.find(
                            (obj) =>
                              obj.accountLabel ===
                              tableHeaders.operating_expenses,
                          ).firstDateRange?.total
                        }
                        label={tableHeaders.operating_expenses}
                        sign={currencySymbol}
                      />

                      <div className="text-[#101828] self-center">
                        <p className="font-semibold">=</p>
                      </div>
                      <TableHeader
                        price={
                          generatedReport.find(
                            (obj) =>
                              obj.accountLabel === tableHeaders.net_profit,
                          ).firstDateRange?.total
                        }
                        label={tableHeaders.net_profit}
                        color={
                          generatedReport.find(
                            (obj) => obj.accountLabel === 'Net Profit',
                          ).firstDateRange?.total >= 0
                            ? 'text-[#78BD4F]'
                            : 'text-[#FF4B4B]'
                        }
                        sign={currencySymbol}
                      />
                    </div>
                    <div className="flex justify-center border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
                      <div
                        onClick={() => setActiveTab('summary')}
                        className={`text-sm text-center  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                      ${
                        activeTab === 'summary' &&
                        ' !border-indigo-500  font-medium !text-[#101828]'
                      } `}
                      >
                        Summary
                      </div>

                      <div
                        onClick={() => setActiveTab('details')}
                        className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                      ${
                        activeTab === 'details' &&
                        ' !border-indigo-500  font-medium !text-[#101828]'
                      } `}
                      >
                        Details
                      </div>
                    </div>
                    <div className="overflow-x-auto scrollbar w-full">
                      <table className="table-fixed min-w-full dark:text-slate-300">
                        <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                          <tr className="w-full py-[1.438rem] px-[1.375rem] flex items-center justify-between">
                            <th className="">
                              <div className="text-left w-[11rem] font-medium">
                                Account
                              </div>
                            </th>

                            <th
                              className={values.secondStartDate && 'w-[17rem]'}
                            >
                              <div className="flex gap-1 align-middle text-right justify-center font-bold">
                                <CalendarIcon />
                                {values?.firstStartDate &&
                                  `${moment(
                                    getFormattedDate(
                                      values.firstStartDate,
                                      teamDateFormat,
                                    ),
                                  ).format('ll')} -`}
                                {`${moment(getFormattedDate(values.firstEndDate, teamDateFormat)).format('ll')}`}
                              </div>
                            </th>

                            {values.secondStartDate &&
                              values.secondEndDate &&
                              values.compare && (
                                <th className="w-[17rem]">
                                  <div className="flex gap-1 align-middle text-right justify-center font-bold">
                                    <CalendarIcon />

                                    {`${moment(
                                      getFormattedDate(
                                        values.secondStartDate,
                                        teamDateFormat,
                                      ),
                                    ).format('ll')} - ${moment(
                                      getFormattedDate(
                                        values.secondEndDate,
                                        teamDateFormat,
                                      ),
                                    ).format('ll')}`}
                                  </div>
                                </th>
                              )}

                            {values.compare &&
                              values.secondStartDate &&
                              values.secondEndDate && (
                                <th className="">
                                  <div className="text-center w-[17rem] font-medium">
                                    Change
                                  </div>
                                </th>
                              )}
                          </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                          {generatedReport.map((data) => (
                            <>
                              <tr
                                className={`w-full px-[1.375rem] flex items-center justify-between h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 ${
                                  activeTab === 'summary' &&
                                  ([
                                    tableHeaders.gross_proft,
                                    tableHeaders.gross_margin,
                                    tableHeaders.net_profit,
                                    tableHeaders.net_margin,
                                  ].includes(data.accountLabel)
                                    ? 'text-[#101828] bg-[#EEEEEE] font-semibold'
                                    : '')
                                } ${
                                  activeTab == 'details' &&
                                  ([
                                    tableHeaders.gross_proft,
                                    tableHeaders.gross_margin,
                                    tableHeaders.net_profit,
                                    tableHeaders.net_margin,
                                  ].includes(data.accountLabel)
                                    ? 'bg-[#FFF9F4]'
                                    : 'text-[#101828] bg-[#EEEEEE]')
                                }`}
                              >
                                <td className="whitespace-nowrap">
                                  <div className="w-[11rem] text-[#101828] text-left font-semibold">
                                    {data.accountLabel}
                                  </div>
                                </td>

                                <td
                                  className={`whitespace-nowrap ${
                                    data.secondDateRange?.total
                                      ? 'text-center'
                                      : 'text-right'
                                  }`}
                                >
                                  <div className="w-[17rem] text-[#101828] font-semibold ">
                                    {activeTab === 'details' &&
                                    ![
                                      tableHeaders.gross_proft,
                                      tableHeaders.gross_margin,
                                      tableHeaders.net_profit,
                                      tableHeaders.net_margin,
                                    ].includes(data.accountLabel)
                                      ? ''
                                      : formatValueWithSign(
                                          data.firstDateRange?.total,
                                          [
                                            tableHeaders.gross_margin,
                                            tableHeaders.net_margin,
                                          ].includes(data.accountLabel),
                                          currencySymbol,
                                        )}
                                  </div>
                                </td>

                                {values.compare && data?.secondDateRange && (
                                  <td className="whitespace-nowrap">
                                    <div className="w-[17rem] text-center text-[#101828] font-semibold">
                                      {activeTab === 'details' &&
                                      ![
                                        tableHeaders.gross_proft,
                                        tableHeaders.gross_margin,
                                        tableHeaders.net_profit,
                                        tableHeaders.net_margin,
                                      ].includes(data.accountLabel)
                                        ? ''
                                        : formatValueWithSign(
                                            data.secondDateRange?.total,
                                            [
                                              tableHeaders.gross_margin,
                                              tableHeaders.net_margin,
                                            ].includes(data.accountLabel),
                                            currencySymbol,
                                          )}
                                    </div>
                                  </td>
                                )}

                                {values.compare &&
                                  data?.secondDateRange &&
                                  ([
                                    tableHeaders.income,
                                    tableHeaders.cost_of_goods_sold,
                                    tableHeaders.operating_expenses,
                                  ].includes(data.accountLabel) &&
                                  activeTab === 'details' ? (
                                    ''
                                  ) : (
                                    <td className="whitespace-nowrap">
                                      <div className="w-[17rem] flex justify-center gap-2 text-[#101828] self-end text-right float-right">
                                        <p className="font-semibold ">
                                          {formatValueWithSign(
                                            data?.difference,
                                            [
                                              tableHeaders.gross_margin,
                                              tableHeaders.net_margin,
                                            ].includes(data.accountLabel),
                                            currencySymbol,
                                          )}
                                        </p>
                                        {!(
                                          data?.secondDateRange?.total ===
                                            '0' ||
                                          data?.firstDateRange?.total === '0'
                                        ) && (
                                          <div className="flex gap-1 flex-wrap align-middle font-light">
                                            {data?.difference < 0 ? (
                                              <DecreaseIcon />
                                            ) : (
                                              <IncreaseIcon />
                                            )}
                                            <p>
                                              {formatValueWithSign(
                                                data?.percentage,
                                                true,
                                                currencySymbol,
                                              )}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                  ))}
                              </tr>

                              {activeTab === 'details' &&
                                data?.firstDateRange?.details?.length > 0 &&
                                data?.firstDateRange?.details?.map(
                                  (elem, index) => (
                                    <>
                                      <tr
                                        className={`w-full px-[1.375rem] ${
                                          Number(elem?.total || 0) == 0 &&
                                          Number(
                                            data.secondDateRange?.details.find(
                                              (val) =>
                                                val?.account?.id ==
                                                elem?.account?.id,
                                            )?.total || 0,
                                          ).toFixed() == 0
                                            ? 'hidden'
                                            : 'block'
                                        } flex items-center justify-between h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 text-[#E48642]`}
                                      >
                                        <td
                                          className="whitespace-nowrap cursor-pointer"
                                          onClick={() =>
                                            navigate(
                                              `/reports/account-transaction?accountId=${
                                                elem?.account?.id
                                              }&startDate=${
                                                filter?.startDate
                                              }&endDate=${filter?.endDate}&${
                                                filter?.accounts?.length > 0
                                                  ? `accounts=${filter?.accounts?.join(
                                                      ',',
                                                    )}`
                                                  : 'accounts'
                                              }&from=profit-and-loss&reportType=${selectedType}&currency=${selectedCurrency}&tags=${selectedTags?.join(',')}&reportType=${selectedType}&compare=${
                                                values.compare
                                                  ? 'true'
                                                  : 'false'
                                              }&secondStartDate=${
                                                values?.compare &&
                                                values.secondStartDate
                                                  ? getFormattedDate(
                                                      values.secondStartDate,
                                                      teamDateFormat,
                                                    )
                                                  : ''
                                              }&secondEndDate=${
                                                values?.compare &&
                                                values.secondEndDate
                                                  ? getFormattedDate(
                                                      values.secondEndDate,
                                                      teamDateFormat,
                                                    )
                                                  : ''
                                              }`,
                                            )
                                          }
                                        >
                                          <div className="w-[11rem] text-left pl-4">
                                            {elem?.account?.name}
                                          </div>
                                        </td>
                                        <td className="whitespace-nowrap">
                                          <div
                                            className={`w-[17rem]  ${
                                              data.secondDateRange
                                                ? 'text-center'
                                                : 'text-right'
                                            }  text-[#101828]`}
                                          >
                                            {formatValueWithSign(
                                              elem?.total || 0,
                                              false,
                                              currencySymbol,
                                            )}
                                          </div>
                                        </td>
                                        {values.compare &&
                                          data?.secondDateRange && (
                                            <td className="whitespace-nowrap">
                                              <div className="w-[17rem] text-center text-[#101828] ">
                                                {formatValueWithSign(
                                                  data.secondDateRange?.details.find(
                                                    (val) =>
                                                      val?.account?.id ==
                                                      elem?.account?.id,
                                                  )?.total || 0,
                                                  false,
                                                  currencySymbol,
                                                )}
                                              </div>
                                            </td>
                                          )}

                                        {values.compare &&
                                          data.secondDateRange && (
                                            <td className="whitespace-nowrap">
                                              <div className="w-[17rem] flex justify-center gap-2 text-[#101828] self-end text-right float-right">
                                                <p className="font-semibold ">
                                                  {formatValueWithSign(
                                                    elem?.detailDifference,
                                                    false,
                                                    currencySymbol,
                                                  )}
                                                </p>
                                                {!(
                                                  elem?.total === '0' ||
                                                  !data.secondDateRange?.details.find(
                                                    (val) =>
                                                      val?.account?.id ==
                                                      elem?.account?.id,
                                                  )
                                                ) && (
                                                  <div className="flex gap-1 flex-wrap align-middle font-light">
                                                    {elem?.detailDifference <
                                                    0 ? (
                                                      <DecreaseIcon />
                                                    ) : (
                                                      <IncreaseIcon />
                                                    )}
                                                    <p>
                                                      {formatValueWithSign(
                                                        elem?.detailPercentage,
                                                        true,
                                                        currencySymbol,
                                                      )}
                                                    </p>
                                                  </div>
                                                )}
                                              </div>
                                            </td>
                                          )}
                                      </tr>

                                      {data?.firstDateRange?.details?.length ==
                                        index + 1 && (
                                        <tr className="w-full px-[1.375rem] flex items-center justify-between h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 text-[#101828] font-semibold">
                                          <td className="whitespace-nowrap">
                                            <div className="w-[11rem] text-left">
                                              {data.accountLabel ==
                                              tableHeaders.income
                                                ? 'Total Income'
                                                : data.accountLabel ==
                                                    tableHeaders.operating_expenses
                                                  ? 'Total Operating Expenses'
                                                  : 'Total Cost Of Goods Sold'}
                                            </div>
                                          </td>

                                          <td className="whitespace-nowrap">
                                            <div
                                              className={`w-[17rem] ${
                                                data.secondDateRange
                                                  ? 'text-center font-bold'
                                                  : 'text-right'
                                              }  text-[#101828]`}
                                            >
                                              {formatValueWithSign(
                                                data?.firstDateRange?.total,
                                                false,
                                                currencySymbol,
                                              )}
                                            </div>
                                          </td>

                                          {values.compare &&
                                            data?.secondDateRange && (
                                              <td className="whitespace-nowrap">
                                                <div className="w-[17rem] text-center font-bold text-[#101828]">
                                                  {formatValueWithSign(
                                                    data?.secondDateRange
                                                      ?.total,
                                                    false,
                                                    currencySymbol,
                                                  )}
                                                </div>
                                              </td>
                                            )}

                                          {values.compare &&
                                            data?.secondDateRange && (
                                              <td className="whitespace-nowrap">
                                                <div className="w-[17rem] flex justify-center gap-2 text-[#101828] self-end text-right float-right">
                                                  <p className="font-bold">
                                                    {formatValueWithSign(
                                                      data?.difference,
                                                      false,
                                                      currencySymbol,
                                                    )}
                                                  </p>
                                                  <div className="flex gap-1 flex-wrap align-middle font-light">
                                                    {data?.difference < 0 ? (
                                                      <DecreaseIcon />
                                                    ) : (
                                                      <IncreaseIcon />
                                                    )}
                                                    <p>
                                                      {formatValueWithSign(
                                                        data?.difference,
                                                        true,
                                                        currencySymbol,
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </td>
                                            )}
                                        </tr>
                                      )}
                                    </>
                                  ),
                                )}
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className=" flex justify-center">
                      <button
                        className="w-[7rem] cursor-pointer justify-center mt-2  rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-10  bg-indigo-500 text-white"
                        onClick={() => {
                          activeTab === 'summary'
                            ? setActiveTab('details')
                            : setActiveTab('summary');
                        }}
                      >
                        {activeTab === 'summary'
                          ? 'View Details'
                          : 'View Summary'}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};
export default ProfitAndLossReport;
