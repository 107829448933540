import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { boot, shutdown } from '@intercom/messenger-js-sdk';
import { useSelector, useDispatch } from 'react-redux';
import Members from './members';
import TextInputDash from '../../components/TextInputDash';
import {
  STAFF,
  TEAM_CURRENCY,
  TEAM_DATE_FORMAT,
  TEAM_ID,
  TEAM_NAME,
} from '../../utils/Utils';
import {
  updateTeamById,
  getCountriesData,
  getCurrenciesData,
  uploadTeamLogo,
  getTeamById,
} from '../../API/backend_helper';
import States from '../../data/states.json';
import DropdownFull from '../../components/DropdownFull';
import {
  BussinessDropdown1,
  BussinessDropdownCorporation,
  BussinessDropdownLLC,
  BussinessDropdownOther,
  Currencies,
  CustomerPay,
  IndustryData,
  OnlinePayment,
} from '../createworkspace/data';
import UploadFile from '../../components/UploadFile';
import ImageCropModal from '../../components/ImageCropModal';
import Billing from './billing/Billing';
import AlertBox from '../../components/AlertBox';
import WebhookEvents from './webhookEvents';
import WorkspaceDetails from './WorkspaceDetails';
import EnrichPlayground from './enrichPlayground';
import EnrichTeamLogs from './enrichLogs';
import AppLayout from '../../components/AppLayout';
import { setLocalUser } from '../../redux/User/reducer';
import { setTeam } from '../../redux/Team/reducer';

const ManageWorspace = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [activeTab, setActiveTab] = useState(1);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [loading] = useState(false);
  const [files, setFiles] = useState([]);
  const [imgPreview, setImgPreview] = useState('');
  const [imgName, setImgName] = useState('');
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [currentImageForCrop, setCurrentImageForCrop] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const { localUser } = useSelector((state) => state.User);
  const { subscription } = useSelector((state) => state.Subscription);

  useEffect(() => {
    if (activeTab === 4) {
      shutdown();
    } else {
      boot();
    }
  }, [activeTab]);

  useEffect(
    () => () => files.forEach((file) => URL.revokeObjectURL(file.preview)),
    [],
  );

  useEffect(() => {
    if (team?.logoUrl) {
      setImgPreview(team?.logoUrl);
    }
  }, [team]);

  useEffect(() => {
    if (id) {
      if (id === 'details') {
        setActiveTab(1);
      } else if (id === 'members') {
        setActiveTab(2);
      } else if (id === 'billing') {
        setActiveTab(4);
      }
    }
  }, [id]);

  const loadCountries = async () => {
    try {
      const res = await getCountriesData();
      setCountries(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (activeTab) {
      if (
        (subscription?.plan?.id &&
          (subscription?.status === 'trialing' ||
            subscription?.status === 'active')) ||
        team.isOnComplimentary
      ) {
        loadCountries();
      } else if (activeTab !== 4) {
        setActiveTab(4);
      }
    }
  }, [subscription, id, activeTab]);

  const getBusinessSubTypes = (type) => {
    if (type === 'Self-employed' || type === 'Sole Proprietorship') {
      return [];
    }
    if (type === 'Limited Liability Company') {
      return BussinessDropdownLLC;
    }
    if (type === 'Corporation') {
      return BussinessDropdownCorporation;
    }
    return BussinessDropdownOther;
  };

  const uploadLogo = async (logo) => {
    const formData = new FormData();
    formData.append('logo', logo);
    uploadTeamLogo(formData, team?.id);
  };

  const handleSubmit = async (_values, formik) => {
    const logo = files[0];
    setDetailsLoading(true);
    const values = { ..._values };
    const { email } = values;
    if (!email) {
      values.email = null;
    }
    try {
      if (logo) {
        await uploadLogo(logo);
      } else {
        values.logoUrl = '';
      }
      if (!values.website) {
        delete values.website;
      }
      if (!values?.billEmail?.includes('@bills.getcount.com')) {
        values.billEmail = `${values?.billEmail}@bills.getcount.com`;
      }
      await updateTeamById(team?.id, values);
      const res = await getTeamById(team?.id);
      localStorage.setItem(TEAM_NAME, values?.name);
      dispatch(setTeam(res));
      setSuccessAlert(true);
      setTimeout(() => setSuccessAlert(false), 5000);
      const tempUser = JSON.parse(JSON.stringify(localUser));
      const teamIndex = tempUser?.teams?.findIndex((t) => t?.id === res?.id);
      tempUser.teams[teamIndex] = res;
      dispatch(setLocalUser(tempUser));
    } catch (err) {
      setSuccessAlert(false);
      console.log('err', err);
    } finally {
      setDetailsLoading(false);
      if (values?.billEmail?.includes('@bills.getcount.com')) {
        formik.setFieldValue('billEmail', values?.billEmail?.split('@')?.[0]);
      }
    }
  };

  document.title = 'COUNT | Manage Workspace';

  return (
    <>
      <ImageCropModal
        isOpen={cropModalOpen}
        setIsOpen={setCropModalOpen}
        image={currentImageForCrop}
        setPreviewImage={setImgPreview}
        setFiles={setFiles}
        imgName={imgName}
      />
      <AppLayout pageId="manageWorkspaceContainer">
        {loading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <main className="grow px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="flex overflow-hidden" style={{ width: '100%' }}>
              <div className="relative flex  flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-[#182235]">
                <div className="flex  gap-[30px] items-center mb-[30px]">
                  <h1 className="text-2xl md:text-[36px] text-[#00000099] dark:text-slate-100 font-medium ">
                    Manage Workspace
                  </h1>
                </div>

                <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
                  {((subscription?.plan?.id &&
                    (subscription?.status === 'trialing' ||
                      subscription?.status === 'active')) ||
                    team.isOnComplimentary) && (
                    <div
                      onClick={() => setActiveTab(1)}
                      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                    ${
                      activeTab === 1 &&
                      ' !border-indigo-500  font-medium !text-[#101828]'
                    } `}
                    >
                      Workspace Details
                    </div>
                  )}
                  {((subscription?.plan?.id &&
                    (subscription?.status === 'trialing' ||
                      subscription?.status === 'active')) ||
                    team.isOnComplimentary) && (
                    <div
                      onClick={() => setActiveTab(2)}
                      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                    ${
                      activeTab === 2 &&
                      ' !border-indigo-500  font-medium !text-[#101828]'
                    } `}
                    >
                      Manage Team
                    </div>
                  )}
                  <div
                    onClick={() => setActiveTab(4)}
                    className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                    ${
                      activeTab === 4 &&
                      ' !border-indigo-500  font-medium !text-[#101828]'
                    } `}
                  >
                    Billing
                  </div>

                  {localUser?.realm === STAFF && (
                    <div
                      onClick={() => setActiveTab(3)}
                      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                      ${
                        activeTab === 3 &&
                        ' !border-indigo-500  font-medium !text-[#101828]'
                      } `}
                    >
                      Webhook Events
                    </div>
                  )}
                  {localUser?.realm === STAFF && (
                    <div
                      onClick={() => setActiveTab(5)}
                      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                      ${
                        activeTab === 5 &&
                        ' !border-indigo-500  font-medium !text-[#101828]'
                      } `}
                    >
                      Enrich Playground
                    </div>
                  )}
                  {localUser?.realm === STAFF && (
                    <div
                      onClick={() => setActiveTab(6)}
                      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                      ${
                        activeTab === 6 &&
                        ' !border-indigo-500  font-medium !text-[#101828]'
                      } `}
                    >
                      Workspace Logs
                    </div>
                  )}
                </div>
                {/* TODO: Could you please move this section to a separate file? */}
                {activeTab === 1 && (
                  <WorkspaceDetails
                    successAlert={successAlert}
                    team={team}
                    handleSubmit={handleSubmit}
                    countries={countries}
                    currencies={currencies}
                    getBusinessSubTypes={getBusinessSubTypes}
                    imgPreview={imgPreview}
                    setImgPreview={setImgPreview}
                    detailsLoading={detailsLoading}
                    files={files}
                    setFiles={setFiles}
                    setCurrentImageForCrop={setCurrentImageForCrop}
                    setCropModalOpen={setCropModalOpen}
                    setImgName={setImgName}
                  />
                )}
                {activeTab === 2 && <Members />}
                {activeTab === 3 && <WebhookEvents />}
                {activeTab === 4 && <Billing />}
                {activeTab === 5 && localUser?.realm === STAFF && (
                  <EnrichPlayground />
                )}
                {activeTab === 6 && localUser?.realm === STAFF && (
                  <EnrichTeamLogs />
                )}
              </div>
            </div>
          </main>
        )}
      </AppLayout>
    </>
  );
};

export default ManageWorspace;
