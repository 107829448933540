import React from 'react';
import { DevEnv } from 'utils/Utils';

const TobTabs = ({
  activeTab,
  setActiveTab,
  MANAGE_REPORTERS_TAB,
  REPORTERS_TRANSACTIONS_TAB,
  EXPENSE_TYPES_TAB,
  UNMATCHED_RECEIPTS_TAB,
}) => (
  <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
    <div
      onClick={() => setActiveTab(REPORTERS_TRANSACTIONS_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === REPORTERS_TRANSACTIONS_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Transactions
    </div>

    <div
      onClick={() => setActiveTab(UNMATCHED_RECEIPTS_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === UNMATCHED_RECEIPTS_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Unmatched Receipts
    </div>

    <div
      onClick={() => setActiveTab(MANAGE_REPORTERS_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === MANAGE_REPORTERS_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Manage Reporters
    </div>
    <div
      onClick={() => setActiveTab(EXPENSE_TYPES_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === EXPENSE_TYPES_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Manage Expense Types
    </div>
  </div>
);

export default TobTabs;
