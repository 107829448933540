import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MembersModal from './MembersModal';
import {
  changeMemberRole,
  deleteInvitedMemberApi,
  deleteTeamApi,
  getFirmMembersApi,
  removeMemberFromFirmApi,
  withdrawMemberInvitationAPI,
  addMemberToFirm,
} from '../../../API/backend_helper';
import {
  VIEWER,
  ACCOUNTANT,
  TEAM_ID,
  TEAM_NAME,
  getMemberInvitationStatusColor,
} from '../../../utils/Utils';
import LeaveModal from '../../manageWorkspaces/manage/LeaveModal';

const FirmMembers = ({
  accountantFirmId,
  memberInvitations,
  loadAccountantFirm,
}) => {
  const navigate = useNavigate();
  const TeamId = localStorage.getItem(TEAM_ID);
  const TeamName = localStorage.getItem(TEAM_NAME);
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [members, setMembers] = useState([]);
  const [localInvite, setLocalInvite] = useState({});
  const { accessType, localUser } = useSelector((state) => state.User);

  const [deleteWorkspace, setDeleteWorkspace] = useState(false);
  const [removeFirmMemberLoader, setRemoveFirmMemberLoader] = useState({});
  const [withdrawFirmMemberLoader, setWithdrawFirmMemberLoader] = useState({});

  const getMembers = async (isLoading = false) => {
    if (!loading && isLoading) {
      setLoading(true);
    }
    try {
      const res = await getFirmMembersApi(accountantFirmId);
      setMembers(res?.data?.firmMembers);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMembers(true);
    loadAccountantFirm({ loading: false });
  }, []);

  const deleteInvitedMember = async (id) => {
    try {
      const res = await deleteInvitedMemberApi(id);
      getMembers();
    } catch (err) {
      console.log('err', err);
    }
  };

  const removeMemberFromFirm = async (id) => {
    setRemoveFirmMemberLoader((prev) => ({ ...prev, [id]: true }));
    try {
      await removeMemberFromFirmApi(accountantFirmId, id);
      await getMembers();
    } catch (err) {
      console.log('err', err);
    } finally {
      setRemoveFirmMemberLoader((prev) => {
        delete prev[id];
        return prev;
      });
    }
  };

  const resendInvite = async (invite) => {
    setLocalInvite(invite);
    setIsOpenModal(true);
  };

  const onRoleChange = async (id, role) => {
    try {
      const res = await changeMemberRole(id, { newRole: role });
      getMembers();
    } catch (err) {
      console.log('err', err);
    }
  };

  const onDeleteWorkspace = async () => {
    setLoading(true);
    try {
      const res = await deleteTeamApi(TeamId);
      localStorage.setItem(TEAM_ID, '');
      localStorage.setItem(TEAM_NAME, '');
      setLoading(false);
      navigate('/manage-workspaces');
    } catch (err) {
      setLoading(false);
      setDeleteWorkspace(false);
      console.log('err', err);
    }
  };

  const withdrawMemberInvitation = async (invitationId) => {
    setWithdrawFirmMemberLoader((prev) => ({ ...prev, [invitationId]: true }));
    try {
      await withdrawMemberInvitationAPI(accountantFirmId, invitationId);
      await loadAccountantFirm({ loading: false });
    } catch (err) {
      console.log('err', err);
    } finally {
      setWithdrawFirmMemberLoader((prev) => {
        delete prev[invitationId];
        return prev;
      });
    }
  };

  const inviteMemberByEmail = async (email, invitationId = '') => {
    if (invitationId) {
      setWithdrawFirmMemberLoader((prev) => ({
        ...prev,
        [invitationId]: true,
      }));
    }
    try {
      await addMemberToFirm(accountantFirmId, {
        email: email?.toLowerCase(),
      });
      await loadAccountantFirm({ loading: false });
    } catch (err) {
      console.log('err', err);
    } finally {
      if (invitationId) {
        setWithdrawFirmMemberLoader((prev) => {
          delete prev[invitationId];
          return prev;
        });
      }
    }
  };

  return (
    <div className="flex overflow-hidden" style={{ width: '100%' }}>
      <MembersModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        localInvite={localInvite}
        setLocalInvite={setLocalInvite}
        inviteMemberByEmail={inviteMemberByEmail}
      />
      <LeaveModal
        isOpen={deleteWorkspace}
        title="Delete Workspace"
        description={`Are you sure you want to delete "${TeamName}"? This action cannot be undone.`}
        setIsOpen={setDeleteWorkspace}
        onCompletion={onDeleteWorkspace}
        loading={loading}
        team={deleteWorkspace}
      />
      <div className="relative flex  flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-[#182235]">
        {loading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <main className="grow pb-4 w-full max-w-9xl mx-auto">
            <div className="flex items-center justify-between mb-7  ">
              <h1 className="text-2xl  text-[#101828B2] dark:text-slate-100 font-medium">
                Members
              </h1>
              {accessType !== VIEWER && accessType !== ACCOUNTANT && (
                <button
                  className="btn !bg-indigo-500 text-base hover:!bg-indigo-600 text-white"
                  aria-controls="basic-modal"
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                  type="button"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Add Members
                </button>
              )}
            </div>
            <div className="bg-white flex-1 dark:bg-slate-800 rounded-sm  overflow-x-auto">
              <table className="table-auto w-full dark:text-slate-300 mb-[5.938rem]">
                {/* Table header */}
                <thead className="text-sm shadow-md text-[#667085] dark:text-slate-400 border-none ">
                  <tr>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Name</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Email</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Access Level</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" />
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm  divide-y divide-slate-200 dark:divide-slate-700 pb-5">
                  {members?.map?.((d, i) => (
                    <tr className="border-b border-slate-300" key={i}>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center text-slate-800 pr-3 w-max">
                          <div className=" shrink-0 mr-2 sm:mr-3 ">
                            <div className="w-10 h-10 flex items-center justify-center bg-slate-400 dark:bg-slate-700 rounded-full">
                              {d.avatarUrl ? (
                                <img
                                  src={d.avatarUrl}
                                  alt={`${d.firstName} Avatar`}
                                  className="w-10 h-10 rounded-full"
                                />
                              ) : (
                                <div className="capitalize text-white font-bold text-lg">
                                  {d?.firstName?.charAt?.(0)}
                                  {d?.lastName?.charAt?.(0)}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className=" text-[#667085] dark:text-slate-100">
                            {d?.firstName} {d?.lastName}
                          </div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className=" text-[#667085] dark:text-slate-100">
                          {d?.email}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                        <div className="flex">
                          <div className="cursor-default capitalize text-[#667085] dark:text-slate-100">
                            {d?.isAccountingFirmUser ? 'Owner' : 'Accountant'}
                          </div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                        <div className="flex justify-center">
                          <button
                            onClick={() => removeMemberFromFirm(d?.id)}
                            type="button"
                            className="ms-2 h-7 w-[75px] font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085] disabled:cursor-not-allowed disabled:opacity-80"
                            disabled={removeFirmMemberLoader?.[d?.id]}
                          >
                            {removeFirmMemberLoader?.[d?.id] ? (
                              <svg
                                className="animate-spin w-4 h-4 fill-current shrink-0"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                              </svg>
                            ) : d?.isDeleted ? (
                              'Resend Invite'
                            ) : (
                              'Delete'
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {memberInvitations?.filter((m) => m?.status !== 'accepted')
                    ?.length > 0 && (
                    <tr>
                      <td
                        colSpan={3}
                        className="px-2 text-base font-semibold first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                      >
                        Invitations:
                      </td>
                    </tr>
                  )}
                  {memberInvitations?.map?.(
                    (d, i) =>
                      d?.status !== 'accepted' && (
                        <tr className="border-b border-slate-300" key={i}>
                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                            <div
                              className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                                ${getMemberInvitationStatusColor(d?.status)}`}
                            >
                              {d?.status}
                            </div>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                            <div className=" text-[#667085] dark:text-slate-100">
                              {d?.email}
                            </div>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                            <div className="flex">
                              <div className="cursor-default capitalize text-[#667085] dark:text-slate-100">
                                {d?.isAccountingFirmUser
                                  ? 'Owner'
                                  : 'Accountant'}
                              </div>
                            </div>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                            <div className="flex justify-center">
                              <button
                                onClick={() =>
                                  d?.status === 'withdrawn' ||
                                  d?.status === 'declined'
                                    ? inviteMemberByEmail(d?.email, d?.id)
                                    : withdrawMemberInvitation(d?.id)
                                }
                                type="button"
                                className="ms-2 h-7 w-24 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085] disabled:cursor-not-allowed disabled:opacity-80"
                                disabled={withdrawFirmMemberLoader?.[d?.id]}
                              >
                                {withdrawFirmMemberLoader?.[d?.id] ? (
                                  <svg
                                    className="animate-spin w-4 h-4 fill-current shrink-0"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                  </svg>
                                ) : d?.status === 'withdrawn' ||
                                  d?.status === 'declined' ? (
                                  'Resend Invite'
                                ) : (
                                  'Cancel Invite'
                                )}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ),
                  )}
                </tbody>
              </table>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default FirmMembers;
