/* eslint-disable no-param-reassign */
import axios from 'axios';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { TEAM_ID, TEAM_NAME } from '../utils/Utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const getRefreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken) {
    try {
      const response = await axios.post(`${API_URL}/auth/refresh-token`, {
        refreshToken,
      });
      localStorage.setItem('accessToken', response?.data?.auth?.access_token);
      localStorage.setItem('refreshToken', response?.data?.auth?.refresh_token);

      return response?.data?.auth?.access_token;
    } catch (error) {
      // Handle error in refreshing the token (e.g., redirect to login)
      console.error('Unable to refresh token', error);
      toast.info('Session has expired. Please log in to continue');

      const valueToPreserve = localStorage.getItem('twoFactorToken'); // Save the value

      localStorage.clear(); // Clear all other keys

      if (valueToPreserve) {
        localStorage.setItem('twoFactorToken', valueToPreserve); // Restore the preserved key
      }

      window.location.href = '/signin';
      throw new Error('Unable to refresh token');
    }
  } else {
    toast.info('Session has expired. Please log in to continue');
    const valueToPreserve = localStorage.getItem('twoFactorToken'); // Save the value

    localStorage.clear(); // Clear all other keys

    if (valueToPreserve) {
      localStorage.setItem('twoFactorToken', valueToPreserve); // Restore the preserved key
    }

    window.location.href = '/signin';
    throw new Error('Unable to refresh token');
  }
};

function logout() {
  // Perform logout actions (e.g., clear tokens, redirect to login page)
  toast.info('Your session is being logged out due to inactivity');
  setTimeout(() => {
    const valueToPreserve = localStorage.getItem('twoFactorToken'); // Save the value

    localStorage.clear(); // Clear all other keys

    if (valueToPreserve) {
      localStorage.setItem('twoFactorToken', valueToPreserve); // Restore the preserved key
    }
    window.location.href = '/signin';
  }, 2000);
  // Redirect to login page or perform any other necessary actions
}
try {
  const lastActivityKey = 'lastActivityTime'; // Key to store the last activity time in localStorage
  let activityTimeout; // Variable to hold the timeout for debouncing

  // Function to update last activity time (debounced)
  const debounceActivityUpdate = () => {
    clearTimeout(activityTimeout); // Clear the previous timeout
    activityTimeout = setTimeout(() => {
      if (localStorage.getItem('accessToken')) {
        localStorage.setItem(lastActivityKey, Date.now()); // Update localStorage after the debounce period
      }
    }, 1000 * 10); // Debounce period of 10 seconds
  };

  // Function to check for inactivity
  const checkActivity = () => {
    const lastActivityTime =
      localStorage.getItem(lastActivityKey) || Date.now();
    const currentTime = Date.now();

    // Check if there was no activity in the last 3 hours
    if (currentTime - lastActivityTime > 3 * 60 * 60 * 1000) {
      if (localStorage.getItem('accessToken')) {
        logout(); // Log out the user if no activity for 3 hours
      }
    }
  };

  // Event listeners to detect user activity (e.g., mouse movements, key presses)
  window.addEventListener('mousemove', debounceActivityUpdate);
  window.addEventListener('keydown', debounceActivityUpdate);

  // Periodically check for user activity across all tabs every 30 minutes
  setInterval(checkActivity, 15 * 60 * 1000); // 30 minutes interval

  // Listen for `storage` event to synchronize activity across tabs
  window.addEventListener('storage', (event) => {
    if (event.key === lastActivityKey && localStorage.getItem('accessToken')) {
      checkActivity(); // Re-check activity when another tab updates it
    }
  });
} catch (e) {
  console.log('error', e);
}

export const getTeamId = () => localStorage.getItem(TEAM_ID);

// Function to check if the token is expired or about to expire
function isTokenExpired(token) {
  if (!token) {
    return false;
  }
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  // Consider token expired if it will expire in less than 5 minutes
  return decodedToken.exp < currentTime + 5 * 60;
}

// Create an Axios instance
const AxiosInstance = axios.create({
  baseURL: API_URL,
  // withCredentials: true,
  // mode: 'cors',
  headers: {
    // 'Content-Type': 'application/json',
    'count-workspace-id': getTeamId(),
    'X-Two-Factor': localStorage.getItem('twoFactorToken') || '',
  },
});

// Request interceptor to handle token expiration and refresh
AxiosInstance.interceptors.request.use(
  async (config) => {
    let accessToken = localStorage.getItem('accessToken');

    // Check if token is expired or about to expire
    if (isTokenExpired(accessToken)) {
      try {
        accessToken = await getRefreshToken();
      } catch (error) {
        // Handle error (e.g., redirect to login)
        return Promise.reject(error);
      }
    }
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers['count-workspace-id'] = getTeamId();
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  (response) =>
    // Return the response if it's successful
    response,
  // eslint-disable-next-line consistent-return
  (error) => {
    if (
      error?.response?.data?.errors?.error?.type === 'TEAM_NOT_FOUND' ||
      error?.response?.data?.errors?.error?.type === 'NO_TEAM_USER'
    ) {
      toast.error(
        'Access to this workspace is no longer available. Please contact the owner',
      );
      setTimeout(() => {
        localStorage.setItem(TEAM_ID, '');
        localStorage.setItem(TEAM_NAME, '');
        window.location.replace('/manage-workspaces');
      }, 3500);
    } else if (error.response.status === 401) {
      toast.error('Unauthorized');
      const valueToPreserve = localStorage.getItem('twoFactorToken'); // Save the value

      localStorage.clear(); // Clear all other keys

      if (valueToPreserve) {
        localStorage.setItem('twoFactorToken', valueToPreserve); // Restore the preserved key
      }

      setTimeout(() => {
        window.location.replace('/signin');
      }, 3500);
    } else {
      // Return a rejected Promise with the error for further handling
      return Promise.reject(error);
    }
  },
);

export default AxiosInstance;
