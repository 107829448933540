/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import Tooltip from 'components/Tooltip';
import { getStringSegments } from 'utils/Utils';
import DropdownFull from '../../components/DropdownFull';

const Step2 = ({ mapping, setMapping, keyArray, parsedInfo, error }) => {
  const { accounts } = parsedInfo;
  const dropdownHeaders = [
    { name: 'Account Number', id: 'accountNumber' },
    { name: 'Account Name', id: 'accountName' },
    { name: 'Account Description', id: 'accountDescription' },
    { name: 'Account Type', id: 'accountType' },
    { name: 'Account Sub-Type', id: 'accountSubType' },
  ];

  const checkIfMatched = (mapping, key) => {
    const _key = Object.keys(mapping).find((mapKey) => mapping[mapKey] === key);
    const exists = dropdownHeaders?.filter((d) => d?.id === _key);
    if (exists?.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <div className="">
      <h3 className="text-2xl mb-2 font-semibold">
        Match Columns to COUNT Fields
      </h3>
      {error && <p className="text-rose-500">{error}</p>}
      <div className="bg-transparent  w-full rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] max-w-[600px] ">
        {/* Table */}
        <div
          className="overflow-x-auto h-full w-full "
          id="createBillTableContainer"
        >
          <table className="table-auto w-full  ">
            {/* Table header */}
            <thead className="text-base font-semibold text-[#667085] border-b border-[#D0D5DD] bg-[#A0CD850F]">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-medium text-left">File Column</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 w-[180px] py-3 whitespace-nowrap">
                  <div className="font-medium text-left">COUNT's Column</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-medium text-left">Match Status</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-[#D0D5DD] ">
              {keyArray?.map((key, i) => (
                <tr key={i} className="h-16">
                  <td className="px-2 first:pl-5 last:pr-5  py-2 text-left text-base">
                    <div className="flex justify-between items-center gap-4">
                      <span>{key.name}</span>
                      <Tooltip
                        content={(() => {
                          const str = `
                          Data preview for "${key.name || '-- '}" (first ${accounts?.total || 0} row)`;
                          const segments = getStringSegments(str, 70);
                          return (
                            <>
                              {segments.map((segment, index) => (
                                <p key={index}>{segment}</p>
                              ))}
                              {accounts?.length > 0 && (
                                <ul className="list-disc pl-4">
                                  {accounts?.map(
                                    (p, i) =>
                                      p?.[key?.name] && (
                                        <li key={i}> {p?.[key?.name]}</li>
                                      ),
                                  )}
                                </ul>
                              )}
                            </>
                          );
                        })()}
                        contentClassName="border-none rounded-[10px] overflow-visible text-xs text-[#667085] relative tooltipArrowUp"
                        size="lg"
                        position="bottom"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 14 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mb-px"
                        >
                          <path
                            d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                            stroke="#667085"
                            strokeWidth="0.784912"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                            stroke="#667085"
                            strokeWidth="0.784912"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                            fill="#667085"
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5  py-2 text-left">
                    <DropdownFull
                      options={[{ id: '-', name: '--' }, ...dropdownHeaders]}
                      className="!w-fit !h-fit"
                      name="name"
                      bottomOfTable
                      selected={
                        Object.keys(mapping).find(
                          (mapKey) => mapping[mapKey] === keyArray[i]?.name,
                        ) || '(Unmatched)'
                      }
                      setSelected={(id) => {
                        const newMapping = { ...mapping };

                        const previousKey = Object.keys(newMapping).find(
                          (mapKey) => newMapping[mapKey] === keyArray[i]?.name,
                        );
                        if (previousKey) {
                          delete newMapping[previousKey];
                        }

                        newMapping[id] = keyArray[i]?.name;
                        setMapping(newMapping);
                      }}
                      ids={['importAccountsContainer']}
                      isTableDropdown
                      height="h-[38px] min-w-[175px] max-w-[175px] text-nowrap overflow-hidden text-ellipsis"
                      buttonTextClass="max-w-[90%] overflow-hidden text-ellipsis"
                      buttonTextClassSub="max-w-[100%] overflow-hidden text-ellipsis"
                    />
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5  py-2 text-left text-base">
                    {checkIfMatched(mapping, keyArray[i]?.name) ? (
                      <div className="flex gap-2">
                        <span className="inline-block h-2 w-2 rounded-full bg-green-600 mt-[7px]" />
                        <div>
                          <p>Matched</p>
                        </div>
                      </div>
                    ) : (
                      <div className="flex gap-2">
                        <span className="inline-block h-2 w-2 rounded-full bg-slate-400 mt-2" />
                        <div>
                          <p> No match found</p>
                          <p className="text-xs text-slate-400">
                            Will not be imported
                          </p>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Step2;
