import PaginationClassic from 'components/PaginationClassic';
import { Loader } from 'components/Svg';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { trimText, VIEWER } from 'utils/Utils';

const SavedReportsTab = ({
  records,
  isLoading,
  searchVal,
  descriptionLength,
  viewReportDetails,
  localLimit,
  page,
  totalRecords,
  loadReport,
  setLocalLimit,
  handleDelete,
}) => {
  const { accessType } = useSelector((state) => state.User);

  const [deleteLoading, setDeleteLoading] = useState({});

  const onDelete = async (id) => {
    setDeleteLoading((prev) => ({ ...prev, [id]: true }));
    try {
      await handleDelete(id);
    } catch (err) {
      console.log('err', err);
    } finally {
      setDeleteLoading((prev) => {
        delete prev[id];
        return prev;
      });
    }
  };

  return !(records?.length > 0) || isLoading ? (
    isLoading ? (
      <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
        <Loader />
        <p className="text-slate-600 font-medium text-[0.875rem] text-center">
          Fetching data, please wait...
        </p>
      </div>
    ) : (
      <div className="flex justify-between flex-grow pt-5 pl-7  flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
        <p className="text-left text-[#667085] h-[2.375rem] text-[1.25rem] font-semibold leading-[2.375rem] flex  justify-start self-stretch">
          Your Past Reports
        </p>
        <p className="self-stretch flex mt-6 flex-col justify-center gap-2  text-center text-slate-600 text-[1rem] leading-6 font-normal h-16">
          <p className="text-[#667085]">
            You don&acute;t have any past reports!
          </p>
          <p className="text-[#101828]">
            You don't have any saved reports. Press save when viewing a report
            to save it to this list!
          </p>
        </p>
        <svg
          width="224"
          height="124"
          viewBox="0 0 224 124"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2815_61891)">
            <path
              d="M69.5581 140.116C107.974 140.116 139.116 108.974 139.116 70.5581C139.116 32.1422 107.974 1 69.5581 1C31.1422 1 0 32.1422 0 70.5581C0 108.974 31.1422 140.116 69.5581 140.116Z"
              fill="#F9EAE0"
            />
            <g clipPath="url(#clip1_2815_61891)">
              <path
                d="M224.26 160.427L131.5 213.981V106.872L224.26 53.3164V160.427Z"
                fill="#78BD4F"
              />
              <path
                d="M131.5 213.981L38.7401 160.427V53.3164L131.5 106.872V213.981Z"
                fill="#78BD4F"
              />
              <path
                d="M40.1315 53.3177L131.5 0.566406L222.869 53.3177L131.5 106.07L40.1315 53.3177Z"
                fill="white"
              />
              <path
                d="M131.5 1.3718L221.477 53.32L131.5 105.268L41.5228 53.32L131.5 1.3718ZM131.5 -0.234375L38.7401 53.32L131.5 106.875L224.26 53.32L131.5 -0.234375Z"
                fill="#081F2D"
              />
              <path
                d="M131.5 106.871V213.981"
                stroke="#081F2D"
                strokeWidth="1.39131"
                strokeLinejoin="round"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2815_61891">
              <rect width="289" height="254.02" fill="white" />
            </clipPath>
            <clipPath id="clip1_2815_61891">
              <rect
                width="193"
                height="197"
                fill="white"
                transform="translate(39 -6)"
              />
            </clipPath>
          </defs>
        </svg>

        {/* <img
  src={reportBook}
  alt=""
  className="max-h-[10rem] h-auto w-auto flex items-center justify-center max-w-full  rounded-xl"
/> */}
      </div>
    )
  ) : (
    <div className="flex flex-col px-7 py-5 overflow-x-auto scrollbar  flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
      <p className="pb-6 py-2 text-[#667085] font-semibold">
        Your Past Reports
      </p>
      <table className="table-fixed overflow-x-auto scrollbar w-full dark:text-slate-300 min-w-max">
        <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
          <tr className="w-full py-[1.438rem] px-[1.375rem] flex items-center justify-between">
            <th className="">
              <div className="text-left w-[6rem] font-medium">Date</div>
            </th>
            <th className="">
              <div className="text-left w-[17rem] font-medium">
                Report Title
              </div>
            </th>
            <th className="">
              <div className="text-left w-[12rem] font-medium">Report Type</div>
            </th>

            <th className="">
              <div className="text-left w-[9rem] font-medium">Action</div>
            </th>
          </tr>
        </thead>
        {records && (
          <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
            {records.filter((report) =>
              report.name.toLowerCase().includes(searchVal.toLowerCase()),
            ).length === 0 ? (
              <tr>
                <td colSpan="4" className="text-center py-4">
                  No reports found
                </td>
              </tr>
            ) : (
              records
                .filter((report) =>
                  report.name.toLowerCase().includes(searchVal.toLowerCase()),
                )
                .map((report, index) => (
                  <tr
                    className="w-full px-[1.375rem] flex items-center justify-between h-20 hover:bg-[#a0cd85] hover:bg-opacity-5"
                    key={report.id}
                  >
                    <td className="whitespace-nowrap">
                      <div className="w-[6rem] text-left">
                        {moment(report.createdAt).format('ll')}
                      </div>
                    </td>
                    <td className="whitespace-nowrap">
                      <div className="w-[17rem] text-left text-[#101828]">
                        {report?.name
                          ? trimText(report?.name, descriptionLength)
                          : '---'}
                      </div>
                    </td>
                    <td className="whitespace-nowrap">
                      <div className="w-[12rem] text-left">
                        {report?.subType || '---'}
                      </div>
                    </td>
                    <td className="whitespace-nowrap text-left w-[9rem]">
                      <div className="h-[1.875rem] w-[4rem] flex items-center justify-center p-2.5 rounded-[0.313rem] bg-opacity-10 gap-[8px]">
                        <button
                          className="flex items-center justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap bg-indigo-500 text-white"
                          onClick={() => viewReportDetails(report)}
                          type="button"
                        >
                          View Report
                        </button>
                        {accessType !== VIEWER && (
                          <button
                            onClick={() => onDelete(report?.id)}
                            className="flex items-center min-w-20 justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap border border-[#667085] text-[#667085] disabled:cursor-not-allowed disabled:opacity-70"
                            type="button"
                            disabled={deleteLoading[report?.id]}
                          >
                            {deleteLoading[report?.id] ? (
                              <Loader
                                height="h-5"
                                width="w-5"
                                color="#667085"
                              />
                            ) : (
                              'Delete'
                            )}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        )}
      </table>
      <div className="mt-10">
        {!isLoading && records?.length > 0 && (
          <PaginationClassic
            pagination={{
              limit: localLimit,
              page,
              totalRecords,
            }}
            onPageChange={loadReport}
            setLimit={setLocalLimit}
          />
        )}
      </div>
    </div>
  );
};
export default SavedReportsTab;
