import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import TagModal from './TagModal';
import {
  createTag,
  deleteTag,
  getTags,
  updateTag,
} from '../../../API/backend_helper';
import NoTagsImg from '../../../images/custom/empty-search.svg';
import DeleteModal from './DeleteModal';
import GroupTagsModal from './GroupTagsModal';

const Tags = () => {
  const [loading, setLoading] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [activeTag, setActiveTag] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [groupTagsModalOpen, setGroupTagsModalOpen] = useState(false);

  useEffect(() => {
    loadTags();
  }, []);

  const loadTags = async (loading = true) => {
    if (loading) {
      setLoading(true);
    }
    try {
      const res = await getTags();
      setTags(res);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddTag = async (tag) => {
    try {
      await createTag(tag);
      loadTags(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUpdateTag = async (tag, id) => {
    try {
      await updateTag(tag, id);
      loadTags(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDeleteTag = async (id) => {
    try {
      await deleteTag(id);
      await loadTags(false);
      setActiveTag(null);
    } catch (err) {
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
      throw new Error(err);
    }
  };

  const handleSelectTag = (_tag) => {
    if (selectedTags.find((t) => t?.id === _tag?.id)) {
      setSelectedTags(selectedTags.filter((tag) => tag?.id !== _tag?.id));
    } else {
      setSelectedTags([...selectedTags, _tag]);
    }
  };

  return (
    <div className="flex overflow-hidden" style={{ width: '100%' }}>
      <DeleteModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        handleDeleteTag={handleDeleteTag}
        activeTag={activeTag}
      />
      <GroupTagsModal
        isOpen={groupTagsModalOpen}
        setIsOpen={setGroupTagsModalOpen}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        loadTags={loadTags}
      />
      <TagModal
        isOpen={tagModalOpen}
        setIsOpen={setTagModalOpen}
        handleAddTag={handleAddTag}
        activeTag={activeTag}
        handleUpdateTag={handleUpdateTag}
      />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-[#182235]">
        <main className="grow  pb-4 w-full max-w-9xl mx-auto">
          <div className="flex justify-between mb-12 ">
            <h1 className="text-2xl  text-[#101828B2] dark:text-slate-100 font-medium">
              Tags
            </h1>
            <div>
              {selectedTags?.length > 1 && (
                <button
                  className="btn border-indigo-500 font-normal text-base  text-indigo-500 mr-3"
                  aria-controls="basic-modal"
                  onClick={() => {
                    setGroupTagsModalOpen(true);
                  }}
                  type="button"
                >
                  Merge Selected Tags
                </button>
              )}
              <button
                className="btn !bg-indigo-500 font-normal text-base hover:!bg-indigo-600 text-white"
                aria-controls="basic-modal"
                onClick={() => {
                  setActiveTag(null);
                  setTagModalOpen(true);
                }}
                type="button"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2"
                >
                  <path
                    d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Add Tag
              </button>
            </div>
          </div>
          {loading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              {tags.map((tag) => (
                <div
                  key={tag?.id}
                  className="flex justify-between gap-5 rounded-lg mb-3 flex-wrap border px-5 py-4 border-slate-200 dark:border-slate-700"
                >
                  <div className="flex gap-3 items-center flex-wrap">
                    <label className="cursor-pointer text-base font-medium text-nowrap inline-flex justify-center items-center">
                      <input
                        type="checkbox"
                        checked={
                          selectedTags?.find((t) => t?.id === tag?.id)?.id
                        }
                        className="form-checkbox mr-2 h-5 w-5"
                        onChange={() => handleSelectTag(tag)}
                      />

                      <h3 className="text-sm font-medium ">{tag.name}</h3>
                    </label>
                    {/* <span className="font-bold">·</span>
                  <h4 className="font-medium text-xs uppercase text-slate-400 dark:text-slate-500">
                    transaction {tag.transactionCount}
                  </h4> */}
                  </div>
                  <div className="flex gap-3">
                    <button
                      onClick={() => {
                        setActiveTag(tag);
                        setTagModalOpen(true);
                      }}
                      type="button"
                      className="btn btn-sm h-fit px-5 py-1.5 bg-white dark:text-slate-300 border border-slate-600 text-slate-600 text-base leading-6"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        setDeleteModalOpen(true);
                        setActiveTag(tag);
                      }}
                      type="button"
                      className="btn btn-sm h-fit px-5 py-1.5 bg-white dark:text-slate-300 border border-slate-600 text-slate-600 text-base leading-6"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
              {!tags?.length > 0 && (
                <div className="flex flex-col gap-5 items-center justify-center">
                  <h6 className="text-center mt-4 pb-3">No Tags Found</h6>
                  <img
                    src={NoTagsImg}
                    alt="No Tags Found"
                    className="h-[12.5rem]"
                  />
                </div>
              )}
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default Tags;
