import React from 'react';
import ReleaseNotesBg from '../../../images/ReleaseNotesBg.png';

const CountIcon = ({ className = '' }) => (
  <svg
    width="261"
    height="265"
    viewBox="0 0 261 265"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M405.462 106.57C323.018 24.0418 189.35 24.0418 106.906 106.57C24.462 189.098 24.462 322.902 106.906 405.431C189.35 487.959 323.018 487.959 405.462 405.431C414.176 396.708 428.304 396.708 437.019 405.431C445.733 414.154 445.733 428.297 437.019 437.02C337.147 536.994 175.222 536.994 75.3491 437.02C-24.5233 337.045 -24.5233 174.955 75.3491 74.9809C175.222 -24.9934 337.147 -24.9934 437.019 74.9809C445.733 83.704 445.733 97.8472 437.019 106.57C428.304 115.293 414.176 115.293 405.462 106.57Z"
      fill="#F07422"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M362.456 149.683C303.792 90.9598 208.68 90.9598 150.016 149.683C91.3522 208.407 91.3522 303.616 150.016 362.34C158.73 371.063 158.73 385.206 150.016 393.928C141.302 402.651 127.173 402.651 118.459 393.928C42.3671 317.759 42.3671 194.264 118.459 118.094C194.551 41.9245 317.92 41.9245 394.012 118.094C402.726 126.818 402.726 140.96 394.012 149.683C385.298 158.406 371.17 158.406 362.456 149.683Z"
      fill="#88C870"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M219.689 148.047C225.851 158.731 222.195 172.392 211.521 178.559C168.799 203.251 154.161 257.936 178.827 300.703C203.493 343.47 258.123 358.122 300.845 333.431C311.518 327.263 325.165 330.923 331.327 341.608C337.489 352.291 333.832 365.952 323.159 372.12C259.091 409.148 177.167 387.174 140.178 323.04C103.187 258.906 125.139 176.898 189.207 139.871C199.881 133.703 213.527 137.363 219.689 148.047Z"
      fill="#B7DEA8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M262.334 231.317C247.699 227.391 232.656 236.085 228.735 250.734C224.813 265.385 233.499 280.442 248.133 284.368C260.037 287.561 267.102 299.809 263.912 311.725C260.722 323.641 248.486 330.713 236.582 327.52C198.141 317.209 175.327 277.654 185.628 239.172C195.928 200.69 235.442 177.854 273.884 188.165C285.788 191.358 292.853 203.606 289.663 215.522C286.473 227.438 274.238 234.509 262.334 231.317Z"
      fill="#F7AA7C"
    />
  </svg>
);

const ReleaseNotesBox = () => (
  <div className="relative min-h-[265px] xml:h-[265px] border border-[#D0D5DD] rounded-[12px] overflow-hidden">
    <div className=" absolute top-0 left-0 h-full w-full">
      <img
        className="h-full w-full object-cover opacity-30 absolute top-0 left-0 z-[5]"
        src={ReleaseNotesBg}
        alt="release-notes-bg"
      />
    </div>
    <CountIcon className="opacity-60 absolute bottom-0 right-0" />
    <div className="relative z-10 h-full w-full p-6 flex flex-col  justify-between">
      <div>
        <h3 className="text-[20px] font-semibold text-[#101828]">
          Release Notes
        </h3>
        <p className="text-sm font-semibold text-indigo-500">Oct 25, 2024</p>
      </div>
      <div
        className="inline-flex w-fit font-medium text-sm text-indigo-500 gap-1.5 items-center"
        role="button"
        tabIndex="0"
      >
        Review
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <g>
            <path
              d="M11.4339 6.3949C11.2847 6.3949 11.1416 6.45416 11.0362 6.55965C10.9307 6.66514 10.8714 6.80821 10.8714 6.9574V9.22169C10.8709 9.65902 10.697 10.0783 10.3877 10.3875C10.0785 10.6968 9.65921 10.8707 9.22188 10.8712H2.77844C2.34111 10.8707 1.92183 10.6968 1.61259 10.3875C1.30335 10.0783 1.1294 9.65902 1.12891 9.22169V2.77825C1.1294 2.34092 1.30335 1.92165 1.61259 1.61241C1.92183 1.30317 2.34111 1.12922 2.77844 1.12872H5.04273C5.19192 1.12872 5.33499 1.06946 5.44048 0.963971C5.54597 0.858482 5.60523 0.715407 5.60523 0.566223C5.60523 0.417039 5.54597 0.273965 5.44048 0.168476C5.33499 0.0629864 5.19192 0.00372314 5.04273 0.00372314H2.77844C2.04285 0.00459153 1.33764 0.297186 0.817507 0.817324C0.297369 1.33746 0.00477464 2.04267 0.00390625 2.77825V9.22169C0.00477464 9.95728 0.297369 10.6625 0.817507 11.1826C1.33764 11.7028 2.04285 11.9954 2.77844 11.9962H9.22188C9.95746 11.9954 10.6627 11.7028 11.1828 11.1826C11.7029 10.6625 11.9955 9.95728 11.9964 9.22169V6.9574C11.9964 6.80821 11.9371 6.66514 11.8317 6.55965C11.7262 6.45416 11.5831 6.3949 11.4339 6.3949Z"
              fill="#F18D4B"
            />
            <path
              d="M11.4218 4.75257e-08H7.92497C7.77768 -2.81995e-05 7.63625 0.0577205 7.53107 0.160839C7.42589 0.263957 7.36536 0.404218 7.36247 0.551484C7.35661 0.867188 7.62216 1.125 7.93763 1.125H10.0782L5.59833 5.60625C5.49285 5.71174 5.43359 5.85481 5.43359 6.00398C5.43359 6.15316 5.49285 6.29623 5.59833 6.40172C5.70382 6.5072 5.84689 6.56646 5.99607 6.56646C6.14525 6.56646 6.28831 6.5072 6.3938 6.40172L10.8751 1.92188V4.07016C10.8751 4.21934 10.9343 4.36241 11.0398 4.4679C11.1453 4.57339 11.2884 4.63266 11.4376 4.63266C11.5867 4.63266 11.7298 4.57339 11.8353 4.4679C11.9408 4.36241 12.0001 4.21934 12.0001 4.07016V0.577969C12.0001 0.502049 11.9851 0.426873 11.956 0.356736C11.927 0.286598 11.8844 0.222873 11.8307 0.1692C11.777 0.115528 11.7132 0.0729599 11.6431 0.043928C11.5729 0.0148961 11.4978 -3.0739e-05 11.4218 4.75257e-08Z"
              fill="#F18D4B"
            />
          </g>
          <defs>
            <clipPath id="clip0_115_1154">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
);

export default ReleaseNotesBox;
