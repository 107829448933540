import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';

const InfoBox = ({ customer, setCustomerModalOpen }) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  const navigate = useNavigate();
  return (
    <div className="flex flex-col 1xl:flex-row 1xl:items-center gap-x-6 gap-y-4  p-6 border border-[#D0D5DD] rounded-[12px]">
      <div className="flex-1 grid md:grid-cols-2 xl:grid-cols-3 gap-4">
        {/* Address  */}
        {(customer?.billingAddress?.street ||
          customer?.billingAddress?.street2 ||
          customer?.billingAddress?.city ||
          customer?.billingAddress?.state ||
          customer?.billingAddress?.zipCode ||
          customer?.billingAddress?.country) && (
          <div className="flex items-center gap-4 xxl:gap-6">
            <div className="min-h-14 min-w-14 h-14 w-14 rounded-full border border-[#D0D5DD] inline-flex justify-center items-center">
              <svg
                width="23"
                height="29"
                viewBox="0 0 23 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7732 6.41016C10.7843 6.41016 9.81759 6.7034 8.99534 7.25281C8.1731 7.80222 7.53223 8.58311 7.1538 9.49674C6.77536 10.4104 6.67634 11.4157 6.86927 12.3856C7.06219 13.3555 7.5384 14.2464 8.23766 14.9457C8.93692 15.645 9.82784 16.1212 10.7977 16.3141C11.7676 16.507 12.773 16.408 13.6866 16.0296C14.6002 15.6511 15.3811 15.0103 15.9305 14.188C16.4799 13.3658 16.7732 12.3991 16.7732 11.4102C16.7732 10.0841 16.2464 8.8123 15.3087 7.87462C14.371 6.93694 13.0993 6.41016 11.7732 6.41016ZM11.7732 14.4102C11.1798 14.4102 10.5998 14.2342 10.1065 13.9046C9.61314 13.5749 9.22862 13.1064 9.00155 12.5582C8.77449 12.01 8.71508 11.4068 8.83084 10.8249C8.94659 10.2429 9.23232 9.70839 9.65187 9.28884C10.0714 8.86928 10.606 8.58356 11.1879 8.4678C11.7699 8.35204 12.3731 8.41145 12.9212 8.63852C13.4694 8.86558 13.938 9.2501 14.2676 9.74345C14.5972 10.2368 14.7732 10.8168 14.7732 11.4102C14.7732 12.2058 14.4571 12.9689 13.8945 13.5315C13.3319 14.0941 12.5688 14.4102 11.7732 14.4102ZM11.7732 0.410156C8.85683 0.413465 6.06086 1.57345 3.99867 3.63564C1.93649 5.69782 0.776502 8.49379 0.773193 11.4102C0.773193 15.3352 2.58694 19.4952 6.02319 23.4414C7.56722 25.2246 9.30501 26.8303 11.2044 28.2289C11.3726 28.3467 11.5729 28.4099 11.7782 28.4099C11.9835 28.4099 12.1838 28.3467 12.3519 28.2289C14.2479 26.8298 15.9823 25.2241 17.5232 23.4414C20.9544 19.4952 22.7732 15.3352 22.7732 11.4102C22.7699 8.49379 21.6099 5.69782 19.5477 3.63564C17.4855 1.57345 14.6896 0.413465 11.7732 0.410156ZM11.7732 26.1602C9.70694 24.5352 2.77319 18.5664 2.77319 11.4102C2.77319 9.02321 3.7214 6.73402 5.40923 5.0462C7.09706 3.35837 9.38624 2.41016 11.7732 2.41016C14.1601 2.41016 16.4493 3.35837 18.1372 5.0462C19.825 6.73402 20.7732 9.02321 20.7732 11.4102C20.7732 18.5639 13.8394 24.5352 11.7732 26.1602Z"
                  fill="#667085"
                />
              </svg>
            </div>
            <div>
              <p className="text-[#667085] font-medium leading-tight">
                {customer?.billingAddress?.street}
                {customer?.billingAddress?.street2
                  ? `, ${customer?.billingAddress?.street2}`
                  : ''}
              </p>
              <p className="text-[#667085] font-medium leading-tight">
                {customer?.billingAddress?.city}
                {customer?.billingAddress?.state
                  ? `, ${customer?.billingAddress?.state}`
                  : ''}
                {customer?.billingAddress?.zipCode
                  ? `, ${customer?.billingAddress?.zipCode}`
                  : ''}
              </p>
              <p className="text-[#667085] font-medium leading-tight">
                {customer?.billingAddress?.country}
              </p>
            </div>
          </div>
        )}

        {/* Email  */}
        {customer?.contacts?.[0]?.email && (
          <div className="flex items-center gap-4 xxl:gap-6">
            <div className="min-h-14 min-w-14 h-14 w-14 rounded-full border border-[#D0D5DD] inline-flex justify-center items-center">
              <svg
                width="27"
                height="21"
                viewBox="0 0 27 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.9585 0.410156H1.9585C1.69328 0.410156 1.43893 0.515513 1.25139 0.70305C1.06385 0.890586 0.958496 1.14494 0.958496 1.41016V18.4102C0.958496 18.9406 1.16921 19.4493 1.54428 19.8244C1.91936 20.1994 2.42806 20.4102 2.9585 20.4102H24.9585C25.4889 20.4102 25.9976 20.1994 26.3727 19.8244C26.7478 19.4493 26.9585 18.9406 26.9585 18.4102V1.41016C26.9585 1.14494 26.8531 0.890586 26.6656 0.70305C26.4781 0.515513 26.2237 0.410156 25.9585 0.410156ZM23.3872 2.41016L13.9585 11.0539L4.52975 2.41016H23.3872ZM24.9585 18.4102H2.9585V3.68391L13.2822 13.1477C13.4667 13.317 13.7081 13.411 13.9585 13.411C14.2089 13.411 14.4503 13.317 14.6347 13.1477L24.9585 3.68391V18.4102Z"
                  fill="#667085"
                />
              </svg>
            </div>
            <p className="text-[#667085] font-medium leading-tight break-all	 max-w-full flex-1 ">
              {customer?.contacts?.[0]?.email || ''}
            </p>
          </div>
        )}
        {/* Phone  */}
        {customer?.contacts?.[0]?.phone && (
          <div className="flex items-center gap-4 xxl:gap-6">
            <div className="min-h-14 min-w-14 h-14 w-14 rounded-full border border-[#D0D5DD] inline-flex justify-center items-center">
              <svg
                width="19"
                height="29"
                viewBox="0 0 19 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.144 0.410156H3.14404C2.34839 0.410156 1.58533 0.726227 1.02272 1.28884C0.460114 1.85145 0.144043 2.61451 0.144043 3.41016V25.4102C0.144043 26.2058 0.460114 26.9689 1.02272 27.5315C1.58533 28.0941 2.34839 28.4102 3.14404 28.4102H15.144C15.9397 28.4102 16.7028 28.0941 17.2654 27.5315C17.828 26.9689 18.144 26.2058 18.144 25.4102V3.41016C18.144 2.61451 17.828 1.85145 17.2654 1.28884C16.7028 0.726227 15.9397 0.410156 15.144 0.410156ZM2.14404 6.41016H16.144V22.4102H2.14404V6.41016ZM3.14404 2.41016H15.144C15.4093 2.41016 15.6636 2.51551 15.8512 2.70305C16.0387 2.89059 16.144 3.14494 16.144 3.41016V4.41016H2.14404V3.41016C2.14404 3.14494 2.2494 2.89059 2.43694 2.70305C2.62447 2.51551 2.87883 2.41016 3.14404 2.41016ZM15.144 26.4102H3.14404C2.87883 26.4102 2.62447 26.3048 2.43694 26.1173C2.2494 25.9297 2.14404 25.6754 2.14404 25.4102V24.4102H16.144V25.4102C16.144 25.6754 16.0387 25.9297 15.8512 26.1173C15.6636 26.3048 15.4093 26.4102 15.144 26.4102Z"
                  fill="#667085"
                />
              </svg>
            </div>
            <p className="text-[#667085] font-medium leading-tight break-all	">
              {customer?.contacts?.[0]?.phone || ''}
            </p>
          </div>
        )}
      </div>
      <div className="flex gap-4 justify-end">
        <button
          type="button"
          className="h-11 whitespace-nowrap  px-6 border border-indigo-500 text-indigo-500 rounded-[5px]"
          onClick={() => navigate(`/customer-statement/${customer?.id}`)}
        >
          Customer Statement
        </button>
        {checkPermission(
          PermissionKeys.MANAGE_CUSTOMERS,
          accessType,
          permissions,
        ) && (
          <button
            type="button"
            className="h-11 px-6 border border-indigo-500 bg-indigo-500 text-white rounded-[5px]"
            onClick={() => setCustomerModalOpen(true)}
          >
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default InfoBox;
