import React from 'react';
import CategoriesDropdown from 'pages/transactions/CategoriesDropdown';
import AlertBox from 'components/AlertBox';
import { useSelector } from 'react-redux';
import DropdownFull from '../../components/DropdownFull';

const Step3 = ({
  tableRows,
  setTableRows,
  checkRowsError,
  incomeCategories,
  expenseCategories,
  getCheckedProductsCount,
  errorIndexes,
}) => {
  const { currencies } = useSelector((state) => state.Currencies);

  const handleUpdateValue = (index, field, value) => {
    setTableRows((prevRows) => {
      const updatedRows = [...prevRows]; // Shallow copy of rows
      const updatedRow = { ...updatedRows[index] }; // Shallow copy of the row to be updated
      updatedRow[field] = {
        ...updatedRow[field],
        value,
        error: value ? false : updatedRow.selected?.value,
      };

      if (field === 'selected' && value === false) {
        [
          'code',
          'stockQuantity',
          'currency',
          'pricingMethod',
          'unitPrice',
          'purchasePrice',
          'purchaseDescription',
          'name',
          'description',
          'purchaseCategoryAccountId',
          'categoryAccountId',
          'tax',
          'taxName',
          'status',
        ].forEach((f) => {
          updatedRow[f] = { ...updatedRow[f], error: false };
        });
      }

      updatedRows[index] = updatedRow;
      return updatedRows; // Return the updated state
    });
  };

  const selectAll = (selected) => {
    const tempRows = [...tableRows];
    tableRows.forEach((d, i) => {
      const tempRow = { ...tableRows[i] };
      tempRow.selected.value = !selected;
    });
    setTableRows(tempRows);
  };

  return (
    <div>
      <h3 className="text-2xl mb-2 font-semibold">
        {getCheckedProductsCount?.()} products & services are ready to be
        imported
      </h3>
      <p className="pt-1 ml-2 text-slate-400">
        Status must be ‘active’ or ‘inactive’; others will be ignored and set to
        ‘active’ by default
      </p>
      {errorIndexes?.length > 0 && (
        <div id="productServiceWarningError">
          <AlertBox
            hideActionButton
            heading="Validation Error"
            type="error"
            text={`Please resolved the errors in the following Data Row in order to proceed : #${errorIndexes?.join(',#  ')}`}
          />
        </div>
      )}
      <div className="bg-transparent  w-fit rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] max-w-full ">
        {/* Table */}
        <div
          className="overflow-x-scroll h-full w-fit max-w-full scrollbar"
          id="productsImportStep3"
        >
          <table className="table-fixed ">
            {/* Table header */}
            <thead className="text-sm font-semibold text-[#667085] border-b border-[#D0D5DD] bg-[#A0CD850F]">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  w-[50px] min-w-[50px]">
                  #
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  w-[50px] min-w-[50px]">
                  <div className="flex items-center">
                    <label className="inline-flex ">
                      <span className="sr-only">Select</span>
                      <input
                        className="form-checkbox cursor-pointer h-5 w-5"
                        type="checkbox"
                        onChange={() =>
                          selectAll(
                            getCheckedProductsCount?.() === tableRows?.length,
                          )
                        }
                        checked={
                          getCheckedProductsCount?.() === tableRows?.length
                        }
                      />
                    </label>
                  </div>
                </th>

                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[170px] min-w-[170px]">
                  <div className="font-medium text-left">Code</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[170px] min-w-[170px]">
                  <div className="font-medium text-left">Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[350px] min-w-[350px]">
                  <div className="font-medium text-left">Description</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell  w-[300px] min-w-[300px] ">
                  <div className="font-medium text-left">Pricing Method</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[350px] min-w-[350px]">
                  <div className="font-medium text-left">Stock Qty</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell  w-[300px] min-w-[300px] ">
                  <div className="font-medium text-left">Currency</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell  w-[300px] min-w-[300px] ">
                  <div className="font-medium text-left">Unit Price</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[200px] min-w-[200px]">
                  <div className="font-medium text-left">Income Account</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[350px] min-w-[350px]">
                  <div className="font-medium text-left">
                    Purchase Description
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell  w-[300px] min-w-[300px] ">
                  <div className="font-medium text-left">Purchase Price</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[200px] min-w-[200px]">
                  <div className="font-medium text-left">Expense Account</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[200px] min-w-[200px]">
                  <div className="font-medium text-left">Tax Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[200px] min-w-[200px]">
                  <div className="font-medium text-left">Tax %</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[200px] min-w-[200px]">
                  <div className="font-medium text-left">Status</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-[#D0D5DD] ">
              {tableRows?.map?.((b, i) => (
                <tr className="" key={i}>
                  <td className="px-2 first:pl-5 last:pr-5  pb-[2px] text-left">
                    #{i + 1}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5  pb-[2px] text-left">
                    <div className="flex h-[60px] pt-3">
                      <label className="inline-flex ">
                        <span className="sr-only">Select</span>
                        <input
                          className="form-checkbox cursor-pointer h-5 w-5"
                          type="checkbox"
                          onChange={() =>
                            handleUpdateValue(i, 'selected', !b.selected.value)
                          }
                          checked={b.selected.value}
                        />
                      </label>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="text"
                        placeholder="Code"
                        value={b?.code?.value}
                        className={`form-input placeholder:text-slate-300 ${
                          b.code.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'code', e.target.value)
                        }
                        // onBlur={b.code.error ? checkRowsError : () => {}}
                      />
                      <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                        {b?.code?.error || ''}
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="text"
                        placeholder="Name"
                        value={b?.name?.value}
                        className={`form-input placeholder:text-slate-300  ${
                          b.name.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'name', e.target.value)
                        }
                        // onBlur={b.name.error ? checkRowsError : () => {}}
                      />
                      <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                        {b?.name?.error || ''}
                      </span>
                    </div>
                  </td>

                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="text"
                        placeholder="Description"
                        value={b?.description?.value}
                        className={`form-input placeholder:text-slate-300 ${
                          b.description.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'description', e.target.value)
                        }
                        // onBlur={b.description.error ? checkRowsError : () => {}}
                      />
                      <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                        {b?.description?.error || ''}
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <DropdownFull
                        options={[
                          { name: 'Unit', id: 'item' },
                          { name: 'Hour', id: 'hour' },
                        ]}
                        name="name"
                        setSelected={(name) =>
                          handleUpdateValue(i, 'pricingMethod', name)
                        }
                        ids={['productsImportContainer', 'productsImportStep3']}
                        selected={b?.pricingMethod?.value}
                        error={b?.type?.error || ''}
                        placeholder="Select"
                        noSearch
                        bottomOfTable
                        isTableDropdown
                        height="h-[38px]"
                        buttonTextClass="max-w-[90%] overflow-hidden text-ellipsis"
                        buttonTextClassSub="max-w-[100%] overflow-hidden text-ellipsis"
                      />
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="number"
                        placeholder="Stock Qty"
                        value={b?.stockQuantity?.value}
                        className={`form-input placeholder:text-slate-300 ${
                          b.stockQuantity.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'stockQuantity', e.target.value)
                        }
                        step="0.0"
                        // onBlur={
                        //   b.stockQuantity.error ? checkRowsError : () => {}
                        // }
                      />
                      <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                        {b?.stockQuantity?.error || ''}
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <DropdownFull
                        options={currencies.map((c) => ({
                          id: c.isoCode,
                          name: c.name,
                        }))}
                        name="name"
                        setSelected={(name) =>
                          handleUpdateValue(i, 'currency', name)
                        }
                        ids={['productsImportContainer', 'productsImportStep3']}
                        selected={b?.currency?.value}
                        error={b?.type?.error || ''}
                        placeholder="Select"
                        noSearch
                        bottomOfTable
                        isTableDropdown
                        height="h-[38px]"
                        buttonTextClass="max-w-[90%] overflow-hidden text-ellipsis"
                        buttonTextClassSub="max-w-[100%] overflow-hidden text-ellipsis"
                      />
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="number"
                        placeholder="Price"
                        value={b?.unitPrice?.value}
                        className={`form-input placeholder:text-slate-300 ${
                          b.unitPrice.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'unitPrice', e.target.value)
                        }
                        step="0.0"
                        // onBlur={b.unitPrice.error ? checkRowsError : () => {}}
                      />
                      <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                        {b?.unitPrice?.error || ''}
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <CategoriesDropdown
                        allCategories={incomeCategories}
                        selectedCategoryId={b?.categoryAccountId?.value}
                        setSelectedCategoryId={(id) =>
                          handleUpdateValue(i, 'categoryAccountId', id)
                        }
                        isSetCategoryStyle
                        height={`h-12 ${b?.categoryAccountId?.value ? '' : '!opacity-30}'}`}
                        bottomOfTable
                        isTableDropdown
                        ids={['productsImportContainer', 'productsImportStep3']}
                      />
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="text"
                        placeholder="Purchase Description"
                        value={b?.purchaseDescription?.value}
                        className={`form-input placeholder:text-slate-300  ${
                          b.purchaseDescription.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(
                            i,
                            'purchaseDescription',
                            e.target.value,
                          )
                        }
                        // onBlur={
                        //   b.purchaseDescription.error
                        //     ? checkRowsError
                        //     : () => {}
                        // }
                      />
                      <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                        {b?.purchaseDescription?.error || ''}
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="number"
                        placeholder="Purchase Price"
                        value={b?.purchasePrice?.value}
                        className={`form-input placeholder:text-slate-300  ${
                          b.purchasePrice.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'purchasePrice', e.target.value)
                        }
                        step="0.0"
                        // onBlur={
                        //   b.purchasePrice.error ? checkRowsError : () => {}
                        // }
                      />
                      <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                        {b?.purchasePrice?.error || ''}
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <CategoriesDropdown
                        allCategories={expenseCategories}
                        selectedCategoryId={b?.purchaseCategoryAccountId?.value}
                        setSelectedCategoryId={(id) =>
                          handleUpdateValue(i, 'purchaseCategoryAccountId', id)
                        }
                        isSetCategoryStyle
                        height={`h-12 ${b?.purchaseCategoryAccountId?.value ? '' : '!opacity-30}'}`}
                        bottomOfTable
                        isTableDropdown
                        ids={['productsImportContainer', 'productsImportStep3']}
                      />
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="text"
                        placeholder="Tax Name"
                        value={b?.taxName?.value}
                        className={`form-input placeholder:text-slate-300  ${
                          b.taxName.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'taxName', e.target.value)
                        }
                        // onBlur={b.taxName.error ? checkRowsError : () => {}}
                      />
                      <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                        {b?.taxName?.error || ''}
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="number"
                        placeholder="Tax Percentage"
                        value={b?.tax?.value}
                        className={`form-input placeholder:text-slate-300  ${
                          b.tax.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'tax', e.target.value)
                        }
                        step="0.0"
                        // onBlur={b.tax.error ? checkRowsError : () => {}}
                      />
                      <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                        {b?.tax?.error || ''}
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="text"
                        placeholder="Status"
                        value={b?.status?.value}
                        className={`form-input placeholder:text-slate-300  ${
                          b.status.error ? '!border-[#B54708]' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'status', e.target.value)
                        }
                        // onBlur={checkRowsError}
                      />
                      <span className="h-[10px] text-xs mt-1 text-[#B54708] ">
                        {b?.status?.error || ''}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Step3;
