import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'components/Tooltip';
import PaginationClassic from '../../../components/PaginationClassic';
import {
  getCurrencySymbolFromIso,
  getInvoiceStatusColor,
  trimText,
} from '../../../utils/Utils';

const EstimatesTable = ({
  estimates,
  estimatesLocalLimit,
  getEstimatesData,
  setEstimatesLocalLimit,
}) => {
  const navigate = useNavigate();
  const { currencies } = useSelector((state) => state.Currencies);

  return (
    <div>
      <div className="w-full overflow-auto">
        <table className="table-fixed w-full dark:text-slate-300 divide-y divide-slate-200">
          {/* Table header */}
          <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
            <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[9rem] max-w-[9rem] whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-left">Estimate Number</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div className="font-medium text-left">Status</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div className="font-medium text-left">Date</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div className="font-medium text-left">Due</div>
              </th>

              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[8rem] max-w-[8rem] whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-left">Amount Due</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          {estimates?.invoices?.length ? (
            <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
              {estimates?.invoices?.map((b) => (
                <tr
                  key={b?.id}
                  onClick={() =>
                    navigate(
                      `/invoices?tab=estimates&id=${b?.id}&from=customer`,
                    )
                  }
                  className={`h-20 cursor-pointer  hover:bg-[#A0CD850F]  `}
                >
                  <td className="px-2 font-semibold first:pl-5 last:pr-5 py-2.5 text-left sm:table-cell hidden ">
                    <Tooltip
                      className="w-fit"
                      position="right"
                      contentClassName="border-none rounded-[10px] overflow-visible font-normal text-sm text-[#667085] relative tooltipArrowLeft"
                      content={
                        <p onClick={(e) => e.stopPropagation()}>
                          {b?.invoiceNumber}
                        </p>
                      }
                      onClick={(e) => e.stopPropagation()}
                      tooltipShow={b?.invoiceNumber?.length > 8}
                    >
                      <span>
                        {b?.invoiceNumber?.length > 8
                          ? trimText(b?.invoiceNumber, 8)
                          : /^\d+$/.test(b?.invoiceNumber)
                            ? b?.invoiceNumber?.toString()?.padStart(4, '0')
                            : b?.invoiceNumber}
                      </span>
                    </Tooltip>
                  </td>
                  <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap">
                    <div
                      className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
               ${getInvoiceStatusColor(b?.isDraft ? 'Draft' : b?.paymentStatus)}
             `}
                    >
                      {b?.isDraft ? 'Draft' : b?.paymentStatus}
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                    {/* {moment(b?.date).format("MMM DD, YYYY")} */}
                    {DateTime.fromISO(b?.date, { setZone: true }).toFormat(
                      'MMM dd, yyyy',
                    )}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left ">
                    {/* {moment(b?.dueDate).format("MMM DD, YYYY")} */}
                    {DateTime.fromISO(b?.dueDate, { setZone: true }).toFormat(
                      'MMM dd, yyyy',
                    )}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left  sm:table-cell hidden ">
                    {getCurrencySymbolFromIso(b?.currency, currencies)}
                    {parseFloat(b?.total ?? 0)?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
        {!estimates?.invoices?.length > 0 && (
          <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
            No Estimates Found
          </h6>
        )}
      </div>
      <div className="mt-8">
        <PaginationClassic
          pagination={{
            limit: estimatesLocalLimit,
            page: estimates?.page,
            totalRecords: estimates?.totalRecords,
          }}
          onPageChange={getEstimatesData}
          setLimit={setEstimatesLocalLimit}
        />
      </div>
    </div>
  );
};

export default EstimatesTable;
