import DoughnutChart from 'components/DoughnutChart';
import { Loader } from 'components/Svg';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrencySymbolFromIso, TEAM_CURRENCY } from 'utils/Utils';
import MonthDropdown from './MonthDropdown';

const ExpensesBox = ({
  expensesData,
  selectedExpensesYear,
  overviewLoadings,
  handleExpenseMonthChange,
  ChartColors,
  setActiveTab,
}) => {
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const chartData = useMemo(
    () =>
      expensesData?.yearOrMonthlyData?.expenses?.catagories?.length > 0
        ? {
            labels: expensesData?.yearOrMonthlyData?.expenses?.catagories?.map(
              (c) => c?.name,
            ),
            datasets: [
              {
                label: '',
                data: expensesData?.yearOrMonthlyData?.expenses?.catagories?.map(
                  (c) => c?.percentage,
                ),
                backgroundColor:
                  expensesData?.yearOrMonthlyData?.expenses?.catagories?.map(
                    (_, i) => ChartColors[i % ChartColors?.length],
                  ),
                hoverBackgroundColor:
                  expensesData?.yearOrMonthlyData?.expenses?.catagories?.map(
                    (_, i) => ChartColors[i % ChartColors?.length],
                  ),
                borderWidth: 4,
                borderColor: 'rgba(255, 255, 255, 1)',
                borderRadius: 50,
                hoverBorderColor: 'rgba(255, 255, 255, 1)',
              },
            ],
          }
        : null,
    [expensesData],
  );

  return (
    <div className="flex flex-col p-6 rounded-[12px] border border-[#D0D5DD] ">
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-1 items-center">
          <h3 className="text-[22px] text-[#666] font-medium">Expenses</h3>
        </div>
        <div className="w-32 z-20 relative">
          <MonthDropdown
            selected={selectedExpensesYear}
            setSelected={handleExpenseMonthChange}
            name="name"
            scrollIntoView={false}
          />
        </div>
      </div>
      {overviewLoadings?.expenses ? (
        <div className="flex flex-col h-[750px] justify-center items-center">
          <Loader height="h-8 w-8" />
          <p>Fetching data please wait...</p>
        </div>
      ) : (
        <div className="flex-1 flex flex-col justify-between">
          <div>
            <h3 className="text-[36px] text-black font-medium mb-5">
              {' '}
              {expensesData?.yearOrMonthlyData?.expenses?.total
                ?.toString()
                .charAt(0) === '-'
                ? '-'
                : ''}
              {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
              {Number.isNaN(expensesData?.yearOrMonthlyData?.expenses?.total) ||
              !Number.isFinite(expensesData?.yearOrMonthlyData?.expenses?.total)
                ? '--'
                : expensesData?.yearOrMonthlyData?.expenses?.total
                      ?.toString()
                      .charAt(0) === '-'
                  ? parseFloat(
                      expensesData?.yearOrMonthlyData?.expenses?.total
                        ?.toString()
                        .replace('-', ''),
                    )?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : parseFloat(
                      expensesData?.yearOrMonthlyData?.expenses?.total,
                    )?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
            </h3>
            <div className="relative">
              {chartData && (
                <DoughnutChart
                  data={chartData}
                  width={389}
                  height={260}
                  hideLegends
                />
              )}
            </div>
            <div className="flex flex-col gap-6 ">
              {expensesData?.yearOrMonthlyData?.expenses?.catagories?.map(
                (t, i) => (
                  <div
                    key={t}
                    className="flex justify-between py-3 items-center border-b border-[#D0D5DD] last:border-none"
                  >
                    <div className="text-[18px] text-[#667085] inline-flex gap-2 items-center">
                      <div
                        className="h-2 w-2 rounded-full bg-[#F68D2B]"
                        style={{
                          background: ChartColors[i % ChartColors?.length],
                        }}
                      />
                      {t?.name === 'others'
                        ? `+${t?.numOfCategories} Others`
                        : t?.name}
                    </div>
                    <div>
                      <h4 className="text-[18px] font-bold text-[#667085]">
                        {t?.total?.toString().charAt(0) === '-' ? '-' : ''}
                        {getCurrencySymbolFromIso(
                          teamDefaultCurrency,
                          currencies,
                        )}

                        {t?.total < 0
                          ? (-1 * t?.total)?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : t?.total?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </h4>
                      <p className="text-xs text-[#66708580]">
                        {t?.percentage?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        %
                      </p>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
          <button
            type="button"
            className=" w-full h-[38px] border border-indigo-500 mt-6 rounded-[6px] text-indigo-500"
            onClick={() => setActiveTab('spending')}
          >
            View All
          </button>
        </div>
      )}
    </div>
  );
};

export default ExpensesBox;
