import React from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Tooltip from 'components/Tooltip';
import {
  getCurrencySymbolFromIso,
  getInvoiceStatusColor,
  trimText,
} from '../../../../utils/Utils';

const EstimatesTable = ({ estimates }) => {
  const navigate = useNavigate();
  const { currencies } = useSelector((state) => state.Currencies);
  return (
    <>
      <table className="table-fixed w-full dark:text-slate-300 divide-y divide-slate-200">
        {/* Table header */}
        <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
          <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
            <th className="px-2 first:pl-5 last:pr-5 py-4 w-[8rem] max-w-[8rem] whitespace-nowrap sm:table-cell hidden ">
              <div className="font-medium text-left">#</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
              <div className="font-medium text-left">Status</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
              <div className="font-medium text-left">Date</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
              <div className="font-medium text-left">Due</div>
            </th>

            <th className="px-2 first:pl-5 last:pr-5 py-4 w-[17rem] max-w-[17rem] whitespace-nowrap sm:table-cell hidden">
              <div className="font-medium text-left">Customer</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-4 w-[6rem] max-w-[8rem] whitespace-nowrap sm:table-cell hidden ">
              <div className="font-medium text-center">Amount Due</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        {estimates?.length > 0 ? (
          <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
            {estimates?.map((b) => (
              <tr
                key={b?.id}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/invoices?tab=estimates&id=${b?.id}&from=projects`);
                }}
                className={`h-20 cursor-pointer  hover:bg-[#A0CD850F]  `}
              >
                <td className="px-2 font-semibold first:pl-5 last:pr-5 py-2.5 text-left sm:table-cell hidden ">
                  <Tooltip
                    className="w-fit"
                    position="right"
                    contentClassName="border-none rounded-[10px] overflow-visible font-normal text-sm text-[#667085] relative tooltipArrowLeft"
                    content={
                      <p onClick={(e) => e.stopPropagation()}>
                        {b?.invoiceNumber}
                      </p>
                    }
                    onClick={(e) => e.stopPropagation()}
                    tooltipShow={b?.invoiceNumber?.length > 8}
                  >
                    <span>
                      {b?.invoiceNumber?.length > 8
                        ? trimText(b?.invoiceNumber, 8)
                        : /^\d+$/.test(b?.invoiceNumber)
                          ? b?.invoiceNumber?.toString()?.padStart(4, '0')
                          : b?.invoiceNumber}
                    </span>
                  </Tooltip>
                </td>
                <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap">
                  <div
                    className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
              ${getInvoiceStatusColor(b?.isDraft ? 'Draft' : b?.paymentStatus)}
            `}
                  >
                    {b?.isDraft ? 'Draft' : b?.paymentStatus}
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                  {/* {moment(b?.date).format("MMM DD, YYYY")} */}
                  {DateTime.fromISO(b?.date, { setZone: true }).toFormat(
                    'MMM dd, yyyy',
                  )}
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left ">
                  {/* {moment(b?.dueDate).format("MMM DD, YYYY")} */}
                  {DateTime.fromISO(b?.dueDate, { setZone: true }).toFormat(
                    'MMM dd, yyyy',
                  )}
                </td>

                {/* <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
              <div className="px-3 mx-auto py-1 w-fit rounded-md bg-slate-100 text-sm font-semibold dark:bg-slate-700">
                {b.upcoming}
              </div>
            </td> */}
                <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left  sm:table-cell hidden ">
                  {b?.customer?.customer}
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center  sm:table-cell hidden ">
                  {getCurrencySymbolFromIso(b?.currency, currencies)}
                  {parseFloat(b?.total ?? 0)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>
      {!(estimates?.length > 0) && (
        <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
          No Estimates Found
        </h6>
      )}
    </>
  );
};

export default EstimatesTable;
