/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { boot, showArticle } from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';
import WarningBox from 'components/WarningBox';
import { toast } from 'react-toastify';
import ModalBasic from '../../components/ModalBasic';
import UploadFile from '../../components/UploadFile';
import { uploadBillPDF } from '../../API/backend_helper';
import Tooltip from '../../components/Tooltip';

const BillsChoiceModal = ({
  modalOpen,
  setModalOpen,
  setIsActive,
  setUploadedBillData,
  BILLS_MODAL,
}) => {
  const { team } = useSelector((state) => state.Team);
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  // File Management
  const [files, setFiles] = useState([]);
  const [imgPreview, setImgPreview] = useState('');

  useEffect(() => {
    if (!modalOpen) {
      setFiles([]);
      setImgPreview('');
    }
  }, [modalOpen]);

  const handleBillUpload = async (file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('invoice', file);
    try {
      const res = await uploadBillPDF(formData);
      if (res) {
        setUploadedBillData(res);
        setIsActive(BILLS_MODAL);
        setModalOpen(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        team?.billEmail?.includes('@bills.getcount.com')
          ? team?.billEmail
          : `${team?.billEmail}@bills.getcount.com`,
      )
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
        // setIsOpen(false);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  return (
    <ModalBasic
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title="Add Bill"
    >
      <div className="w-full px-[30px] py-4 pb-6">
        <div className="flex flex-col gap-2.5">
          {isLoading && (
            <WarningBox
              isInfo
              content="Importing can take between 1-2 minutes. Please do not refresh the page"
            />
          )}
          <UploadFile
            onFilesAdded={(files) => {
              setFiles((prevFiles) => [...prevFiles, ...files]);
              if (files[0]) {
                setImgPreview(files[0].preview);
              }
              handleBillUpload(files[0]);
            }}
            name="bill"
            id="bill"
            imgPreview={imgPreview}
            setImgPreview={setImgPreview}
            files={files}
            setFiles={setFiles}
            pdf
            loading={isLoading}
            title="Import Bill"
          />
          {!isLoading && (
            <>
              <div className="w-full flex gap-2.5 items-center">
                <div className="h-px w-[90%] bg-[#D0D5DD]" />
                <p>Or</p>
                <div className="h-px w-[90%] bg-[#D0D5DD]" />
              </div>
              <div className="flex flex-col items-center">
                <div className="text-sm text-center">
                  Forward to your bill inbox.{' '}
                  <span
                    onClick={() => {
                      boot();
                      showArticle(9763035);
                    }}
                    role="button"
                    tabIndex="0"
                    className="ml-1 text-indigo-500 font-medium cursor-pointer"
                  >
                    Learn more
                  </span>
                </div>
                <div className="text-sm inline-flex items-center gap-1 py-1 px-2  mt-2 border border-[#D0D5DD] w-fit rounded-[6px]">
                  {team?.billEmail?.includes('@bills.getcount.com')
                    ? team?.billEmail
                    : `${team?.billEmail}@bills.getcount.com`}{' '}
                  <span onClick={copyToClipboard}>
                    <Tooltip
                      content={copied ? 'Copied' : 'Copy'}
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        className="h-4 w-4 fill-slate-400 cursor-pointer"
                      >
                        <path d="M384 336l-192 0c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l140.1 0L400 115.9 400 320c0 8.8-7.2 16-16 16zM192 384l192 0c35.3 0 64-28.7 64-64l0-204.1c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1L192 0c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-32-48 0 0 32c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l32 0 0-48-32 0z" />
                      </svg>
                    </Tooltip>
                  </span>
                </div>
              </div>
              <div className="w-full flex gap-2.5 items-center">
                <div className="h-px w-[90%] bg-[#D0D5DD]" />
                <p>Or</p>
                <div className="h-px w-[90%] bg-[#D0D5DD]" />
              </div>
              <div className="flex flex-col gap-2.5 cursor-pointer">
                <div
                  className="text-indigo-500 text-center underline"
                  onClick={() => {
                    setIsActive(BILLS_MODAL);
                    setUploadedBillData({});
                    setModalOpen(false);
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyDown={null}
                >
                  Create a Bill Manually
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </ModalBasic>
  );
};

export default BillsChoiceModal;
