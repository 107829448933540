import React, { useState } from 'react';
import InvoicesTable from './InvoicesTable';
import EstimatesTable from './EstimatesTable';

const INVOICES_TABLE = 'invoice_table';
const ESTIMATES_TABLE = 'estimate_table';

const Invoices = ({ invoices, estimates }) => {
  const [activeTable, setActiveTable] = useState(INVOICES_TABLE);

  return (
    <div className="py-4 flex-1 flex flex-col">
      {/* Tabs  */}
      <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-6 flex-wrap">
        <div
          onClick={() => setActiveTable(INVOICES_TABLE)}
          className={`text-sm px-2  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
   ${
     activeTable === INVOICES_TABLE &&
     ' !border-indigo-500  font-medium !text-[#101828]'
   } `}
          role="button"
          tabIndex="0"
        >
          Invoices
        </div>
        <div
          onClick={() => setActiveTable(ESTIMATES_TABLE)}
          className={`text-sm px-2  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
   ${
     activeTable === ESTIMATES_TABLE &&
     ' !border-indigo-500  font-medium !text-[#101828]'
   } `}
          role="button"
          tabIndex="0"
        >
          Estimates
        </div>
      </div>
      <div className="w-full overflow-auto flex-1 ">
        {activeTable === INVOICES_TABLE ? (
          <InvoicesTable invoices={invoices} />
        ) : (
          <EstimatesTable estimates={estimates} />
        )}
      </div>
    </div>
  );
};

export default Invoices;
