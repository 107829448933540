import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import Tooltip from 'components/Tooltip';
import { InfoIcon } from 'components/Svg';
import { getStringSegments } from 'utils/Utils';
import { EmptyFavoriteIcon, FavoriteIcon } from './svg';

const ReportBox = ({
  title = '',
  description = '',
  tooltipContent = '',
  isFavorite = false,
  handleFavorite = () => {},
  navigateTo = '/reports',
  reportId = '',
}) => {
  const navigate = useNavigate();
  const { accessType, permissions } = useSelector((state) => state.User);

  return (
    <div
      className="relative flex px-6 py-8 flex-col gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-full h-full cursor-pointer"
      onClick={() => {
        if (
          !checkPermission(PermissionKeys.VIEW_REPORTS, accessType, permissions)
        ) {
          return;
        }
        navigate(navigateTo);
      }}
      role="button"
      tabIndex="0"
    >
      <div
        className="absolute top-3 right-4 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          handleFavorite(reportId);
        }}
        role="button"
        tabIndex="0"
      >
        {isFavorite ? <FavoriteIcon /> : <EmptyFavoriteIcon />}
      </div>
      <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-normal text-left">
        {title}
      </p>
      <p className="leading-6 text-slate-600 self-stretch">{description}</p>
      {tooltipContent && (
        <div className="absolute bottom-3 right-4">
          <Tooltip
            content={
              tooltipContent?.length > 50
                ? (() => {
                    const segments = getStringSegments(tooltipContent, 40);
                    return segments.map((segment, index) => (
                      <p key={index}>{segment}</p>
                    ));
                  })()
                : tooltipContent
            }
            contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowRight `}
            position="left"
            size="lg"
          >
            <InfoIcon />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ReportBox;
