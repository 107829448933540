import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import { TEAM_DATE_FORMAT } from 'utils/Utils';
import {
  deleteInvoiceDefaultDocs,
  getInvoiceDefaultDocs,
  updateInvoiceDefaultDocs,
  updateTeamDefaults,
  uploadInvoiceDefaultDoc,
} from '../../../API/backend_helper';
import { Loader } from '../../../components/Svg';
import AISettingsTab from './AISettingsTab';
import InvoiceSettingsTab from './InvoiceSettingsTab';
import InvoiceDefaultAttachments from './InvoiceDefaultAttachments';

const Configuration = ({ fetchTeam }) => {
  const { team } = useSelector((state) => state.Team);

  const [loading, setLoading] = useState(false);
  const [configLoading, setConfigLoading] = useState({});
  const [selectedColor, setSelectedColor] = useState('');
  const [fontFamily, setFontFamily] = useState('Outfit');
  const [defaultAttachments, setDefaultAttachments] = useState([]);
  const [dateFormat, setDateFormat] = useState(
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT),
  );

  const loadDefaultAttachments = async () => {
    try {
      const res = await getInvoiceDefaultDocs();
      setDefaultAttachments(res?.data?.attachments);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    const loadTeam = async () => {
      setLoading(true);
      const res = await fetchTeam();
      await loadMiscData(false);
      setDateFormat(res?.defaults?.dateFormat);
      setSelectedColor(`#${res?.defaults?.color}`);
      setFontFamily(res?.defaults?.font);
      setLoading(false);
    };

    const loadMiscData = async (loading = true) => {
      if (loading) setLoading(true);
      if (loading) setLoading(false);
    };

    if (Object.keys(team).length === 0) {
      loadTeam();
    } else {
      loadMiscData();
      setSelectedColor(`#${team?.defaults?.color}`);
      setFontFamily(team?.defaults?.font);
    }
    loadDefaultAttachments();
  }, []);

  useEffect(() => {
    if (
      Object.keys(team).length > 0 &&
      selectedColor !== `#${team?.defaults?.color}`
    ) {
      const timeoutId = setTimeout(() => {
        handleUpdateTeam(
          team?.id,
          { color: selectedColor.slice(1) },
          'Invoice Color',
        );
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [selectedColor]);

  const handleUpdateTeam = async (id, data, configName) => {
    try {
      setConfigLoading((prev) => ({ ...prev, [configName]: true }));
      await updateTeamDefaults(id, data);
      const res = await fetchTeam();
      return res;
    } catch (err) {
      console.log('err', err);
    } finally {
      setConfigLoading((prev) => ({ ...prev, [configName]: false }));
      toast.success(`${configName} updated successfully`);
    }
  };

  const handleDefaultAttachmentUpload = async (file, name) => {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('title', name);
    try {
      const res = await uploadInvoiceDefaultDoc(formData);
      await loadDefaultAttachments();
      return res;
    } catch (error) {
      console.log('error', error);
      throw new Error(error);
    }
  };

  const handleDefaultAttachmentDelete = async (id) => {
    try {
      await deleteInvoiceDefaultDocs(id);
      await loadDefaultAttachments();
    } catch (error) {
      console.log('error', error);
      throw new Error(error);
    }
  };

  const handleDefaultAttachmentUpdate = async (id, data) => {
    try {
      await updateInvoiceDefaultDocs(id, data);
      await loadDefaultAttachments();
    } catch (error) {
      console.log('error', error);
      throw new Error(error);
    }
  };

  const handleInvoiceTitleUpdate = async (values) => {
    try {
      await handleUpdateTeam(team?.id, values, 'Invoice Default Titles');
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDateFormatChange = async (value) => {
    setDateFormat(value);
    try {
      const res = await handleUpdateTeam(
        team?.id,
        {
          dateFormat: value,
        },
        'Date Format',
      );
      if (res?.id) {
        localStorage.setItem(TEAM_DATE_FORMAT, res?.defaults?.dateFormat);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <div className="flex min-h-[60dvh]" style={{ width: '100%' }}>
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-[#182235]">
        <main className="grow  pb-4 w-full max-w-9xl mx-auto">
          <div className="flex justify-between mb-12 ">
            <h1 className="text-2xl  text-[#101828B2] dark:text-slate-100 font-medium">
              Configuration
            </h1>
          </div>
          {loading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <Loader />
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              <h1 className="text-2xl mb-4  text-[#101828B2] dark:text-slate-100 font-medium flex items-center">
                Date Input Format{' '}
                {configLoading['Date Format'] && (
                  <div className="ml-3">
                    <Loader width="w-6" height="h-6" />
                  </div>
                )}
              </h1>
              <div className="mb-8 ">
                <label
                  htmlFor="mm/dd/yyyy"
                  className="flex items-center gap-4 mb-2 cursor-pointer w-fit"
                  key="mm/dd/yyyy"
                  onClick={() => handleDateFormatChange('mm/dd/yyyy')}
                >
                  <input
                    type="radio"
                    name="mm/dd/yyyy"
                    id="mm/dd/yyyy "
                    className="form-radio checked:!bg-indigo-500  disabled:!border-indigo-300"
                    checked={dateFormat === 'mm/dd/yyyy'}
                    disabled={configLoading['Date Format']}
                  />
                  <div className="flex flex-col">
                    <p className="text-lg font-medium">mm/dd/yyyy </p>
                  </div>
                </label>
                <label
                  htmlFor="dd/mm/yyyy"
                  className="flex items-center gap-4 cursor-pointer w-fit"
                  key="dd/mm/yyyy"
                  onClick={() => handleDateFormatChange('dd/mm/yyyy')}
                >
                  <input
                    type="radio"
                    name="dd/mm/yyyy"
                    id="dd/mm/yyyy"
                    className="form-radio checked:!bg-indigo-500  disabled:!border-indigo-300"
                    checked={dateFormat === 'dd/mm/yyyy'}
                    disabled={configLoading['Date Format']}
                  />
                  <div className="flex flex-col">
                    <p className="text-lg font-medium">dd/mm/yyyy</p>
                  </div>
                </label>
              </div>

              <div>
                <h1 className="text-2xl mb-4  text-[#101828B2] dark:text-slate-100 font-medium">
                  AI Settings
                </h1>
                <AISettingsTab
                  team={team}
                  handleUpdateTeam={handleUpdateTeam}
                  configLoading={configLoading}
                />
                <h1 className="text-2xl mt-8 mb-4  text-[#101828B2] dark:text-slate-100 font-medium">
                  Invoices & Estimates Settings
                </h1>
                <InvoiceSettingsTab
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  fontFamily={fontFamily}
                  setFontFamily={setFontFamily}
                  handleUpdateTeam={handleUpdateTeam}
                  configLoading={configLoading}
                  team={team}
                  handleInvoiceTitleUpdate={handleInvoiceTitleUpdate}
                />
                <InvoiceDefaultAttachments
                  files={defaultAttachments}
                  handleDefaultAttachmentUpload={handleDefaultAttachmentUpload}
                  handleDefaultAttachmentDelete={handleDefaultAttachmentDelete}
                  handleDefaultAttachmentUpdate={handleDefaultAttachmentUpdate}
                />
              </div>
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default Configuration;
