import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  amountColor,
  getContrast,
  getCurrencySymbolFromIso,
  TEAM_CURRENCY,
} from '../../../utils/Utils';
import emptyWalletImg from '../../../images/custom/empty-wallet.svg';
import Tooltip from '../../../components/Tooltip';
import PaginationClassic from '../../../components/PaginationClassic';

const TransactionsTable = ({
  transactions,
  transactionsLocalLimit,
  setTransactionLocalLimit,
  loadTransactions,
  setSelectedRow,
  setTransactionsPanelOpen,
}) => {
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);
  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [accountLength] = useState(40);
  const [descriptionLength, setDescriptionLength] = useState(40);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1700) {
        setDescriptionLength(70);
      } else if (window.innerWidth > 1400) {
        setDescriptionLength(50);
      } else if (window.innerWidth > 1200) {
        setDescriptionLength(40);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onRowClick = async (row) => {
    setTransactionsPanelOpen(true);
    setSelectedRow(row);
  };

  const { page, totalRecords } = transactions;
  return (
    <div className="bg-white mt-10 dark:bg-slate-800  rounded-sm ">
      {/* Table */}
      <div
        className="overflow-x-auto scrollbar"
        id="transactionsTransactionsTable"
      >
        <table className="table-fixed w-full min-w-max sm:min-w-full dark:text-slate-300  ">
          {/* Table header */}
          <thead className=" w-full shadow-md  text-slate-600 text-sm font-medium leading-[18px]">
            <tr>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[8rem] max-w-[8rem]">
                <div
                  className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                   hover:text-slate-800 dark:hover:text-slate-200
               "
                  role="button"
                  tabIndex="0"
                >
                  <span className="text-slate-600 text-sm font-medium leading-[18px]">
                    Date
                  </span>
                </div>
              </th>
              <th className="px-2 first:pl-5 py-3 whitespace-nowrap w-[22rem] max-w-[22rem]">
                <div
                  className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                   hover:text-slate-800 dark:hover:text-slate-200
               "
                  role="button"
                  tabIndex="0"
                >
                  <span className="text-slate-600 text-sm font-medium leading-[18px]">
                    Description
                  </span>
                </div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  w-[14.736rem]">
                <div className="text-slate-600 text-sm font-medium leading-[18px] text-left">
                  Account
                </div>
              </th>

              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  w-[8.659rem]">
                <div
                  className="text-left flex gap-2 cursor-pointer -bottom-1
                   hover:text-slate-800 dark:hover:text-slate-200
               "
                  // onClick={() => handleSortBy('amount')}
                  role="button"
                  tabIndex="0"
                >
                  <span className="text-slate-600 text-sm font-medium leading-[18px]">
                    Amount
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          {transactions?.transactions?.length > 0 && (
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {transactions?.transactions?.map((transaction) => (
                <tr
                  key={transaction.id}
                  className={` hover:bg-[#A0CD850F] cursor-pointer`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRowClick(transaction);
                  }}
                >
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 leading-6 font-normal w-[9.156rem] text-slate-700 whitespace-nowrap">
                    {moment
                      ?.utc(transaction?.authorizedDate)
                      ?.format('MMM DD, YYYY')}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-5 whitespace-nowrap text-slate-700">
                    <div className="flex items-center">
                      <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                        {transaction?.vendor?.logoUrl ||
                        transaction?.plaidCategoryIconUrl ? (
                          <img
                            className="rounded-full"
                            src={
                              transaction?.vendor?.logoUrl ||
                              transaction?.plaidCategoryIconUrl
                            }
                            alt={transaction?.name}
                          />
                        ) : (
                          <div
                            className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                            style={{
                              backgroundColor: transaction?.categoryAccount
                                ?.group?.color
                                ? transaction?.categoryAccount?.group?.color
                                : '',
                            }}
                          >
                            <div
                              className={`${
                                transaction?.categoryAccount?.group?.color
                                  ? getContrast(
                                      transaction?.categoryAccount?.group
                                        ?.color,
                                      'text-black',
                                      'text-white',
                                    )
                                  : 'text-black'
                              } text-xs font-normal uppercase`}
                            >
                              {transaction?.description
                                ? transaction.description
                                    .replace(/[^\w\s]/gi, '')
                                    .split(' ')
                                    .filter(Boolean)
                                    .slice(0, 2)
                                    .map((word) => word.charAt(0))
                                    .join('')
                                : ''}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="font-normal leading-6 mr-2">
                        {transaction?.description?.length >
                        descriptionLength ? (
                          <Tooltip
                            content={transaction?.description}
                            contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown ${
                              transaction?.description?.length > 50 ? '' : ''
                            }`}
                          >
                            <span>
                              {`${transaction?.description?.slice(
                                0,
                                descriptionLength,
                              )}...`}
                            </span>
                          </Tooltip>
                        ) : (
                          <span>{transaction?.description}</span>
                        )}
                      </div>
                    </div>
                  </td>

                  {(() => {
                    const name = transaction?.account?.name || '';
                    const mask = transaction?.account?.mask || '';
                    const slicedName =
                      name.length > accountLength
                        ? `${name.slice(0, accountLength)}...`
                        : name;
                    const combinedAccount = mask
                      ? `${slicedName} (...${mask})`
                      : slicedName;
                    return (
                      <td className="px-2 first:pl-5 last:pr-5 py-2.5 whitespace-nowrap text-slate-700 font-sm font-normal leading-6">
                        <Tooltip
                          tooltipShow={name.length > accountLength}
                          content={`${transaction?.account?.name}${
                            transaction?.account?.mask
                              ? ` (...${transaction?.account?.mask})`
                              : ''
                          }`}
                        >
                          {combinedAccount}
                        </Tooltip>
                      </td>
                    );
                  })()}

                  <td className={`px-2  py-2.5 whitespace-nowrap  `}>
                    <div
                      className={`font-semibold leading-6 text-sm  ${amountColor(
                        transaction?.amount?.toString(),
                      )}`}
                    >
                      {transaction?.amount?.toString().charAt(0) === '-'
                        ? ''
                        : '-'}
                      {getCurrencySymbolFromIso(
                        transaction?.currency,
                        currencies,
                      )}
                      {transaction?.amount?.toString().charAt(0) === '-'
                        ? parseFloat(
                            transaction?.amount?.toString().replace('-', ''),
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : parseFloat(transaction?.amount)?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                          )}
                      {team &&
                        transaction?.currency !== teamDefaultCurrency &&
                        ` (${transaction?.currency})`}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {!transactions?.transactions?.length > 0 && (
          <div className="flex flex-col gap-5 items-center justify-center w-full">
            <h6 className="text-center mt-4">No Transactions Found</h6>
            <img
              src={emptyWalletImg}
              alt="No transactions found"
              className="h-[12.5rem]"
            />
          </div>
        )}
      </div>
      <div className="mt-8">
        <PaginationClassic
          pagination={{
            limit: transactionsLocalLimit,
            page,
            totalRecords,
          }}
          onPageChange={loadTransactions}
          setLimit={setTransactionLocalLimit}
        />
      </div>
    </div>
  );
};

export default TransactionsTable;
