import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import { statusOptions } from 'pages/projects';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStringSegments, trimText } from 'utils/Utils';

const ProjectsBox = ({ projects, overviewLoadings }) => {
  const navigate = useNavigate();
  const [descriptionLength, setDescriptionLength] = useState(20);

  const handleResize = () => {
    const descriptionCell = document.getElementById('projectCellId0');
    if (descriptionCell) {
      setDescriptionLength(parseInt((descriptionCell?.clientWidth - 2) / 10));
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [projects]);

  return (
    <div className="p-6 border border-[#D0D5DD] rounded-[12px]">
      <h3 className="text-[22px] font-medium text-[#666] mb-4">
        Current Projects
      </h3>
      {overviewLoadings?.projects ? (
        <div className="flex flex-col h-[180px] justify-center items-center">
          <Loader height="h-8 w-8" />
          <p>Fetching data please wait...</p>
        </div>
      ) : (
        <>
          {projects?.length > 0 ? (
            <div
              className="overflow-auto scrollbar"
              id="insightsOverviewProjectsTable"
            >
              <table className="w-full table-auto">
                <tbody className="divide-y divide-[#D0D5DD]">
                  {projects?.map(
                    (project, i) =>
                      i < 3 && (
                        <tr key={project?.id} className="">
                          <td
                            className="py-3 pr-2 w-full"
                            id={`projectCellId${i}`}
                          >
                            <div className=" w-full">
                              <Tooltip
                                tooltipShow={
                                  project?.name?.length > descriptionLength
                                }
                                content={
                                  project?.name?.length > 30
                                    ? (() => {
                                        const segments = getStringSegments(
                                          project?.name,
                                          60,
                                        );
                                        return segments.map(
                                          (segment, index) => (
                                            <p key={index}>{segment}</p>
                                          ),
                                        );
                                      })()
                                    : project?.name
                                }
                                contentClassName="border-none rounded-[10px] translate-y-1 overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                isFixed
                                ids={[
                                  'insightsOverviewProjectsTable',
                                  'insightsPage',
                                ]}
                              >
                                <h4 className="text-[18px] my-0 leading-normal text-[#667085]">
                                  {trimText(project?.name, descriptionLength)}
                                </h4>
                              </Tooltip>
                              <div
                                className="capitalize text-[10px] w-fit font-medium  inline-flex items-center gap-1 px-2 h-4 rounded-[5px]"
                                style={{
                                  color: statusOptions?.find(
                                    (s) => s?.id === project?.status,
                                  )?.textColor,
                                  backgroundColor: statusOptions?.find(
                                    (s) => s?.id === project?.status,
                                  )?.background,
                                }}
                              >
                                {
                                  statusOptions?.find(
                                    (s) => s?.id === project?.status,
                                  )?.name
                                }
                              </div>
                            </div>
                          </td>

                          <td className="py-3 px-2 min-w-[2rem] ">
                            <div className="flex flex-col items-end">-</div>
                          </td>
                        </tr>
                      ),
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex w-full items-center justify-center flex-col my-4">
              <p className="text-slate-600 text-[20px] text-center">
                No Project Found
              </p>
            </div>
          )}
          <button
            type="button"
            className="w-full h-[38px] border border-indigo-500 mt-4 rounded-[6px] text-indigo-500"
            onClick={() =>
              navigate(projects?.length > 0 ? '/projects' : '/projects?id=new')
            }
          >
            {projects?.length > 0 ? 'View All' : 'Create New Project'}
          </button>
        </>
      )}
    </div>
  );
};

export default ProjectsBox;
