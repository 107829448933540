import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CategoriesFilter from 'pages/transactions/Filters/CategoriesFilter';
import DropdownFull from 'components/DropdownFull';
import {
  formatNumber,
  amountColor,
  trimText,
  getContrast,
  transformAccounts,
  recurringTransStatusColor,
  TEAM_CURRENCY,
} from '../../utils/Utils';
import {
  getRecurringTransactions,
  getAccounts,
} from '../../API/backend_helper';
import { Loader } from '../../components/Svg';

const RecurringTransactions = ({
  teamCurrency,
  setRecurringFlyoutOpen,
  setActiveRecurring,
  activeRecurring,
  recurringFlyoutOpen,
}) => {
  const { team } = useSelector((state) => state.Team);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [isLoading, setIsLoading] = useState(true);
  const [recurringTransactions, setRecurringTransactions] = useState([]);
  const [filterRecurringTransactions, setFilterRecurringTransactions] =
    useState([]);
  const [descriptionLength, setDescriptionLength] = useState(16);
  const [accounts, setAccounts] = useState([]);
  const [selectedUsedAcc, setSelectedUsedAcc] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0);
  const [unpaidAmount, setUnpaidAmount] = useState(0);
  const [incomingCompleteAmount, setIncomingCompleteAmount] = useState(0);
  const [incomingPendingAmount, setIncomingPendingAmount] = useState(0);

  const [status, setStatus] = useState('all');

  const [usedAccounts, setUsedAccounts] = useState([]);

  const fetchRecurringTransactions = async (_accounts = accounts) => {
    try {
      const res = await getRecurringTransactions();
      setRecurringTransactions(res.data);
      setFilterRecurringTransactions(res.data);
      if (res?.data?.length > 0) {
        const tempAccounts = [];
        let paid = 0;
        let nonPaid = 0;

        let incomingComplete = 0;
        let incomingPending = 0;
        res?.data?.forEach((trans) => {
          if (!tempAccounts?.find?.((ac) => ac?.id === trans?.accountId)) {
            const tempAcc = _accounts?.find?.(
              (acc) => acc.id === trans?.accountId,
            );
            if (tempAcc) {
              tempAccounts?.push(tempAcc);
            }
          }
          if (trans?.currency === teamDefaultCurrency) {
            if (trans?.paidStatus === 'paid') {
              if (trans?.transactions?.[0]?.type === 'Expense') {
                paid += trans?.amount;
              } else {
                incomingComplete += trans?.amount;
              }
            } else {
              if (trans?.transactions?.[0]?.type === 'Expense') {
                nonPaid += trans?.amount;
              } else {
                incomingPending += trans?.amount;
              }
            }
          }
        });
        setPaidAmount(paid);
        setUnpaidAmount(nonPaid);
        setIncomingCompleteAmount(incomingComplete);
        setIncomingPendingAmount(incomingPending);
        if (tempAccounts?.length > 0) {
          const transformedAcc = transformAccounts(tempAccounts, 'allAccounts');
          setUsedAccounts(transformedAcc);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error', error);
    }
  };

  const getAccountsApi = async () => {
    try {
      const res = await getAccounts();
      await fetchRecurringTransactions(res);
      setAccounts(res);
    } catch (error) {
      setIsLoading(false);
      console.error('Error', error);
    }
  };
  const findAccountMask = (accountId) => {
    const account = accounts?.find?.((acc) => acc.id === accountId);
    return account?.mask ? `(...${account.mask})` : '';
  };

  const loadData = async () => {
    setIsLoading(true);
    await getAccountsApi();
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setDescriptionLength(55);
      } else if (window.innerWidth > 1700) {
        setDescriptionLength(45);
      } else if (window.innerWidth > 1280) {
        setDescriptionLength(30);
      } else if (window.innerWidth > 992) {
        setDescriptionLength(20);
      } else {
        setDescriptionLength(16);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  // Helper function to handle filtering logic
  const filterTransactions = (transactions, status, accounts) =>
    transactions?.filter((trans) => {
      let flag = true;

      // Status filtering
      switch (status) {
        case 'paid':
          flag = trans?.paidStatus === 'paid';
          break;
        case 'expected':
          flag = trans?.paidStatus !== 'paid';
          break;
        case 'all':
          flag = true;
          break;
        default:
          flag = false;
      }

      // Account filtering
      if (accounts?.length > 0) {
        flag = flag && accounts.includes(trans?.accountId);
      }
      return flag;
    });

  const onStatusChange = (value) => {
    setStatus(value);
    const temp = filterTransactions(
      recurringTransactions,
      value,
      selectedUsedAcc,
    );
    setFilterRecurringTransactions(temp);
  };

  const onAccountChange = (value) => {
    setSelectedUsedAcc(value);
    const temp = filterTransactions(recurringTransactions, status, value);
    setFilterRecurringTransactions(temp);
  };

  return isLoading ? (
    <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
      <Loader />
      <p className="text-slate-600 font-medium text-[0.875rem] text-center">
        Fetching data, please wait...
      </p>
    </div>
  ) : (
    <div className="flex w-full flex-col items-center gap-5 mt-6">
      <div className="w-full grid sm:grid-cols-3 space-y-4 sm:space-y-0 divide-y sm:divide-y-0 sm:divide-x divide-[#D0D5DD] border border-[#D0D5DD] rounded-[12px] p-4 mb-3">
        <div className="sm:pr-[10%]">
          <h3 className="text-[18px] font-medium text-[#666666] leading-tight mb-6">
            Total Monthly Recurring (Expense)
          </h3>
          <h2 className="text-[22px] font-medium text-[#101828] text-right w-full">
            {teamCurrency ? teamCurrency?.symbol : '$'}
            {parseFloat(paidAmount + unpaidAmount)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h2>
          <h3 className="text-[18px] font-medium text-[#666666] leading-tight mb-6">
            Total Monthly Recurring (Incoming)
          </h3>
          <h2 className="text-[22px] font-medium text-[#78BD4F] text-right w-full">
            {teamCurrency ? teamCurrency?.symbol : '$'}
            {parseFloat(
              (incomingCompleteAmount + incomingPendingAmount) * -1,
            )?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h2>
        </div>
        <div className=" pt-4 sm:pt-0 sm:px-[10%]">
          <h3 className="text-[18px] font-medium text-[#666666] leading-tight mb-6">
            Already Paid (Expense)
          </h3>
          <h2 className="text-[22px] font-medium text-[#101828] w-full text-right">
            {teamCurrency ? teamCurrency?.symbol : '$'}
            {parseFloat(paidAmount)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h2>
          <h3 className="text-[18px] font-medium text-[#666666] leading-tight mb-6">
            Already Received (Incoming)
          </h3>
          <h2 className="text-[22px] font-medium text-[#78BD4F] w-full text-right">
            {teamCurrency ? teamCurrency?.symbol : '$'}
            {parseFloat(incomingCompleteAmount * -1)?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },
            )}
          </h2>
        </div>
        <div className="pt-4 sm:pt-0 sm:pl-[10%]">
          <h3 className="text-[18px] font-medium text-[#666666] leading-tight mb-6">
            Still Owing (Expense)
          </h3>
          <h2 className="text-[22px] font-medium text-[#101828] w-full text-right">
            {teamCurrency ? teamCurrency?.symbol : '$'}
            {parseFloat(unpaidAmount)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h2>
          <h3 className="text-[18px] font-medium text-[#666666] leading-tight mb-6">
            Still Expecting (Incoming)
          </h3>
          <h2 className="text-[22px] font-medium text-[#78BD4F] w-full text-right">
            {teamCurrency ? teamCurrency?.symbol : '$'}
            {parseFloat(incomingPendingAmount * -1)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h2>
        </div>
      </div>
      <div className="w-full flex flex-col items-center gap-6 shrink-0 self-stretch rounded-xl border border-[#D0D5DD] bg-white p-6">
        <div className="flex justify-between w-full items-center flex-wrap gap-4 gap-y-2">
          <div className="w-fit flex items-start self-stretch text-slate-600 text-[1.375rem] font-medium leading-[2.375rem]">
            Recurring Monthly Transactions
          </div>
          <div className="flex sm:flex-row flex-col gap-y-1 gap-4 sm:ml-auto">
            <div className="flex flex-col w-[250px]">
              <CategoriesFilter
                allCategories={usedAccounts}
                selectedCategoryId={selectedUsedAcc}
                setSelectedCategoryId={(value) => {
                  onAccountChange(value);
                }}
                getTransactionsApi={() => {}}
                apiFilter={selectedUsedAcc}
                isReset
                allAccount
                type="account"
                report
                id="accounts"
              />
            </div>
            <div className="flex flex-col w-[250px]">
              <DropdownFull
                selected={status}
                setSelected={onStatusChange}
                apiFunc={{}}
                options={[
                  { id: 'all', name: 'All Statuses' },
                  { id: 'expected', name: 'Expected / Owing' },
                  { id: 'paid', name: 'Paid / Received' },
                ]}
                name="name"
                onClear={() => {
                  onStatusChange('all');
                }}
                label="Select Currency"
                type="tags"
                flyout
                tags
                apiFilter=""
                scrollIntoView={false}
                id="status"
                height="h-10"
              />
            </div>
          </div>
        </div>
        <div className="w-full items-start flex overflow-x-auto scrollbar">
          <table className="min-w-max w-full flex flex-col items-start">
            <thead className="flex w-full justify-around items-center gap-11 rounded-[0.313rem] shadow-md bg-white h-[3.5rem] mb-1">
              <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[80px] flex-shrink-0 flex-grow-0 whitespace-nowrap ">
                Recurring Date
              </th>
              <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[60px] flex-shrink-0 flex-grow-0 ">
                Status
              </th>
              <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[270px] 2xl:w-[600px] flex-shrink-0 flex-grow-0 ">
                Description
              </th>
              <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[150px]  flex-shrink-0 flex-grow-0 ">
                Account
              </th>
              <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px]  flex-shrink-0 flex-grow-0 ">
                Amount
              </th>
            </thead>
            {!isLoading &&
              filterRecurringTransactions &&
              filterRecurringTransactions?.map?.((transaction) => (
                <tr
                  key={transaction?.id}
                  className="flex w-full justify-around items-center gap-11 bg-white h-[3.5rem] border-b border-[#D0D5DD] hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveRecurring(null);
                    setActiveRecurring(transaction);
                    setRecurringFlyoutOpen(true);
                  }}
                  style={
                    recurringFlyoutOpen &&
                    activeRecurring?.transactions?.[0]?.id ===
                      transaction?.transactions?.[0]?.id
                      ? { background: '#a0cd8520' }
                      : {}
                  }
                >
                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[80px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    {moment(transaction?.expectedDate).format('MMM DD, YYYY')}
                  </td>
                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[60px] flex-shrink-0 flex-grow-0">
                    <div
                      className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                ${recurringTransStatusColor(transaction?.paidStatus)}
              `}
                    >
                      {transaction?.transactions?.[0]?.type === 'Income' &&
                      transaction?.paidStatus === 'paid'
                        ? 'Received'
                        : transaction?.transactions?.[0]?.type === 'Expense' &&
                            transaction?.paidStatus === 'expected'
                          ? 'Owing'
                          : transaction?.paidStatus}
                    </div>
                  </td>
                  <td className=" text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[270px] 2xl:w-[600px] whitespace-nowrap flex items-center gap-2 flex-shrink-0 flex-grow-0 ">
                    <div className="h-[1.688rem] w-[1.688rem] min-h-[1.688rem] min-w-[1.688rem] rounded-full">
                      {transaction?.vendorLogo ||
                      transaction?.plaidCategoryIconUrl ? (
                        <img
                          className="rounded-full"
                          src={
                            transaction?.vendorLogo ||
                            transaction?.plaidCategoryIconUrl
                          }
                          alt={transaction?.name}
                        />
                      ) : (
                        <div
                          className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                          style={{
                            backgroundColor: transaction?.categoryAccount?.group
                              ?.color
                              ? transaction?.categoryAccount?.group?.color
                              : '',
                          }}
                        >
                          <div
                            className={`${
                              transaction?.categoryAccount?.group?.color
                                ? getContrast(
                                    transaction?.categoryAccount?.group?.color,
                                    'text-black',
                                    'text-white',
                                  )
                                : 'text-black'
                            } text-xs font-normal uppercase`}
                          >
                            {transaction?.description
                              ? transaction.description
                                  .replace(/[^\w\s]/gi, '')
                                  .split(' ')
                                  .filter(Boolean)
                                  .slice(0, 2)
                                  .map((word) => word.charAt(0))
                                  .join('')
                              : ''}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="whitespace-nowrap flex flex-col">
                      <p className="leading-6 font-medium text-slate-600 text-[14px]">
                        {trimText(transaction?.description, descriptionLength)}
                      </p>
                      <p className="leading-6 text-slate-600 text-opacity-80 text-[12px]">
                        {transaction?.categoryAccount}
                      </p>
                    </div>
                  </td>
                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[150px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    {transaction?.accountName}{' '}
                    {findAccountMask(transaction?.accountId)}
                  </td>
                  <td
                    className={`text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 ${amountColor(
                      transaction?.amount?.toString(),
                    )}`}
                  >
                    {teamCurrency ? teamCurrency?.symbol : '$'}
                    {formatNumber(Math.abs(transaction.amount.toFixed(2)))}
                    <div className="flex gap-1">
                      {!(
                        transaction?.amount -
                          transaction?.transactions[
                            transaction?.transactions?.length - 1
                          ]?.amount ===
                        0
                      ) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 128 512"
                          className="h-4 gap-[2px] items-center fill-rose-500"
                        >
                          <path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                        </svg>
                      )}
                      {transaction?.amount -
                        transaction?.transactions[
                          transaction?.transactions?.length - 1
                        ]?.amount <
                      0 ? (
                        // Up Arrow
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          width="12"
                          viewBox="0 0 384 512"
                          className="h-3 mt-[2px] shrink-0 fill-current text-[#78BD4F]"
                        >
                          <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                        </svg>
                      ) : transaction?.amount -
                          transaction?.transactions[
                            transaction?.transactions?.length - 1
                          ]?.amount >
                        0 ? (
                        // Down Arrow
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          width="12"
                          viewBox="0 0 384 512"
                          className={`h-3 mt-[2px] ${'rotate-180'} shrink-0 fill-current text-[#FF4B4B]`}
                        >
                          <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                        </svg>
                      ) : (
                        <span className="text-xs text-slate-400 mx-[2px]">
                          --
                        </span>
                      )}
                      {transaction?.amount -
                        transaction?.transactions[
                          transaction?.transactions?.length - 1
                        ]?.amount !==
                        0 && (
                        <span
                          className={`text-xs ${
                            transaction?.amount -
                              transaction?.transactions[
                                transaction?.transactions?.length - 1
                              ]?.amount <
                            0
                              ? 'text-[#78BD4F]'
                              : transaction?.amount -
                                    transaction?.transactions[
                                      transaction?.transactions?.length - 1
                                    ]?.amount >
                                  0
                                ? 'text-[#FF4B4B]'
                                : 'text-slate-400'
                          }`}
                        >
                          {transaction?.amount -
                            transaction?.transactions[
                              transaction?.transactions?.length - 1
                            ]?.amount <
                          0
                            ? `${
                                teamCurrency ? teamCurrency?.symbol : '$'
                              }${formatNumber(
                                Math.abs(
                                  transaction?.amount -
                                    transaction?.transactions[
                                      transaction?.transactions?.length - 1
                                    ]?.amount,
                                ).toFixed(2),
                              )}`
                            : `${
                                transaction?.amount -
                                  transaction?.transactions[
                                    transaction?.transactions?.length - 1
                                  ]?.amount !==
                                0
                                  ? '-'
                                  : ''
                              }${
                                teamCurrency ? teamCurrency?.symbol : '$'
                              }${formatNumber(
                                Math.abs(
                                  transaction?.amount -
                                    transaction?.transactions[
                                      transaction?.transactions?.length - 1
                                    ]?.amount,
                                ).toFixed(2),
                              )}`}
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default RecurringTransactions;
