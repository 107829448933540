import React, { useRef, useEffect, useState } from 'react';
import {
  Chart,
  DoughnutController,
  ArcElement,
  TimeScale,
  Tooltip,
} from 'chart.js';

import 'chartjs-adapter-moment';

// Import utilities
import { tailwindConfig } from '../utils/Utils';

Chart.register(DoughnutController, ArcElement, TimeScale, Tooltip);

function DoughnutChart({ data, width, height, hideLegends = false }) {
  const [chart, setChart] = useState(null);
  const canvas = useRef(null);
  const legend = useRef(null);

  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    const newChart = new Chart(ctx, {
      type: 'doughnut',
      data,
      options: {
        cutout: '75%',
        layout: {
          padding: 24,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            titleColor: 'black',
            bodyColor: '#94a3b8',
            backgroundColor: '#fff',
            borderColor: '#cbd5e1',
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [
        {
          id: 'htmlLegend',
          afterUpdate(c, args, options) {
            const ul = legend.current;
            if (!ul) return;
            // Remove old legend items
            while (ul.firstChild) {
              ul.firstChild.remove();
            }
            // Reuse the built-in legendItems generator
            const items = c.options.plugins.legend.labels.generateLabels(c);
            items.forEach((item) => {
              const li = document.createElement('li');
              li.style.margin = tailwindConfig().theme.margin[1];
              // Button element
              const button = document.createElement('button');
              button.classList.add(
                'btn-xs',
                'bg-white',
                'bg-slate-800',
                'text-slate-500',
                'text-slate-400',
                'border',
                'border-slate-200',
                'border-slate-700',
                'shadow-md',
              );
              button.style.opacity = item.hidden ? '.3' : '';
              button.onclick = () => {
                c.toggleDataVisibility(item.index);
                c.update();
              };
              // Color box
              const box = document.createElement('span');
              box.style.display = 'block';
              box.style.width = tailwindConfig().theme.width[2];
              box.style.height = tailwindConfig().theme.height[2];
              box.style.backgroundColor = item.fillStyle;
              box.style.borderRadius = tailwindConfig().theme.borderRadius.sm;
              box.style.marginRight = tailwindConfig().theme.margin[1];
              box.style.pointerEvents = 'none';
              // Label
              const label = document.createElement('span');
              label.style.display = 'flex';
              label.style.alignItems = 'center';
              const labelText = document.createTextNode(item.text);
              label.appendChild(labelText);
              li.appendChild(button);
              button.appendChild(box);
              button.appendChild(label);
              ul.appendChild(li);
            });
          },
        },
      ],
    });
    setChart(newChart);
    return () => newChart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chart) return;
    chart.options.plugins.tooltip.titleColor = 'black';
    chart.options.plugins.tooltip.bodyColor = '#e2e8f0';
    chart.options.plugins.tooltip.backgroundColor = '#e2e8f0';
    chart.options.plugins.tooltip.borderColor = '#64748b';

    chart.update('none');
  }, []);

  return (
    <div className="grow flex flex-col justify-center">
      <div className="z-10">
        <canvas ref={canvas} width={width} height={height} />
      </div>

      <div
        className="px-5 pt-2 pb-6"
        style={hideLegends ? { display: 'none' } : {}}
      >
        <ul ref={legend} className="flex flex-wrap justify-center -m-1" />
      </div>
    </div>
  );
}

export default DoughnutChart;
