import React from 'react';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';

const Tabs = ({ activeTab, setActiveTab, AUTOMATION_TAB, TEMPLATES_TAB }) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  return (
    <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-6 mt-8 flex-wrap">
      <div
        onClick={() => setActiveTab(AUTOMATION_TAB)}
        className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === AUTOMATION_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
        role="button"
        tabIndex="0"
      >
        Automations
      </div>
      {checkPermission(
        PermissionKeys.MANAGE_AUTOMATIONS,
        accessType,
        permissions,
      ) && (
        <div
          onClick={() => setActiveTab(TEMPLATES_TAB)}
          className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === TEMPLATES_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
          role="button"
          tabIndex="0"
        >
          Templates
        </div>
      )}
    </div>
  );
};

export default Tabs;
