import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { trimText } from '../../utils/Utils';
import Tooltip from '../../components/Tooltip';

const CustomersTable = ({
  customers,
  displayedCustomers,
  setTitle,
  setActiveCustomer,
  setFormOpen,
  setModalOpen,
}) => {
  const navigate = useNavigate();

  const { accessType, permissions } = useSelector((state) => state.User);

  const [notesLength, setNotesLength] = useState(60);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1700) {
        setNotesLength(80);
      } else if (window.innerWidth > 1400) {
        setNotesLength(50);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="bg-white flex-1">
      {/* Table */}
      <div className="overflow-x-auto h-full">
        <table className="table-fixed w-full dark:text-slate-300">
          {/* Table header */}
          <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
            <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
              <th className="px-4 w-[18rem] max-w-[18rem]">
                <div className="text-left w-[6.25rem] font-medium">Name</div>
              </th>
              <th className="w-[14rem] max-w-[14rem]">
                <div className="text-left w-[8.75rem] font-medium">Email</div>
              </th>
              <th className="w-[9rem] max-w-[9rem]">
                <div className="text-left font-medium">Phone</div>
              </th>
              <th className="w-[16rem] max-w-[16rem]">
                <div className="text-left font-medium">Notes</div>
              </th>
              <th className="w-[8rem] max-w-[8rem]">
                <div className="text-left font-medium">Actions</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          {customers?.records?.length > 0 && (
            <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
              {displayedCustomers?.records?.map((customer, index) => (
                <tr
                  key={index}
                  onClick={() => navigate(`/customer/${customer?.id}`)}
                  className="cursor-pointer w-full pl-[1.375rem]  pr-[1.125rem] h-20 hover:bg-[#a0cd85] hover:bg-opacity-5"
                >
                  <td className="px-4">
                    <div className=" break-words text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                      {customer?.customer}
                    </div>
                  </td>
                  <td className="pr-4">
                    <div className=" text-left text-slate-600 text-[0.938rem] leading-6 break-words">
                      {customer?.contacts[0]?.email
                        ? customer?.contacts[0]?.email
                        : '---'}
                    </div>
                  </td>
                  <td className="whitespace-nowrap">
                    <div className="text-left">
                      <p className="text-slate-600 text-[0.938rem] leading-6">
                        {customer?.contacts[0]?.phone
                          ? customer?.contacts[0]?.phone
                          : '---'}
                      </p>
                    </div>
                  </td>
                  <td className="pr-4">
                    <div className="text-left ">
                      <Tooltip
                        tooltipShow={customer?.notes?.length > notesLength}
                        content={`${customer?.notes}`}
                        contentClassName="border-none rounded-[10px] overflow-visible break-words text-wrap text-sm text-[#667085] relative tooltipArrowDown w-[400px] xxl:w-[550px]"
                      >
                        {trimText(customer?.notes, notesLength) || '--'}
                      </Tooltip>
                    </div>
                  </td>

                  <td className="whitespace-nowrap pr-4">
                    <div className="inline-flex items-center gap-3.5">
                      {/* Customer Statement  */}
                      <Tooltip
                        content="Customer Statement"
                        contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                      >
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/customer-statement/${customer?.id}`);
                          }}
                          className="w-[1.625rem] h-[1.625rem]  cursor-pointer inline-flex justify-center items-center rounded-[7px] bg-white border border-slate-300"
                          role="button"
                          tabIndex="0"
                        >
                          <svg
                            width="12"
                            height="14"
                            viewBox="0 0 12 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.72383 11.7907C3.2341 11.2431 4.01196 11.2867 4.46 11.8841L5.0885 12.7242C5.59255 13.39 6.40774 13.39 6.91178 12.7242L7.54029 11.8841C7.98833 11.2867 8.76618 11.2431 9.27645 11.7907C10.3841 12.9731 11.2864 12.581 11.2864 10.9258V3.91266C11.2926 1.40487 10.7077 0.776367 8.35547 0.776367H3.65103C1.29881 0.776367 0.713867 1.40487 0.713867 3.91266V10.9195C0.713867 12.581 1.6224 12.9668 2.72383 11.7907Z"
                              stroke="#667085"
                              strokeOpacity="0.8"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="stroke-current"
                            />
                            <path
                              d="M3.57432 6.37657H3.57991"
                              stroke="#667085"
                              strokeOpacity="0.8"
                              strokeWidth="1.2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="stroke-current"
                            />
                            <path
                              d="M5.31738 6.37695H8.73993"
                              stroke="#667085"
                              strokeOpacity="0.8"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="stroke-current"
                            />
                            <path
                              d="M3.57432 3.88829H3.57991"
                              stroke="#667085"
                              strokeOpacity="0.8"
                              strokeWidth="1.2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="stroke-current"
                            />
                            <path
                              d="M5.31738 3.8877H8.73993"
                              stroke="#667085"
                              strokeOpacity="0.8"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="stroke-current"
                            />
                          </svg>
                        </div>
                      </Tooltip>
                      {checkPermission(
                        PermissionKeys.MANAGE_CUSTOMERS,
                        accessType,
                        permissions,
                      ) && (
                        <>
                          {/* Edit Button  */}
                          <Tooltip
                            content="Edit Customer"
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                          >
                            <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setTitle('Edit Customer');
                                  setActiveCustomer(customer);
                                  setFormOpen(true);
                                }}
                              >
                                <path
                                  d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                  fill="white"
                                  stroke="#D0D5DD"
                                  strokeOpacity="0.6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                                  stroke="#858D9D"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </Tooltip>
                          {/* Delete Button  */}
                          <Tooltip
                            content="Delete Customer"
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                            position="left"
                          >
                            <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setActiveCustomer(customer);
                                  setModalOpen(true);
                                }}
                              >
                                <path
                                  d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                  fill="white"
                                  stroke="#D0D5DD"
                                  strokeOpacity="0.6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                                  stroke="#858D9D"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.693 16.3574H14.8505"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.1552 13.7656H15.3948"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default CustomersTable;
