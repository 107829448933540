import React from 'react';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import InvoiceImage from '../../images/payment.png';
import EstimateImage from '../../images/payment-success.png';

const InvoiceHeading = 'Accelerate Your Receivables';
const InvoiceText =
  'Manage your income efficiently. Easily create and send invoices, offering flexible payment options like credit cards and bank transfers. An invoice represents money that is owed to your business, typically paid by customers at a future date.';
const EstimateeHeading = 'Streamline Deal Proposals';
const EstimateText =
  'Quickly generate and deliver precise estimates, providing potential customers with clear pricing and service details. An estimate presents the anticipated cost for your offerings, which can be adjusted as needed to finalize agreements.';

const NoInvoiceBox = ({ handleCreate, type, accessType }) => {
  const { permissions } = useSelector((state) => state.User);

  return (
    <div className="flex-1 flex flex-col justify-center items-center mt-0 pt-0">
      {/* Avatar Image  */}
      {type !== 'Recurring' && (
        <img
          src={type === 'Invoice' ? InvoiceImage : EstimateImage}
          alt=""
          className="h-[12.5rem]"
        />
      )}
      {type !== 'Recurring' && (
        <>
          <h3 className="mb-6 mt-4 leading-tight text-[36px] font-medium text-[#00000099] ">
            {type === 'Invoice' ? InvoiceHeading : EstimateeHeading}
          </h3>
          <p className="text-sm text-[#667085] max-w-[425px] text-center mb-8 leading-tight">
            {type === 'Invoice' ? InvoiceText : EstimateText}
          </p>
        </>
      )}
      {type === 'Recurring' && (
        <h3 className="mb-4 mt-2 leading-tight text-[24px] font-medium text-[#00000099] ">
          No recurring templates.
        </h3>
      )}
      {checkPermission(
        PermissionKeys.MANAGE_INVOICES,
        accessType,
        permissions,
      ) && (
        <button
          onClick={handleCreate}
          className="h-11 px-6 text-white bg-indigo-500 shadow-button rounded-[5px]"
          type="button"
        >
          {type !== 'Recurring'
            ? `Create Your First ${type}`
            : 'Create Recurring Invoice'}
        </button>
      )}
    </div>
  );
};

export default NoInvoiceBox;
