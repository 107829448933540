import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  formatNumber,
  getCurrencySymbolFromIso,
  TEAM_CURRENCY,
} from 'utils/Utils';

const InvoiceBalanceBox = ({ invoiceBalance, overviewLoadings }) => {
  const navigate = useNavigate();

  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  return (
    <div className="h-fit p-6 rounded-[12px] border border-[#D0D5DD] ">
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-1 items-center">
          <h3 className="text-[22px] text-[#666] font-medium">
            Invoice Balance
          </h3>
          <Tooltip
            content="How much total Invoice revenue can be expected."
            contentClassName="overflow-visible relative tooltipArrowDown"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <g>
                <path
                  d="M8 15.5625C9.48336 15.5625 10.9334 15.1226 12.1668 14.2985C13.4001 13.4744 14.3614 12.3031 14.9291 10.9326C15.4968 9.56218 15.6453 8.05418 15.3559 6.59933C15.0665 5.14447 14.3522 3.8081 13.3033 2.7592C12.2544 1.71031 10.918 0.996003 9.46318 0.706614C8.00832 0.417225 6.50032 0.56575 5.12987 1.13341C3.75943 1.70106 2.58809 2.66236 1.76398 3.89573C0.939867 5.1291 0.5 6.57914 0.5 8.0625C0.5 10.0516 1.29018 11.9593 2.6967 13.3658C4.10322 14.7723 6.01088 15.5625 8 15.5625ZM8 3.3725C8.19778 3.3725 8.39112 3.43115 8.55557 3.54103C8.72002 3.65092 8.84819 3.80709 8.92388 3.98982C8.99957 4.17255 9.01937 4.37361 8.98079 4.56759C8.9422 4.76157 8.84696 4.93976 8.70711 5.07961C8.56725 5.21946 8.38907 5.3147 8.19509 5.35329C8.00111 5.39187 7.80004 5.37207 7.61732 5.29638C7.43459 5.2207 7.27841 5.09252 7.16853 4.92807C7.05865 4.76362 7 4.57028 7 4.3725C7 4.10729 7.10536 3.85293 7.29289 3.6654C7.48043 3.47786 7.73478 3.3725 8 3.3725ZM7 8.0625C7 7.79729 7.10536 7.54293 7.29289 7.3554C7.48043 7.16786 7.73478 7.0625 8 7.0625C8.26522 7.0625 8.51957 7.16786 8.70711 7.3554C8.89464 7.54293 9 7.79729 9 8.0625V12.0625C9 12.3277 8.89464 12.5821 8.70711 12.7696C8.51957 12.9571 8.26522 13.0625 8 13.0625C7.73478 13.0625 7.48043 12.9571 7.29289 12.7696C7.10536 12.5821 7 12.3277 7 12.0625V8.0625Z"
                  fill="#949494"
                />
              </g>
              <defs>
                <clipPath id="clip0_115_1502">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.0625)"
                  />
                </clipPath>
              </defs>
            </svg>
          </Tooltip>
        </div>
        <button
          className="h-8  px-[18px] bg-white text-indigo-500 border border-indigo-500 inline-flex justify-center items-center text-base rounded-[6px] "
          type="button"
          onClick={() => navigate('/invoices?tab=invoices')}
        >
          View All Invoices
        </button>
      </div>
      {overviewLoadings?.invoiceBalance ? (
        <div className="flex flex-col h-[130px] justify-center items-center">
          <Loader height="h-8 w-8" />
          <p>Fetching data please wait...</p>
        </div>
      ) : (
        <>
          <h3 className="text-[36px] text-black font-medium mb-5">
            {' '}
            {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
            {formatNumber(invoiceBalance?.totalUnpaid)}
          </h3>
          <div className="flex ">
            <div className="p-4 border-r border-[#D0D5DD] min-w-32">
              <p className="text-xs text-[#66708580]">Next 7 days</p>
              <p className="text-[18px] text-[#F9C657]">
                {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {formatNumber(invoiceBalance?.totalDueWithin7Days)}
              </p>
            </div>
            <div className="p-4">
              <p className="text-xs text-[#66708580]">Overdue</p>
              <p className="text-[18px] text-[#F8393C]">
                {' '}
                {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {formatNumber(invoiceBalance?.totalDuePassed)}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InvoiceBalanceBox;
