import { DevEnv } from './Utils';

export const PermissionKeys = {
  INVITE_USERS: 'Invite Users',
  REMOVE_USERS: 'Remove Users',
  MANAGE_WORKSPACE: 'Manage Workspace',
  MANAGE_PERMISSIONS: 'Manage Permissions',
  VIEW_ACCOUNTS: 'View Accounts',
  MANAGE_ACCOUNTS: 'Manage Accounts',
  VIEW_TRANSACTIONS: 'View Transactions',
  MANAGE_TRANSACTIONS: 'Manage Transactions',
  VIEW_JOURNALS_ENTRIES: 'View Journal Entries',
  MANAGE_JOURNAL_ENTRIES: 'Manage Journal Entries',
  RECONCILE_ACCOUNTS: 'Reconcile Accounts',
  VIEW_INVOICES: 'View Invoices',
  MANAGE_INVOICES: 'Manage Invoices',
  VIEW_BILLS: 'View Bills',
  MANAGE_BILLS: 'Manage Bills',
  MANAGE_EXPENSE_CLAIMS: 'Manage Expense Claims',
  APPROVE_EXPENSE_CLAIMS: 'Approve Expense Claims',
  VIEW_PEOPLE: 'View People',
  MANAGE_PEOPLE: 'Manage People',
  VIEW_VENDORS: 'View Vendors',
  MANAGE_VENDORS: 'Manage Vendors',
  VIEW_CUSTOMERS: 'View Customers',
  MANAGE_CUSTOMERS: 'Manage Customers',
  VIEW_PRODUCTS: 'View Product & Services',
  MANAGE_PRODUCTS: 'Manage Product & Services',
  VIEW_REPORTS: 'View Reports',
  MANAGE_AUTOMATIONS: 'Manage Automations',
  MANAGE_SETTINGS: 'Manage Settings',
  VIEW_DASHBOARD: 'View Dashboards',
  Manage_Bank_Account: 'Manage Bank Account',
  VIEW_TASKS: 'View Tasks',
};

export const checkPermission = (
  permissionKey = '',
  accessType = '',
  permissions = [],
) => {
  if (permissionKey && accessType && permissions?.length > 0) {
    const permission = permissions?.find((p) => p?.name === permissionKey);
    // let customAccessType = accessType;
    // if (process.env.REACT_APP_ENV === DevEnv) {
    //   if (customAccessType === 'accountant') {
    //     customAccessType = 'standard';
    //   } else if (customAccessType === 'viewer') {
    //     customAccessType = 'read only';
    //   }
    // }
    return permission?.roles?.includes(accessType) || false;
  }
  return false;
};
