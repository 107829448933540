import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  formatDateLocally,
  getFormattedDate,
  TEAM_DATE_FORMAT,
} from 'utils/Utils';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DatePickerDash from '../../components/DatePickerDash';
import { updateInvoice } from '../../API/backend_helper';
import TextInputDash from '../../components/TextInputDash';
import ModalBasic from '../../components/ModalBasic';
import DropdownFull from '../../components/DropdownFull';

const ConvertEstimateModal = ({
  isOpen,
  setIsOpen,
  activeEstimate,
  getData,
  navigateToInvoices = false,
  setIsActive = () => {},
  sendEmailAction = null,
  projects = [],
}) => {
  const formikRef = useRef();
  const { team } = useSelector((state) => state.Team);
  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [sendInvoice, setSendInvoice] = useState(false);
  const [noClick, setNoClick] = useState(false);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: false,
      monthSelectorType: 'static',
      onReady: (selectedDates, dateStr, instance) => {
        instance?.calendarContainer?.classList?.add(`flatpickr-right`);
      },
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen() {
        setNoClick(true);
      },
      onClose() {
        setNoClick(false);
      },
    }),
    [team],
  );

  useEffect(() => {
    if (!isOpen) {
      formikRef?.current?.resetForm();
    }
  }, [isOpen]);

  const handleSubmit = async (values, formik) => {
    try {
      const data = {
        ...values,
        dueDate: getFormattedDate(values.dueDate, teamDateFormat),
      };
      await updateInvoice(
        { invoiceType: 'invoice', ...data },
        activeEstimate?.id,
      );
      await getData({
        loading: false,
        type: 'invoice',
      });
      formik.resetForm();
      toast.success('Estimate Converted to Invoice Successfully.');
      if (navigateToInvoices) {
        navigate('/invoices?tab=invoices');
        setIsActive('');
      }
      setIsOpen(false);
      if (sendInvoice) {
        sendEmailAction(activeEstimate);
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (formikRef.current?.setFieldValue && isOpen) {
      formikRef.current?.setFieldValue(
        'projectId',
        activeEstimate?.projectId || null,
      );
      formikRef.current?.setFieldValue(
        'invoiceTitle',
        team?.defaults?.invoiceDefaultTitle || 'Invoice',
      );
    }
  }, [isOpen, formikRef?.current?.setFieldValue]);

  return (
    <ModalBasic
      title="Convert To Invoice"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
      noClick={noClick}
    >
      <Formik
        initialValues={{
          invoiceTitle: team?.defaults?.invoiceDefaultTitle || 'Invoice',
          dueDate: formatDateLocally(new Date(), teamDateFormat),
          paymentTerm: 0,
          projectId: activeEstimate?.projectId || null,
        }}
        validationSchema={Yup.object({
          invoiceTitle: Yup.string().required('Title is required'),
          dueDate: Yup.string().required('Due Date is required'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form className="min-h-[500px] flex flex-col justify-between">
            <div className="w-full flex flex-col  px-5 py-4 items-center self-stretch bg-white">
              <TextInputDash
                label="Invoice Title"
                id="invoiceTitle"
                name="invoiceTitle"
                type="text"
                paddingBottom="pb-4"
                required
                placeholder="Title"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.invoiceTitle || ''}
                error={
                  validation.touched.invoiceTitle &&
                  validation.errors.invoiceTitle
                }
              />
              <div className="w-full mt-1">
                <label className="text-sm font-medium text-slate-600 block  w-full mb-2">
                  Due Date
                  <span className="text-rose-500">*</span>
                </label>
                <DropdownFull
                  options={[
                    {
                      id: 0,
                      name: 'Due on receipt',
                    },
                    {
                      id: 15,
                      name: 'Net 15',
                    },
                    {
                      id: 30,
                      name: 'Net 30',
                    },
                    {
                      id: 60,
                      name: 'Net 60',
                    },
                    {
                      id: -1,
                      name: 'Custom',
                    },
                  ]}
                  name="name"
                  selected={validation.values.paymentTerm}
                  setSelected={(name) => {
                    if (name > 0) {
                      validation.setFieldValue(
                        'dueDate',
                        formatDateLocally(
                          moment().add(name, 'days').format('YYYY-MM-DD'),
                          teamDateFormat,
                        ),
                      );
                    } else if (name === -1) {
                      validation.setFieldValue('dueDate', null);
                    } else {
                      validation.setFieldValue(
                        'dueDate',
                        formatDateLocally(new Date(), teamDateFormat),
                      );
                    }
                    validation.setFieldValue('paymentTerm', name);
                  }}
                  placeholder="Select"
                  scrollIntoView={false}
                  height=" h-12"
                  label=""
                />
              </div>
              <DatePickerDash
                placeholder="Due Date"
                required
                validation={validation}
                value={validation.values.dueDate}
                onChange={(selectedDates) => {
                  validation.setFieldValue('dueDate', selectedDates[0]);
                }}
                disabled={validation.values.paymentTerm !== -1}
                options={options}
                width="w-full mt-3"
                name="dueDate"
                id="dueDate"
                error={validation.touched.dueDate && validation.errors.dueDate}
                onBlur={(e) => {
                  if (!e.target.classList?.value?.includes('active')) {
                    validation.setFieldValue('dueDate', e.target.value);
                  }
                }}
              />
              <div className="w-full mt-5">
                <p className={`text-sm font-medium text-slate-600 block mb-2 `}>
                  Assign Project
                  {validation.values.projectId && (
                    <span
                      className="text-indigo-500 pl-2 cursor-pointer"
                      onClick={() =>
                        validation.setFieldValue('projectId', null)
                      }
                      role="button"
                      tabIndex="0"
                    >
                      Clear
                    </span>
                  )}
                </p>
                <DropdownFull
                  options={projects}
                  selected={validation.values.projectId}
                  setSelected={(value) => {
                    validation.setFieldValue('projectId', value);
                  }}
                  name="name"
                  scrollIntoView={false}
                  // search
                  placeholder="Assign Project"
                  bottomOfTable
                  className="whitespace-nowrap truncate !h-fit"
                  ids={['invoiceEstimateContainer']}
                  isTableDropdown
                  setNoClick={setNoClick}
                />
              </div>
            </div>
            <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
              <button
                className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
                type="button"
              >
                Cancel
              </button>
              <button
                className=" h-11
           disabled:border-indigo-300 disabled:cursor-not-allowed
          flex items-center justify-center py-2.5 px-[1rem] rounded-[0.313rem] shadow text-indigo-500 border border-indigo-500 leading-6"
                type="submit"
                disabled={loading || loading2}
                onClick={() => {
                  if (
                    validation.values.dueDate &&
                    validation.values.invoiceTitle
                  ) {
                    setLoading(true);
                    setSendInvoice(false);
                    validation.handleSubmit();
                  }
                }}
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Save & Close
              </button>
              <button
                className="h-11
          disabled:bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed
          flex items-center justify-center py-2.5 px-[1rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
                type="submit"
                onClick={() => {
                  if (
                    validation.values.dueDate &&
                    validation.values.invoiceTitle
                  ) {
                    setLoading2(true);
                    setSendInvoice(true);
                    validation.handleSubmit();
                  }
                }}
                disabled={loading || loading2}
              >
                {loading2 && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Save & Send
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default ConvertEstimateModal;
