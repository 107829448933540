import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { peopleSignIn } from '../../API/backend_helper';
import Logo from '../../images/custom/count-logo.png';
import { ADMIN, IS_PERSON, TEAM_ID, TEAM_NAME } from '../../utils/Utils';
import AuthSlider from '../../components/AuthSlider';
import TextInput from '../../components/TextInput';
import { setAccessType, setLocalUser } from '../../redux/User/reducer';

function PeopleSignin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const defaultMail = searchParams.get('email') || '';
  const [loading, setLoading] = useState(false);

  document.title = 'COUNT | Sign In';

  const onSubmit = async (email, password) => {
    setLoading(true);
    try {
      const response = await peopleSignIn({
        email: email?.toLowerCase(),
        password,
        web: true,
      });
      if (typeof response === 'string') {
        toast.error(response);
      } else {
        if (
          response?.message ===
          'Your email is not verified. Please verify your email first. You can verify by requesting a reset link by clicking forgot password.'
        ) {
          toast.error('Email not verified. Please click resend for a new OTP');
          navigate(`/verify-email?email=${encodeURIComponent(email)}`);
          return;
        }
        const token = response?.auth?.access_token;
        const refreshToken = response?.auth?.refresh_token;
        localStorage.setItem('accessToken', token);
        localStorage.setItem('refreshToken', refreshToken);
        dispatch(setLocalUser(response?.user));
        localStorage.setItem(TEAM_ID, response?.user?.teamId);
        localStorage.setItem(TEAM_NAME, response?.user?.team?.name);
        localStorage.setItem(IS_PERSON, true);
        dispatch(setAccessType(ADMIN));
        navigate('/person/invoices?tab=invoices');
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>
              </div>
            </div>

            <div className="max-w-[476px] min-w-[50%] mx-auto w-full px-4 pt-8 pb-12">
              <h1 className="text-[40px] font-medium text-[#232323CC] mb-3 ">
                Employee Sign in
              </h1>
              <p className="text-lg text-[#667085CC] mb-9">
                Please login to continue to your account.
              </p>

              {/* Form */}
              <Formik
                enableReinitialize
                initialValues={{
                  email: decodeURI(defaultMail || ''),
                  password: '',
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .matches(
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      'Please enter valid email',
                    )
                    .required('Please Enter Email'),
                  password: Yup.string()
                    // .min(8, "Password must be at least 8 characters")
                    // .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
                    // .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
                    // .matches(RegExp("(.*[0-9].*)"), "At least one number")
                    .required('Please Enter Password'),
                })}
                onSubmit={async (values) => {
                  const { email, password } = values;
                  onSubmit(email, password);
                }}
              >
                {(validation) => (
                  <Form>
                    <div className="space-y-8">
                      <TextInput
                        label="Email"
                        type="email"
                        placeholder=" "
                        id="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ''}
                        error={
                          validation.touched.email
                            ? validation.errors.email
                            : ''
                        }
                        required
                      />
                      <TextInput
                        label="Password"
                        type="password"
                        placeholder=" "
                        id="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ''}
                        error={
                          validation.touched.password
                            ? validation.errors.password
                            : ''
                        }
                        autoComplete="on"
                        required
                      />
                    </div>
                    <div
                      className={`flex items-center justify-between ${validation.errors.password && validation.touched.password ? 'mt-8' : 'mt-6'} mb-7`}
                    >
                      <label className="inline-flex items-center text-slate-600 font-medium">
                        <input
                          type="checkbox"
                          className="form-checkbox mr-[10px] h-[18px] w-[18px]"
                        />{' '}
                        Keep me logged in
                      </label>
                      <div className="mr-1">
                        <Link
                          className="text-sm text-indigo-500 hover:underline"
                          to="/person/forgot-password"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <button
                      className="btn w-full py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
                           disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && (
                        <svg
                          className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                      )}
                      Sign in
                    </button>
                  </Form>
                )}
              </Formik>
              {/* Footer */}
              {/* Divider  Line */}

              <div className=" mt-8 text-center">
                <div className="text-base">
                  Sign in as an Admin?{' '}
                  <Link
                    className="font-medium underline ml-1 text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                    to="/signin"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Auth Slider */}

        <AuthSlider />
        {/* <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div> */}
      </div>
    </main>
  );
}

export default PeopleSignin;
