import { updateExpenseReportStatus } from 'API/backend_helper';
import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  formatNumber,
  getContrast,
  getCurrencySymbolFromIso,
  TEAM_CURRENCY,
  trimText,
} from 'utils/Utils';

const NoExpenseClaimsText = () => {
  const navigate = useNavigate();
  return (
    <div>
      <p className="text-[#6D7585]">
        We make it easy for you and employees to manage expense claims.
      </p>
      <div className="flex flex-col gap-5 my-6">
        {[
          'Download the COUNT Go app on iOS and Android.',
          'Snap a photo of the receipt and upload instantly.',
          'Advanced AI matches receipts to bank expenseClaims automatically.',
          'Transactions are matched even if they post several days later.',
        ].map((t, i) => (
          <div key={i} className="flex gap-4 items-center">
            <div className="min-h-5 min-w-5 h-5 w-5 rounded inline-flex justify-center items-center bg-[#78BD4F]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  d="M8.33317 2.5L3.74984 7.08333L1.6665 5"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="leading-normal text-sm font-medium text-[#1F2937]">
              {t}
            </p>
          </div>
        ))}
      </div>
      <button
        type="button"
        className="w-full h-[38px] bg-indigo-500 text-white border border-indigo-500 rounded-[6px]"
        onClick={() => navigate('/expense-claims')}
      >
        Get Started
      </button>
    </div>
  );
};

const ExpenseClaimsBox = ({
  expenseClaims,
  overviewLoadings,
  loadExpenseClaims = () => {},
}) => {
  const navigate = useNavigate();
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [descriptionLength, setDescriptionLength] = useState(20);
  const [loader, setLoader] = useState({});

  const handleResize = () => {
    const descriptionCell = document.getElementById('expenseClaimCellId0');
    if (descriptionCell) {
      setDescriptionLength(parseInt((descriptionCell?.clientWidth - 2) / 10));
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [expenseClaims]);

  const updateExpenseClaim = async (id, status) => {
    setLoader((prev) => ({ ...prev, [id]: true }));
    try {
      await updateExpenseReportStatus(id, {
        status,
      });
      if (status === 'Approved') {
        toast.success('Transaction approved successfully');
      } else if (status === 'Denied') {
        toast.success('Transaction denied successfully');
      }
      loadExpenseClaims();
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoader((prev) => {
        delete prev[id];
        return prev;
      });
    }
  };

  return (
    <div className="p-6 border border-[#D0D5DD] rounded-[12px]">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-[22px] font-medium text-[#666]  ">
          Effortless Expense Claims
        </h3>
      </div>
      {overviewLoadings?.expenseClaims ? (
        <div className="flex flex-col h-[180px] justify-center items-center">
          <Loader height="h-8 w-8" />
          <p>Fetching data please wait...</p>
        </div>
      ) : expenseClaims?.length > 0 ? (
        <>
          <div
            className="overflow-auto scrollbar"
            id="insightsOverviewExpenseClaimsTable"
          >
            <table className="w-full table-auto">
              <tbody className="divide-y divide-[#D0D5DD]">
                {expenseClaims?.map(
                  (expenseClaim, i) =>
                    i < 3 && (
                      <tr key={i} className="">
                        <td className="py-3 pr-2">
                          <div className="flex gap-2.5">
                            <div className="h-10 w-10 min-h-10 min-w-10 rounded-full">
                              {expenseClaim?.vendorLogo ||
                              expenseClaim?.plaidCategoryIconUrl ? (
                                <img
                                  className="rounded-full"
                                  src={
                                    expenseClaim?.vendorLogo ||
                                    expenseClaim?.plaidCategoryIconUrl
                                  }
                                  alt={expenseClaim?.name}
                                />
                              ) : (
                                <div
                                  className="w-10 h-10 rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                                  style={{
                                    backgroundColor: expenseClaim
                                      ?.categoryAccount?.group?.color
                                      ? expenseClaim?.categoryAccount?.group
                                          ?.color
                                      : '',
                                  }}
                                >
                                  <div
                                    className={`${
                                      expenseClaim?.categoryAccount?.group
                                        ?.color
                                        ? getContrast(
                                            expenseClaim?.categoryAccount?.group
                                              ?.color,
                                            'text-black',
                                            'text-white',
                                          )
                                        : 'text-black'
                                    } text-sm font-normal uppercase`}
                                  >
                                    {expenseClaim?.transaction?.description
                                      ? expenseClaim?.transaction?.description
                                          .replace(/[^\w\s]/gi, '')
                                          .split(' ')
                                          .filter(Boolean)
                                          .slice(0, 2)
                                          .map((word) => word.charAt(0))
                                          .join('')
                                      : ''}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="flex-1">
                              <p className="text-xs text-[#66708580]">
                                {moment(expenseClaim?.expectedDate).format(
                                  'MMM DD, YYYY',
                                )}
                              </p>
                              <Tooltip
                                tooltipShow={
                                  expenseClaim?.transaction?.description
                                    ?.length > descriptionLength
                                }
                                content={expenseClaim?.transaction?.description}
                                contentClassName="border-none rounded-[10px] translate-y-1 overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                isFixed
                                ids={[
                                  'insightsOverviewRecurringTransactionsTable',
                                  'insightsPage',
                                ]}
                              >
                                <h4
                                  className="text-[18px] my-0 leading-normal text-[#667085]"
                                  id={`expenseClaimCellId${i}`}
                                >
                                  {trimText(
                                    expenseClaim?.transaction?.description,
                                    descriptionLength,
                                  )}
                                </h4>
                              </Tooltip>
                            </div>
                          </div>
                        </td>
                        <td className="py-3 px-2 min-w-[8rem] ">
                          <div className="flex flex-col items-end">
                            <div className="text-[18px] text-[#667085] font-bold text-right">
                              {getCurrencySymbolFromIso(
                                expenseClaim?.transaction?.currency,
                                currencies,
                              )}
                              {parseFloat(
                                expenseClaim?.transaction?.amount
                                  ?.toString()
                                  .replace('-', ''),
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              {expenseClaim?.transaction?.currency !==
                                teamDefaultCurrency &&
                                ` (${expenseClaim?.transaction?.currency})`}
                            </div>
                            <div className="flex gap-2 mt-1 min-h-5">
                              {loader[expenseClaim?.id] ? (
                                <Loader height="h-4" />
                              ) : (
                                <>
                                  {/* Approve  */}
                                  <Tooltip
                                    content="Approve"
                                    contentClassName="relative overflow-visible tooltipArrowDown translate-y-1"
                                    isFixed
                                    ids={[
                                      'insightsPage',
                                      'insightsOverviewExpenseClaimsTable',
                                    ]}
                                  >
                                    <button
                                      className="h-[26px] w-[26px] bg-[#78BD4F] rounded-[6px] inline-flex justify-center items-center"
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        updateExpenseClaim(
                                          expenseClaim?.id,
                                          'Approved',
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="15"
                                        viewBox="0 0 14 15"
                                        fill="none"
                                      >
                                        <path
                                          d="M12.715 5.05095L5.71501 12.0509C5.65404 12.1121 5.58159 12.1607 5.50182 12.1938C5.42206 12.2269 5.33653 12.244 5.25016 12.244C5.16379 12.244 5.07827 12.2269 4.9985 12.1938C4.91873 12.1607 4.84629 12.1121 4.78532 12.0509L1.72282 8.98845C1.66177 8.9274 1.61335 8.85493 1.58031 8.77518C1.54728 8.69542 1.53027 8.60993 1.53027 8.5236C1.53027 8.43727 1.54728 8.35179 1.58031 8.27203C1.61335 8.19227 1.66177 8.1198 1.72282 8.05876C1.78386 7.99772 1.85633 7.94929 1.93609 7.91626C2.01585 7.88322 2.10133 7.86622 2.18766 7.86622C2.27399 7.86622 2.35948 7.88322 2.43923 7.91626C2.51899 7.94929 2.59146 7.99772 2.65251 8.05876L5.25071 10.657L11.7864 4.12235C11.9097 3.99907 12.0769 3.92981 12.2513 3.92981C12.4256 3.92981 12.5928 3.99907 12.7161 4.12235C12.8394 4.24564 12.9086 4.41285 12.9086 4.5872C12.9086 4.76155 12.8394 4.92876 12.7161 5.05204L12.715 5.05095Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </button>
                                  </Tooltip>
                                  {/* Deny  */}
                                  <Tooltip
                                    content="Deny"
                                    contentClassName="relative overflow-visible tooltipArrowDown translate-y-1"
                                    isFixed
                                    ids={[
                                      'insightsPage',
                                      'insightsOverviewExpenseClaimsTable',
                                    ]}
                                  >
                                    <button
                                      className="h-[26px] w-[26px] bg-[#FF4B4B] rounded-[6px] inline-flex justify-center items-center"
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        updateExpenseClaim(
                                          expenseClaim?.id,
                                          'Denied',
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="15"
                                        viewBox="0 0 14 15"
                                        fill="none"
                                      >
                                        <path
                                          d="M11.4025 11.1224C11.5258 11.2457 11.595 11.4129 11.595 11.5873C11.595 11.7616 11.5258 11.9288 11.4025 12.0521C11.2792 12.1754 11.112 12.2446 10.9377 12.2446C10.7633 12.2446 10.5961 12.1754 10.4728 12.0521L7.00071 8.57889L3.52751 12.051C3.40422 12.1743 3.23701 12.2435 3.06266 12.2435C2.88831 12.2435 2.7211 12.1743 2.59782 12.051C2.47453 11.9277 2.40527 11.7605 2.40527 11.5862C2.40527 11.4118 2.47453 11.2446 2.59782 11.1213L6.07102 7.6492L2.59891 4.176C2.47563 4.05272 2.40637 3.88551 2.40637 3.71116C2.40637 3.53681 2.47563 3.3696 2.59891 3.24631C2.7222 3.12303 2.88941 3.05377 3.06376 3.05377C3.23811 3.05377 3.40532 3.12303 3.5286 3.24631L7.00071 6.71952L10.4739 3.24577C10.5972 3.12248 10.7644 3.05322 10.9388 3.05322C11.1131 3.05322 11.2803 3.12248 11.4036 3.24577C11.5269 3.36905 11.5961 3.53626 11.5961 3.71061C11.5961 3.88496 11.5269 4.05217 11.4036 4.17545L7.9304 7.6492L11.4025 11.1224Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </button>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ),
                )}
              </tbody>
            </table>
          </div>
          <button
            type="button"
            className="w-full h-[38px] border border-indigo-500 mt-4 rounded-[6px] text-indigo-500"
            onClick={() => navigate('/expense-claims')}
          >
            View All
          </button>
        </>
      ) : (
        <NoExpenseClaimsText />
      )}
    </div>
  );
};

export default ExpenseClaimsBox;
