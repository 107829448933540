import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { resendInvitePeopleEmail } from '../../API/backend_helper';
import ModalBasic from '../../components/ModalBasic';

const SendEmailModal = ({ isOpen, setIsOpen, getData, activeEmailPerson }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await resendInvitePeopleEmail(activeEmailPerson?.id);
      getData({});
      setIsOpen(null);
      toast.success('Email sent successfully!');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`Invite Your Employee to Create an Account`}
      width="!w-[700px]"
    >
      <div className="px-10 py-8 space-y-1 text-base ">
        <p>
          Give your team members access to the tools they need, like filing
          expense claims, uploading receipts, clocking in/out, and sending
          estimates or invoices. With an Employee Login, they can use the Web
          and Mobile portals to manage their tasks efficiently.
        </p>
        <p className="!mt-4">Why Invite Them?</p>
        <ul className="list-disc pl-4">
          <li>Streamline expense management and time tracking.</li>
          <li>
            Enable easy communication with clients through estimates and
            invoices.
          </li>
          <li>
            Allow employees to manage their responsibilities on the go with the
            Mobile App.
          </li>
          <li>
            Rest assured, employees will not have access to your main accounting
            views or sensitive financial reports.
          </li>
        </ul>
      </div>

      <div className="w-full flex justify-between flex-wrap items-center gap-4 py-6 px-10 border-t border-[#D0D5DD]">
        <div>
          <p className="text-sm text-[#101828] mb-0">Send to:</p>
          <p className=" font-medium text-[#667085]">
            {activeEmailPerson?.email || ''}
          </p>
        </div>
        <div className="flex items-center gap-4 justify-end ml-auto">
          <button
            className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-gray-300 border-opacity-40 shadow w-[6.875rem] h-11 text-slate-600 leading-6 whitespace-nowrap"
            onClick={(event) => {
              event.preventDefault();
              setIsOpen(false);
            }}
            type="button"
          >
            Send Later
          </button>
          <button
            className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6
             disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
            type="button"
            onClick={onSubmit}
            disabled={loading}
          >
            {loading && (
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            )}
            Send
          </button>
        </div>
      </div>
    </ModalBasic>
  );
};

export default SendEmailModal;
