/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react';
import QuickBookIcon from '../../images/quickbookIcon.png';
import XeroIcon from '../../images/xeroIcon.png';
import DatePickerDash from 'components/DatePickerDash';
import { useSelector } from 'react-redux';
import { TEAM_DATE_FORMAT } from 'utils/Utils';

// White Onboarding Icon
const StartNewIcon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_9095_34563)">
      <path
        d="M18.2075 17.5192C18.1509 17.5192 18.0944 17.5095 18.0405 17.4916L4.41305 12.8341C4.21709 12.7665 4.08047 12.5899 4.06391 12.3835C4.04873 12.1772 4.15706 11.9813 4.33991 11.8854L19.8649 3.77786C20.0381 3.68885 20.2472 3.70265 20.4045 3.81512C20.5632 3.92828 20.6453 4.1201 20.617 4.3133L18.7195 17.0783C18.6967 17.2301 18.6077 17.364 18.4759 17.444C18.3945 17.4937 18.3014 17.5192 18.2075 17.5192ZM5.88827 12.2442L17.7866 16.311L19.4433 5.16614L5.88827 12.2442Z"
        fill="white"
      />
      <path
        d="M10.6112 19.5902C10.5574 19.5902 10.5029 19.5819 10.4505 19.5647C10.2373 19.4943 10.0938 19.2963 10.0938 19.0727V14.4152C10.0938 14.2848 10.1434 14.1578 10.2324 14.0619L19.7199 3.88442C19.9145 3.67466 20.2436 3.66431 20.452 3.85889C20.6611 4.05347 20.6721 4.38122 20.4776 4.59098L11.1287 14.6187V17.4947L12.8558 15.1438C13.0249 14.9148 13.3492 14.8637 13.5796 15.0334C13.8101 15.2025 13.8591 15.5268 13.69 15.7572L11.0287 19.3797C10.9286 19.5143 10.7727 19.5902 10.6112 19.5902Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_9095_34563">
        <rect
          width="16.56"
          height="16.56"
          fill="white"
          transform="translate(4.0625 3.71973)"
        />
      </clipPath>
    </defs>
  </svg>
);

const ItemBox = ({
  checked = false,
  label = '',
  Icon = () => {},
  handleClick = () => {},
}) => (
  <div
    className={`relative cursor-pointer inline-flex justify-center items-center w-full border border-[#D9D9D9] rounded-[8px] min-h-[112px]
     ${checked ? 'bg-[#FCF1E9] !border-[#FCF1E9]' : ''}`}
    onClick={handleClick}
    role="button"
    tabIndex="0"
  >
    <input
      type="radio"
      className="form-radio absolute top-2 right-2 h-4 w-4 ml-auto checked:!bg-indigo-500  disabled:!border-indigo-300"
      checked={checked}
    />
    <div className="flex flex-col items-center">
      <Icon />
      <p className="text-[#101828] text-sm mt-2">{label}</p>
    </div>
  </div>
);

const OnboardingBox = () => {
  const { team } = useSelector((state) => state.Team);
  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [selectedItem, setSelectedItem] = useState('startNew');
  const [cutoverDate, setCutoverDate] = useState(null);

  const options = useMemo(
    () => ({
      mode: 'single',
      enableTime: false,
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  return (
    <div className="w-full h-fit bg-white shadow-md rounded-[12px] p-6">
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Fresh Setup
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        <ItemBox
          label="Start New"
          Icon={() => (
            <div className="h-9 w-9 rounded-full bg-indigo-500 inline-flex justify-center items-center">
              <StartNewIcon />
            </div>
          )}
          checked={selectedItem === 'startNew'}
          handleClick={() => setSelectedItem('startNew')}
        />
      </div>
      <h3 className="text-[20px] font-medium text-[#101828] mb-4 mt-8">
        Migrate From Another Accounting Platform
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        <ItemBox
          label="QuickBooks"
          Icon={() => <img src={QuickBookIcon} alt="quickbook" />}
          checked={selectedItem === 'quickBooks'}
          handleClick={() => setSelectedItem('quickBooks')}
        />
        <ItemBox
          label="Xero"
          Icon={() => (
            <img src={XeroIcon} alt="xero" className="h-9 w-9 object-contain" />
          )}
          checked={selectedItem === 'xero'}
          handleClick={() => setSelectedItem('xero')}
        />
        <ItemBox
          label="Other"
          Icon={() => (
            <div className="h-9 w-9 rounded-full bg-[#6561FF] inline-flex justify-center items-center">
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.15 9.6C15.9246 9.6 15.7084 9.68429 15.549 9.83432C15.3896 9.98434 15.3 10.1878 15.3 10.4V13.6C15.3 13.8122 15.2104 14.0157 15.051 14.1657C14.8916 14.3157 14.6754 14.4 14.45 14.4H2.55C2.32457 14.4 2.10837 14.3157 1.94896 14.1657C1.78955 14.0157 1.7 13.8122 1.7 13.6V10.4C1.7 10.1878 1.61045 9.98434 1.45104 9.83432C1.29163 9.68429 1.07543 9.6 0.85 9.6C0.624566 9.6 0.408365 9.68429 0.248959 9.83432C0.0895533 9.98434 0 10.1878 0 10.4V13.6C0 14.2365 0.26866 14.847 0.746878 15.2971C1.2251 15.7471 1.8737 16 2.55 16H14.45C15.1263 16 15.7749 15.7471 16.2531 15.2971C16.7313 14.847 17 14.2365 17 13.6V10.4C17 10.1878 16.9104 9.98434 16.751 9.83432C16.5916 9.68429 16.3754 9.6 16.15 9.6ZM7.8965 10.968C7.97734 11.0408 8.07266 11.0979 8.177 11.136C8.27875 11.1783 8.38876 11.2002 8.5 11.2002C8.61124 11.2002 8.72126 11.1783 8.823 11.136C8.92734 11.0979 9.02266 11.0408 9.1035 10.968L12.5035 7.768C12.6636 7.61736 12.7535 7.41304 12.7535 7.2C12.7535 6.98696 12.6636 6.78264 12.5035 6.632C12.3434 6.48136 12.1264 6.39673 11.9 6.39673C11.6736 6.39673 11.4566 6.48136 11.2965 6.632L9.35 8.472V0.8C9.35 0.587827 9.26045 0.384344 9.10104 0.234315C8.94164 0.0842854 8.72543 0 8.5 0C8.27457 0 8.05837 0.0842854 7.89896 0.234315C7.73955 0.384344 7.65 0.587827 7.65 0.8V8.472L5.7035 6.632C5.62425 6.55741 5.53016 6.49824 5.42661 6.45787C5.32306 6.4175 5.21208 6.39673 5.1 6.39673C4.98792 6.39673 4.87694 6.4175 4.77339 6.45787C4.66984 6.49824 4.57575 6.55741 4.4965 6.632C4.41725 6.70659 4.35438 6.79514 4.31149 6.8926C4.2686 6.99006 4.24652 7.09451 4.24652 7.2C4.24652 7.30549 4.2686 7.40994 4.31149 7.5074C4.35438 7.60486 4.41725 7.69341 4.4965 7.768L7.8965 10.968Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
          checked={selectedItem === 'other'}
          handleClick={() => setSelectedItem('other')}
        />
      </div>

      <DatePickerDash
        width="max-w-[300px] mt-6"
        label="Select Cutover Date"
        placeholder="Select Date"
        value={cutoverDate}
        options={options}
        onChange={(selectedDates) => {
          setCutoverDate(selectedDates[0]);
        }}
        name="cutoverDate"
        id="cutoverDate"
        onBlur={(e) => {
          if (!e.target.classList?.value?.includes('active')) {
            setCutoverDate(e.target.value);
          }
        }}
      />
      <footer className="flex justify-between mt-[60px] gap-4">
        <button
          type="button"
          className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
        >
          Back
        </button>
        <button
          type="button"
          className="h-[38px]  w-24 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px]"
        >
          Next
        </button>
      </footer>
    </div>
  );
};

export default OnboardingBox;
