import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import useClickOutside from '../../../utils/useClickOutside';
import {
  AddReconciliation,
  CheckCircle,
  InProgressReconciliation,
} from './Svg';
import { formatNumber } from '../../../utils/Utils';
import { Loader } from '../../../components/Svg';

const ReconciliationPanel = ({
  account,
  accountsPanelOpen,
  setAccountsPanelOpen,
  setModalOpen,
  getHistory,
  teamCurrency,
}) => {
  const navigate = useNavigate();
  const panelContent = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reconcileData, setReconcileData] = useState([]);

  const loadData = async () => {
    setIsLoading(true);
    const res = await getHistory(account?.id);
    if (res) {
      setReconcileData(res);
    }
    setIsLoading(false);
  };

  useClickOutside(panelContent, () => {
    if (accountsPanelOpen) {
      setAccountsPanelOpen(false);
    }
  });

  useEffect(() => {
    if (accountsPanelOpen && account) {
      loadData();
    }
  }, [accountsPanelOpen, account]);

  const hasDraft = account?.reconcileBalances.some(
    (reconcile) => reconcile?.draft,
  );

  return (
    <div
      ref={panelContent}
      className={`absolute inset-0 bg-[#FBFDFA] sm:left-auto  transition-transform duration-200 ease-in-out z-40 ${
        accountsPanelOpen ? 'translate-x-0 shadow-xl' : 'translate-x-full'
      }`}
    >
      <div
        className="sticky top-16 bg-[#FBFDFA] dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0  w-full sm:w-[455px] h-[calc(100dvh-64px)]"
        style={
          accountsPanelOpen
            ? { boxShadow: ' -10px 0px 15px 0px #D0D5DD80' }
            : {}
        }
      >
        <div className="sticky top-0 h-[3.875rem] bg-[#FBFDFA]  z-10">
          <div className="flex justify-end pt-2.5 pr-2.5">
            <button
              className="ml-auto group"
              onClick={() => setAccountsPanelOpen(false)}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="h-5 w-5 fill-[#667085] group-hover:fill-slate-700"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </button>
          </div>
        </div>
        <div className="border-b-[2px] border-[#D0D5DD]">
          <h3 className="text-[#101828] text-sm ml-6 mb-4">
            Reconciled History
          </h3>
        </div>
        {isLoading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <Loader />
          </div>
        ) : (
          <div className="pt-12 flex flex-col gap-[56px] relative">
            <div className="absolute left-[42px] w-[1px] h-full bg-[#D0D5DD] top-0" />
            <div className="flex relative justify-between items-center w-full px-7">
              <div
                className={`flex gap-2 items-center ${
                  hasDraft ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                onClick={() => {
                  if (hasDraft) {
                    return;
                  }
                  setAccountsPanelOpen(false);
                  setModalOpen(true);
                }}
                role="button"
                tabIndex="0"
                onKeyDown={null}
              >
                <div className="h-7 w-7 rounded-full bg-[#D0D5DD] inline-flex justify-center items-center">
                  <AddReconciliation />
                </div>
                <p className="text-sm text-[#667085]">
                  Start New Reconciliation
                </p>
              </div>
            </div>
            {reconcileData.map((reconcile, index) => (
              <div
                key={index}
                className="flex relative justify-between items-center w-full px-7"
              >
                <div
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => {
                    if (reconcile?.complete) {
                      navigate(
                        `/account/reconcile/${account.id}?id=${reconcile.id}`,
                      );
                    } else {
                      navigate(`/account/reconcile/${account.id}`);
                    }
                    //         if (!reconcile?.complete) {
                    //           navigate(`/account/reconcile/${account.id}`);
                    //         }
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyDown={null}
                >
                  {reconcile?.complete && (
                    <div className="h-7 w-7 rounded-full bg-[#78BD4F] inline-flex justify-center items-center">
                      <CheckCircle />
                    </div>
                  )}
                  {reconcile?.draft && (
                    <div className="h-7 w-7 rounded-full bg-indigo-500 inline-flex justify-center items-center">
                      <InProgressReconciliation />
                    </div>
                  )}

                  <p className="text-sm text-[#667085]">
                    {moment(reconcile?.endingBalanceDate).format(
                      'MMMM Do, YYYY',
                    )}
                  </p>
                </div>
                <p className="text-sm font-bold text-[#101828]">
                  {teamCurrency?.symbol}
                  {formatNumber(reconcile?.endingBalanceAmount)}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReconciliationPanel;
