import React, { useContext, useRef, useState } from 'react';
import ModalBasic from '../../components/ModalBasic';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import TextInputDash from '../../components/TextInputDash';
import { getTeamById, updateTeamById } from '../../API/backend_helper';
import { TEAM_NAME } from '../../utils/Utils';
import WarningBox from '../../components/WarningBox';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLocalUser } from '../../redux/User/reducer';

const UpdateWorkspaceModal = ({ isOpen, setIsOpen, team, _setTeam }) => {
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  const { localUser } = useSelector((state) => state.User);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, formikRef) => {
    try {
      setLoading(true);
      await updateTeamById(team?.id, values);
      const res = await getTeamById(team?.id);
      let tempUser = JSON.parse(JSON.stringify(localUser));
      const teamIndex = tempUser?.teams?.findIndex((t) => t?.id === res?.id);
      tempUser.teams[teamIndex] = res;
      dispatch(setLocalUser(tempUser));
      localStorage.setItem(TEAM_NAME, res?.name);
      _setTeam(res);
      setIsOpen(false);
      formikRef.resetForm();
    } catch (e) {
      setIsOpen(false);
      console.log('error', e);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Edit Your Business Details"
      width="!w-[500px]"
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: team?.email || '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid Email')
            .required('Email is required'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="grid grid-cols-12 p-4 gap-x-5 px-8">
              <div className="col-span-full">
                <WarningBox
                  className="mb-5"
                  content={
                    'To set up payment methods, please add your workspace / business email'
                  }
                />
              </div>
              <div className="col-span-full">
                <TextInputDash
                  label="Business Email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                  error={validation.touched.email && validation.errors.email}
                  required
                />
              </div>
            </div>

            {/* Close/Add Buttons */}
            <div className="w-full flex justify-end items-center gap-4 py-4 px-8 border-t border-[#D0D5DD]">
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-gray-300 border-opacity-40 shadow w-[6.875rem] h-11 text-slate-600 leading-6"
                onClick={(event) => {
                  event.preventDefault();
                  setIsOpen(false);
                }}
              >
                Close
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6
                disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                type="submit"
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default UpdateWorkspaceModal;
