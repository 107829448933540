import React, { useState } from 'react';
import AppLayout from 'components/AppLayout';
import Onboarding1 from './onboarding1';
import Onboarding2 from './Onboarding04';
import StepsBox from './StepsBox';
import InfoBox from './InfoBox';
import OnboardingBox from './OnboardingBox';

const Onboardings = () => {
  document.title = 'COUNT | On Boarding';
  const [step, setStep] = useState(1);
  return (
    <AppLayout>
      <main className="relative grow bg-[#ECF1F5]">
        {/* Content */}
        <div className=" px-4 sm:px-6 lg:px-8 pt-[60px] py-8 w-full max-w-9xl mx-auto h-full flex flex-col ">
          <h1 className="text-[32px] font-medium text-[#101828] mb-10 text-center">
            Welcome To <span className="text-indigo-500">COUNT</span> Launchpad
          </h1>

          <div className="grid grid-cols-12 xl:grid-cols-11 gap-6">
            {/* Show On Large Screen  */}

            <div className="hidden xl:block xl:col-span-3">
              <StepsBox />
            </div>
            <div className="col-span-full xl:col-span-5">
              <OnboardingBox />
            </div>
            {/* Show On Small Screen  */}
            <div className="block xl:hidden sm:col-span-6 col-span-full">
              <StepsBox />
            </div>
            <div className="xl:col-span-3 sm:col-span-6 col-span-full">
              <InfoBox />
            </div>
          </div>
        </div>
      </main>
    </AppLayout>
  );
};

export default Onboardings;
