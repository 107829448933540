import React, { useState, useEffect, useRef } from 'react';
import { Form, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import AddressBar from 'components/AddressBar';
import { useSelector } from 'react-redux';
import TextInputDash from '../../../components/TextInputDash';
import ModalBasic from '../../../components/ModalBasic';
import ImageCropModal from '../../../components/ImageCropModal';
import UploadFile from '../../../components/UploadFile';

const InternalVendorForm = ({
  setActiveVendor,
  activeVendor,
  handleAddVendor,
  handleUpdateVendor,
  isOpen,
  setIsOpen,
  addEditLoader,
  handleUploadLogo,
}) => {
  const { team } = useSelector((state) => state.Team);

  const formikRef = useRef(null);
  // File Management
  const [files, setFiles] = useState([]);
  const [imgPreview, setImgPreview] = useState('');
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [currentImageForCrop, setCurrentImageForCrop] = useState(null);
  const [imgName, setImgName] = useState('');

  useEffect(() => {
    if (activeVendor?.logoUrl) {
      setImgPreview(activeVendor?.logoUrl);
    }
  }, [activeVendor]);

  useEffect(() => {
    if (!isOpen) {
      formikRef.current.resetForm();
    }
  }, [isOpen]);

  const handleSubmit = async (values) => {
    try {
      const {
        name,
        website,
        mcc,
        street,
        street2,
        country,
        city,
        state,
        zipCode,
      } = values;
      const d =
        activeVendor?.logoUrl && !imgPreview
          ? {
              name,
              website,
              mcc,
              address: {
                street,
                street2,
                country,
                city,
                state,
                zipCode,
              },
              logoUrl: null,
            }
          : {
              name,
              website,
              mcc,
              address: {
                street,
                street2,
                country,
                city,
                state,
                zipCode,
              },
            };
      if (activeVendor) {
        await handleUpdateVendor(d, activeVendor?.id);
        if (files?.length) {
          const formData = new FormData();
          formData.append('logo', files?.[0]);
          await handleUploadLogo(formData, activeVendor?.id);
        }
      } else {
        const response = await handleAddVendor(d);
        if (files?.length) {
          const formData = new FormData();
          formData.append('logo', files?.[0]);
          await handleUploadLogo(formData, response?.data?.internalVendor?.id);
        }
      }
      formikRef.current?.resetForm();
      setFiles([]);
      setImgName('');
      setImgPreview('');
      setIsOpen(false);
    } catch (e) {
      console.log('error', e);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={activeVendor?.id ? 'Edit Vendor' : 'Add Vendor'}
      width="!w-[1080px]"
    >
      <ImageCropModal
        isOpen={cropModalOpen}
        setIsOpen={setCropModalOpen}
        image={currentImageForCrop}
        setPreviewImage={setImgPreview}
        setFiles={setFiles}
        imgName={imgName}
      />
      {/* Modal content */}
      <div className="px-5">
        <Formik
          enableReinitialize
          initialValues={{
            name: activeVendor?.name || '',
            website: activeVendor?.website || '',
            mcc: activeVendor?.mcc || '',
            street: activeVendor?.address?.street || '',
            street2: activeVendor?.address?.street2 || '',
            country: activeVendor?.address?.country || team?.country || '',
            city: activeVendor?.address?.city || '',
            state: activeVendor?.address?.state || '',
            zipCode: activeVendor?.address?.zipCode || '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Please Enter Name'),
            website: Yup.string().optional().nullable(),
            mcc: Yup.string().optional().nullable(),
            street: Yup.string().optional().nullable(),
            street2: Yup.string().optional().nullable(),
            country: Yup.string().optional().nullable(),
            city: Yup.string().optional().nullable(),
            state: Yup.string().optional().nullable(),
            zipCode: Yup.string().optional().nullable(),
          })}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {(validation) => (
            <Form>
              <div className="pb-1 grid grid-cols-12 gap-3 sm:gap-6 sm:gap-y-0 gap-y-0 pt-4">
                <div className="col-span-full sm:col-span-full md:col-span-6 xl:col-span-4">
                  <TextInputDash
                    label="Name"
                    id="name"
                    name="name"
                    placeholder="Vendor Name"
                    value={validation.values.name || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    error={validation.touched.name && validation.errors.name}
                    required
                  />
                </div>
                <div className="col-span-full sm:col-span-full md:col-span-6 xl:col-span-4">
                  <TextInputDash
                    label="Website"
                    id="website"
                    name="website"
                    placeholder="Website"
                    value={validation.values.website || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    error={
                      validation.touched.website && validation.errors.website
                    }
                  />
                </div>
                <div className="col-span-full sm:col-span-full md:col-span-6 xl:col-span-4">
                  <TextInputDash
                    label="MCC"
                    id="mcc"
                    name="mcc"
                    placeholder="MCC"
                    value={validation.values.mcc || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    error={validation.touched.mcc && validation.errors.mcc}
                  />
                </div>
              </div>
              <label className="text-sm mb-2 font-medium text-slate-600 block">
                Address
              </label>
              <AddressBar
                validation={validation}
                streetId="street"
                streetValue={validation.values.street}
                streetError={
                  validation.touched?.street && validation.errors?.street
                }
                street2Id="street2"
                street2Value={validation.values.street2}
                street2Error={
                  validation.touched?.street2 && validation.errors?.street2
                }
                cityId="city"
                cityValue={validation.values.city}
                cityError={validation.touched?.city && validation.errors?.city}
                stateId="state"
                stateValue={validation.values.state}
                stateError={
                  validation.touched?.state && validation.errors?.state
                }
                zipCodeId="zipCode"
                zipCodeValue={validation.values.zipCode}
                zipCodeError={
                  validation.touched?.zipCode && validation.errors?.zipCode
                }
                countryId="country"
                countryValue={validation.values.country}
                countryError={
                  validation.touched?.country && validation.errors?.country
                }
              />

              {/* Close/Add Buttons */}
              <div className="w-full flex justify-end items-center gap-4 mb-5 mt-10">
                <button
                  className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-slate-600 w-[6.875rem] h-11 text-slate-600 leading-6"
                  onClick={(event) => {
                    event.preventDefault();
                    formikRef.current?.resetForm();
                    setActiveVendor(null);

                    setIsOpen(false);
                  }}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6"
                  type="submit"
                >
                  {addEditLoader && (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  )}
                  {activeVendor ? 'Update' : 'Add'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ModalBasic>
  );
};

export default InternalVendorForm;
