// TextInput Using Inside Dashboard
import React, { forwardRef, useState } from 'react';

const TextInputDash = forwardRef(
  (
    {
      id,
      label,
      error,
      type = 'text',
      width = '',
      reports = false,
      paddingBottom = 'pb-5',
      inputClass = '',
      required = false,
      currency = null,
      percentage = false,
      ...rest
    },
    ref,
  ) => {
    const [passwordShow, setPasswordShow] = useState(false);

    return (
      <div className={`${width || 'w-full'}`}>
        {label && (
          <label
            className={`text-sm font-medium text-slate-600 block mb-2 ${
              error ? '!text-rose-400' : ''
            }`}
            htmlFor={id}
          >
            {label} {required && <span className="text-rose-500">*</span>}{' '}
            {currency && (
              <span className="text-sm font-medium text-slate-600">
                ({currency?.isoCode})
              </span>
            )}
          </label>
        )}
        <div className={`relative w-full ${paddingBottom}`}>
          {type === 'textarea' ? (
            <textarea
              className={`form-input w-full ${
                reports ? 'min-h-10' : 'min-h-20'
              } text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80 border-[#D0D5DD] shadow-sm mb-[2px] rounded-[5px] ${
                error ? '!border-rose-400' : ''
              } ${inputClass} ${currency ? 'pl-8' : ''}`}
              ref={ref}
              id={id}
              required={false}
              {...rest}
            />
          ) : (
            <input
              className={`form-input w-full ${
                reports ? 'h-10' : 'h-12'
              } text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80 border-[#D0D5DD] shadow-sm mb-[2px] rounded-[5px] ${
                error ? '!border-rose-400' : ''
              } ${inputClass} ${currency ? 'pl-8' : ''}`}
              ref={ref}
              id={id}
              onWheel={(e) => e?.target?.blur?.()}
              type={type === 'password' && passwordShow ? 'text' : type}
              required={false}
              {...rest}
            />
          )}
          {currency && (
            <span className="absolute top-[0.75rem] left-1.5 flex items-center gap-2">
              <span className="text-slate-600">{currency?.symbol}</span>
              <div className="w-[0.038rem] h-[1.188rem] bg-slate-600" />
            </span>
          )}
          {percentage && (
            <span className="absolute top-[0.75rem] right-1.5 flex items-center gap-2">
              <span className="text-slate-600">%</span>
            </span>
          )}
          {type === 'password' ? (
            !passwordShow ? (
              <svg
                className="absolute  z-[2]   fill-[#9A9A9A] h-[18px] top-[50%] right-4 cursor-pointer"
                style={{ transform: 'translate(0, -100%)' }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                onClick={() => setPasswordShow(true)}
              >
                <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
              </svg>
            ) : (
              <svg
                className="absolute z-[2] fill-[#9A9A9A] h-[18px] top-[50%] right-4 cursor-pointer"
                style={{ transform: 'translate(0, -100%)' }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                onClick={() => setPasswordShow(false)}
              >
                <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z" />
              </svg>
            )
          ) : null}
          {error && (
            <div className="absolute bottom-0 mt-1 text-xs text-rose-400">
              {error}
            </div>
          )}
        </div>
      </div>
    );
  },
);

TextInputDash.displayName = 'TextInputDash';

export default TextInputDash;
