import React, { useEffect, useMemo } from 'react';
import Tooltip from '../../components/Tooltip';
import WarningBox from '../../components/WarningBox';
import CategoriesDropdown from '../transactions/CategoriesDropdown';
import { getCurrencySymbolFromIso } from '../../utils/Utils';

const SplitTransactionForm = ({
  activeTransaction,
  instanceTotal,
  splitTransactions,
  setSplitTransactions,
  handleTransactionValueChange,
  calcSplitsSum,
  instanceAmount,
  allCategories,
  currencies,
  isOpen,
  isRefund,
  isFixedDropdown = false,
  setNoClick = () => {},
  ids = [],
}) => {
  const accountPayableCat = useMemo(() => {
    if (allCategories?.length > 0) {
      return allCategories
        ?.flatMap((category) => category?.categories ?? [])
        .find(
          (subCategory) =>
            subCategory?.name ===
              (isRefund ? 'Accounts Payable' : 'Accounts Receivable') &&
            !subCategory?.editable,
        );
    }
  }, [allCategories]);

  useEffect(() => {
    if (activeTransaction?.id) {
      setSplitTransactions([
        {
          amount: {
            value: parseFloat(parseFloat(instanceTotal).toFixed(2)),
            error: false,
          },
          categoryAccountId: {
            value: accountPayableCat?.id,
            error: false,
          },
        },
        {
          amount: {
            value: parseFloat(
              parseFloat(
                parseFloat(
                  parseFloat(
                    isRefund
                      ? activeTransaction?.amount
                      : activeTransaction?.amount * -1,
                  ).toFixed(2),
                ) - parseFloat(parseFloat(instanceTotal).toFixed(2)),
              ).toFixed(2),
            ),
            error: false,
          },
          categoryAccountId: {
            value:
              activeTransaction?.categoryAccount?.status === 'active'
                ? activeTransaction?.categoryAccountId || null
                : null,
            error: false,
          },
        },
      ]);
    }
  }, [activeTransaction]);

  return (
    <div>
      <div className="grid grid-cols-12 gap-x-8 gap-y-4">
        {splitTransactions?.map((s, i) => (
          <div key={i} className="col-span-full md:col-span-6 flex gap-4">
            <div className="flex flex-col gap-2.5 flex-1 min-w-[30%]">
              <label
                className={`text-slate-600 text-sm leading-5 font-medium ${
                  s.amount.error ? '!text-rose-500' : ''
                }`}
              >
                Split Amount
              </label>
              <Tooltip
                content={`One of the Split Transaction should be equal to Invoice Total $${instanceTotal.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  },
                )}`}
                tooltipShow={
                  parseFloat(instanceTotal).toFixed(2) === s.amount.value &&
                  i === 0
                }
                contentClassName="w-[250px] text-xs h-auto !overflow-visible text-wrap	"
              >
                <div className="relative">
                  <input
                    placeholder="0.00"
                    value={s.amount.value}
                    onChange={(e) =>
                      handleTransactionValueChange(e.target.value, 'amount', i)
                    }
                    type="number"
                    className={`btn w-full border-[#D0D5DD] focus:border-slate-400 hover:border-slate-400 form-input pl-[3rem] pr-4 py-2.5 justify-between rounded-[0.313rem] font-normal leading-6 bg-white h-12 text-slate-600
                   placeholder:text-slate-600 placeholder:text-slate-80  read-only:cursor-not-allowed ${
                     s.amount.error ? '!border-rose-500' : ''
                   }`}
                    readOnly={
                      parseFloat(instanceTotal).toFixed(2) === s.amount.value &&
                      i === 0
                    }
                  />
                  <div className="absolute left-0 top-1/2 transform -translate-y-1/2">
                    <span className="ml-4 mr-2 text-slate-600 text-base leading-6 font-normal">
                      {getCurrencySymbolFromIso(isOpen?.currency, currencies)}
                    </span>
                    <span className="border-l-[0.038rem] h-4 bg-slate-600"></span>
                  </div>
                </div>
              </Tooltip>
              {s.amount.error && (
                <span className="text-xs text-rose-500 -mt-1.5">
                  {s.amount.error ? 'Split amount is required' : ''}
                </span>
              )}
            </div>

            <div
              className={`flex flex-col gap-2.5 flex-1 max-w-[30%] min-w-[200px] `}
            >
              <label
                className={`text-slate-600 text-sm leading-5 font-medium ${
                  s.categoryAccountId.error ? '!text-rose-500' : ''
                }`}
              >
                Category
              </label>
              <Tooltip
                content={`Transactions assigned to an invoice should always be categorized as ${isRefund ? 'Accounts Payable' : 'Accounts Receivable'}`}
                tooltipShow={
                  i === 0 && s.categoryAccountId.value === accountPayableCat?.id
                }
                contentClassName="w-[250px] text-xs h-auto !overflow-visible text-wrap	"
              >
                <CategoriesDropdown
                  allCategories={allCategories}
                  selectedCategoryId={s.categoryAccountId.value}
                  setSelectedCategoryId={(value) => {
                    handleTransactionValueChange(value, 'categoryAccountId', i);
                  }}
                  isSetCategoryStyle
                  isError={s.categoryAccountId.error}
                  height="h-12"
                  disabled={
                    i === 0 &&
                    s.categoryAccountId.value === accountPayableCat?.id
                  }
                  isTableDropdown={isFixedDropdown}
                  bottomOfTable={isFixedDropdown}
                  setNoClick={setNoClick}
                  ids={ids}
                  buttonClass={` !w-full !max-w-full ${s.categoryAccountId.error ? '!border-rose-500' : ''}`}
                />
              </Tooltip>
              {s.categoryAccountId.error && (
                <span className="text-xs text-rose-500 -mt-1.5">
                  {s.categoryAccountId.error ? 'Category is required' : ''}
                </span>
              )}
            </div>
            {!(
              i === 0 && s.categoryAccountId.value === accountPayableCat?.id
            ) && (
              <button
                className="mt-10 h-fit"
                onClick={() => {
                  setSplitTransactions((prev) =>
                    prev.filter((t, index) => index !== i),
                  );
                }}
                type="button"
              >
                {/* Trash Icon  */}
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 6.64648C17.67 6.31648 14.32 6.14648 10.98 6.14648C9 6.14648 7.02 6.24648 5.04 6.44648L3 6.64648"
                    stroke="#FF4B4B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 5.63602L8.72 4.32602C8.88 3.37602 9 2.66602 10.69 2.66602H13.31C15 2.66602 15.13 3.41602 15.28 4.33602L15.5 5.63602"
                    stroke="#FF4B4B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.8504 9.80566L18.2004 19.8757C18.0904 21.4457 18.0004 22.6657 15.2104 22.6657H8.79039C6.00039 22.6657 5.91039 21.4457 5.80039 19.8757L5.15039 9.80566"
                    stroke="#FF4B4B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.3301 17.166H13.6601"
                    stroke="#FF4B4B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.5 13.166H14.5"
                    stroke="#FF4B4B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
        ))}
      </div>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setSplitTransactions((prev) => [
            ...prev,
            {
              amount: {
                value: '',
                error: false,
              },
              categoryAccountId: {
                value: null,
                error: false,
              },
            },
          ]);
        }}
        className="text-sm font-medium text-indigo-500 underline my-4"
      >
        Add Another Split
      </button>
      {calcSplitsSum() !==
        (isRefund
          ? activeTransaction?.amount
          : activeTransaction?.amount * -1) && (
        <WarningBox
          content={`Sum of All Split Transaction should be equal to Parent Transaction $${(isRefund
            ? activeTransaction?.amount
            : activeTransaction?.amount * -1
          )?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}
          isError
          className="mb-4"
        />
      )}
    </div>
  );
};

export default SplitTransactionForm;
