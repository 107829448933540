import Tooltip from 'components/Tooltip';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  amountColor,
  getContrast,
  getStringSegments,
  TEAM_CURRENCY,
} from 'utils/Utils';
import { Loader } from 'components/Svg';
import { useNavigate } from 'react-router-dom';
import emptyWalletImg from '../../../images/custom/empty-wallet.svg';

const Transactions = ({
  transactions,
  activeTransaction,
  overviewLoadings,
  setActiveTransaction,
  setTransactionPanelOpen,
}) => {
  const navigate = useNavigate();

  const { currencies } = useSelector((state) => state.Currencies);
  const { team } = useSelector((state) => state.Team);
  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [descriptionLength, setDescriptionLength] = useState(20);
  const [accountLength, setAccountLength] = useState(15);

  const findCurrencySymbol = (isoCode) => {
    const currency = currencies?.find?.(
      (currency) => currency.isoCode === isoCode,
    );
    return currency?.symbol === '$' && currency?.isoCode !== 'USD'
      ? currency.symbol
      : currency?.symbol;
  };

  const handleResize = () => {
    setDescriptionLength(
      parseInt(
        (document.getElementById('descriptionCellId')?.clientWidth -
          (window.innerWidth > 1100 ? 40 : 0)) /
          10,
      ),
    );
    setAccountLength(
      parseInt(
        (document.getElementById('accountCellId')?.clientWidth - 45) / 10,
      ),
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [transactions]);

  return (
    <div className="p-6 border border-[#D0D5DD] rounded-[12px]">
      <h3 className="text-[22px] font-medium text-[#666] mb-4">
        Recent Transactions
      </h3>
      {overviewLoadings?.transactions ? (
        <div className="flex flex-col h-[180px] justify-center items-center">
          <Loader height="h-8 w-8" />
          <p>Fetching data please wait...</p>
        </div>
      ) : (
        <div
          className="overflow-x-auto scrollbar flex-1"
          id="insightsTransactionsTable"
        >
          <table className="table-fixed w-full dark:text-slate-300  ">
            {/* Table header */}
            <thead className=" text-slate-600 text-sm font-medium leading-[18px] shadow-md">
              <tr id="tableHeadId">
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  w-[120px]  min-w-full">
                  <div
                    className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                     hover:text-slate-800 dark:hover:text-slate-200
                 "
                    role="button"
                    tabIndex="0"
                  >
                    <span className="text-slate-600 text-sm font-medium leading-[18px]">
                      Date
                    </span>
                  </div>
                </th>
                <th
                  className={`px-2 first:pl-5 py-3 whitespace-nowrap w-full  `}
                >
                  <div
                    className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                     hover:text-slate-800 dark:hover:text-slate-200
                 "
                    role="button"
                    tabIndex="0"
                  >
                    <span className="text-slate-600 text-sm font-medium leading-[18px]">
                      Description
                    </span>
                  </div>
                </th>

                <th className="w-full px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap md:table-cell hidden">
                  <div className="text-slate-600 text-sm font-medium leading-[18px] text-left">
                    Account
                  </div>
                </th>

                <th
                  className={`w-full px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap `}
                >
                  <div
                    className="text-left flex gap-2 cursor-pointer -bottom-1
                     hover:text-slate-800 dark:hover:text-slate-200
                 "
                    role="button"
                    tabIndex="0"
                  >
                    <span className="text-slate-600 text-sm font-medium leading-[18px]">
                      Amount
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            {transactions?.length > 0 && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                {transactions?.map((transaction, i) => (
                  <tr
                    key={transaction.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveTransaction(transaction);
                      setTransactionPanelOpen(true);
                    }}
                    className={`cursor-pointer h-[68px]   hover:bg-[#A0CD850F]  ${i === 0 ? '!border-none' : ''} ${
                      activeTransaction?.id === transaction.id
                        ? 'bg-[#A0CD850F]'
                        : ''
                    }`}
                  >
                    <td className="px-2 first:pl-5 last:pr-5 py-2.5 leading-6 font-normal text-slate-700 whitespace-nowrap">
                      {moment
                        ?.utc(transaction?.authorizedDate)
                        ?.format('MMM DD, YYYY')}
                    </td>

                    <td
                      className="px-2 first:pl-5 last:pr-5 py-5 whitespace-nowrap text-slate-700"
                      id="descriptionCellId"
                    >
                      <div className="flex items-center">
                        <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                          {transaction?.vendor?.logoUrl ||
                          transaction?.plaidCategoryIconUrl ? (
                            <img
                              className="rounded-full"
                              src={
                                transaction?.vendor?.logoUrl ||
                                transaction?.plaidCategoryIconUrl
                              }
                              alt={transaction?.name}
                            />
                          ) : (
                            <div
                              className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                              style={{
                                backgroundColor: transaction?.categoryAccount
                                  ?.group?.color
                                  ? transaction?.categoryAccount?.group?.color
                                  : '',
                              }}
                            >
                              <div
                                className={`${
                                  transaction?.categoryAccount?.group?.color
                                    ? getContrast(
                                        transaction?.categoryAccount?.group
                                          ?.color,
                                        'text-black',
                                        'text-white',
                                      )
                                    : 'text-black'
                                } text-xs font-normal uppercase`}
                              >
                                {transaction?.description
                                  ? transaction.description
                                      .replace(/[^\w\s]/gi, '')
                                      .split(' ')
                                      .filter(Boolean)
                                      .slice(0, 2)
                                      .map((word) => word.charAt(0))
                                      .join('')
                                  : ''}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="font-normal leading-6 mr-2">
                          {transaction?.description?.length >
                          descriptionLength ? (
                            <Tooltip
                              content={
                                transaction?.description?.length > 40
                                  ? (() => {
                                      const segments = getStringSegments(
                                        transaction?.description,
                                        50,
                                      );
                                      return segments.map((segment, index) => (
                                        <p key={index}>{segment}</p>
                                      ));
                                    })()
                                  : transaction?.description
                              }
                              contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown `}
                              tooltipShow={window.innerWidth > 640}
                              isFixed
                              ids={[
                                'insightsTransactionsTable',
                                'insightsPage',
                              ]}
                            >
                              <span>
                                {`${transaction?.description?.slice(
                                  0,
                                  descriptionLength,
                                )}...`}
                              </span>
                            </Tooltip>
                          ) : (
                            <span>{transaction?.description}</span>
                          )}
                        </div>
                      </div>
                    </td>

                    {(() => {
                      const name = transaction?.account?.name || '';
                      const mask = transaction?.account?.mask || '';
                      const slicedName =
                        name.length > accountLength
                          ? `${name.slice(0, accountLength)}...`
                          : name;
                      const combinedAccount = mask
                        ? `${slicedName} (...${mask})`
                        : slicedName;
                      return (
                        <td
                          className="px-2 first:pl-5 last:pr-5 py-2.5 whitespace-nowrap text-slate-700 font-sm font-normal leading-6 md:table-cell hidden"
                          id="accountCellId"
                        >
                          <Tooltip
                            tooltipShow={name?.length > accountLength}
                            content={`${transaction?.account?.name}${
                              transaction?.account?.mask
                                ? ` (...${transaction?.account?.mask})`
                                : ''
                            }`}
                            contentClassName={`border-none rounded-[10px] overflow-visible translate-y-1 text-sm text-[#667085] translate-y-1 relative tooltipArrowDown `}
                            isFixed
                            ids={['insightsTransactionsTable', 'insightsPage']}
                          >
                            {combinedAccount}
                          </Tooltip>
                        </td>
                      );
                    })()}

                    <td className={`px-5  py-2.5 whitespace-nowrap  `}>
                      <div
                        className={`font-semibold leading-6 text-sm  ${amountColor(
                          transaction?.amount?.toString(),
                        )}`}
                      >
                        {transaction?.amount?.toString().charAt(0) === '-'
                          ? ''
                          : '-'}
                        {findCurrencySymbol(transaction?.currency)}
                        {transaction?.amount?.toString().charAt(0) === '-'
                          ? parseFloat(
                              transaction?.amount?.toString().replace('-', ''),
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : parseFloat(transaction?.amount)?.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}
                        {team &&
                          transaction?.currency !== teamDefaultCurrency &&
                          ` (${transaction?.currency})`}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {!transactions?.length > 0 && (
            <div className="flex flex-col gap-5 items-center justify-center w-full">
              <h6 className="text-center mt-4">No Transactions Found</h6>
              <img
                src={emptyWalletImg}
                alt="No transactions found"
                className="h-[12.5rem]"
              />
            </div>
          )}
          {transactions?.length > 0 && (
            <button
              type="button"
              className="-translate-x-1/2 ml-[50%] w-full max-w-[210px] mx-auto h-[38px] border border-indigo-500 mt-5 rounded-[6px] text-indigo-500"
              onClick={() => navigate('/transactions')}
            >
              View All Transactions
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Transactions;
