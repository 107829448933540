import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useClickOutside from '../../utils/useClickOutside';
import { Loader } from '../../components/Svg';
import TextInputDash from '../../components/TextInputDash';
import DatePickerDash from '../../components/DatePickerDash';
import DropdownFull from '../../components/DropdownFull';
import TransactionsReceiptPanel from './TransactionsReceiptsPanel';
import ReceiptModal from '../transactions/ReceiptModal';
import MerchantsDropdown from '../../components/MerchantsDropdown';
import {
  getFormattedDate,
  TEAM_CURRENCY,
  TEAM_DATE_FORMAT,
} from '../../utils/Utils';
import TagsBoard from '../invoices/TagsBoard';

const DETAILS_TAB = 'details';

function TransactionsFlyout({
  isOpen,
  setIsOpen,
  activeTransaction,
  findCurrencySymbol,
  vendors,
  setVendors,
  expenseTypes,
  handleTransactionUpdate,
}) {
  const panelContent = useRef(null);
  const { team } = useSelector((state) => state.Team);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(DETAILS_TAB);
  const [isEditing, setIsEditing] = useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!isOpen || keyCode !== 27) return;
      setIsOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useClickOutside(panelContent, () => {
    if (!receiptModalOpen) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    if (!isOpen) {
      setIsEditing(false);
    }
  }, [isOpen]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      values.date = getFormattedDate(values.date);
      if (values?.vendorId) {
        const selectedVendor = vendors?.find((v) => v?.id === values?.vendorId);
        values.vendorName = selectedVendor?.name;
      }
      await handleTransactionUpdate(values);
      setIsEditing(false);
      setIsOpen(false);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt}
        uploadedBillData={activeReceipt?.type}
        isExpenseReporting
      />
      <div
        ref={panelContent}
        className={`absolute inset-0 bg-white sm:left-auto  transition-transform duration-200 ease-in-out z-40 ${
          isOpen ? 'translate-x-0 shadow-xl' : 'translate-x-full'
        }`}
      >
        <div
          className="sticky top-16 bg-white dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0  w-full sm:w-[510px] h-[calc(100dvh-64px)]"
          style={isOpen ? { boxShadow: ' -10px 0px 15px 0px #D0D5DD80' } : {}}
        >
          <div className="sticky top-0 h-[3.875rem] bg-white z-10">
            <div className="flex justify-end pt-2.5 pr-2.5">
              <button
                className="ml-auto group"
                onClick={() => setIsOpen(false)}
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  className="h-5 w-5 fill-[#667085] group-hover:fill-slate-700"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>
            <div className="relative flex flex-row gap-[3.125rem] justify-start items-center mt-3 pb-1 w-full border-b-2 border-gray-300 pl-[1.625rem] bg-white">
              <p
                className={`text-sm leading-6 font-normal cursor-pointer ${
                  currentTab === 'details' ? 'text-black' : 'text-slate-600'
                }`}
                onClick={() => setCurrentTab('details')}
              >
                Transaction Details
              </p>

              {currentTab === 'details' && (
                <span className="absolute -bottom-0.5 left-[1.4rem] w-[8.188rem] bg-indigo-500 h-[0.188rem] rounded-[0.563rem]" />
              )}
              {!activeTransaction?.pending && (
                <>
                  <p
                    className={`text-sm leading-6 font-normal cursor-pointer ${
                      currentTab === 'receipt' ? 'text-black' : 'text-slate-600'
                    }`}
                    onClick={() => setCurrentTab('receipt')}
                  >
                    Attachments
                  </p>
                  {currentTab === 'receipt' && (
                    <span className="absolute -bottom-0.5 left-[12rem] w-[5.5rem] bg-indigo-500 h-[0.188rem] rounded-[0.563rem]" />
                  )}
                </>
              )}
            </div>
          </div>
          {currentTab === 'receipt' && (
            <TransactionsReceiptPanel
              activeTransaction={activeTransaction}
              setActiveReceipt={setActiveReceipt}
              setReceiptModalOpen={setReceiptModalOpen}
            />
          )}
          {currentTab === DETAILS_TAB &&
            (!isEditing ? (
              // Transaction Details Box
              <div className="my-[1.875rem] mx-6">
                <div
                  className="flex flex-col w-full  border border-black border-opacity-5 py-4 px-[1rem] bg-white rounded-xl"
                  style={{
                    boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.06)',
                  }}
                >
                  {/* Top Section */}
                  <div className="flex justify-between w-full items-center">
                    <p className="text-slate-600 text-[16px] font-medium leading-6">
                      Reporter: {activeTransaction?.people?.firstName}{' '}
                      {activeTransaction?.people?.lastName}
                    </p>
                    {activeTransaction?.status === 'Need Review' && (
                      <button
                        aria-controls="feedback-modal"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsEditing(!isEditing);
                        }}
                        className={`border border-opacity-50 shadow-sm h-8 w-[4.625rem] inline-flex py-[0.375rem] px-[0.625rem] rounded-md font-inter ${'border-gray-300'}`}
                        type="button"
                      >
                        <span
                          className={`${'text-sm pr-[0.563rem]'} font-semibold leading-5 text-slate-600 text-opacity-80`}
                        >
                          Edit
                        </span>
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-[18px] h-[18px]"
                        >
                          <path
                            d="M10.2731 3.20041L4.11564 9.71791C3.88314 9.96541 3.65814 10.4529 3.61314 10.7904L3.33564 13.2204C3.23814 14.0979 3.86814 14.6979 4.73814 14.5479L7.15314 14.1354C7.49064 14.0754 7.96314 13.8279 8.19564 13.5729L14.3531 7.05541C15.4181 5.93041 15.8981 4.64791 14.2406 3.08041C12.5906 1.52791 11.3381 2.07541 10.2731 3.20041Z"
                            stroke="#667085"
                          />
                          <path
                            d="M9.24561 4.28711C9.56811 6.35711 11.2481 7.93961 13.3331 8.14961"
                            stroke="#667085"
                          />
                          <path d="M2.57812 17H16.0781" stroke="#667085" />
                        </svg>
                      </button>
                    )}
                  </div>
                  <div className="flex items-center self-stretch gap-2 mt-4">
                    <p className="text-slate-600 text-[0.875rem] font-bold leading-5">
                      Details
                    </p>
                    <div className="bg-[#C6C7C9] h-[0.5px] w-full flex-grow flex-shrink-0 basis-0" />
                  </div>
                  <div className="flex items-start justify-between self-stretch mt-4">
                    <div className="flex flex-col">
                      <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                        Date
                      </p>
                      <p className="text-slate-600 leading-6 text-[0.875rem]">
                        {moment
                          ?.utc(
                            activeTransaction?.pendingReceiptId
                              ? activeTransaction?.pendingReceipt?.date
                              : activeTransaction?.transaction?.authorizedDate,
                          )
                          ?.format('MMM DD, YYYY')}
                      </p>
                    </div>
                    <div className="flex flex-col w-[9rem]">
                      <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                        Expense
                      </p>
                      <p className="text-slate-600 leading-6 text-[0.875rem]">
                        {
                          activeTransaction?.transaction?.expenseReportType
                            ?.name
                        }
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start justify-between self-stretch mt-3">
                    <div className="flex flex-col">
                      <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                        Amount
                      </p>
                      <p className="text-slate-600 leading-6 text-[0.875rem]">
                        {findCurrencySymbol(
                          activeTransaction?.transaction?.currency,
                        )}
                        {parseFloat(
                          activeTransaction?.transaction?.amount
                            ?.toString()
                            .replace('-', ''),
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        {activeTransaction?.transaction?.currency !==
                          teamDefaultCurrency &&
                          ` (${activeTransaction?.transaction?.currency})`}
                      </p>
                    </div>
                    <div className="flex flex-col w-[9rem]">
                      <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                        Vendor
                      </p>
                      <p className="text-slate-600 leading-6 text-[0.875rem]">
                        {activeTransaction?.transaction?.vendor?.name ||
                          activeTransaction?.pendingReceipt?.vendorName ||
                          '--'}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start justify-between self-stretch mt-3">
                    <div className="flex flex-col">
                      <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                        Project
                      </p>
                      <p className="text-slate-600 leading-6 text-[0.875rem]">
                        {activeTransaction?.transaction?.project?.name || '--'}
                      </p>
                    </div>
                    <div className="flex flex-col w-[9rem]">
                      <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                        Tags
                      </p>
                      <p className="text-slate-600 leading-6 text-[0.875rem] mt-1 ">
                        {activeTransaction?.transaction?.tags?.length > 0 ? (
                          <TagsBoard
                            tags={activeTransaction?.transaction?.tags}
                            tagsCharLength={50}
                            mainClass="!ml-0"
                          />
                        ) : (
                          '--'
                        )}
                      </p>
                    </div>
                  </div>
                  <p className="text-slate-600 leading-6 text-[0.875rem]">
                    {activeTransaction?.transaction?.description}
                  </p>
                </div>
              </div>
            ) : (
              // Transaction Edit Form
              <Formik
                enableReinitialize
                initialValues={{
                  date:
                    moment?.utc(
                      activeTransaction?.pendingReceiptId
                        ? activeTransaction?.pendingReceipt?.date
                        : activeTransaction?.transaction?.authorizedDate,
                    ) || '',
                  expenseReportTypeId:
                    activeTransaction?.pendingReceipt?.expenseReportTypeId ||
                    '',
                  amount: activeTransaction?.transaction?.amount || '',
                  vendorId: activeTransaction?.pendingReceipt?.vendorId || null,
                }}
                validationSchema={Yup.object({
                  date: Yup.string().required('Date is required'),
                  amount: Yup.string().required('Amount is required'),
                  expenseReportTypeId: Yup.string().required(
                    'Expense Report Type is required',
                  ),
                  vendorId: Yup.string().required('Vendor is required'),
                })}
                onSubmit={handleSubmit}
                // innerRef={formikRef}
              >
                {(validation) => (
                  <Form>
                    <div className="py-[1.875rem] px-7 flex flex-col items-start gap-[1rem]">
                      <div className="flex flex-col gap-2.5 w-full">
                        <p className="text-slate-600 text-[14px] font-medium leading-5">
                          Date<span className="text-red-500">*</span>
                        </p>
                        <DatePickerDash
                          required
                          validation={validation}
                          placeholder="Select Date"
                          value={
                            validation.values.date
                              ? moment(validation.values.date).toISOString()
                              : null
                          }
                          options={options}
                          onChange={(selectedDates) => {
                            validation.setFieldValue('date', selectedDates[0]);
                          }}
                          name="date"
                          id="date"
                          error={
                            validation.touched.date && validation.errors.date
                              ? validation.errors.date
                              : ''
                          }
                          onBlur={(e) => {
                            if (
                              !e.target.classList?.value?.includes('active')
                            ) {
                              validation.setFieldValue('date', e.target.value);
                            }
                          }}
                        />
                        {validation.touched.date && validation.errors.date ? (
                          <span className="text-xs text-rose-500 h-[14px] -mt-1">
                            {validation.errors.date}
                          </span>
                        ) : null}
                      </div>
                      <div className="flex flex-col gap-2.5 w-full">
                        <p className="text-slate-600 text-[14px] font-medium leading-5">
                          Vendor
                          <span className="text-red-500">*</span>
                        </p>
                        <MerchantsDropdown
                          vendors={vendors}
                          setVendors={setVendors}
                          selectedMerchant={validation.values.vendorId}
                          setSelectedMerchant={(value) => {
                            validation.setFieldValue('vendorId', value);
                          }}
                          isSetCategoryStyle
                        />
                        {validation.touched.vendorId &&
                        validation.errors.vendorId ? (
                          <span className="text-xs text-rose-500 h-[14px] -mt-1">
                            {validation.errors.vendorId}
                          </span>
                        ) : null}
                      </div>
                      <div className="flex flex-col gap-2.5 w-full">
                        <p className="text-slate-600 text-[14px] font-medium leading-5">
                          Expense <span className="text-red-500">*</span>
                        </p>
                        <DropdownFull
                          options={expenseTypes}
                          placeholder="Select Expense"
                          name="name"
                          selected={validation.values.expenseReportTypeId}
                          setSelected={(value) =>
                            validation.setFieldValue(
                              'expenseReportTypeId',
                              value,
                            )
                          }
                          scrollIntoView={false}
                        />
                        {validation.touched.expenseReportTypeId &&
                        validation.errors.expenseReportTypeId ? (
                          <span className="text-xs text-rose-500 h-[14px] -mt-1">
                            {validation.errors.expenseReportTypeId}
                          </span>
                        ) : null}
                      </div>
                      <div className="flex flex-col gap-2.5 w-full">
                        <p className="text-slate-600 text-[14px] font-medium leading-5">
                          Amount
                          <span className="text-red-500">*</span>
                        </p>
                        <TextInputDash
                          placeholder="0.00"
                          value={validation.values.amount}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          paddingBottom="pb-5"
                          name="amount"
                          inputClass="!h-12 !mb-0"
                          error={
                            validation.touched.amount
                              ? validation.errors.amount
                              : ''
                          }
                        />
                      </div>
                      <div className="flex gap-2.5">
                        <button
                          className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
                          type="button"
                          onClick={() => setIsEditing(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="w-fit min-w-[140px] bg-indigo-500 text-white rounded-[5px] h-11 py-2.5 px-[18px] flex items-center justify-center leading-6 text-[16px] font-base"
                        >
                          {isLoading ? (
                            <Loader width="w-4" height="h-4" color="#FFFFFF" />
                          ) : (
                            <span>Save Changes</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            ))}
        </div>
      </div>
    </>
  );
}

export default TransactionsFlyout;
