import { getUser, leaveAccountingFirmApi } from 'API/backend_helper';
import FirmIcon from 'pages/accountantFirm/FirmIcon';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FIRM_ID, IS_ACCOUNTANT } from 'utils/Utils';
import { setLocalUser } from '../../../redux/User/reducer';
import LeaveModal from '../manage/LeaveModal';

const ManageFirms = ({ setActiveTab, manageWorkspaces }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { localUser } = useSelector((state) => state.User);

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const getUserApi = async () => {
    try {
      const response = await getUser();
      dispatch(setLocalUser(response));
      setUser(response);
      setLoading(false);
      setIsOpenModal(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (localUser?.id) {
      setUser(localUser);
    }
  }, [localUser]);

  const onTeamLeave = async () => {
    setLoading(true);
    try {
      const res = await leaveAccountingFirmApi(isOpenModal);
      setActiveTab(manageWorkspaces);
      getUserApi(true);
    } catch (err) {
      setLoading(false);
      setIsOpenModal(false);
      console.log('err', err);
    }
  };

  return (
    <div>
      <LeaveModal
        isOpen={isOpenModal}
        title="Leave Firm"
        description="Are you sure you want to leave firm? This action cannot be undone."
        setIsOpen={setIsOpenModal}
        onCompletion={onTeamLeave}
        loading={loading}
      />
      <div className="flex items-center justify-between mb-4  ">
        <h1 className="text-2xl  text-[#101828B2] dark:text-slate-100 font-medium">
          Accounting Firms
        </h1>
      </div>
      {false ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-6">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-x-8 gap-y-10">
          {user?.accountingFirms?.length > 0 ? (
            user?.accountingFirms?.map((bookkeeper) => (
              <div
                className="col-span-full sm:col-span-6 xl:col-span-4 2xl:col-span-3"
                key={bookkeeper?.id}
              >
                <div className="h-full border border-[#D0D5DD] rounded-[12px] p-6">
                  <div className="flex gap-4 items-center mb-5">
                    <div className="min-h-20 min-w-20 h-20 w-20 rounded-full inline-flex justify-center items-center bg-[#EDEDED]">
                      {bookkeeper?.logoUrl ? (
                        <img
                          src={bookkeeper?.logoUrl}
                          alt="firm_logo"
                          className="h-full w-full rounded-full object-fit"
                        />
                      ) : (
                        <FirmIcon />
                      )}
                    </div>
                    <div>
                      <h3 className="capitalize text-[24px] text-black leading-tight">
                        {bookkeeper?.name}
                      </h3>
                      <p className="text-[#667085] text-sm">
                        {bookkeeper?.tagLine}
                      </p>
                    </div>
                  </div>
                  <div className="text-sm text-[#667085] mb-5">
                    {bookkeeper?.description}
                  </div>
                  {bookkeeper?.website && (
                    <p className="text-[#667085] text-sm mb-4 break-all">
                      Website:{' '}
                      <a
                        href={bookkeeper?.website}
                        target="_blank"
                        rel="noreferrer"
                        className="text-indigo-500 underline"
                      >
                        {bookkeeper?.website}
                      </a>{' '}
                    </p>
                  )}
                  {bookkeeper?.email && (
                    <p className="text-[#667085] text-sm mb-4 break-all">
                      Contact:{' '}
                      <a
                        href={`mailto:${bookkeeper?.email}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-indigo-500 underline"
                      >
                        {bookkeeper?.email}
                      </a>{' '}
                    </p>
                  )}
                  {(bookkeeper?.address?.city ||
                    bookkeeper?.address?.country) && (
                    <div className="flex gap-4 items-center mb-4">
                      {/* Location icon  */}
                      <svg
                        width="13"
                        height="18"
                        viewBox="0 0 13 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 4.25C5.88811 4.25 5.28997 4.43145 4.7812 4.77139C4.27244 5.11134 3.87591 5.59451 3.64175 6.15982C3.40759 6.72513 3.34632 7.34718 3.4657 7.94731C3.58507 8.54744 3.87972 9.09869 4.31239 9.53136C4.74506 9.96403 5.29631 10.2587 5.89644 10.3781C6.49657 10.4974 7.11862 10.4362 7.68393 10.202C8.24924 9.96784 8.73241 9.57131 9.07236 9.06254C9.4123 8.55378 9.59375 7.95564 9.59375 7.34375C9.59282 6.52352 9.26657 5.73715 8.68658 5.15717C8.1066 4.57718 7.32023 4.25093 6.5 4.25ZM6.5 8.75C6.22187 8.75 5.94999 8.66752 5.71873 8.513C5.48747 8.35848 5.30723 8.13886 5.20079 7.8819C5.09436 7.62494 5.06651 7.34219 5.12077 7.0694C5.17503 6.79662 5.30896 6.54605 5.50563 6.34938C5.7023 6.15271 5.95287 6.01878 6.22565 5.96452C6.49844 5.91026 6.78119 5.93811 7.03815 6.04454C7.29511 6.15098 7.51473 6.33122 7.66925 6.56248C7.82377 6.79374 7.90625 7.06562 7.90625 7.34375C7.90625 7.71671 7.75809 8.0744 7.49437 8.33812C7.23065 8.60184 6.87296 8.75 6.5 8.75ZM6.5 0.875C4.78495 0.876861 3.14068 1.55899 1.92796 2.77171C0.715236 3.98443 0.0331111 5.6287 0.03125 7.34375C0.03125 12.7831 5.77156 16.8641 6.01625 17.0349C6.15805 17.1341 6.32693 17.1874 6.5 17.1874C6.67307 17.1874 6.84195 17.1341 6.98375 17.0349C8.07097 16.2337 9.06568 15.3139 9.94953 14.2927C11.9246 12.0245 12.9688 9.61977 12.9688 7.34375C12.9669 5.6287 12.2848 3.98443 11.072 2.77171C9.85932 1.55899 8.21505 0.876861 6.5 0.875ZM8.70078 13.1593C8.03038 13.9294 7.29398 14.6394 6.5 15.2813C5.70602 14.6394 4.96962 13.9294 4.29922 13.1593C3.125 11.7995 1.71875 9.68586 1.71875 7.34375C1.71875 6.07568 2.22249 4.85955 3.11915 3.9629C4.0158 3.06624 5.23193 2.5625 6.5 2.5625C7.76807 2.5625 8.9842 3.06624 9.88085 3.9629C10.7775 4.85955 11.2812 6.07568 11.2812 7.34375C11.2812 9.68586 9.875 11.7995 8.70078 13.1593Z"
                          fill="#78BD4F"
                        />
                      </svg>
                      <p className="text-sm text-[#667085]">
                        {bookkeeper?.address?.city || ''}
                        {bookkeeper?.address?.city &&
                        bookkeeper?.address?.country
                          ? ', '
                          : ''}
                        {bookkeeper?.address?.country || ''}
                      </p>
                    </div>
                  )}
                  <div className="flex gap-4 items-center mb-6 !hidden">
                    {/* rating icon  */}
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.5 1.53125L10.8175 6.22625L16 6.98375L12.25 10.6363L13.135 15.7963L8.5 13.3588L3.865 15.7963L4.75 10.6363L1 6.98375L6.1825 6.22625L8.5 1.53125Z"
                        stroke="#78BD4F"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <p className="text-sm text-[#667085]">Rating: 4.7/5</p>
                  </div>

                  <div className="flex flex-wrap justify-between gap-4">
                    <button
                      className="btn min-w-fit whitespace-nowrap w-[45%] h-10 border border-indigo-500 rounded-[5px] text-indigo-500 px-6"
                      type="button"
                      onClick={() => {
                        localStorage.setItem(IS_ACCOUNTANT, 'true');
                        localStorage.setItem(FIRM_ID, bookkeeper?.id);
                        navigate('/accountant');
                      }}
                    >
                      Go To Firm
                    </button>
                    <button
                      className="btn min-w-fit whitespace-nowrap w-[45%] h-10 border border-indigo-500 rounded-[5px] text-white px-6 !bg-indigo-500"
                      type="button"
                      onClick={() => {
                        setIsOpenModal(bookkeeper?.id);
                      }}
                    >
                      Leave Firm
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className=" text-center w-full col-span-full">
              No Firm Found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ManageFirms;
