import React, { useRef } from 'react';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import AttachmentBox from './AttachmentBox';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} !h-5 !w-5 rounded-full !bg-indigo-500 !inline-flex justify-center items-center`}
      style={{ ...style }}
      onClick={onClick}
      role="button"
      tabIndex="0"
    >
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="-rotate-90 ml-[1px] mb-[2px]"
      >
        <path
          d="M1 1.5L5 5.5L9 1.5"
          stroke="#ffffff"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} !h-5 !w-5 rounded-full !bg-indigo-500 !inline-flex justify-center items-center`}
      style={{ ...style }}
      onClick={onClick}
      role="button"
      tabIndex="0"
    >
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="rotate-90 mr-[3px] mb-[2px]"
      >
        <path
          d="M1 1.5L5 5.5L9 1.5"
          stroke="#ffffff"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

const TaskAttachments = ({
  attachments,
  setAttachments,
  setActiveAttachment,
  setDeleteModalOpen,
  attachmentsLoading,
  uploadTaskAttachment,
  setReceiptModalOpen,
}) => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (current) => {
      if (current === sliderRef.current.props.children.length - 1) {
        sliderRef.current.slickGoTo(current);
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onClickDelete = (attachment) => {
    setActiveAttachment(attachment);
    setDeleteModalOpen('attachment');
  };

  const onClickView = (attachment) => {
    setActiveAttachment(null);
    setActiveAttachment(attachment);
    setReceiptModalOpen(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 50) {
        toast.error('File size must be less than 50 MB');
        return;
      }
      uploadTaskAttachment(file);
      let preview = '';
      if (file.type === 'video/mp4') {
        const fileReader = new FileReader();

        // Once file is read, set the video source
        fileReader.onload = (e) => {
          preview = e.target.result;
          const previewFile = {
            preview,
            name: file?.name,
            type: file.type,
          };
          setAttachments((prevFiles) => [...prevFiles, previewFile]);
        };

        // Read the file as a Data URL
        fileReader.readAsDataURL(file);
      } else {
        preview = URL.createObjectURL(file);
        const previewFile = {
          preview,
          name: file?.name,
          type: file?.type,
        };
        setAttachments((prevFiles) => [...prevFiles, previewFile]);
      }
    }
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(sliderRef.current.props.children.length - 1);
    }
  };

  return (
    <div className="pt-4 pb-6 px-6 ">
      <div className="flex justify-between">
        <h3 className="mb-3 text-black text-opacity-60 text-[16px] font-medium ">
          Attachments {!attachmentsLoading && `(${attachments?.length || 0})`}
        </h3>
        <button
          type="button"
          className="btn mt-1 cursor-pointer hover:bg-slate-300 h-6 w-6 bg-slate-200 rounded "
        >
          <label htmlFor="attachmentFile" className="cursor-pointer">
            <input
              id="attachmentFile"
              type="file"
              style={{ display: 'none' }}
              accept=".svg, .pdf, .jpeg, .jpg, .png, .doc, .docx, .xls, .xlsx, .mp4, .avi, .mov, .wmv, .flv, .mkv, .webm, .3gp, .vob, .rmvb"
              onChange={(e) => handleFileChange(e)}
            />
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="fill-slate-500 stroke-slate-500"
            >
              <path
                d="M10.5013 4.16699V15.8337M4.66797 10.0003H16.3346"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="stroke-current"
              />
            </svg>
          </label>
        </button>
      </div>
      {attachmentsLoading && (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-4">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Please wait...
          </p>
        </div>
      )}
      {!attachmentsLoading && (
        <div
          className={`taskAttachmentsSlider ${attachments?.length <= 3 ? 'attachmentsSliderTransformNone' : ''}`}
        >
          <Slider ref={sliderRef} {...settings}>
            {attachments?.map((attachment, index) => (
              <AttachmentBox
                key={index}
                attachment={attachment}
                onClickDelete={onClickDelete}
                onClickView={onClickView}
              />
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default TaskAttachments;
