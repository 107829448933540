import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import useClickOutside from '../../utils/useClickOutside';
import { amountColor, formatNumber, getContrast } from '../../utils/Utils';

const RecurringFlyout = ({
  flyoutOpen,
  setFlyoutOpen,
  teamCurrency,
  activeRecurring,
  setActiveRecurring,
}) => {
  const flyoutContent = useRef(null);
  const [descriptionLength, setDescriptionLength] = useState(28);
  const [isMobile, setIsMobile] = useState(false);
  const [reverseTransactions, setReverseTransactions] = useState([]);

  useEffect(() => {
    if (activeRecurring?.transactions?.length > 0) {
      const tempTransactions = JSON.parse(
        JSON.stringify(activeRecurring?.transactions),
      );
      setReverseTransactions(tempTransactions?.reverse());
    }
  }, [activeRecurring, flyoutOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 475) {
        setDescriptionLength(15);
        setIsMobile(true);
      } else {
        setDescriptionLength(35);
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!flyoutOpen) {
      setFlyoutOpen(false);
      setActiveRecurring(null);
    }
  }, [flyoutOpen]);

  useClickOutside(flyoutContent, () => {
    if (flyoutOpen) {
      setFlyoutOpen(false);
      setActiveRecurring(null);
    }
  });

  return (
    <div
      ref={flyoutContent}
      className={`absolute inset-0 bg-white sm:left-auto transition-transform duration-200 ease-in-out z-40 ${
        flyoutOpen ? 'translate-x-0 shadow-xl' : 'translate-x-full'
      }`}
    >
      <div
        className="sticky top-16 bg-white dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0  w-full sm:w-[550px] h-[calc(100dvh-64px)]"
        style={flyoutOpen ? { boxShadow: ' -10px 0px 15px 0px #D0D5DD80' } : {}}
      >
        {/* Header */}
        <div className="sticky top-0 h-[3.875rem] bg-white z-10">
          <div className="flex justify-end pt-2.5 pr-2.5">
            <button
              className="ml-auto group"
              onClick={() => setFlyoutOpen(false)}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="h-5 w-5 fill-[#667085] group-hover:fill-slate-700"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </button>
          </div>
          <div className="relative flex flex-row gap-[3.125rem] justify-start items-center mt-3 pb-1 w-full border-b-2 border-gray-300 pl-[1.625rem] bg-white">
            <p className="text-sm leading-6 font-normal cursor-pointer text-black">
              {activeRecurring?.description}
            </p>
          </div>
        </div>
        <div className="flex flex-col px-[1.688rem] pt-5">
          <div className="flex flex-col items-start gap-2.5 self-stretch mt-3">
            <table className="w-full flex flex-col items-start">
              <thead className="flex w-full items-center gap-5 rounded-[0.313rem] shadow-md bg-white h-[3.5rem] mb-2">
                <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[15%] sm:w-[20%] ml-4">
                  Date
                </th>
                <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[50%] sm:w-[60%]">
                  Description
                </th>
                <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[40%] sm:w-[20%]">
                  Amount
                </th>
              </thead>
              {reverseTransactions?.map((transaction, index) => (
                <tr
                  key={transaction?.id}
                  className={`flex w-full items-center gap-5 bg-white h-[3.5rem] ${
                    index === activeRecurring?.transactions?.length
                      ? ''
                      : 'border-b border-[#D0D5DD]'
                  }`}
                >
                  <td className="whitespace-nowrap text-slate-600 text-[14px] leading-6 w-[15%] sm:w-[20%] ml-4">
                    {!isMobile
                      ? moment
                          ?.utc(transaction?.authorizedDate)
                          ?.format('MMM DD, YYYY')
                      : moment
                          ?.utc(transaction?.authorizedDate)
                          ?.format('MMM DD')}
                  </td>
                  <td className="text-slate-600 text-[14px] font-medium leading-6 flex items-center gap-2 w-[50%] sm:w-[60%]">
                    <div className="h-[1.688rem] w-[1.688rem] min-h-[1.688rem] min-w-[1.688rem] rounded-full">
                      {transaction?.vendor?.logoUrl ||
                      transaction?.plaidCategoryIconUrl ? (
                        <img
                          className="rounded-full"
                          src={
                            transaction?.vendor?.logoUrl ||
                            transaction?.plaidCategoryIconUrl
                          }
                          alt={transaction?.name}
                        />
                      ) : (
                        <div
                          className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                          style={{
                            backgroundColor: transaction?.categoryAccount?.group
                              ?.color
                              ? transaction?.categoryAccount?.group?.color
                              : '',
                          }}
                        >
                          <div
                            className={`${
                              transaction?.categoryAccount?.group?.color
                                ? getContrast(
                                    transaction?.categoryAccount?.group?.color,
                                    'text-black',
                                    'text-white',
                                  )
                                : 'text-black'
                            } text-xs font-normal uppercase`}
                          >
                            {transaction?.description
                              ? transaction.description
                                  .replace(/[^\w\s]/gi, '')
                                  .split(' ')
                                  .filter(Boolean)
                                  .slice(0, 2)
                                  .map((word) => word.charAt(0))
                                  .join('')
                              : ''}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="whitespace-nowrap flex flex-col">
                      <p className="leading-6 font-medium text-slate-600 text-[14px]">
                        {transaction?.description.length > descriptionLength
                          ? `${transaction?.description.substring(
                              0,
                              descriptionLength,
                            )}...`
                          : transaction?.description}
                      </p>
                      <p className="leading-6 text-slate-600 text-opacity-80 text-[12px]">
                        {transaction?.categoryAccount?.name}
                      </p>
                    </div>
                  </td>
                  <td
                    className={`text-[14px] font-semibold leading-6 w-[40%] sm:w-[20%] ${amountColor(
                      transaction?.amount.toString(),
                    )}`}
                  >
                    <div className="">
                      {transaction?.amount < 0
                        ? `${
                            teamCurrency ? teamCurrency?.symbol : '$'
                          }${formatNumber(
                            Math.abs(transaction.amount).toFixed(2),
                          )}`
                        : `-${
                            teamCurrency ? teamCurrency?.symbol : '$'
                          }${formatNumber(
                            Math.abs(transaction.amount).toFixed(2),
                          )}`}

                      <div className="flex gap-1">
                        {!(index === reverseTransactions?.length - 1
                          ? true
                          : transaction?.amount -
                              reverseTransactions[index + 1]?.amount ===
                            0) && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 128 512"
                            className="h-4 gap-[2px] items-center fill-rose-500"
                          >
                            <path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                          </svg>
                        )}
                        {index < reverseTransactions?.length - 1 &&
                        transaction?.amount -
                          reverseTransactions[index + 1]?.amount <
                          0 ? (
                          // Up Arrow
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="12"
                            viewBox="0 0 384 512"
                            className="h-3 mt-[2px] shrink-0 fill-current text-[#78BD4F]"
                          >
                            <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                          </svg>
                        ) : index < reverseTransactions?.length - 1 &&
                          transaction?.amount -
                            reverseTransactions[index + 1]?.amount >
                            0 ? (
                          // Down Arrow
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="12"
                            viewBox="0 0 384 512"
                            className={`h-3 mt-[2px] ${'rotate-180'} shrink-0 fill-current text-[#FF4B4B]`}
                          >
                            <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                          </svg>
                        ) : (
                          <span className="text-xs text-slate-400 mx-[2px]">
                            --
                          </span>
                        )}
                        {index < reverseTransactions?.length - 1 &&
                          transaction?.amount -
                            reverseTransactions[index + 1]?.amount !==
                            0 && (
                            <span
                              className={`text-xs ${
                                (
                                  index === reverseTransactions?.length - 1
                                    ? transaction?.amount < 0
                                    : transaction?.amount -
                                        reverseTransactions[index + 1]?.amount <
                                      0
                                )
                                  ? 'text-[#78BD4F]'
                                  : (
                                        index ===
                                        reverseTransactions?.length - 1
                                          ? transaction?.amount > 0
                                          : transaction?.amount -
                                              reverseTransactions[index + 1]
                                                ?.amount >
                                            0
                                      )
                                    ? 'text-[#FF4B4B]'
                                    : 'text-slate-400'
                              }`}
                            >
                              {(
                                index === reverseTransactions?.length - 1
                                  ? transaction?.amount < 0
                                  : transaction?.amount -
                                      reverseTransactions[index + 1]?.amount <
                                    0
                              )
                                ? `${
                                    teamCurrency ? teamCurrency?.symbol : '$'
                                  }${formatNumber(
                                    Math.abs(
                                      index === reverseTransactions?.length - 1
                                        ? transaction?.amount
                                        : transaction?.amount -
                                            reverseTransactions[index + 1]
                                              ?.amount,
                                    ).toFixed(2),
                                  )}`
                                : `${
                                    (index === reverseTransactions?.length - 1
                                      ? transaction?.amount
                                      : transaction?.amount -
                                        reverseTransactions[index + 1]
                                          ?.amount) !== 0
                                      ? '-'
                                      : ''
                                  }${
                                    teamCurrency ? teamCurrency?.symbol : '$'
                                  }${formatNumber(
                                    Math.abs(
                                      index === reverseTransactions?.length - 1
                                        ? transaction?.amount
                                        : transaction?.amount -
                                            reverseTransactions[index + 1]
                                              ?.amount,
                                    ).toFixed(2),
                                  )}`}
                            </span>
                          )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecurringFlyout;
