/* eslint-disable no-compare-neg-zero */
import React, { useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LineChart from '../../charts/LineChart08';
import {
  generateDates,
  getCurrencySymbolFromIso,
  hexToRGB,
  TEAM_CURRENCY,
} from '../../utils/Utils';
import Tooltip from '../../components/Tooltip';

const DashboardBox = ({
  chartColor,
  title = '',
  selectedDate = '',
  highlightColor = '',
  isProfit = false,
  className = '',

  percentageDifference = '',
  isExpense = false,
  data,
  details,
  allProfits = [],
  toolTipPos,
  currencies,
}) => {
  const { team } = useSelector((state) => state.Team);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const navigate = useNavigate();
  const chartData = useMemo(
    () => ({
      labels: generateDates(
        selectedDate?.split(',')?.[0]
          ? moment(selectedDate?.split(',')?.[0], 'MMMM').format('M')
          : '',
        selectedDate?.split(',')?.[1],
      ),
      datasets: [
        // Line
        {
          data,
          fill: true,
          backgroundColor: `rgba(${hexToRGB(chartColor)}, 0.08)`,
          borderColor: chartColor,
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: chartColor,
          pointHoverBackgroundColor: chartColor,
          pointBorderWidth: 0,
          pointHoverBorderWidth: 0,
          clip: 20,
        },
      ],
    }),
    [data, selectedDate],
  );

  return (
    <div
      className={`py-4 col-span-12 sm:col-span-6 xl:col-span-4 pr-4 pl-4 ${className}`}
    >
      <h4 className="text-sm font-medium text-[#667085] leading-tight mb-5 flex items-center">
        <Tooltip
          content={
            <div className="flex flex-col text-center">
              <p>
                These calculations are only for your default currency of{' '}
                {teamDefaultCurrency || 'USD'}. Any transaction in a
              </p>
              <p>
                different currency is not included in these counts, sums and
                figures. Excluded and
              </p>
              <p>Pending transactions are also not included.</p>
            </div>
          }
          contentClassName="rounded-[10px] overflow-visible text-sm text-[#667085] relative"
          size="sm"
          position="bottom"
          className="mr-1"
          isFixed
          ids={['dashboardPageContainer']}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
              stroke="#667085"
              strokeWidth="0.784912"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
              stroke="#667085"
              strokeWidth="0.784912"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
              fill="#667085"
            />
          </svg>
        </Tooltip>
        {title} (
        {selectedDate?.split(',')?.[0]
          ? moment(selectedDate?.split(',')?.[0], 'MMM').format('MMM')
          : ''}{' '}
        {selectedDate?.split(',')?.[1]})
      </h4>
      <div className="flex items-center">
        <h3 className="text-[28px] mr-2 font-semibold text-[#101828] leading-tight">
          {isProfit && details < 0 && details?.toString().charAt(0) === '-'
            ? '-'
            : ''}
          {title === 'Expenses' && details?.total?.toString().charAt(0) === '-'
            ? '-'
            : ''}
          {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
          {isProfit
            ? Number.isNaN(details) || !Number.isFinite(details)
              ? '--'
              : parseFloat(
                  details?.toString().replace('-', ''),
                )?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
            : Number.isNaN(details?.total) || !Number.isFinite(details?.total)
              ? '--'
              : details?.total?.toString().charAt(0) === '-'
                ? parseFloat(
                    details?.total?.toString().replace('-', ''),
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : parseFloat(details?.total)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
        </h3>
        {/* Increasing Arrow  */}
        {Number.isNaN(percentageDifference) ||
        !Number.isFinite(percentageDifference) ||
        percentageDifference === 0 ||
        percentageDifference === 0.0 ||
        percentageDifference === '0.00' ? (
          '--'
        ) : percentageDifference > 0 ? (
          <svg
            width="17"
            height="18"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1639_20017)">
              <path
                d="M13.4173 10.5L7.87565 4.95833L4.95898 7.875L0.583984 3.5"
                stroke={isExpense ? '#78BD4F' : '#FF4B4B'}
                strokeWidth="1.23529"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.91797 10.5H13.418V7"
                stroke={isExpense ? '#78BD4F' : '#FF4B4B'}
                strokeWidth="1.23529"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1639_20017">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1639_19872)">
              <path
                d="M16.2923 4.75L9.56315 11.4792L6.02148 7.9375L0.708984 13.25"
                stroke={isExpense ? '#FF4B4B' : '#78BD4F'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.043 4.75H16.293V9"
                stroke={isExpense ? '#FF4B4B' : '#78BD4F'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1639_19872">
                <rect
                  width="17"
                  height="17"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        )}
        {Number.isNaN(percentageDifference) ||
        !Number.isFinite(percentageDifference) ||
        percentageDifference === 0 ||
        percentageDifference === 0.0 ||
        percentageDifference === '0.00' ||
        !percentageDifference ? (
          <span className="ml-1 text-sm font-medium">0.00%</span>
        ) : (
          <span
            className={`ml-1 text-sm font-medium ${
              percentageDifference > 0
                ? isExpense
                  ? 'text-[#78BD4F]'
                  : 'text-[#FF4B4B]'
                : isExpense
                  ? 'text-[#FF4B4B]'
                  : 'text-[#78BD4F]'
            }`}
          >
            {percentageDifference?.toString().replace('-', '')}%
          </span>
        )}
      </div>
      <div className="grow w-[100%] mb-2">
        <LineChart
          data={chartData}
          width={150}
          height={70}
          IsYearSelected={!selectedDate?.split(',')?.[0]}
        />
      </div>
      <div className="space-y-[6px]">
        {!isProfit &&
          details?.catagories?.map?.((d, index) => (
            <div key={index} className="flex justify-between">
              <div className="px-2 relative text-sm font-medium text-[#667085] flex-1">
                <div
                  className={`absolute top-0 left-0 h-full `}
                  style={{
                    backgroundColor: highlightColor,
                    width: `${d?.percentage <= 100 ? d?.percentage : 100}%`,
                  }}
                />

                <span className="relative capitalize">
                  {d?.name === 'others'
                    ? `+${d?.numOfCategories} Others`
                    : d?.name}
                </span>
              </div>
              <div className={`flex items-center gap-2.5 `}>
                <span className="text-sm font-medium text-[#667085]">
                  {d?.total?.toString().charAt(0) === '-' ? '-' : ''}
                  {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}

                  {d?.total < 0
                    ? (-1 * d?.total)?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : d?.total?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </span>

                <svg
                  width="3"
                  height="7"
                  viewBox="0 0 3 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.416016 5.75L2.66602 3.5L0.416015 1.25"
                    stroke="#101828"
                    strokeOpacity="0.65"
                    strokeWidth="0.675"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          ))}

        {isProfit &&
          allProfits?.map?.((d, index) => (
            <div key={index} className="flex justify-between">
              <div className="px-2 relative text-sm font-medium text-[#667085] flex-1">
                <span className="relative capitalize">{d?.date}</span>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-sm font-medium text-[#667085]">
                  {!(
                    d?.amount === -0 ||
                    d?.amount === 0 ||
                    d?.amount === -0.0 ||
                    d?.amount === '-0.00'
                  ) && d?.amount?.toString().charAt(0) === '-'
                    ? '-'
                    : ''}{' '}
                  {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                  {d?.amount === -0 ||
                  d?.amount === 0 ||
                  d?.amount === -0.0 ||
                  d?.amount === '-0.00'
                    ? 0.0
                    : d?.amount
                        ?.toString()
                        ?.replace('-', '')
                        ?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                </span>
                <div className="flex items-center gap-1">
                  {!Number.isNaN(d?.percentage) &&
                  Number.isFinite(d?.percentage) &&
                  d?.percentage !== 0 &&
                  d?.percentage !== 0.0 &&
                  d?.percentage !== '0.00' ? (
                    parseFloat(d?.percentage) > 0 ? (
                      // Increasing Icon
                      <svg
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1639_19872)">
                          <path
                            d="M16.2923 4.75L9.56315 11.4792L6.02148 7.9375L0.708984 13.25"
                            stroke="#78BD4F"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.043 4.75H16.293V9"
                            stroke="#78BD4F"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1639_19872">
                            <rect
                              width="17"
                              height="17"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    ) : (
                      // Decreasing Icon
                      <svg
                        width="17"
                        height="18"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1639_20017)">
                          <path
                            d="M13.4173 10.5L7.87565 4.95833L4.95898 7.875L0.583984 3.5"
                            stroke="#FF4B4B"
                            strokeWidth="1.23529"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.91797 10.5H13.418V7"
                            stroke="#FF4B4B"
                            strokeWidth="1.23529"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1639_20017">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    )
                  ) : (
                    '-'
                  )}

                  <span
                    className={`text-xs leading-tight font-medium ${
                      !Number.isNaN(d?.percentage) &&
                      Number.isFinite(d?.percentage) &&
                      d?.percentage !== 0 &&
                      d?.percentage !== 0.0 &&
                      d?.percentage !== '0.00'
                        ? parseFloat(d?.percentage) > 0
                          ? 'text-[#78BD4F]'
                          : 'text-[#FF4B4B]'
                        : ''
                    }`}
                  >
                    {!Number.isNaN(d?.percentage) &&
                    Number.isFinite(d?.percentage)
                      ? d?.percentage === -0 ||
                        d?.percentage === -0.0 ||
                        d?.percentage === '-0.00'
                        ? 0.0
                        : parseFloat(d?.percentage)?.toFixed(2)
                      : '-'}
                    %
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>
      {!isProfit && (
        <div className="mt-5 flex justify-end">
          <button
            className="btn border-none px-0 outline-none shadow-none text-[#E48642] text-sm font-medium"
            onClick={() =>
              navigate(isExpense ? '/insights' : '/insights', {
                state: {
                  activeTab: isExpense ? 'spending' : 'income',
                  date: selectedDate,
                },
              })
            }
            type="button"
          >
            View All{' '}
            <svg
              width="3"
              height="7"
              viewBox="0 0 3 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2.5"
            >
              <path
                d="M0.416016 5.75L2.66602 3.5L0.416015 1.25"
                stroke="#E48642"
                strokeOpacity="0.65"
                strokeWidth="0.675"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default DashboardBox;
