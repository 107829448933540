import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import QuickBookReauth from 'pages/appIntegrations/quickbooksSettings/quickBookReauth';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import {
  bulkUpdateReviewTrans,
  getAccounts,
  getDashboard,
  getMerchants,
  getTags,
  getTransactions,
  updateTransactionCategory,
  getPayableBills,
  getReceivableInvoices,
  getCustomers,
  assignInvoiceToBill,
  getInvoices,
  getNotifications,
  getSingleTransaction,
  getTaxes,
} from '../../API/backend_helper';
import DropdownFull from '../../components/DropdownFull';
import DashboardBox from './DashboardBox';
import LaunchPad from './LaunchPad';
import TransactionsTable from './TransactionsTable';
import Notifications from './Notifications';
import MonthDropdown from './MonthDropdown';
import {
  calculatePercentageDifference,
  OWNER,
  ADMIN,
  formatNumber,
  getCurrencySymbolFromIso,
  TEAM_CURRENCY,
} from '../../utils/Utils';
import TransactionDetails from '../transactions/TransactionPanel';
import ReceiptModal from '../transactions/ReceiptModal';
import { getLastData, lastProfitsFunc } from '../../utils/DashboardUtils';
import BankWarning from '../../components/BankWarning';
import StripeWarning from '../../components/StripeWarning';
import Tooltip from '../../components/Tooltip';
import { InfoIcon } from '../../components/Svg';
import CreateRulePopup from '../../components/CreateRulePopup';
import CreateRuleModal from '../../components/CreateRuleModal';
import AppLayout from '../../components/AppLayout';

const Loader = () => (
  <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="animate-spin w-10 h-10 fill-current shrink-0"
    >
      <path
        d="M14.5 2.41699V7.25033"
        stroke="#E48642"
        strokeWidth="2.55882"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 21.749V26.5824"
        stroke="#E48642"
        strokeWidth="2.55882"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.95703 5.95703L9.37662 9.37662"
        stroke="#E48642"
        strokeWidth="1.58955"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.623 19.6211L23.0426 23.0407"
        stroke="#E48642"
        strokeWidth="1.58955"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.41699 14.5H7.25033"
        stroke="#E48642"
        strokeWidth="1.58955"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.749 14.5H26.5824"
        stroke="#E48642"
        strokeWidth="1.58955"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.95703 23.0407L9.37662 19.6211"
        stroke="#E48642"
        strokeWidth="1.58955"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.623 9.37662L23.0426 5.95703"
        stroke="#E48642"
        strokeWidth="1.58955"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

const DefaultYear = moment().format('MMMM,YYYY');
function Dashboard() {
  document.title = 'COUNT | Dashboard';
  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);
  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [dashData, setDashData] = useState({});
  const [selectedYear, setSelectedYear] = useState(DefaultYear);
  const [lastIncome, setLastIncome] = useState({});
  const [lastExpense, setLastExpense] = useState({});
  const [lastProfit, setLastProfit] = useState('');
  const [allProfits, setAllProfits] = useState([]);
  const [launchPadOpen, setLaunchPadOpen] = useState(false);
  const [selectedPayableRange, setSelectedPayableRange] = useState('all');
  const [selectedReceivableRange, setSelectedReceivableRange] = useState('all');
  const [invoiceBalance, setInvoiceBalance] = useState({});
  const [billBalance, setBillBalance] = useState({});
  const [step, setStep] = useState(3);

  // Transactions
  const [transactions, setTransactions] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowDetailsOpen, setRowDetailsOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [currentTransDetailTab, setCurrentTransDetailTab] = useState('details');
  const [accounts, setAccounts] = useState([]);
  const [fromDropdown, setFromDropdown] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [allExpenseCategories, setAllExpenseCategories] = useState([]);
  const [commentsMentioned, setCommentsMentioned] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [taxes, setTaxes] = useState([]);

  // Rule popup logic
  const [popupOpen, setPopupOpen] = useState(false);
  const [ruleModalOpen, setRuleModalOpen] = useState(false);
  const [popupCategory, setPopupCategory] = useState(null);
  const [popupTransaction, setPopupTransaction] = useState(null);

  // Loaders
  const [dashboardDataLoader, setDashboardDataLoader] = useState(true);
  const [billBalanceLoader, setBillBalanceLoader] = useState(true);
  const [invoiceBalLoader, setInvoiceBalLoader] = useState(true);
  const [transactionsLoader, setTransactionsLoader] = useState(true);
  const [needsAttentionLoader, setNeedsAttentionLoader] = useState(true);
  const [unreviewedTransLoader, setUnreviewedTransLoader] = useState(false);

  const getDashboardData = async ({ year = selectedYear, loading = false }) => {
    if (loading) {
      setDashboardDataLoader(true);
    }
    try {
      const res = await getDashboard(
        year?.split(',')?.[1],
        year?.split(',')?.[0]
          ? moment(year?.split(',')?.[0], 'MMMM').format('M')
          : '',
      );
      setDashData(res?.data);
      const prevIncome = getLastData(year, res?.data?.pastDataByType?.income);
      const prevExpense = getLastData(
        year,
        res?.data?.pastDataByType?.expenses,
      );
      setLastIncome(prevIncome);
      setLastExpense(prevExpense);
      setAllProfits(lastProfitsFunc(year, res?.data));
      setLastProfit(prevIncome?.total - prevExpense?.total);
      setDashboardDataLoader(false);
    } catch (e) {
      setDashboardDataLoader(false);
      console.log('error', e);
    }
  };

  const getData = async ({ year = selectedYear, loading = false }) => {
    try {
      getDashboardData({ year, loading });
    } catch (e) {
      console.log('error', e);
    }
  };

  const getInvoicesApi = async () => {
    try {
      const res = await getInvoices({
        status: ['overdue', 'unpaid', 'partial'],
      });
      setInvoices(res?.data?.invoices);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getCustomersApi = async () => {
    try {
      const res = await getCustomers({});
      setCustomers(res?.data?.records);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const callLaunchPadStatus = async () => {
    if (!team?.launchpadStatus) return;
    const { accountConnections, users, bills, invoices } = team.launchpadStatus;

    const hasOrSkip = (status) => status?.has || status?.skip;

    if (
      hasOrSkip(accountConnections) &&
      hasOrSkip(users) &&
      hasOrSkip(bills) &&
      hasOrSkip(invoices)
    ) {
      setLaunchPadOpen(false);
    } else {
      setLaunchPadOpen(true);
      if (!hasOrSkip(accountConnections)) {
        setStep(3);
      } else if (hasOrSkip(accountConnections) && !hasOrSkip(users)) {
        setStep(4);
      } else if (
        hasOrSkip(accountConnections) &&
        hasOrSkip(users) &&
        !hasOrSkip(bills)
      ) {
        setStep(5);
      } else if (
        hasOrSkip(accountConnections) &&
        hasOrSkip(users) &&
        hasOrSkip(bills) &&
        !hasOrSkip(invoices)
      ) {
        setStep(6);
      } else if (
        hasOrSkip(accountConnections) &&
        hasOrSkip(users) &&
        hasOrSkip(bills) &&
        hasOrSkip(invoices)
      ) {
        setStep(7);
      }
    }
  };

  const handlePopupLogic = (category, transaction) => {
    setPopupCategory(category);
    setPopupTransaction(transaction);
    setPopupOpen(true);
  };

  const loadTransactions = async ({ loading = false }) => {
    if (loading) {
      setTransactionsLoader(true);
    }
    try {
      const transRes = await getTransactions({
        limit: 5,
        orderBy: 'date',
        reviewed: false,
        pending: false,
      });
      setTransactions(transRes?.transactions);
      setTransactionsLoader(false);
    } catch (e) {
      setTransactionsLoader(false);
      console.log('error', e);
    }
  };

  const assignTransactionToInvoice = async (invoiceId, transactionId) => {
    try {
      await assignInvoiceToBill(invoiceId, { transactions: [transactionId] });
      getInvoicesApi();
      await loadTransactions({ loading: false });
      toast.success('Transaction assigned to invoice successfully.');
    } catch (e) {
      console.log('error', e);
      toast.error('An error occurred while assigning transaction to invoice.');
    }
  };

  const getDatesForRange = (range) => {
    let startDate = '';
    let endDate = '';

    // eslint-disable-next-line default-case
    switch (range) {
      case 'next30':
        startDate = moment().format('YYYY-MM-DD');
        endDate = moment().add(30, 'days').format('YYYY-MM-DD');
        break;
      case 'next60':
        startDate = moment().format('YYYY-MM-DD');
        endDate = moment().add(60, 'days').format('YYYY-MM-DD');
        break;
      case 'next90':
        startDate = moment().format('YYYY-MM-DD');
        endDate = moment().add(90, 'days').format('YYYY-MM-DD');
        break;
      case 'year':
        startDate = moment().startOf('year').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
        break;
      case 'all':
        startDate = '';
        endDate = '';
        break;
    }

    return { startDate, endDate };
  };

  const getBillBalance = async (loading = false) => {
    if (loading) {
      setBillBalanceLoader(true);
    }
    try {
      const { startDate, endDate } = getDatesForRange(selectedPayableRange);
      const res = await getPayableBills({
        startDate,
        endDate,
      });
      setBillBalance(res?.data);
      setBillBalanceLoader(false);
    } catch (e) {
      console.log('error', e);
      setBillBalanceLoader(false);
    }
  };

  const getInvoiceBalance = async (loading = false) => {
    if (loading) {
      setInvoiceBalLoader(true);
    }
    try {
      const { startDate, endDate } = getDatesForRange(selectedReceivableRange);
      const res = await getReceivableInvoices({
        startDate,
        endDate,
      });
      setInvoiceBalance(res?.data);
      setInvoiceBalLoader(false);
    } catch (e) {
      setInvoiceBalLoader(false);
      console.log('error', e);
    }
  };

  const transformAccounts = (allAccounts, anchorTier, type) => {
    let typeIds;

    // eslint-disable-next-line no-unused-expressions
    type === 'expense'
      ? (typeIds = {
          Expenses: 1,
          Assets: 2,
          Liabilities: 3,
          Equity: 4,
          Income: 5,
        })
      : (typeIds = {
          Income: 1,
          Assets: 2,
          Liabilities: 3,
          Equity: 4,
          Expenses: 5,
        });

    let filteredAccounts;

    if (anchorTier === 'CATEGORY_PHYSICAL_ACCOUNT') {
      filteredAccounts = allAccounts.filter(
        (account) =>
          account.subType.anchorTier === anchorTier ||
          account.type === 'Income' ||
          account.type === 'Expenses',
      );
    } else {
      filteredAccounts = allAccounts.filter(
        (account) => account.subType.anchorTier === anchorTier,
      );
    }

    const groupedByType = filteredAccounts.reduce((acc, current) => {
      const { type } = current;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(current);
      return acc;
    }, {});

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const type in groupedByType) {
      groupedByType[type].sort((a, b) => a.name.localeCompare(b.name));
    }

    const transformed = Object.keys(groupedByType).map((type) => ({
      id: typeIds[type],
      name: type,
      categories: groupedByType[type],
    }));

    transformed.sort((a, b) => a.id - b.id);

    return transformed;
  };

  const getTransSupportData = async () => {
    try {
      const res = await getMerchants({});
      setVendors(res?.vendors);
      const resTags = await getTags();
      setTags(resTags);
      const accountsRes = await getAccounts();
      setAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
      setAllCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
      );
      setAllExpenseCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
    } catch (e) {
      console.log('error', e);
    }
  };

  const getTransactionCommentsMentioned = async ({ loading = true }) => {
    if (loading) {
      setNeedsAttentionLoader(true);
    }
    try {
      const res = await getNotifications({});
      const transactionIds = [];

      res?.notifications.forEach((notification) => {
        if (notification?.notificationType === 'transaction') {
          transactionIds.push(notification?.associationId);
        }
      });

      const transactions = await Promise.all(
        transactionIds.map((transactionId) =>
          getSingleTransaction(transactionId),
        ),
      );

      const updatedNotifications = res?.notifications.map((notification) => {
        if (notification?.notificationType === 'transaction') {
          const transaction = transactions.find(
            (transaction) => transaction?.id === notification?.associationId,
          );
          return {
            ...notification,
            description: transaction?.description,
          };
        }
        return notification;
      });
      setCommentsMentioned(updatedNotifications);
      setNeedsAttentionLoader(false);
    } catch (err) {
      setNeedsAttentionLoader(false);
      console.log('err', err);
    }
  };

  useEffect(() => {
    getData({ loading: true });
    getBillBalance(true);
    getInvoiceBalance(true);
    loadTransactions({ loading: true });
    getTransactionCommentsMentioned({ loading: false });
    getCustomersApi();
    loadTaxes();
    getInvoicesApi();
    getTransSupportData();
  }, []);

  useEffect(() => {
    callLaunchPadStatus();
  }, [team?.launchpadStatus]);

  useEffect(() => {
    getBillBalance();
  }, [selectedPayableRange]);

  useEffect(() => {
    getInvoiceBalance();
  }, [selectedReceivableRange]);

  const { monthOrDaylyData, yearOrMonthlyData } = dashData || {};

  const { expenses, income } = monthOrDaylyData || {};

  const expensesData = useMemo(() => {
    const data = [];
    if (expenses && Object?.keys(expenses)?.length > 0) {
      Object.keys(expenses).forEach((key, index) => {
        data?.push(
          expenses?.[
            selectedYear?.split(',')?.[0]
              ? `day_${index + 1}`
              : `month_${index + 1}`
          ]?.total,
        );
      });
    }
    return data;
  }, [expenses]);

  const incomeData = useMemo(() => {
    const data = [];
    if (income && Object?.keys(income)?.length > 0) {
      Object.keys(income).forEach((key, index) =>
        data?.push(
          income?.[
            selectedYear?.split(',')?.[0]
              ? `day_${index + 1}`
              : `month_${index + 1}`
          ]?.total,
        ),
      );
    }
    return data;
  }, [income]);

  const profitData = useMemo(() => {
    const data = [];
    if (expenses && Object?.keys(expenses)?.length > 0) {
      Object.keys(expenses).forEach((key, index) =>
        data?.push(
          expenses?.[
            selectedYear?.split(',')?.[0]
              ? `day_${index + 1}`
              : `month_${index + 1}`
          ]?.profit,
        ),
      );
    }
    return data;
  }, [expenses]);

  // Mark Reviewed
  const handleMarkReviewedAll = async ({ reviewed = true }) => {
    setUnreviewedTransLoader(true);
    try {
      await bulkUpdateReviewTrans({
        transactions: transactions?.map((t) => t?.id),
        reviewed,
      });
      await loadTransactions({ loading: false });
      await getData({ loading: false });
      setUnreviewedTransLoader(false);
    } catch (e) {
      setUnreviewedTransLoader(false);
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  const onChangeCategory = async (transactionId, categoryAccountId) => {
    try {
      await updateTransactionCategory(transactionId, {
        categoryAccountId,
      });
      await loadTransactions({ loading: false });
      await getData({ loading: false });
    } catch (e) {
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  const currentMonthYear = moment().format('MMMM,YYYY');

  return (
    <>
      <CreateRuleModal
        open={ruleModalOpen}
        setOpen={setRuleModalOpen}
        category={popupCategory}
        transaction={popupTransaction}
      />
      {popupOpen && (
        <CreateRulePopup
          open={popupOpen}
          setOpen={setPopupOpen}
          category={popupCategory}
          transaction={popupTransaction}
          openModal={setRuleModalOpen}
        />
      )}
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt}
      />
      <AppLayout
        pageId="dashboardPageContainer"
        mainClass="relative"
        isDashboardPage
      >
        {accessType &&
        permissions?.length > 0 &&
        !checkPermission(
          PermissionKeys.VIEW_DASHBOARD,
          accessType,
          permissions,
        ) ? (
          <div className="py-[23px] px-[38px] max-w-9xl mx-auto w-full text-center">
            You don't have access to this page
          </div>
        ) : (
          <main className="grow py-[23px] px-[38px] max-w-9xl mx-auto w-full">
            <BankWarning teamProp={team} />
            <QuickBookReauth />
            {team?.id &&
              team?.stripeAccountId &&
              checkPermission(
                PermissionKeys.MANAGE_SETTINGS,
                accessType,
                permissions,
              ) && <StripeWarning teamProp={team} />}

            {launchPadOpen &&
              (accessType === OWNER || accessType === ADMIN) && (
                <LaunchPad
                  setLaunchPadOpen={setLaunchPadOpen}
                  launchPadOpen={launchPadOpen}
                  team={team}
                  step={step}
                  setStep={setStep}
                />
              )}

            <div className="px-7 py-5 border border-[#D0D5DD] rounded-[12px]">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-xl font-medium text-[#00000099] leading-tight">
                  {selectedYear === currentMonthYear
                    ? 'This month so far...'
                    : 'Quick Views'}
                </h3>
                <div className="h-10 ">
                  {!dashboardDataLoader && (
                    <MonthDropdown
                      selected={selectedYear}
                      setSelected={(v) => {
                        // if (
                        //   v?.split(",")?.[1] !== selectedYear?.split(",")?.[1]
                        // ) {
                        getData({ year: v });
                        setSelectedYear(v);
                        // } else {
                        //   setSelectedYear(v);
                        // }
                      }}
                      name="name"
                      scrollIntoView={false}
                    />
                  )}
                </div>
              </div>
              {dashboardDataLoader ? (
                Loader()
              ) : (
                <div className="grid grid-cols-12 gap-4">
                  <DashboardBox
                    chartColor="#78BD4F"
                    highlightColor="#C2E3AE"
                    className="sm:border-r border-[#F1F1F1]"
                    percentageDifference={
                      lastIncome?.total > yearOrMonthlyData?.income?.total
                        ? calculatePercentageDifference(
                            lastIncome?.total < 0
                              ? -lastIncome?.total
                              : lastIncome?.total,
                            yearOrMonthlyData?.income?.total < 0
                              ? -yearOrMonthlyData?.income?.total
                              : yearOrMonthlyData?.income?.total,
                          )
                        : -calculatePercentageDifference(
                            lastIncome?.total < 0
                              ? -lastIncome?.total
                              : lastIncome?.total,
                            yearOrMonthlyData?.income?.total < 0
                              ? -yearOrMonthlyData?.income?.total
                              : yearOrMonthlyData?.income?.total,
                          )
                    }
                    data={incomeData}
                    details={yearOrMonthlyData?.income}
                    title="Income"
                    selectedDate={selectedYear}
                    team={team}
                    currencies={currencies}
                    toolTipPos="left-[11rem]"
                  />
                  <DashboardBox
                    chartColor="#FF4B4B"
                    highlightColor="#FFD1D1"
                    className="xl:border-r border-[#F1F1F1]"
                    title="Expenses"
                    data={expensesData}
                    isExpense
                    selectedDate={selectedYear}
                    details={yearOrMonthlyData?.expenses}
                    percentageDifference={
                      lastExpense?.total < yearOrMonthlyData?.expenses?.total
                        ? -calculatePercentageDifference(
                            lastExpense?.total < 0
                              ? -lastExpense?.total
                              : lastExpense?.total,
                            yearOrMonthlyData?.expenses?.total < 0
                              ? -yearOrMonthlyData?.expenses?.total
                              : yearOrMonthlyData?.expenses?.total,
                          )
                        : calculatePercentageDifference(
                            lastExpense?.total < 0
                              ? -lastExpense?.total
                              : lastExpense?.total,
                            yearOrMonthlyData?.expenses?.total < 0
                              ? -yearOrMonthlyData?.expenses?.total
                              : yearOrMonthlyData?.expenses?.total,
                          )
                    }
                    team={team}
                    currencies={currencies}
                  />
                  <DashboardBox
                    chartColor="#E48642"
                    highlightColor="#FFD1D1"
                    data={profitData}
                    title="Profit"
                    selectedDate={selectedYear}
                    details={yearOrMonthlyData?.profit}
                    percentageDifference={
                      lastProfit > yearOrMonthlyData?.profit
                        ? calculatePercentageDifference(
                            lastProfit,
                            yearOrMonthlyData?.profit,
                          )
                        : -calculatePercentageDifference(
                            lastProfit,
                            yearOrMonthlyData?.profit,
                          )
                    }
                    isProfit
                    allProfits={allProfits}
                    team={team}
                    toolTipPos=""
                    currencies={currencies}
                  />
                </div>
              )}
            </div>

            <div className="w-full mt-6 flex flex-col xl:flex-row gap-6">
              <div className="px-7 py-5 border border-[#D0D5DD] rounded-[12px] w-full xl:w-1/2">
                <div className="flex justify-between items-center mb-1 w-full">
                  <div className="flex items-center gap-2">
                    <h3 className="text-xl font-medium text-[#00000099] leading-tight whitespace-nowrap">
                      Invoice Balance
                    </h3>
                    <Tooltip
                      contentClassName="border-none overflow-visible text-sm text-[#667085] text-center relative left-[1rem]"
                      position="top"
                      content={
                        selectedReceivableRange === 'all' ? (
                          <p>How much total Invoice revenue can be expected.</p>
                        ) : selectedReceivableRange === 'next30' ? (
                          <p>
                            How much Invoice revenue can be expected in the next
                            30 days.
                          </p>
                        ) : selectedReceivableRange === 'next60' ? (
                          <p>
                            How much Invoice revenue can be expected in the next
                            60 days.
                          </p>
                        ) : (
                          <p>
                            How much Invoice revenue can be expected in the next
                            90 days.
                          </p>
                        )
                      }
                    >
                      <InfoIcon />
                    </Tooltip>
                  </div>
                  {!invoiceBalLoader && (
                    <div className="w-[30%]">
                      <DropdownFull
                        name="name"
                        scrollIntoView={false}
                        options={[
                          { name: 'All', id: 'all' },
                          { name: 'Next 30 Days', id: 'next30' },
                          { name: 'Next 60 Days', id: 'next60' },
                          { name: 'Next 90 Days', id: 'next90' },
                        ]}
                        selected={selectedReceivableRange}
                        setSelected={setSelectedReceivableRange}
                        height="h-10"
                        pagination
                        bulkDropdown
                      />
                    </div>
                  )}
                </div>
                {invoiceBalLoader ? (
                  Loader()
                ) : (
                  <>
                    <div className="flex justify-between items-center text-[#333333] text-4xl mb-4">
                      <span className="font-semibold">
                        {getCurrencySymbolFromIso(
                          teamDefaultCurrency,
                          currencies,
                        )}
                        {formatNumber(invoiceBalance?.totalUnpaid)}
                      </span>
                    </div>
                    <div className="border-t border-[#D0D5DD] w-full flex items-center justify-start gap-6 pt-4">
                      <div className="flex flex-col">
                        <div className="flex">
                          <p className="text-[14px]">Next 7 Days</p>
                        </div>
                        <p className="font-medium text-[#F9C74F]">
                          {getCurrencySymbolFromIso(
                            teamDefaultCurrency,
                            currencies,
                          )}
                          {formatNumber(invoiceBalance?.totalDueWithin7Days)}
                        </p>
                      </div>
                      <div className="w-px h-6 bg-[#D0D5DD]" />
                      <div className="flex flex-col">
                        <div className="flex flex-col">
                          <div className="flex">
                            <p className="text-[14px]">Overdue</p>
                          </div>
                          <p className="font-medium text-[#F94144]">
                            {getCurrencySymbolFromIso(
                              teamDefaultCurrency,
                              currencies,
                            )}
                            {formatNumber(invoiceBalance?.totalDuePassed)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="px-7 py-5 border border-[#D0D5DD] rounded-[12px] w-full xl:w-1/2">
                <div className="flex justify-between items-center mb-1">
                  <div className="flex items-center gap-2">
                    <h3 className="text-xl font-medium text-[#00000099] leading-tight">
                      Bill Balance
                    </h3>
                    <Tooltip
                      contentClassName="border-none overflow-visible text-sm text-[#667085] text-center relative left-[2rem]"
                      position="top"
                      content={
                        selectedPayableRange === 'all' ? (
                          <p>Total amount you owe towards Bills.</p>
                        ) : selectedPayableRange === 'next30' ? (
                          <p>
                            Expected amount you owe towards Bills in the next 30
                            days.
                          </p>
                        ) : selectedPayableRange === 'next60' ? (
                          <p>
                            Expected amount you owe towards Bills in the next 60
                            days.
                          </p>
                        ) : (
                          <p>
                            Expected amount you owe towards Bills in the next 90
                            days.
                          </p>
                        )
                      }
                    >
                      <InfoIcon />
                    </Tooltip>
                  </div>
                  <div className="w-[30%]">
                    {!billBalanceLoader && (
                      <DropdownFull
                        name="name"
                        scrollIntoView={false}
                        options={[
                          { name: 'All', id: 'all' },
                          { name: 'Next 30 Days', id: 'next30' },
                          { name: 'Next 60 Days', id: 'next60' },
                          { name: 'Next 90 Days', id: 'next90' },
                        ]}
                        selected={selectedPayableRange}
                        setSelected={setSelectedPayableRange}
                        height="h-10"
                        pagination
                        bulkDropdown
                      />
                    )}
                  </div>
                </div>
                {billBalanceLoader ? (
                  Loader()
                ) : (
                  <>
                    <div className="flex justify-between items-center text-[#333333] text-4xl mb-4">
                      <span className="font-semibold">
                        {getCurrencySymbolFromIso(
                          teamDefaultCurrency,
                          currencies,
                        )}
                        {formatNumber(billBalance?.totalUnpaid)}
                      </span>
                    </div>
                    <div className="border-t border-[#D0D5DD] w-full flex items-center justify-start gap-6 pt-4">
                      <div className="flex flex-col">
                        <div className="flex">
                          <p className="text-[14px]">Next 7 Days</p>
                        </div>
                        <p className="font-medium text-[#F9C74F]">
                          {getCurrencySymbolFromIso(
                            teamDefaultCurrency,
                            currencies,
                          )}
                          {formatNumber(billBalance?.totalDueWithin7Days)}
                        </p>
                      </div>
                      <div className="w-px h-6 bg-[#D0D5DD]" />
                      <div className="flex flex-col">
                        <div className="flex flex-col">
                          <div className="flex">
                            <p className="text-[14px]">Overdue</p>
                          </div>
                          <p className="font-medium text-[#F94144]">
                            {getCurrencySymbolFromIso(
                              teamDefaultCurrency,
                              currencies,
                            )}
                            {formatNumber(billBalance?.totalDuePassed)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="grid grid-cols-12 gap-6 mt-6">
              <div className="col-span-full xl:col-span-6 border border-[#D0D5DD] rounded-[12px] py-5 px-7">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-medium text-[#00000099] leading-tight">
                    Latest Unreviewed Transactions
                  </h3>
                  {/* <button className="btn border-none px-0 outline-none shadow-none text-[#E48642] text-sm font-medium">
                      View All
                    </button> */}
                </div>
                {transactionsLoader ? (
                  Loader()
                ) : (
                  <>
                    <TransactionsTable
                      transactions={transactions}
                      setSelectedRow={setSelectedRow}
                      setRowDetailsOpen={setRowDetailsOpen}
                      handleMarkReviewedAll={handleMarkReviewedAll}
                      allCategories={allCategories}
                      allExpenseCategories={allExpenseCategories}
                      onChangeCategory={onChangeCategory}
                      allAccounts={accounts}
                      currencies={currencies}
                      handlePopupLogic={handlePopupLogic}
                      team={team}
                      invoices={invoices}
                      assignTransactionToInvoice={assignTransactionToInvoice}
                      unreviewedTransLoader={unreviewedTransLoader}
                    />
                    {selectedRow?.account?.name && (
                      <TransactionDetails
                        transactionPanelOpen={rowDetailsOpen}
                        setTransactionPanelOpen={setRowDetailsOpen}
                        transaction={selectedRow}
                        setTransaction={setSelectedRow}
                        reloadTransaction={loadTransactions}
                        tags={tags}
                        allCategories={allCategories}
                        vendors={vendors || []}
                        setVendors={setVendors}
                        accessType={accessType}
                        setReceiptModalOpen={setReceiptModalOpen}
                        setActiveReceipt={setActiveReceipt}
                        currentTab={currentTransDetailTab}
                        setCurrentTab={setCurrentTransDetailTab}
                        accounts={accounts}
                        setFromDropdown={setFromDropdown}
                        fromDropdown={fromDropdown}
                        allExpenseCategories={allExpenseCategories}
                        customers={customers}
                        team={team}
                        currencies={currencies}
                        taxes={taxes}
                        loadTaxes={loadTaxes}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="col-span-full xl:col-span-6 border border-[#D0D5DD] rounded-[12px] py-5 px-7">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-medium text-[#00000099] leading-tight">
                    Needs Your Attention
                  </h3>
                  {/*
                    <button className="btn border-none px-0 outline-none shadow-none text-[#E48642] text-sm font-medium">
                      View All
                    </button> */}
                </div>
                {needsAttentionLoader ? (
                  Loader()
                ) : (
                  <Notifications
                    setSelectedRow={setSelectedRow}
                    commentsMentioned={commentsMentioned}
                    setRowDetailsOpen={setRowDetailsOpen}
                    setCurrentTransDetailTab={setCurrentTransDetailTab}
                    reloadComments={getTransactionCommentsMentioned}
                  />
                )}
              </div>
            </div>
          </main>
        )}
      </AppLayout>
    </>
  );
}

export default Dashboard;
