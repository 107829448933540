import React, { useEffect, useRef, useState } from 'react';
import ModalBasic from '../../../components/ModalBasic';
import DropdownFull from '../../../components/DropdownFull';
import TextInputDash from '../../../components/TextInputDash';
import CategoriesDropdown from '../../transactions/CategoriesDropdown';

const TaxModal = ({
  isOpen,
  setIsOpen,
  handleAddTax,
  activeTax,
  handleUpdateTax,
}) => {
  const [name, setName] = useState('');
  const [percentage, setPercentage] = useState('');
  const [nameError, setNameError] = useState(false);
  const [percentageError, setPercentageError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (activeTax) {
      setName(activeTax?.name);
      setPercentage(activeTax?.percentage);
    } else {
      setName('');
      setPercentage('');
    }
    setPercentageError(false);
    setNameError(false);
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || nameError) {
      setNameError(true);
      return;
    }
    if (!percentage || percentageError) {
      setPercentageError(true);
      return;
    }
    setLoading(true);
    if (activeTax) {
      await handleUpdateTax(
        {
          name: name,
          percentage: percentage,
        },
        activeTax.id,
      );
    } else {
      await handleAddTax({
        name: name,
        percentage: percentage,
      });
    }

    setName('');
    setPercentage('');
    setPercentageError(false);
    setNameError(false);
    setLoading(false);
    setIsOpen(false);
  };

  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`${activeTax ? 'Edit' : 'Create'} Tax`}
      dialogClassName="!overflow-visible"
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col  justify-between overflow-visible "
      >
        <div className="px-5 pt-4 pb-1">
          <TextInputDash
            id="name"
            label="Name"
            placeholder="Name "
            name="name"
            required
            onChange={(e) => {
              if (e.target.value) {
                setNameError(false);
              }
              setName(e.target.value);
            }}
            onBlur={() => {
              if (!name) {
                setNameError(true);
              }
            }}
            value={name || ''}
            error={nameError && !name ? 'Name is required.' : ''}
          />
          <div className="w-1/4">
            <TextInputDash
              id="percentage"
              label="Percentage"
              placeholder="Percentage "
              name="percentage"
              paddingBottom="pb-0"
              required
              percentage
              type="number"
              inputClass="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              onChange={(e) => {
                setPercentage(e.target.value);
                const parsedPercentage = parseFloat(percentage);
                if (
                  percentage &&
                  (isNaN(parsedPercentage) ||
                    parsedPercentage < 0 ||
                    parsedPercentage > 100)
                ) {
                  setPercentageError(true);
                } else {
                  setPercentageError(false);
                }
              }}
              onBlur={() => {
                const parsedPercentage = parseFloat(percentage);
                if (
                  percentage &&
                  (isNaN(parsedPercentage) ||
                    parsedPercentage < 0 ||
                    parsedPercentage > 100)
                ) {
                  setPercentageError(true);
                } else {
                  setPercentageError(false);
                }
              }}
              value={percentage || ''}
            />
          </div>
          <div className="text-xs text-rose-400 w-full">
            {percentageError
              ? percentage
                ? 'Percentage must be a number between 0 and 100.'
                : 'Percentage is Required.'
              : ''}
          </div>
        </div>

        {/* Modal footer */}
        <div className="flex flex-wrap  justify-end space-x-2 px-5 py-4 mt-5">
          <button
            type="submit"
            className="h-10 w-20 btn !bg-indigo-500 text-white text-base leading-6 font-base rounded-[0.313rem]
            disabled:!bg-indigo-300    disabled:cursor-not-allowed"
            disabled={loading}
          >
            {loading ? (
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            ) : (
              `${activeTax ? 'Update' : 'Add'}`
            )}
          </button>
        </div>
      </form>
    </ModalBasic>
  );
};

export default TaxModal;
