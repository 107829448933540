import React, { useEffect, useRef } from 'react';

import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import DollarIcon from '../../images/custom/DollarIcon.png';
import useClickOutside from '../component/custom/useClickOutside';
import { ADMIN, OWNER } from '../../utils/Utils';

function AccoutDetails({
  accountPanelOpen,
  setAccountPanelOpen,
  accountDetails,
  resetAccountDetails,
  setEditAccountModalOpen,
  modalOpen,
}) {
  const { accessType, permissions } = useSelector((state) => state.User);

  const closeBtn = useRef(null);
  const panelContent = useRef(null);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!accountPanelOpen || keyCode !== 27) return;
      setAccountPanelOpen(false);
      resetAccountDetails(null);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useClickOutside(panelContent, () => {
    if (!modalOpen) {
      setAccountPanelOpen(false);
      resetAccountDetails(null);
    }
  });

  return (
    <div
      ref={panelContent}
      className={`absolute inset-0 sm:left-auto z-20 shadow-xl transition-transform duration-200 ease-in-out ${
        accountPanelOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className=" sticky top-16 bg-slate-50 dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-l border-slate-200 dark:border-slate-700 w-full sm:w-[390px] h-[calc(100dvh-64px)]">
        <div className="flex items-center">
          {(accessType === OWNER || accessType === ADMIN) && (
            <button
              aria-controls="feedback-modal"
              onClick={(e) => {
                e.stopPropagation();
                setEditAccountModalOpen(true);
              }}
              className="btn btn-sm bg-indigo-500 hover:bg-indigo-600 text-white mt-6 ml-4  lg:ml-8"
              type="button"
            >
              <svg
                className="w-4 h-4 fill-current text-white shrink-0"
                viewBox="0 0 16 16"
              >
                <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
              </svg>
              <span className="ml-2">Edit</span>
            </button>
          )}
          <button
            ref={closeBtn}
            onClick={() => {
              setAccountPanelOpen(false);
              resetAccountDetails(null);
            }}
            className="absolute top-0 right-0 mt-6 mr-6 group p-2"
            type="button"
          >
            <svg
              className="w-4 h-4 fill-slate-400 group-hover:fill-slate-600 pointer-events-none"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
            </svg>
          </button>
        </div>
        <div className="py-8 px-4 lg:px-8">
          <div className="max-w-sm mx-auto lg:max-w-none">
            <div className="text-slate-800 dark:text-slate-100 font-semibold text-center mb-1">
              Account Detail
            </div>

            <div
              className="mt-12 rounded-t-xl flex-column items-center py-2"
              style={
                accountDetails?.institution?.color || accountDetails?.color
                  ? {
                      background:
                        accountDetails?.color ||
                        accountDetails?.institution?.color,
                    }
                  : {}
              }
            >
              {(accountDetails?.type?.type === 'cash' ||
                accountDetails?.type?.subtype === 'cash') && (
                <div className="mt-2">
                  <img
                    src={DollarIcon}
                    alt="logo"
                    className="w-10 h-7  fill-current text-white mx-auto border-2 border-white"
                  />
                </div>
              )}
              {accountDetails?.institution?.logoUrl && (
                <div className="mt-2">
                  <img
                    src={`${accountDetails?.institution?.logoUrl}`}
                    alt="logo"
                    className="w-10 h-10 fill-current text-white mx-auto border-2 border-white rounded-full"
                  />
                </div>
              )}
              <div className="text-center py-2 text-white font-semibold">
                {accountDetails?.name}
              </div>
            </div>
            {/* Details */}
            <div className="drop-shadow-lg">
              {/* Bottom */}
              <div className="bg-white dark:bg-slate-800 dark:bg-gradient-to-b dark:from-slate-700 dark:to-slate-700/70 rounded-b-xl p-5 pt-3.5 text-sm space-y-3">
                <div className="flex justify-between space-x-1">
                  <span className="italic">Name:</span>
                  <span className="font-medium text-slate-700 dark:text-slate-100 text-right">
                    <div>{accountDetails?.name || ''}</div>
                    <div className="text-xs">
                      {' '}
                      {accountDetails?.officialName || ''}
                    </div>
                  </span>
                </div>
                {accountDetails?.mask && (
                  <div className="flex justify-between space-x-1">
                    <span className="italic">Account #:</span>
                    <span className="font-medium text-slate-700 dark:text-slate-100 text-right">
                      **{accountDetails?.mask}
                    </span>
                  </div>
                )}
                <div className="flex justify-between space-x-1">
                  <span className="italic">Type:</span>
                  <span
                    style={{ textTransform: 'capitalize' }}
                    className="font-medium text-slate-700 dark:text-slate-100 text-right"
                  >
                    <div>{accountDetails?.type?.type || ''}</div>
                    <div className="text-xs">
                      {' '}
                      {accountDetails?.type?.subtype || ''}
                    </div>
                  </span>
                </div>
                {accountDetails?.balanceAvailable !== null &&
                  accountDetails?.balanceAvailable !== 0 &&
                  accountDetails?.balanceAvailable !== '0' &&
                  accountDetails?.balanceAvailable !== '0.00' && (
                    <div className="flex justify-between space-x-1">
                      <span className="italic">Balance Available:</span>
                      <span className="font-medium text-slate-700 dark:text-slate-100 text-right">
                        $
                        {parseFloat(
                          accountDetails?.balanceAvailable || 0,
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  )}
                {accountDetails?.balanceCurrent !== null &&
                  accountDetails?.balanceCurrent !== 0 &&
                  accountDetails?.balanceCurrent !== '0' &&
                  accountDetails?.balanceCurrent !== '0.00' && (
                    <div className="flex justify-between space-x-1">
                      <span className="italic">Balance Current:</span>
                      <span className="font-medium text-slate-700 dark:text-slate-100 text-right">
                        $
                        {parseFloat(
                          accountDetails?.balanceCurrent || 0,
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  )}
                {accountDetails?.balanceLimit !== null &&
                  accountDetails?.balanceLimit !== 0 &&
                  accountDetails?.balanceLimit !== '0' &&
                  accountDetails?.balanceLimit !== '0.00' && (
                    <div className="flex justify-between space-x-1">
                      <span className="italic">Balance Limit: </span>
                      <span className="font-medium text-slate-700 dark:text-slate-100 text-right">
                        $
                        {parseFloat(
                          accountDetails?.balanceLimit || 0,
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  )}
                {checkPermission(
                  PermissionKeys.VIEW_TRANSACTIONS,
                  accessType,
                  permissions,
                ) && (
                  <div className="flex justify-center">
                    <NavLink
                      to={`/transactions?accountId=${accountDetails?.id}`}
                    >
                      <button
                        className="btn  text-white mx-auto"
                        style={
                          accountDetails?.institution?.color ||
                          accountDetails?.color
                            ? {
                                background:
                                  accountDetails?.color ||
                                  accountDetails?.institution?.color,
                              }
                            : {}
                        }
                        type="button"
                      >
                        View All Transactions
                      </button>
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccoutDetails;
