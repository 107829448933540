import React, { useState, useRef, useEffect } from 'react';
import { boot, shutdown } from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import useClickOutside from '../../utils/useClickOutside';
import { panelAmountColor } from '../../utils/Utils';

const VendorFlyout = ({
  flyoutOpen,
  setFlyoutOpen,
  activeVendor,
  setActiveVendor,
  activeVendorTransactions,
  activeVendorAnalytics,
  goToTransactions,
  handleUpdateVendor,
  flyoutIsLoading,
  setTitle,
  setFormOpen,
  currency,
  insights,
  goToVendors,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  const flyoutContent = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  // Local Values
  const [localVendor, setLocalVendor] = useState(activeVendor);
  const [emailWarning, setEmailWarning] = useState(false);

  useEffect(() => {
    if (flyoutOpen) {
      shutdown();
    } else {
      boot();
    }
  }, [flyoutOpen]);

  useEffect(() => {
    if (activeVendor) {
      setLocalVendor(activeVendor);
    }
  }, [activeVendor]);

  useEffect(() => {
    if (!flyoutOpen) {
      setLocalVendor(null);
      setEmailWarning(false);
      // setActiveVendor(null);
    } else {
      setLocalVendor(activeVendor);
    }
  }, [flyoutOpen]);

  useClickOutside(flyoutContent, () => {
    if (flyoutOpen) {
      setFlyoutOpen(false);
      // setActiveVendor(null);
      setLocalVendor(null);
    }
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalVendor((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleContactInputChange = (e) => {
    const { name, value } = e.target;

    setLocalVendor((prevState) => {
      const newState = { ...prevState };

      if (newState.contacts) {
        newState.contacts[0] = {
          ...newState.contacts[0],
          [name]: value,
        };
      }

      return newState;
    });
  };

  const handleAddressInputChange = (e) => {
    const { name, value } = e.target;

    setLocalVendor((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        [name]: value,
      },
    }));
  };

  const handleSubmit = () => {
    if (localVendor?.contacts[0]?.email) {
      const email = localVendor?.contacts[0]?.email;
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailPattern.test(email)) {
        setEmailWarning(true);
        return;
      }
    }

    const data = {
      accountNumber: localVendor?.accountNumber,
      name: localVendor?.name,
      website: localVendor?.website,
      contacts: [
        {
          email: localVendor?.contacts[0]?.email,
          firstName: localVendor?.contacts[0]?.firstName,
          lastName: localVendor?.contacts[0]?.lastName,
          phone: localVendor?.contacts[0]?.phone,
        },
      ],
      address: localVendor?.address,
    };
    setEmailWarning(false);
    handleUpdateVendor(data, activeVendor?.id);
  };

  return (
    <div
      ref={flyoutContent}
      className={`absolute inset-0 bg-white sm:left-auto  transition-transform duration-200 ease-in-out z-40 ${
        flyoutOpen ? 'translate-x-0 shadow-xl' : 'translate-x-full'
      }`}
    >
      <div
        className="sticky top-16 bg-white dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0  w-full sm:w-[455px] h-[calc(100dvh-64px)]"
        style={flyoutOpen ? { boxShadow: ' -10px 0px 15px 0px #D0D5DD80' } : {}}
      >
        {/* Header */}
        <div className="sticky top-0 h-[3.875rem] bg-white z-10">
          <div className="flex justify-end pt-2.5 pr-2.5">
            <button
              className="ml-auto group"
              onClick={() => setFlyoutOpen(false)}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="h-5 w-5 fill-[#667085] group-hover:fill-slate-700"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </button>
          </div>
          <div className="relative flex flex-row gap-[3.125rem] justify-start items-center mt-3 pb-1 w-full border-b-2 border-gray-300 pl-[1.625rem] bg-white">
            <p className="text-sm leading-6 font-normal cursor-pointer text-black">
              Vendor Details
            </p>
          </div>
        </div>
        {/* Main Content */}
        <div className="bg-white w-full flex flex-col items-center gap-5 px-7">
          {flyoutIsLoading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              <div className="flex w-full flex-col items-start gap-[1.563rem] py-5 px-[1.688rem] rounded-[10px] bg-white border border-black border-opacity-5 shadow-md mt-5">
                <div className="flex w-full flex-col items-start gap-4 self-stretch">
                  <div className="flex w-full justify-between items-center">
                    {/* Name and logo */}
                    <div className="flex items-center gap-[0.563rem] flex-grow flex-shrink-0 basis-0">
                      {activeVendor?.logoUrl ? (
                        <img
                          className="w-[1.687rem] h-[1.687rem] rounded-[5.125rem]"
                          src={localVendor?.logoUrl}
                          width="26.996"
                          height="26.996"
                          alt={localVendor?.name}
                        />
                      ) : (
                        <div className="min-w-[1.687rem] min-h-[1.687rem] rounded-[5.125rem] flex items-center justify-center bg-slate-400 dark:bg-slate-700">
                          <div className="text-xs font-normal uppercase text-white">
                            {localVendor?.name
                              ? localVendor?.name
                                  .replace(/[^\w\s]/gi, '')
                                  .split(' ')
                                  .filter(Boolean)
                                  .slice(0, 2)
                                  .map((word) => word.charAt(0))
                                  .join('')
                              : ''}
                          </div>
                        </div>
                      )}
                      {isEditing ? (
                        <input
                          type="text"
                          name="name"
                          className="text-slate-600 font-medium leading-6 !text-[0.938rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-[52%] p-0"
                          value={localVendor?.name || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p className="text-slate-600 text-[0.938rem] font-medium leading-6">
                          {localVendor?.name}
                        </p>
                      )}
                    </div>
                    {checkPermission(
                      PermissionKeys.MANAGE_VENDORS,
                      accessType,
                      permissions,
                    ) &&
                      !localVendor?.contractorId && (
                        <button
                          aria-controls="feedback-modal"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (insights) {
                              goToVendors();
                            } else {
                              setFlyoutOpen(false);
                              setTitle('Edit Vendor');
                              setActiveVendor(activeVendor);
                              setFormOpen(true);
                            }
                          }}
                          type="button"
                          className={`border border-opacity-50 shadow-sm h-8 w-[4.625rem] inline-flex py-[0.375rem] px-[0.625rem] rounded-md font-inter ${
                            isEditing ? 'border-indigo-500' : 'border-gray-300'
                          }`}
                        >
                          <span
                            className={`${
                              isEditing
                                ? 'text-sm pr-[0.2rem]'
                                : 'text-sm pr-[0.563rem]'
                            } font-semibold leading-5 text-slate-600 text-opacity-80`}
                          >
                            {isEditing ? 'Save' : 'Edit'}
                          </span>
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-[18px] h-[18px]"
                          >
                            <path
                              d="M10.2731 3.20041L4.11564 9.71791C3.88314 9.96541 3.65814 10.4529 3.61314 10.7904L3.33564 13.2204C3.23814 14.0979 3.86814 14.6979 4.73814 14.5479L7.15314 14.1354C7.49064 14.0754 7.96314 13.8279 8.19564 13.5729L14.3531 7.05541C15.4181 5.93041 15.8981 4.64791 14.2406 3.08041C12.5906 1.52791 11.3381 2.07541 10.2731 3.20041Z"
                              stroke="#667085"
                            />
                            <path
                              d="M9.24561 4.28711C9.56811 6.35711 11.2481 7.93961 13.3331 8.14961"
                              stroke="#667085"
                            />
                            <path d="M2.57812 17H16.0781" stroke="#667085" />
                          </svg>
                        </button>
                      )}
                  </div>
                  {/* Account number and website */}
                  <div className="grid grid-cols-2 gap-4 self-stretch">
                    {localVendor?.accountNumber && (
                      <div className="flex flex-col">
                        {isEditing ? (
                          <>
                            <label
                              htmlFor="accountNumber"
                              className="text-slate-600 font-medium text-[0.938rem] leading-6"
                            >
                              Account Number
                            </label>
                            <input
                              type="text"
                              name="accountNumber"
                              className="text-slate-600 leading-6 !text-[0.875rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-[52%] p-0"
                              value={localVendor?.accountNumber || ''}
                              onChange={handleInputChange}
                            />
                          </>
                        ) : (
                          <>
                            <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                              Account Number
                            </p>
                            <p className="text-slate-600 text-[0.875rem] break-all leading-tight">
                              {localVendor?.accountNumber}
                            </p>
                          </>
                        )}
                      </div>
                    )}
                    {localVendor?.website && (
                      <div className="flex flex-col w-[7rem]">
                        {isEditing ? (
                          <>
                            <label
                              htmlFor="website"
                              className="text-slate-600 font-medium text-[0.938rem] leading-6"
                            >
                              Website
                            </label>
                            <input
                              type="text"
                              name="website"
                              className="text-slate-600 leading-6 !text-[0.875rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-full p-0"
                              value={localVendor?.website || ''}
                              onChange={handleInputChange}
                            />
                          </>
                        ) : (
                          <>
                            <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                              Website
                            </p>
                            <p className="text-slate-600 text-[0.875rem] leading-tight break-all">
                              {localVendor?.website}
                            </p>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {(localVendor?.contacts[0]?.email ||
                  localVendor?.contacts[0]?.firstName ||
                  localVendor?.contacts[0]?.lastName ||
                  localVendor?.contacts[0]?.phone) && (
                  <>
                    {/* Contact info */}
                    <div className="flex flex-col items-start gap-4 self-stretch">
                      <div className="flex items-center self-stretch gap-2">
                        <p className="text-slate-600 text-[0.875rem] font-bold leading-5">
                          Contact Information
                        </p>
                        <div className="bg-[#C6C7C9] h-[0.5px] w-full flex-grow flex-shrink-0 basis-0" />
                      </div>
                      <div className="grid grid-cols-2 gap-4 w-full">
                        {localVendor?.contacts[0]?.firstName && (
                          <div className="flex flex-col w-full">
                            {isEditing ? (
                              <>
                                <label
                                  htmlFor="firstName"
                                  className="text-slate-600 font-medium text-[0.938rem] leading-6"
                                >
                                  First Name
                                </label>
                                <input
                                  type="text"
                                  name="firstName"
                                  className="text-slate-600 leading-6 !text-[0.875rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-full p-0"
                                  value={
                                    localVendor?.contacts[0]?.firstName || ''
                                  }
                                  onChange={handleContactInputChange}
                                />
                              </>
                            ) : (
                              <>
                                <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                                  First Name
                                </p>
                                <p className="text-slate-600 leading-6 text-[0.875rem]">
                                  {localVendor?.contacts[0]?.firstName}
                                </p>
                              </>
                            )}
                          </div>
                        )}
                        {localVendor?.contacts[0]?.lastName && (
                          <div className="flex flex-col  w-full">
                            {isEditing ? (
                              <>
                                <label
                                  htmlFor="lastName"
                                  className="text-slate-600 font-medium text-[0.938rem] leading-6"
                                >
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  name="lastName"
                                  className="text-slate-600 leading-6 !text-[0.875rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-full p-0"
                                  value={
                                    localVendor?.contacts[0]?.lastName || ''
                                  }
                                  onChange={handleContactInputChange}
                                />
                              </>
                            ) : (
                              <>
                                <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                                  Last Name
                                </p>
                                <p className="text-slate-600 leading-6 text-[0.875rem]">
                                  {localVendor?.contacts[0]?.lastName}
                                </p>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="grid grid-cols-2 gap-4 w-full">
                        {localVendor?.contacts[0]?.email && (
                          <div className="flex flex-col">
                            {isEditing ? (
                              <>
                                <label
                                  htmlFor="email"
                                  className="text-slate-600 font-medium text-[0.938rem] leading-6"
                                >
                                  Contact Email
                                </label>
                                <input
                                  type="email"
                                  name="email"
                                  className="text-slate-600 leading-6 !text-[0.875rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-full p-0"
                                  value={localVendor?.contacts[0]?.email || ''}
                                  onChange={handleContactInputChange}
                                />
                                {emailWarning && (
                                  <p className="text-red-500 text-[0.75rem] leading-5">
                                    Please enter a valid email address
                                  </p>
                                )}
                              </>
                            ) : (
                              <>
                                <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                                  Contact Email
                                </p>
                                <p className="text-slate-600 leading-6 text-[0.875rem] break-all leading-tight">
                                  {localVendor?.contacts[0]?.email}
                                </p>
                              </>
                            )}
                          </div>
                        )}
                        {localVendor?.contacts[0]?.phone && (
                          <div className="flex flex-col w-[7rem]">
                            {isEditing ? (
                              <>
                                <label
                                  htmlFor="phone"
                                  className="text-slate-600 font-medium text-[0.938rem] leading-6"
                                >
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  name="phone"
                                  className="text-slate-600 leading-6 !text-[0.875rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-full p-0"
                                  value={localVendor?.contacts[0]?.phone || ''}
                                  onChange={handleContactInputChange}
                                />
                              </>
                            ) : (
                              <>
                                <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                                  Phone Number
                                </p>
                                <p className="text-slate-600 leading-6 text-[0.875rem] break-all">
                                  {localVendor?.contacts[0]?.phone}
                                </p>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {(localVendor?.address?.city ||
                  localVendor?.address?.country ||
                  localVendor?.address?.state ||
                  localVendor?.address?.street ||
                  localVendor?.address?.street2 ||
                  localVendor?.address?.zipCode) && (
                  <>
                    {/* Address */}
                    <div className="flex flex-col items-start gap-4 self-stretch">
                      <div className="flex items-center self-stretch gap-2">
                        <p className="text-slate-600 text-[0.875rem] font-bold leading-5">
                          Address
                        </p>
                        <div className="bg-[#C6C7C9] h-[0.5px] w-full flex-grow flex-shrink-0 basis-0" />
                      </div>
                      <div className="grid grid-cols-2 gap-4 w-full">
                        {localVendor?.address?.street && (
                          <div className="flex flex-col">
                            {isEditing ? (
                              <>
                                <label
                                  htmlFor="street"
                                  className="text-slate-600 font-medium text-[0.938rem] leading-6"
                                >
                                  Address 1
                                </label>
                                <input
                                  type="text"
                                  name="street"
                                  className="text-slate-600 leading-6 !text-[0.875rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-full p-0"
                                  value={localVendor?.address?.street || ''}
                                  onChange={handleAddressInputChange}
                                />
                              </>
                            ) : (
                              <>
                                <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                                  Address 1
                                </p>
                                <p className="text-slate-600 leading-6 text-[0.875rem]">
                                  {localVendor?.address?.street}
                                </p>
                              </>
                            )}
                          </div>
                        )}
                        {localVendor?.address?.street2 && (
                          <div className="flex flex-col w-[7rem]">
                            {isEditing ? (
                              <>
                                <label
                                  htmlFor="street2"
                                  className="text-slate-600 font-medium text-[0.938rem] leading-6"
                                >
                                  Address 2
                                </label>
                                <input
                                  type="text"
                                  name="street2"
                                  className="text-slate-600 leading-6 !text-[0.875rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-full p-0"
                                  value={localVendor?.address?.street2 || ''}
                                  onChange={handleAddressInputChange}
                                />
                              </>
                            ) : (
                              <>
                                <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                                  Address 2
                                </p>
                                <p className="text-slate-600 leading-6 text-[0.875rem]">
                                  {localVendor?.address?.street2}
                                </p>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="grid grid-cols-2 gap-4 w-full">
                        {localVendor?.address?.zipCode && (
                          <div className="flex flex-col">
                            {isEditing ? (
                              <>
                                <label
                                  htmlFor="zipCode"
                                  className="text-slate-600 font-medium text-[0.938rem] leading-6"
                                >
                                  Zipcode
                                </label>
                                <input
                                  type="text"
                                  name="zipCode"
                                  className="text-slate-600 leading-6 !text-[0.875rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-full p-0"
                                  value={localVendor?.address?.zipCode || ''}
                                  onChange={handleAddressInputChange}
                                />
                              </>
                            ) : (
                              <>
                                <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                                  Zipcode
                                </p>
                                <p className="text-slate-600 leading-6 text-[0.875rem]">
                                  {localVendor?.address?.zipCode}
                                </p>
                              </>
                            )}
                          </div>
                        )}
                        {localVendor?.address?.city && (
                          <div className="flex flex-col w-[7rem]">
                            {isEditing ? (
                              <>
                                <label
                                  htmlFor="city"
                                  className="text-slate-600 font-medium text-[0.938rem] leading-6"
                                >
                                  City
                                </label>
                                <input
                                  type="text"
                                  name="city"
                                  className="text-slate-600 leading-6 !text-[0.875rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-full p-0"
                                  value={localVendor?.address?.city || ''}
                                  onChange={handleAddressInputChange}
                                />
                              </>
                            ) : (
                              <>
                                <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                                  City
                                </p>
                                <p className="text-slate-600 leading-6 text-[0.875rem]">
                                  {localVendor?.address?.city}
                                </p>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="grid grid-cols-2 gap-4 w-full">
                        {localVendor?.address?.state && (
                          <div className="flex flex-col">
                            {isEditing ? (
                              <>
                                <label
                                  htmlFor="state"
                                  className="text-slate-600 font-medium text-[0.938rem] leading-6"
                                >
                                  State
                                </label>
                                <input
                                  type="text"
                                  name="state"
                                  className="text-slate-600 leading-6 !text-[0.875rem] border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-full p-0"
                                  value={localVendor?.address?.state || ''}
                                  onChange={handleAddressInputChange}
                                />
                              </>
                            ) : (
                              <>
                                <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
                                  State
                                </p>
                                <p className="text-slate-600 leading-6 text-[0.875rem]">
                                  {localVendor?.address?.state}
                                </p>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* Analytics */}
              {(!activeVendorTransactions ||
                activeVendorTransactions?.length === 0) &&
                !flyoutIsLoading && <p>This vendor has no transactions.</p>}
              {activeVendorTransactions?.length > 0 && !flyoutIsLoading && (
                <>
                  <div className="flex flex-col gap-4 w-full">
                    <p className="text-slate-600 text-[0.875rem] font-medium leading-5">
                      Spending Stats
                    </p>
                    <div className="flex items-center gap-4 justify-around">
                      <div className="flex flex-col flex-1">
                        <p
                          className={`text-center text-[1.25rem] font-semibold leading-6 ${panelAmountColor(
                            activeVendorAnalytics?.sumLast30Days,
                          )}`}
                        >
                          {currency?.symbol}
                          {activeVendorAnalytics?.sumLast30Days
                            ?.toString()
                            .charAt(0) === '-'
                            ? parseFloat(
                                activeVendorAnalytics?.sumLast30Days
                                  ?.toString()
                                  .replace('-', ''),
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : parseFloat(
                                activeVendorAnalytics?.sumLast30Days,
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </p>
                        <p className="text-slate-600 text-center text-[0.875rem] leading-5 font-medium">
                          Past 30 days
                        </p>
                      </div>
                      <div className="flex flex-col flex-1">
                        <p
                          className={`text-center text-[1.25rem] font-semibold leading-6 ${panelAmountColor(
                            activeVendorAnalytics?.sumYearToDate,
                          )}`}
                        >
                          {currency?.symbol}
                          {activeVendorAnalytics?.sumYearToDate
                            ?.toString()
                            .charAt(0) === '-'
                            ? parseFloat(
                                activeVendorAnalytics?.sumYearToDate
                                  ?.toString()
                                  .replace('-', ''),
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : parseFloat(
                                activeVendorAnalytics?.sumYearToDate,
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </p>
                        <p className="text-slate-600 text-center text-[0.875rem] leading-5 font-medium">
                          Year to Date
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 justify-around">
                      <div className="flex flex-col flex-1">
                        <p
                          className={`text-center text-[1.25rem] font-semibold leading-6 ${panelAmountColor(
                            activeVendorAnalytics?.sumAllTime,
                          )}`}
                        >
                          {currency?.symbol}
                          {activeVendorAnalytics?.sumAllTime
                            ?.toString()
                            .charAt(0) === '-'
                            ? parseFloat(
                                activeVendorAnalytics?.sumAllTime
                                  ?.toString()
                                  .replace('-', ''),
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : parseFloat(
                                activeVendorAnalytics?.sumAllTime,
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </p>
                        <p className="text-slate-600 text-center text-[0.875rem] leading-5 font-medium">
                          All Time
                        </p>
                      </div>
                      <div className="flex flex-col flex-1">
                        <p
                          className={`text-center text-[1.25rem] font-semibold leading-6 ${panelAmountColor(
                            activeVendorAnalytics?.sumAllTime,
                          )}`}
                        >
                          {activeVendorAnalytics?.totalTransactions}
                        </p>
                        <p className="text-slate-600 text-center text-[0.875rem] leading-5 font-medium">
                          # of Transactions
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Transactions */}
                  <div className="w-full flex flex-col justify-center items-center gap-5">
                    <div className="flex flex-col items-end gap-2.5 self-stretch">
                      <div className="flex flex-col items-start gap-2.5 self-stretch">
                        <p className="text-slate-600 text-[0.875rem] font-medium leading-5">
                          Recent Transactions
                        </p>
                        {activeVendorTransactions.map((transaction) => (
                          <div
                            key={transaction?.id}
                            className="flex py-5 px-[1.688rem] shadow-md rounded-[10px] border border-black border-opacity-5 bg-white w-full gap-[1.563rem]"
                          >
                            <div className="flex items-start self-stretch justify-between w-full gap-[9rem]">
                              <div className="flex flex-col justify-center items-start gap-1 flex-grow flex-shrink-0 basis-0">
                                <p className="text-slate-600 text-[0.875rem] font-medium leading-5">
                                  Date
                                </p>
                                <p className="text-slate-600 leading-6 text-[0.875rem]">
                                  {transaction?.postedDate}
                                </p>
                              </div>
                              <div className="flex flex-col justify-center items-start gap-1 flex-grow flex-shrink-0 basis-0">
                                <p className="text-slate-600 text-[0.875rem] font-medium leading-5">
                                  Amount
                                </p>
                                <p
                                  className={`font-semibold leading-6 text-[0.875rem] ${panelAmountColor(
                                    transaction?.amount,
                                  )}`}
                                >
                                  {transaction?.amount?.toString().charAt(0) ===
                                  '-'
                                    ? '+'
                                    : '-'}
                                  {currency?.symbol}
                                  {transaction?.amount?.toString().charAt(0) ===
                                  '-'
                                    ? parseFloat(
                                        transaction?.amount
                                          ?.toString()
                                          .replace('-', ''),
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : parseFloat(
                                        transaction?.amount,
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* Go to transactions btn */}
                    <button
                      className="mb-4 h-11 flex items-center justify-center gap-2 py-2.5 px-[1.125rem] rounded-[0.313rem] border border-indigo-500 bg-indigo-500 shadow-sm text-[1rem] text-white leading-6"
                      onClick={() => goToTransactions(activeVendor?.id)}
                    >
                      View all transactions (
                      {activeVendorAnalytics?.totalTransactions})
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VendorFlyout;
