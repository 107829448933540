import React, { useState, useEffect } from 'react';
import { boot, shutdown } from '@intercom/messenger-js-sdk';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import AddTaskModal from './AddTaskModal';
import {
  assignTransactionToBill,
  deleteTask,
  deleteTaskAttachment,
  getAccounts,
  getAllProjects,
  getCustomers,
  getInboxCount,
  getMerchants,
  getNotifications,
  getSingleTransaction,
  getTaskAttachments,
  getTaskById,
  getTasks,
  getTaxes,
  updateInstanceById,
  updateTask,
  uploadTaskAttachment,
} from '../../API/backend_helper';
import {
  getFormattedDate,
  TaskStatusOptions,
  TEAM_DATE_FORMAT,
  transformAccounts,
} from '../../utils/Utils';
import TaskFlyout from './TaskFlyout';
import Tasks from './Tasks';
import Notifications from './Notifications';
import TransactionDetails from '../transactions/TransactionPanel';
import { Loader } from '../../components/Svg';
import ReceiptModal from '../transactions/ReceiptModal';
import TransactionsModal from '../bills/TransactionsModal';
import AppLayout from '../../components/AppLayout';
import DeleteModal from './DeleteModal';
import EditTaskModal from './TaskFlyout/EditTaskModal';
import AttachmentModal from './AttachmentModal';

let searchTimeout = null;

const Inbox = () => {
  document.title = 'COUNT | Inbox';

  const location = useLocation();
  const navigate = useNavigate();
  const { accessType, localUser, permissions } = useSelector(
    (state) => state.User,
  );
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const params = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const Id = params?.taskId || searchParams.get('id') || '';
  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [isLoading, setIsLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState({});
  const [assigneeLoading, setAssigneeLoading] = useState({});
  const [priorityLoading, setPriorityLoading] = useState({});
  const [activeTab, setActiveTab] = useState('inbox');
  const [modalOpen, setModalOpen] = useState(false);
  const [editTaskModalOpen, setEditTaskModalOpen] = useState(false);
  const [tasks, setTasks] = useState({});
  const [statusFilter, setStatusFilter] = useState([
    'inProgress',
    'review',
    'notStarted',
  ]);
  const [taskSearchFilter, setTaskSearchFilter] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('');
  const [assignedFilter, setAssignedFilter] = useState('');
  const [priorityFilter, setPriorityFilter] = useState('');
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [inboxCount, setInboxCount] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [localTasksLimit, setLocalTasksLimit] = useState(50);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [projects, setProjects] = useState([]);

  // Transaction Flyout
  const [transactionFlyoutOpen, setTransactionFlyoutOpen] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [accountCategories, setAccountCategories] = useState([]);
  const [currentTab, setCurrentTab] = useState('details');
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [fromDropdown, setFromDropdown] = useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [physicalAccounts, setPhysicalAccounts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [splitTransactionModalOpen, setSplitTransactionModalOpen] =
    useState(false);
  const [selectedTaskOption, setSelectedTaskOption] = useState(null);

  // Flyout
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [activeAttachment, setActiveAttachment] = useState(null);
  const [selectedTaskAttachments, setSelectedTaskAttachments] = useState([]);
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);

  // Transactions Modal
  const [transactionsModalOpen, setTransactionsModalOpen] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [activeBill, setActiveBill] = useState(null);
  const [activeInstance, setActiveInstance] = useState(null);

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      !checkPermission(PermissionKeys.VIEW_TASKS, accessType, permissions)
    ) {
      navigate('/');
    }
  }, [accessType, permissions]);

  const loadNotifications = async () => {
    try {
      const res = await getNotifications({});
      setNotifications(res?.notifications);
    } catch (error) {
      console.error(error);
    }
  };

  const loadTasks = async ({
    page = tasks?.page || 1,
    limit = localTasksLimit,
    assigned = assignedFilter || '',
    status = statusFilter || '',
    priority = priorityFilter || '',
    loading = false,
    orderByVal = orderBy,
    orderDirectionVal = orderDirection,
    search = taskSearchFilter,
  }) => {
    try {
      if (loading) {
        setTableLoading(true);
      }
      const res = await getTasks({
        page,
        limit,
        assigned,
        status,
        priority,
        orderBy: orderByVal,
        orderDirection: orderDirectionVal,
        search,
      });
      setTasks(res);
    } catch (error) {
      console.error(error);
    } finally {
      if (loading) {
        setTableLoading(false);
      }
    }
  };

  const handleSortBy = (name) => {
    let orderByVal = '';
    let orderDirectionVal = '';
    if (orderBy !== name) {
      orderByVal = name;
      orderDirectionVal = 'asc';
    } else if (orderBy === name && orderDirection === 'asc') {
      orderByVal = name;
      orderDirectionVal = 'desc';
    } else {
      orderByVal = '';
      orderDirectionVal = '';
    }
    setOrderBy(orderByVal);
    setOrderDirection(orderDirectionVal);
    loadTasks({
      orderByVal,
      orderDirectionVal,
    });
  };

  const handleTasksSearch = (val) => {
    setTaskSearchFilter(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      loadTasks({
        search: encodeURIComponent(val || ''),
        page: 1,
        loading: true,
      });
    }, 900);
  };

  const loadAllProjects = async () => {
    try {
      const res = await getAllProjects({});
      const newProjects = res?.map((project) => ({
        ...project,
        name: `${project?.name} ${project?.customId ? `(${project.customId})` : ''}`,
      }));
      setProjects(newProjects);
    } catch (error) {
      console.error(error);
    }
  };

  const loadTaskById = async (id) => {
    try {
      const res = await getTaskById(id);
      setSelectedTask(res);
      setFlyoutOpen(true);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadSingleTask = async (id) => {
    setSelectedTask(tasks?.tasks?.find((task) => task.id === id));
  };

  const loadInboxCount = async () => {
    try {
      const res = await getInboxCount();
      setInboxCount(res);
    } catch (error) {
      console.error(error);
    }
  };

  const loadVendors = async () => {
    try {
      const res = await getMerchants({});
      setVendors(res?.vendors);
    } catch (error) {
      console.error(error);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadAccounts = async () => {
    try {
      const accountsRes = await getAccounts();
      setAccountCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
      );
      setExpenseCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
      setPhysicalAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadCustomers = async () => {
    try {
      const res = await getCustomers({});
      setCustomers(res?.data?.records);
    } catch (error) {
      console.error(error);
    }
  };

  const loadSingleTransaction = async (id) => {
    try {
      const res = await getSingleTransaction(id);
      setTransaction(res);
      setTransactionFlyoutOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleDropdown = (dropdownId) => {
    setStatusDropdownOpen(
      statusDropdownOpen === dropdownId ? null : dropdownId,
    );
  };

  const handleUpdateTask = async (id, values, type = '') => {
    try {
      let formattedDate;
      // if (type === 'status') {
      //   setStatusLoading((prevStatusLoading) => ({
      //     ...prevStatusLoading,
      //     [id]: true,
      //   }));
      // }
      // if (type === 'assignee') {
      //   setAssigneeLoading((prevAssigneeLoading) => ({
      //     ...prevAssigneeLoading,
      //     [id]: true,
      //   }));
      // }
      // if (type === 'priority') {
      //   setPriorityLoading((prevPriorityLoading) => ({
      //     ...prevPriorityLoading,
      //     [id]: true,
      //   }));
      // }

      if (values.deadlineDate) {
        formattedDate = getFormattedDate(values.deadlineDate, teamDateFormat);
      } else {
        formattedDate = null;
      }
      const tempTasks = JSON.parse(JSON.stringify(tasks?.tasks));
      const taskIndex = tempTasks?.findIndex((t) => t?.id === id);
      const tempTask = {
        ...tempTasks[taskIndex],
        ...values,
        deadlineDate: formattedDate,
        id,
      };
      tempTasks[taskIndex] = JSON.parse(JSON.stringify(tempTask));
      setTasks((prev) => ({
        ...prev,
        tasks: tempTasks,
      }));
      if (selectedTask?.id) {
        setSelectedTask(JSON.parse(JSON.stringify(tempTask)));
      }
      const res = await updateTask(id, {
        ...values,
        deadlineDate: formattedDate,
      });
      if (selectedTask?.id) {
        setSelectedTask(res);
      }
      // eslint-disable-next-line no-use-before-define
      await loadData(false);
      // if (type === 'status') {
      //   setStatusLoading((prevStatusLoading) => ({
      //     ...prevStatusLoading,
      //     [id]: false,
      //   }));
      // }
      // if (type === 'assignee') {
      //   setAssigneeLoading((prevAssigneeLoading) => ({
      //     ...prevAssigneeLoading,
      //     [id]: false,
      //   }));
      // }
      // if (type === 'priority') {
      //   setPriorityLoading((prevPriorityLoading) => ({
      //     ...prevPriorityLoading,
      //     [id]: false,
      //   }));
      // }
      return res;
    } catch (error) {
      toast.error(
        'An error occurred while saving. Please refresh and try again',
      );
      console.error(error);
    }
  };

  const handleTransactionAssignment = async (
    transactionId = activeTransaction?.id,
  ) => {
    try {
      const res = await assignTransactionToBill(
        activeBill?.id,
        activeInstance?.id,
        {
          transactionId,
        },
      );
      if (res) {
        if (res?.status === 'paid') {
          toast.success('Bill is completely paid successfully');
          handleUpdateTask(
            selectedTask?.id,
            { ...selectedTask, status: 'completed' },
            true,
          );
          setFlyoutOpen(false);
        } else {
          toast.success('Bill is partially paid successfully');
          handleUpdateTask(
            selectedTask?.id,
            { ...selectedTask, status: 'inProgress' },
            true,
          );
          setFlyoutOpen(false);
        }
      }
      return res;
    } catch (err) {
      console.log('err', err);
    }
  };

  const updateInstanceTotal = async (total) => {
    const data = {
      total,
    };

    try {
      const res = await updateInstanceById(
        activeBill?.id,
        activeInstance?.id,
        data,
      );
      return res;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDeleteTask = async () => {
    try {
      await deleteTask(selectedTask?.id);
      // eslint-disable-next-line no-use-before-define
      if (selectedTask?.id) {
        setSelectedTask(null);
      }
      if (Id) {
        setSearchParams({});
      }
      setFlyoutOpen(false);
      await loadTasks({});
    } catch (error) {
      console.error(error);
    }
  };

  const findTaskType = (type) => {
    switch (type) {
      case 'toDo':
        return 'To-Do';
      case 'reviewTransaction':
        return 'Review Transaction';
      case 'transactionVendor':
        return 'Set Transaction Vendor';
      case 'transactionCategory':
        return 'Set Transaction Category';
      case 'payBill':
        return 'Pay Bill';
      case 'transactionAttachment':
        return 'Upload Transaction Attachment';
      default:
        return 'No Type Assigned';
    }
  };

  const loadData = async (loading = true) => {
    if (loading) {
      setIsLoading(true);
    }
    if (Id === 'new') {
      setModalOpen(true);
      setSearchParams({});
    }
    await Promise.all([loadInboxCount(), loadNotifications()]);
    if (loading && Id && Id !== 'new') {
      loadTaskById(Id);
    }

    if (location?.state?.filter) {
      setAssignedFilter(location.state.filter);
      await loadTasks({ assigned: location.state.filter });
    } else {
      await loadTasks({});
    }
    if (loading) {
      setIsLoading(false);
    }
  };

  const loadTaskAttachments = async (loading = false) => {
    if (loading) {
      setAttachmentsLoading(true);
    }
    try {
      const data = await getTaskAttachments(selectedTask?.id);
      setSelectedTaskAttachments(data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setAttachmentsLoading(false);
    }
  };

  const uploadTaskFile = async (file) => {
    try {
      const data = new FormData();
      data.append('attachment', file);
      await uploadTaskAttachment(selectedTask?.id, data);
      loadTaskAttachments();
    } catch (err) {
      toast.error(
        'An error occurred while saving. Please refresh and try again',
      );
      console.log('err', err);
    }
  };

  const handleDeleteAttachment = async () => {
    if (activeAttachment?.id) {
      try {
        await deleteTaskAttachment(
          activeAttachment?.taskId,
          activeAttachment?.id,
        );
      } catch (error) {
        console.error(error);
      }
    }
    await loadTaskAttachments();
    setActiveAttachment(null);
  };

  useEffect(() => {
    if (selectedTask?.id) {
      loadTaskAttachments(true);
    }
  }, [selectedTask?.id]);

  useEffect(() => {}, [location]);

  useEffect(() => {
    if (activeBill) {
      setActiveInstance(
        activeBill?.instances[activeBill?.instances.length - 1],
      );
    }
  }, [activeBill]);

  useEffect(() => {
    loadAllProjects();
    setSelectedTask(null);
    loadVendors();
    loadTaxes();
    loadAccounts();
    loadCustomers();
    loadData();
  }, []);

  useEffect(() => {
    if (flyoutOpen) {
      shutdown();
    } else {
      boot();
    }
  }, [flyoutOpen]);

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        handleDone={
          deleteModalOpen === 'task' ? handleDeleteTask : handleDeleteAttachment
        }
        type={deleteModalOpen}
      />
      <AddTaskModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        loadData={loadTasks}
        selectedOption={transaction ? selectedTaskOption : ''}
        transactions={!!transaction}
        projects={projects}
        task={selectedTask}
        statusOptions={TaskStatusOptions}
      />
      <EditTaskModal
        task={selectedTask}
        modalOpen={editTaskModalOpen}
        setModalOpen={setEditTaskModalOpen}
        handleUpdateTask={handleUpdateTask}
        projects={projects}
        setTask={setSelectedTask}
        loadData={loadData}
      />
      <AttachmentModal
        isOpen={attachmentModalOpen}
        setIsOpen={setAttachmentModalOpen}
        receipt={
          activeAttachment !== null
            ? activeAttachment?.fileUrl || activeAttachment?.preview
            : activeReceipt
        }
        isExpenseReporting={activeAttachment !== null}
        type={
          activeAttachment !== null
            ? activeAttachment?.fileType || activeAttachment?.type
            : ''
        }
        title={
          activeAttachment !== null
            ? activeAttachment?.fileName || activeAttachment?.name
            : ''
        }
        setActiveAttachment={setActiveAttachment}
      />
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={
          activeAttachment !== null
            ? activeAttachment?.fileUrl || activeAttachment?.preview
            : activeReceipt
        }
        isExpenseReporting={activeAttachment !== null}
        type={
          activeAttachment !== null
            ? activeAttachment?.fileType || activeAttachment?.type
            : ''
        }
        title={
          activeAttachment !== null
            ? activeAttachment?.fileName || activeAttachment?.name
            : ''
        }
        setActiveAttachment={setActiveAttachment}
      />
      <TransactionsModal
        isOpen={transactionsModalOpen}
        setIsOpen={setTransactionsModalOpen}
        activeTransaction={activeTransaction}
        setActiveTransaction={setActiveTransaction}
        allCategories={accountCategories}
        activeBill={activeBill}
        currencies={currencies}
        activeInstance={activeInstance}
        setActiveInstance={setActiveInstance}
        handleTransactionAssignment={handleTransactionAssignment}
        updateInstanceTotal={updateInstanceTotal}
      />
      {transaction && (
        <TransactionDetails
          transaction={transaction}
          setTransaction={setTransaction}
          transactionPanelOpen={transactionFlyoutOpen}
          setTransactionPanelOpen={setTransactionFlyoutOpen}
          vendors={vendors}
          setVendors={setVendors}
          accounts={physicalAccounts}
          allCategories={accountCategories}
          allExpenseCategories={expenseCategories}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setFromDropdown={setFromDropdown}
          fromDropdown={fromDropdown}
          receiptModalOpen={receiptModalOpen}
          setReceiptModalOpen={setReceiptModalOpen}
          activeReceipt={activeReceipt}
          setActiveReceipt={setActiveReceipt}
          reloadTransaction={() => {}}
          customers={customers}
          setSplitTransactionModalOpen={setSplitTransactionModalOpen}
          splitTransactionModalOpen={splitTransactionModalOpen}
          taskModalOpen={modalOpen}
          setTaskModalOpen={setModalOpen}
          setSelectedTaskOption={setSelectedTaskOption}
          team={team}
          taxes={taxes}
          loadTaxes={loadTaxes}
        />
      )}
      <AppLayout pageId="inboxPageContainer" isDashboardPage>
        <main className="relative grow ">
          <TaskFlyout
            flyoutOpen={flyoutOpen}
            setFlyoutOpen={setFlyoutOpen}
            task={selectedTask}
            setTask={setSelectedTask}
            team={team}
            handleUpdateTask={handleUpdateTask}
            loadData={loadData}
            findTaskType={findTaskType}
            statusOptions={TaskStatusOptions}
            statusDropdownOpen={statusDropdownOpen}
            setStatusDropdownOpen={setStatusDropdownOpen}
            toggleDropdown={toggleDropdown}
            categories={accountCategories}
            localUser={localUser}
            vendors={vendors}
            setVendors={setVendors}
            allExpenseCategories={expenseCategories}
            setTransactionsModalOpen={setTransactionsModalOpen}
            transactionsModalOpen={transactionsModalOpen}
            bill={activeBill}
            setBill={setActiveBill}
            accessType={accessType}
            statusLoading={statusLoading}
            assigneeLoading={assigneeLoading}
            priorityLoading={priorityLoading}
            projects={projects}
            setEditTaskModalOpen={setEditTaskModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            setActiveAttachment={setActiveAttachment}
            deleteModalOpen={deleteModalOpen}
            selectedTaskAttachments={selectedTaskAttachments}
            setSelectedTaskAttachments={setSelectedTaskAttachments}
            attachmentsLoading={attachmentsLoading}
            uploadTaskAttachment={uploadTaskFile}
            setReceiptModalOpen={setAttachmentModalOpen}
            receiptModalOpen={attachmentModalOpen}
            editTaskModalOpen={editTaskModalOpen}
          />
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            <div className="sm:flex justify-between mb-10">
              <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                Inbox
              </h1>
            </div>
            {isLoading ? (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8 w-full">
                <Loader />
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-start gap-[1.875rem]">
                <div className="flex items-center gap-[3.75rem] w-full h-10 border-b-2 border-[#D0D5DD]">
                  <div
                    className={`flex items-center gap-2.5 pb-1 ${
                      inboxCount?.newTaskCount > 0
                        ? 'translate-y-[3px]'
                        : 'translate-y-[5px]'
                    }  cursor-pointer ${
                      activeTab === 'inbox'
                        ? 'border-b-3 border-indigo-500'
                        : ''
                    }`}
                    onClick={() => setActiveTab('inbox')}
                    role="button"
                    tabIndex="0"
                  >
                    <p
                      className={`text-[1rem] leading-6 ${
                        activeTab === 'inbox'
                          ? 'text-indigo-500 font-semibold'
                          : 'text-slate-600'
                      }`}
                    >
                      Tasks
                    </p>
                    {inboxCount?.newTaskCount > 0 && (
                      <div
                        className={`text-white font-semibold rounded-[0.313rem] w-[1.75rem] h-[1.75rem] flex items-center justify-center ${
                          activeTab === 'inbox'
                            ? 'bg-indigo-500'
                            : 'bg-slate-600'
                        }`}
                      >
                        {inboxCount?.newTaskCount}
                      </div>
                    )}
                  </div>
                  <div
                    className={`flex items-center gap-2.5 pb-1 ${
                      inboxCount?.newNotificationCount > 0
                        ? 'translate-y-[3px]'
                        : 'translate-y-[5px]'
                    } cursor-pointer ${
                      activeTab === 'notifications'
                        ? 'border-b-3 border-indigo-500'
                        : ''
                    }`}
                    onClick={() => setActiveTab('notifications')}
                    role="button"
                    tabIndex="0"
                  >
                    <p
                      className={`text-[1rem] leading-6 ${
                        activeTab === 'notifications'
                          ? 'text-indigo-500 font-semibold'
                          : 'text-slate-600'
                      }`}
                    >
                      Notifications
                    </p>
                    {inboxCount?.newNotificationCount > 0 && (
                      <div
                        className={`text-white font-semibold rounded-[0.313rem] w-[1.75rem] h-[1.75rem] flex items-center justify-center ${
                          activeTab === 'notifications'
                            ? 'bg-indigo-500'
                            : 'bg-slate-600'
                        }`}
                      >
                        {inboxCount?.newNotificationCount}
                      </div>
                    )}
                  </div>
                </div>
                {activeTab === 'inbox' ? (
                  <Tasks
                    statusOptions={TaskStatusOptions}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    assignedFilter={assignedFilter}
                    setAssignedFilter={setAssignedFilter}
                    priorityFilter={priorityFilter}
                    setPriorityFilter={setPriorityFilter}
                    statusLoading={statusLoading}
                    assigneeLoading={assigneeLoading}
                    priorityLoading={priorityLoading}
                    statusDropdownOpen={statusDropdownOpen}
                    toggleDropdown={toggleDropdown}
                    tasks={tasks}
                    team={team}
                    handleUpdateTask={handleUpdateTask}
                    setFlyoutOpen={setFlyoutOpen}
                    setSelectedTask={setSelectedTask}
                    findTaskType={findTaskType}
                    setModalOpen={setModalOpen}
                    localUser={localUser}
                    loadTasks={loadTasks}
                    tableLoading={tableLoading}
                    accessType={accessType}
                    localTasksLimit={localTasksLimit}
                    setLocalTasksLimit={setLocalTasksLimit}
                    setDeleteModalOpen={setDeleteModalOpen}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                    handleSortBy={handleSortBy}
                    taskSearchFilter={taskSearchFilter}
                    handleTasksSearch={handleTasksSearch}
                  />
                ) : (
                  <Notifications
                    isLoading={isLoading}
                    notifications={notifications}
                    loadData={loadData}
                    setCurrentTab={setCurrentTab}
                    loadSingleTransaction={loadSingleTransaction}
                    loadSingleTask={loadSingleTask}
                    setTaskFlyoutOpen={setFlyoutOpen}
                    accessType={accessType}
                  />
                )}
              </div>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default Inbox;
