import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAccessType } from '../../../redux/User/reducer';
import {
  OWNER,
  STAFF,
  SUPPORT,
  TEAM_ID,
  TEAM_NAME,
} from '../../../utils/Utils';
import {
  getFirmClients,
  leaveTeamFromFirmApi,
} from '../../../API/backend_helper';
import LeaveModal from '../../manageWorkspaces/manage/LeaveModal';
import { setTeam } from '../../../redux/Team/reducer';
import ClientsTable from './ClientsTable';

const MyClients = ({
  accountantFirmId,
  firm,
  loadAccountantFirm,
  clients,
  getClients,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { localUser } = useSelector((state) => state.User);

  const [loading, setLoading] = useState(false);
  const [clientsLoader, setClientsLoader] = useState(false);
  // const [clients, setClients] = useState([]);
  const [user, setUser] = useState({});
  const [TeamId, setTeamId] = useState('');
  const [deleteWorkspace, setDeleteWorkspace] = useState(null);
  const [leaveTeam, setLeaveTeam] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  // const getClients = async () => {
  //   setClientsLoader(true);
  //   try {
  //     const response = await getFirmClients(accountantFirmId);
  //     setClients(response?.data?.clients);
  //     setClientsLoader(false);
  //   } catch (err) {
  //     setClientsLoader(false);

  //     console.log('err', err);
  //   }
  // };

  const onTeamLeave = async () => {
    setLoading(true);
    try {
      const { value } = leaveTeam;
      await leaveTeamFromFirmApi(accountantFirmId, value);
      localStorage.setItem(TEAM_ID, '');
      localStorage.setItem(TEAM_NAME, '');
      setTeamId('');
      getClients();
      setIsOpenModal(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setIsOpenModal(false);
      console.log('err', err);
    }
  };

  const onTeamChange = (id, teamObj = null) => {
    if (id) {
      localStorage.setItem(TEAM_ID, id);
      localStorage.setItem(TEAM_NAME, teamObj?.name);
      setTeamId(id);
      dispatch(setTeam(teamObj));
      navigate('/');
      // window.location.reload();
    }
  };

  return (
    <div>
      <LeaveModal
        isOpen={isOpenModal}
        title="Leave Workspace"
        description="Are you sure you want to leave selected worksapce(s) ? This action cannot be undone."
        setIsOpen={setIsOpenModal}
        onCompletion={onTeamLeave}
        loading={loading}
        team={leaveTeam}
      />
      <ClientsTable
        setLeaveTeam={setLeaveTeam}
        user={user}
        onTeamChange={onTeamChange}
        setDeleteWorkspace={setDeleteWorkspace}
        setIsOpenModal={setIsOpenModal}
        clients={clients}
        loading={clientsLoader}
        accountantFirmId={accountantFirmId}
        firm={firm}
        loadAccountantFirm={loadAccountantFirm}
      />
    </div>
  );
};

export default MyClients;
