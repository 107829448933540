import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  TEAM_CURRENCY,
  VIEWER,
  getCurrencySymbol,
  getCurrencySymbolFromIso,
} from '../../../utils/Utils';
import { Cross, EditIcon, TableEditIcon } from '../Svg';
import { InfoIcon, Loader } from '../../../components/Svg';
import Tooltip from '../../../components/Tooltip';

const TimeTab = ({
  timeEntries,
  project,
  billableHours,
  nonBillableHours,
  accessType,
  setTimeTrackingModalOpen,
  handleUpdateTimeEntry,
  setActiveEntry,
  formatMinutes,
  timeTrackingModalOpen,
  activeEntry,
  currencies,
  projectStat,
}) => {
  const { team } = useSelector((state) => state.Team);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [timeEntryLoading, setTimeEntryLoading] = useState({});

  const handleBillableCheck = async (e, entry) => {
    const { checked } = e.target;
    setTimeEntryLoading((prev) => ({ ...prev, [entry.id]: true }));
    const updatedEntry = { ...entry, isBillable: checked };
    await handleUpdateTimeEntry(updatedEntry);
    setTimeEntryLoading((prev) => ({ ...prev, [entry.id]: false }));
  };

  return (
    <>
      <div className="w-full flex mt-4 justify-between">
        <div>
          <div className="flex gap-10">
            {/* <div className="flex flex-col">
              <p className="text-slate-500 text-sm">Total Hours</p>
              <p className="text-slate-600 text-lg font-medium">
                {formatMinutes(
                  parseFloat(projectStat?.billableMinutes) +
                    parseFloat(projectStat?.unBillableMinutes),
                )}
              </p>
            </div> */}
            <div className="flex flex-col">
              <p className="text-slate-500 text-sm">Billable Hours</p>
              <p className="text-slate-600 text-lg font-medium">
                {formatMinutes(projectStat?.billableMinutes)}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-slate-500 text-sm">Unbillable Hours</p>
              <p className="text-slate-600 text-lg font-medium">
                {formatMinutes(Number(projectStat?.unBillableMinutes))}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-slate-500 text-sm">Billable Amount</p>
              <p className="text-slate-600 text-lg font-medium">
                {`${getCurrencySymbol(teamDefaultCurrency?.toLowerCase())}`}
                {parseFloat(projectStat?.billableAmount)?.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-slate-500 text-sm">Labor Cost</p>
              <p className="text-slate-600 text-lg font-medium">
                {`${getCurrencySymbol(teamDefaultCurrency?.toLowerCase())}`}
                {parseFloat(projectStat?.laborCost)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-slate-500 text-sm flex items-center gap-3">
                Profit
                <Tooltip
                  contentClassName="w-[300px] text-wrap border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowLeft"
                  position="right"
                  content="Profit is calculated by comparing the total service billables to the labor costs. For hourly employees, labor costs are calculated based on their hourly wage. For salaried employees, we estimate their hourly rate by dividing their annual salary by 2,000 hours (based on an average of 250 working days per year at 8 hours per day)."
                >
                  <InfoIcon />
                </Tooltip>
              </p>
              <p className="text-slate-600 text-lg font-medium">
                {`${getCurrencySymbol(teamDefaultCurrency?.toLowerCase())}`}
                {parseFloat(projectStat?.profit)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            {/* <div className="flex flex-col">
              <p className="text-slate-500 text-sm">Labor Hours</p>
              <p className="text-slate-600 text-lg font-medium">
                {formatMinutes(Number(projectStat?.laborMinutes))}
              </p>
            </div> */}
          </div>
        </div>

        <div className="flex">
          {accessType !== VIEWER && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setTimeTrackingModalOpen(true);
              }}
              className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
            >
              <Cross />
              Add Time
            </button>
          )}
        </div>
      </div>
      <div className="bg-white flex-1 dark:bg-slate-800  rounded-sm overflow-auto mt-6">
        <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
          {/* Table header */}
          <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
            <tr>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap w-[20%]">
                <div className="font-medium text-left">Person</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap w-[30%]">
                <div className="font-medium text-left">Service</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap w-[30%]">
                <div className="font-medium text-left">Notes</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap w-[10%]">
                <div className="font-medium text-left">Billable</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap w-[10%]">
                <div className="font-medium text-left">Time</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap" />
            </tr>
          </thead>
          {timeEntries?.length ? (
            <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
              {timeEntries.map((group, index) => (
                <React.Fragment key={index}>
                  <tr className="bg-slate-100">
                    <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap font-semibold">
                      {group?.date === 'Today'
                        ? 'Today'
                        : moment.utc(group?.date).format('MMM DD, YYYY')}
                    </td>
                    <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap font-semibold" />
                    <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap font-semibold" />
                    <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap font-semibold">
                      {getCurrencySymbolFromIso(
                        teamDefaultCurrency,
                        currencies,
                      )}
                      {parseFloat(group?.totalBillableAmount).toFixed(2)}
                    </td>
                    <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap font-semibold">
                      {formatMinutes(group?.totalMinutes)}
                    </td>
                    <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap font-semibold" />
                  </tr>
                  {group?.entries.map((entry) => (
                    <tr key={entry.id} className="">
                      <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left">
                        <div className="flex items-center gap-2">
                          <div className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600">
                            <div className="text-black text-xs font-normal uppercase">
                              {entry?.people
                                ? `${entry?.people?.firstName} ${
                                    entry?.people?.lastName
                                  }`
                                    .replace(/[^\w\s]/gi, '')
                                    .split(' ')
                                    .filter(Boolean)
                                    .slice(0, 2)
                                    .map((word) => word.charAt(0))
                                    .join('')
                                : ''}
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-slate-600 text-base">{`${entry?.people?.firstName} ${entry?.people?.lastName}`}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left">
                        <p className="text-slate-600 text-base">
                          {entry?.productService?.name}{' '}
                          {entry?.productService?.unitPrice &&
                            entry?.isBillable &&
                            `(${getCurrencySymbolFromIso(
                              teamDefaultCurrency,
                              currencies,
                            )}${parseFloat(
                              entry?.productService?.unitPrice,
                            ).toFixed(2)}/${
                              entry?.productService?.pricingMethod === 'item'
                                ? 'Unit'
                                : 'Hour'
                            })`}
                        </p>
                      </td>
                      <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left">
                        <p className="text-slate-600 text-base">
                          {entry?.notes}
                        </p>
                      </td>
                      <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap">
                        {timeEntryLoading[entry.id] ? (
                          <Loader width="w-4" height="h-4" />
                        ) : (
                          <div className="flex items-center gap-2">
                            <input
                              id="billable"
                              name="billable"
                              type="checkbox"
                              className="h-4 w-4 form-checkbox cursor-pointer"
                              onChange={(e) => {
                                handleBillableCheck(e, entry);
                              }}
                              checked={entry?.isBillable}
                            />
                            {entry?.isBillable &&
                              entry?.productService?.unitPrice && (
                                <p className="text-slate-600 text-sm">
                                  {getCurrencySymbolFromIso(
                                    teamDefaultCurrency,
                                    currencies,
                                  )}
                                  {parseFloat(
                                    parseFloat(
                                      entry?.productService?.unitPrice,
                                    ) * parseFloat(entry?.minutes / 60),
                                  ).toFixed(2)}
                                </p>
                              )}
                          </div>
                        )}
                      </td>
                      <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap ">
                        {formatMinutes(entry?.minutes)}
                      </td>
                      <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 whitespace-nowrap ">
                        <div className="w-full h-full flex justify-end">
                          <Tooltip
                            content="Edit Entry"
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative right-[1rem]"
                          >
                            <div
                              className="cursor-pointer mr-4"
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveEntry(entry);
                                setTimeTrackingModalOpen(true);
                              }}
                            >
                              <TableEditIcon />
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          ) : (
            <></>
          )}
        </table>
        {!timeEntries?.length > 0 && (
          <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
            No Time Entries Found
          </h6>
        )}
      </div>
    </>
  );
};

export default TimeTab;
