import BarChart from 'components/BarChart';
import { Loader } from 'components/Svg';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  formatNumber,
  getCurrencySymbolFromIso,
  TEAM_CURRENCY,
} from 'utils/Utils';

const CashFlowChart = ({ overviewLoadings, cashFlowData, cashStat }) => {
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const DefaultYear = moment().format('MMMM,YYYY');
  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const chartData = useMemo(
    () =>
      Object.keys(cashFlowData?.monthOrDaylyData?.expenses || {})?.length > 0
        ? {
            labels: Object.keys(
              cashFlowData?.monthOrDaylyData?.income || {},
            )?.map(
              (key, index) =>
                `${(index + 1)?.toString()?.padStart(2, '0')}-01-${DefaultYear?.split(',')?.[1]}`,
            ),
            datasets: [
              // Green Bars => Income
              {
                label: 'Inflow',
                data: Object.keys(
                  cashFlowData?.monthOrDaylyData?.income || {},
                )?.map(
                  (key, index) =>
                    cashFlowData?.monthOrDaylyData?.income?.[
                      `month_${index + 1}`
                    ]?.total,
                ),
                hoverBackgroundColor: '#78BD4F',
                barPercentage: 0.66,
                categoryPercentage: 0.66,
                borderRadius: 4,
                borderWidth: 2,
                borderColor: '#78BD4F',
              },
              // Orange bars => Expense
              {
                label: 'Outflow',
                data: Object.keys(
                  cashFlowData?.monthOrDaylyData?.expenses || {},
                )?.map(
                  (key, index) =>
                    cashFlowData?.monthOrDaylyData?.expenses?.[
                      `month_${index + 1}`
                    ]?.total,
                ),
                hoverBackgroundColor: '#E48642',
                barPercentage: 0.66,
                categoryPercentage: 0.66,
                borderRadius: 4,
                borderWidth: 2,
                borderColor: '#E48642',
              },
            ],
          }
        : null,
    [cashFlowData],
  );

  return (
    <div className="h-fit p-6 rounded-[12px] border border-[#D0D5DD] ">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-[22px] text-[#666] font-medium">
          Total Cash In and Out{' '}
        </h3>
      </div>
      {overviewLoadings?.cashFlow || overviewLoadings?.cashStat ? (
        <div className="flex flex-col h-[400px] justify-center items-center">
          <Loader height="h-8 w-8" />
          <p>Fetching data please wait...</p>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 ">
            <div className="py-5 border-r border-[#D0D5DD]">
              <p className="text-base text-[#667085] mb-3">Current Cash</p>
              <h3 className="text-[28px] font-semibold text-[#101828]">
                {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {formatNumber(cashStat?.currentCash)}
              </h3>
            </div>
            <div className="py-5 md:border-r border-[#D0D5DD]">
              <p className="text-base text-[#667085] mb-3">
                Net Burn (3 month avg)
              </p>
              <h3 className="text-[28px] font-semibold text-[#101828]">
                {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {formatNumber(cashStat?.expense)}
              </h3>
            </div>
            <div className="py-5 border-r md:border-r-0 xl:border-r border-[#D0D5DD]">
              <p className="text-base text-[#667085] mb-3">Runway Run Rate</p>
              <h3 className="text-[28px] font-semibold text-[#101828]">
                {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {formatNumber(cashStat?.runWay)}
              </h3>
            </div>
            <div className="py-5">
              <p className="text-base text-[#667085] mb-3">Total Customers</p>
              <h3 className="text-[28px] font-semibold text-[#101828]">
                {cashStat?.numberOfCustomers}
              </h3>
            </div>
          </div>
          {chartData && (
            <BarChart chartData={chartData} width={595} height={248} />
          )}
        </>
      )}
    </div>
  );
};

export default CashFlowChart;
