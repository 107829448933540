import React, { useEffect, useState } from 'react';
import emptyTaxesImg from '../../../images/custom/empty-search.svg';
import TaxModal from './TaxModal';
import {
  getTaxes,
  createTax,
  updateTax,
  deleteTax,
} from '../../../API/backend_helper';
import Tooltip from '../../../components/Tooltip';
import DeleteTaxModal from './DeleteTaxModal';

const Taxes = () => {
  const [title, setTitle] = useState('Taxes');
  const [formOpen, setFormOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [activeTax, setActiveTax] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [taxes, setTaxes] = useState([]);

  useEffect(() => {
    handleGetTaxes();
  }, []);

  const handleGetTaxes = async () => {
    setIsLoading(true);
    const response = await getTaxes();
    if (response) {
      setTaxes(response.data?.taxes);
    }
    setIsLoading(false);
  };

  const handleAddTax = async (tax) => {
    const response = await createTax(tax);
    handleGetTaxes();
  };

  const handleUpdateTax = async (data, id) => {
    const response = await updateTax(data, id);
    handleGetTaxes();
  };

  const handleDeleteTax = async (tax) => {
    const response = await deleteTax(tax);
    handleGetTaxes();
    setDeleteOpen(false);
  };

  return (
    <>
      <DeleteTaxModal
        isOpen={deleteOpen}
        setIsOpen={setDeleteOpen}
        activeTax={activeTax}
        handleDeleteTax={handleDeleteTax}
      />
      <TaxModal
        isOpen={formOpen}
        setIsOpen={setFormOpen}
        activeTax={activeTax}
        handleAddTax={handleAddTax}
        handleUpdateTax={handleUpdateTax}
      />
      <div className="w-full flex justify-between items-center mb-10">
        <h1 className="text-2xl  text-[#101828B2] dark:text-slate-100 font-medium">
          {title}
        </h1>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setActiveTax(null);
            setFormOpen(true);
          }}
          className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
          type="button"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setFormOpen(true)}
          >
            <path
              d="M10 4.16699V15.8337M4.16669 10.0003H15.8334"
              stroke="white"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Add a Tax
        </button>
      </div>
      {isLoading ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <div className="bg-white flex-1">
          <div className="overflow-x-auto h-full">
            {taxes?.length > 0 && (
              <table className="table-auto w-full dark:text-slate-300">
                <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                  <tr className="w-full py-[1.438rem] px-[1.375rem] flex items-center justify-between">
                    <th className="">
                      <div className="text-left w-[12rem] font-medium">
                        Name
                      </div>
                    </th>
                    <th className="">
                      <div className="text-left w-[8.75rem] font-medium">
                        Type
                      </div>
                    </th>
                    <th className="">
                      <div className="text-left w-[8.75rem] font-medium">
                        Percentage
                      </div>
                    </th>
                    <th className="">
                      <div className="text-left w-[8.75rem] font-medium">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                  {taxes?.map((tax) => (
                    <tr
                      key={tax?.id}
                      className="w-full pl-[1.375rem] pr-[1.125rem] flex items-center justify-between h-20 hover:bg-[#a0cd85] hover:bg-opacity-5"
                    >
                      <td className="">
                        <div className="text-left w-[12rem] font-medium">
                          {tax?.name}
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="text-left w-[8.75rem] font-medium">
                          Sales Tax
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="text-left w-[8.75rem] font-medium">
                          {tax?.percentage}%
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="text-left w-[8.75rem] font-medium inline-flex items-center gap-3.5">
                          <Tooltip
                            content={
                              !tax?.editable ? 'Not Editable' : 'Edit Tax'
                            }
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                          >
                            <button
                              type="button"
                              className="w-[1.625rem] h-[1.625rem] cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
                              disabled={!tax?.editable}
                            >
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => {
                                  setActiveTax(tax);
                                  setFormOpen(true);
                                }}
                              >
                                <path
                                  d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                  fill="white"
                                  stroke="#D0D5DD"
                                  strokeOpacity="0.6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                                  stroke="#858D9D"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                          <Tooltip
                            content={
                              !tax?.editable ? 'Not Deletable' : 'Delete Tax'
                            }
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                          >
                            <button
                              type="button"
                              className="w-[1.625rem] h-[1.625rem] cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
                              disabled={!tax?.editable}
                            >
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => {
                                  setActiveTax(tax);
                                  setDeleteOpen(true);
                                }}
                              >
                                <path
                                  d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                  fill="white"
                                  stroke="#D0D5DD"
                                  strokeOpacity="0.6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                                  stroke="#858D9D"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.693 16.3574H14.8505"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.1552 13.7656H15.3948"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {taxes?.length === 0 && !isLoading && (
              <div className="flex flex-col gap-5 items-center justify-center w-full h-full">
                <h6 className="text-center mt-4">No Taxes Found</h6>
                <img
                  src={emptyTaxesImg}
                  alt="No Taxes found"
                  className="h-[12.5rem]"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Taxes;
