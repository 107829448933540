import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import SearchForm from 'partials/actions/SearchForm';
import { toast } from 'react-toastify';
import DropdownFull from '../../components/DropdownFull';
import { TrashSquare } from './Svg';
import { Loader } from '../../components/Svg';
import Tooltip from '../../components/Tooltip';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import {
  getStringSegments,
  TaskStatusOptions,
  trimText,
  VIEWER,
} from '../../utils/Utils';
import PaginationClassic from '../../components/PaginationClassic';
import TasksDropdown from './TasksDropdown';
import AssigneeDropdown from './AssigneeDropdown';

const Tasks = ({
  statusOptions = TaskStatusOptions,
  statusDropdownOpen,
  setStatusDropdownOpen,
  statusLoading,
  toggleDropdown,
  handleUpdateTask,
  setFlyoutOpen,
  setSelectedTask,
  setModalOpen,
  tasks,
  isLoading,
  team,
  statusFilter,
  setStatusFilter,
  assignedFilter,
  setAssignedFilter,
  findTaskType,
  loadTasks,
  tableLoading,
  accessType,
  localUser,
  localTasksLimit,
  setLocalTasksLimit,
  priorityFilter,
  setPriorityFilter,
  setDeleteModalOpen,
  assigneeLoading,
  priorityLoading,
  orderBy,
  orderDirection,
  handleSortBy,
  taskSearchFilter,
  handleTasksSearch,
  hideTaskType = false,
  showAccountantAssignee = false,
  showClient = false,
  clients = [],
  ids = ['inboxPageContainer', 'tasksTableContainer'],
}) => {
  const [, setSearchParams] = useSearchParams();
  const [taskNameLength, setTaskNameLength] = useState(50);

  const handleResize = () => {
    setTaskNameLength(
      parseInt(
        (document.getElementById('taskNameCellId')?.clientWidth - 30) / 4,
      ),
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [tasks?.tasks?.length]);

  const UsersOtherThenMe =
    team?.users?.filter((user) => user?.id !== localUser?.id)?.length > 0
      ? [
          { name: 'dotted-line-item', id: 'dotted-line' },
          ...team?.users
            ?.filter((user) => user?.id !== localUser?.id)
            ?.map((user) => ({
              name: `${user?.firstName || ''}  ${user?.lastName || ''}`,
              id: user?.id,
            })),
        ]
      : [];

  const { page, totalCount } = tasks;

  const { protocol, host } = window.location;

  const copyToClipboard = (id) => {
    navigator.clipboard
      .writeText(
        `${protocol}//${host}/ws/${team?.billEmail?.replace('@bills.getcount.com', '')}/task/${id}`,
      )
      .then(() => {
        toast.success('Copied');
        // setCopied(true);
        // setTimeout(() => {
        //   setCopied(false);
        // }, 3000);
        // setIsOpen(false);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  return (
    <>
      <div className="w-full flex flex-col items-end gap-2">
        <div className="w-full flex flex-nowrap justify-end items-start gap-6 gap-y-2.5 self-stretch">
          <div className="flex flex-wrap items-center justify-between gap-4 w-full">
            <div className="flex gap-2 sm:gap-4 flex-wrap">
              <div className="sm:w-[40%] xl:w-[20%] w-full min-w-[250px]">
                <DropdownFull
                  options={[
                    { name: 'All Priorities', id: '' },
                    { name: 'High', id: 'high' },
                    { name: 'Medium', id: 'medium' },
                    { name: 'Low', id: 'low' },
                  ]}
                  height="h-11 !border-indigo-500 !bg-[#E4864205]"
                  selected={priorityFilter}
                  setSelected={(value) => {
                    setPriorityFilter(value);
                    loadTasks({ priority: value, loading: true, page: 1 });
                  }}
                  name="name"
                  placeholder="Priority By"
                  scrollIntoView={false}
                  buttonTextClassSub="!text-indigo-500"
                  chevronClass="!text-indigo-500"
                />
              </div>
              <div className="sm:w-[40%] xl:w-[20%] w-full min-w-[250px]">
                <BasicCheckboxDropdown
                  selectedOptions={statusFilter}
                  setSelectedOptions={setStatusFilter}
                  options={[
                    { label: 'Not Started', value: 'notStarted' },
                    { label: 'In Progress', value: 'inProgress' },
                    { label: 'Review', value: 'review' },
                    { label: 'Complete', value: 'completed' },
                  ]}
                  label="Status"
                  type="status"
                  onApply={(values) => {
                    setStatusFilter(values);
                    loadTasks({ status: values, loading: true, page: 1 });
                  }}
                  apiFunc={loadTasks}
                  apiFilter={statusFilter}
                  onClear={() => {
                    setStatusFilter([]);
                    loadTasks({ status: [], loading: true, page: 1 });
                  }}
                  width="w-full"
                  height="h-11"
                  inbox
                />
              </div>
              {!showClient ? (
                <div className="sm:w-[40%] xl:w-[20%] w-full min-w-[250px]">
                  <DropdownFull
                    options={[
                      { name: 'All Assignments', id: '' },
                      { name: 'Assigned To Me', id: 'to-me' },
                      { name: 'Tasks created by me', id: 'by-me' },
                      ...UsersOtherThenMe,
                    ]}
                    height="h-11 !border-indigo-500 !bg-[#E4864205]"
                    selected={assignedFilter}
                    setSelected={(value) => {
                      setAssignedFilter(value);
                      loadTasks({ assigned: value, loading: true, page: 1 });
                    }}
                    name="name"
                    placeholder="Assigned"
                    scrollIntoView={false}
                    buttonTextClassSub="!text-indigo-500"
                    chevronClass="!text-indigo-500"
                  />
                </div>
              ) : (
                <div className="sm:w-[40%] xl:w-[20%] w-full min-w-[250px]">
                  <DropdownFull
                    options={[{ name: 'All Clients', id: '' }, ...clients]}
                    height="h-11 !border-indigo-500 !bg-[#E4864205]"
                    selected={assignedFilter}
                    setSelected={(value) => {
                      setAssignedFilter(value);
                      loadTasks({ clients: value, loading: true, page: 1 });
                    }}
                    name="name"
                    placeholder="All Clients"
                    scrollIntoView={false}
                    buttonTextClassSub="!text-indigo-500"
                    chevronClass="!text-indigo-500"
                  />
                </div>
              )}
            </div>
            <div className="flex flex-wrap gap-4 ml-auto">
              <SearchForm
                searchVal={taskSearchFilter}
                setSearchVal={handleTasksSearch}
                placeholder="Search"
                height="!h-12 pr-6"
                crossButtonClassName="!mt-2"
              />
              <div className="bg-[#D0D5DD] min-w-px h-11" />
              {accessType !== VIEWER && (
                <button
                  className="ml-auto bg-indigo-500 text-white text-[16px] leading-6 h-11 w-fit py-2.5 px-[1.125rem] flex items-center justify-center gap-2 rounded-[0.313rem] shadow-sm whitespace-nowrap"
                  onClick={() => setModalOpen(true)}
                  type="button"
                >
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5013 4.16699V15.8337M4.66797 10.0003H16.3346"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Add Task
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Table */}
      <div
        className="w-full items-start flex overflow-x-auto scrollbar overflow-y-visible min-h-[50vh]"
        id="tasksTableContainer"
      >
        {!isLoading && tasks?.tasks?.length === 0 && !tableLoading ? (
          <div className="flex w-full items-center justify-center flex-col mt-10">
            <p className="text-slate-600 text-[20px] text-center">
              No Pending Tasks
            </p>
            <p className="text-slate-600 text-[16px] text-center">
              You don't have any tasks requiring action right now
            </p>
          </div>
        ) : (
          <table className="table-fixed w-full dark:text-slate-300">
            <thead className="w-full shadow-md rounded-[0.313rem] leading-[1.125rem] text-slate-600">
              <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                {showClient ? (
                  <th className="px-2 font-medium first:pl-5 py-3 whitespace-nowrap text-left w-[150px]">
                    <div
                      className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
                      onClick={() => handleSortBy('teamId')}
                      role="button"
                      tabIndex="0"
                    >
                      <span className="text-slate-600 font-medium leading-[18px]">
                        Client
                      </span>
                      {orderBy === 'teamId' && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          width="12"
                          viewBox="0 0 384 512"
                          className={`w-4 h-4 ${
                            orderDirection === 'asc' ? 'rotate-180' : ''
                          } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                        >
                          <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                        </svg>
                      )}
                    </div>
                  </th>
                ) : (
                  <th className="pl-4 text-left w-[105px] 3xl:w-[120px] px-2 font-medium first:pl-5 py-3 whitespace-nowrap">
                    <div
                      className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                    hover:text-slate-800 dark:hover:text-slate-200
                "
                      onClick={() => handleSortBy('customId')}
                      role="button"
                      tabIndex="0"
                    >
                      <span className="text-slate-600 font-medium leading-[18px]">
                        Task Id
                      </span>
                      {orderBy === 'customId' && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          width="12"
                          viewBox="0 0 384 512"
                          className={`w-4 h-4 ${
                            orderDirection === 'asc' ? 'rotate-180' : ''
                          } shrink-0 fill-current  text-slate-400
                       dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                        >
                          <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                        </svg>
                      )}
                    </div>
                  </th>
                )}

                <th
                  className="px-2 font-medium text-left first:pl-4 py-3 whitespace-nowrap xl:w-full w-full md:w-[250px] 3xl:w-[400px]"
                  id="taskNameCellId"
                >
                  <div
                    className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
                    onClick={() => handleSortBy('name')}
                    role="button"
                    tabIndex="0"
                  >
                    <span className="text-slate-600 font-medium leading-[18px]">
                      Task Name
                    </span>
                    {orderBy === 'name' && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        width="12"
                        viewBox="0 0 384 512"
                        className={`w-4 h-4 ${
                          orderDirection === 'asc' ? 'rotate-180' : ''
                        } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                      >
                        <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                      </svg>
                    )}
                  </div>
                </th>
                {!hideTaskType && (
                  <th className="pl-4 text-left w-[120px] px-2 font-medium first:pl-5 py-3 whitespace-nowrap  hidden md:table-cell">
                    <div
                      className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
                      onClick={() => handleSortBy('type')}
                      role="button"
                      tabIndex="0"
                    >
                      <span className="text-slate-600 font-medium leading-[18px]">
                        Task Type
                      </span>
                      {orderBy === 'type' && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          width="12"
                          viewBox="0 0 384 512"
                          className={`w-4 h-4 ${
                            orderDirection === 'asc' ? 'rotate-180' : ''
                          } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                        >
                          <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                        </svg>
                      )}
                    </div>
                  </th>
                )}
                <th className="px-2 font-medium first:pl-5 py-3 whitespace-nowrap text-left w-[140px] hidden md:table-cell">
                  <div
                    className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
                    onClick={() => handleSortBy('deadlineDate')}
                    role="button"
                    tabIndex="0"
                  >
                    <span className="text-slate-600 font-medium leading-[18px]">
                      Deadline Date
                    </span>
                    {orderBy === 'deadlineDate' && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        width="12"
                        viewBox="0 0 384 512"
                        className={`w-4 h-4 ${
                          orderDirection === 'asc' ? 'rotate-180' : ''
                        } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                      >
                        <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                      </svg>
                    )}
                  </div>
                </th>
                <th className="px-2 font-medium first:pl-5 py-3 whitespace-nowrap text-left w-[140px] hidden md:table-cell">
                  <div
                    className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
                    onClick={() => handleSortBy('priority')}
                    role="button"
                    tabIndex="0"
                  >
                    <span className="text-slate-600 font-medium leading-[18px]">
                      Priority
                    </span>
                    {orderBy === 'priority' && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        width="12"
                        viewBox="0 0 384 512"
                        className={`w-4 h-4 ${
                          orderDirection === 'asc' ? 'rotate-180' : ''
                        } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                      >
                        <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                      </svg>
                    )}
                  </div>
                </th>
                <th className="px-2 font-medium first:pl-5 py-3 whitespace-nowrap text-left w-[105px] 3xl:w-[120px]">
                  <div
                    className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
                    onClick={() => handleSortBy('assigneeId')}
                    role="button"
                    tabIndex="0"
                  >
                    <span className="text-slate-600 font-medium leading-[18px]">
                      Assignee
                    </span>
                    {orderBy === 'assigneeId' && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        width="12"
                        viewBox="0 0 384 512"
                        className={`w-4 h-4 ${
                          orderDirection === 'asc' ? 'rotate-180' : ''
                        } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                      >
                        <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                      </svg>
                    )}
                  </div>
                </th>

                <th className="px-2 font-medium first:pl-5 py-3 whitespace-nowrap text-left w-[160px] hidden md:table-cell">
                  <div
                    className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
                    onClick={() => handleSortBy('status')}
                    role="button"
                    tabIndex="0"
                  >
                    <span className="text-slate-600 font-medium leading-[18px]">
                      Status
                    </span>
                    {orderBy === 'status' && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        width="12"
                        viewBox="0 0 384 512"
                        className={`w-4 h-4 ${
                          orderDirection === 'asc' ? 'rotate-180' : ''
                        } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                      >
                        <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                      </svg>
                    )}
                  </div>
                </th>
                <th className="px-2 font-medium first:pl-5 py-3 whitespace-nowrap text-left w-[120px] 3xl:w-[120px] hidden md:table-cell">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {tableLoading ? (
                <tr className="w-full ">
                  <td colSpan={showClient ? 8 : 7} className="text-center">
                    <div className="flex justify-center w-full mt-10">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : (
                tasks?.tasks?.map((task) => (
                  <tr
                    key={task?.id}
                    className="cursor-pointer text-base w-full text-slate-600 pl-[1.375rem]  pr-[1.125rem] h-20  hover:bg-[#a0cd85] hover:bg-opacity-5"
                    onClick={() => {
                      setSelectedTask(task);
                      setFlyoutOpen(true);
                      setSearchParams({
                        id: task?.id,
                      });
                    }}
                  >
                    {showClient ? (
                      <td className="pl-5 px-2">
                        <div>
                          {clients?.find?.((c) => c?.id === task?.teamId)?.name}
                        </div>
                      </td>
                    ) : (
                      <td className="pl-5">
                        {task?.customId || `TODO-${task?.id}`}
                      </td>
                    )}
                    <td className="text-left pl-4 px-2 break-words ">
                      <Tooltip
                        tooltipShow={task?.name?.length > taskNameLength}
                        content={
                          task?.name?.length > 30
                            ? (() => {
                                const segments = getStringSegments(
                                  task?.name,
                                  60,
                                );
                                return segments.map((segment, index) => (
                                  <p key={index}>{segment}</p>
                                ));
                              })()
                            : task?.name
                        }
                        position={window.innerWidth > 767 ? 'right' : ''}
                        contentClassName={`border-none rounded-[10px] overflow-visible text-sm md:text-base text-[#667085] relative ${window.innerWidth > 767 ? 'tooltipArrowLeft' : 'tooltipArrowDown'}`}
                      >
                        {trimText(task?.name || '', taskNameLength)}
                      </Tooltip>
                    </td>
                    {!hideTaskType && (
                      <td className="pl-4 hidden md:table-cell">
                        {findTaskType(task?.type)}
                      </td>
                    )}
                    <td className="px-2 hidden md:table-cell">
                      {task?.deadlineDate
                        ? moment(task?.deadlineDate).format('MMM DD, YYYY')
                        : 'No Deadline'}
                      {task?.status !== 'completed' &&
                        moment().isAfter(
                          moment(task.deadlineDate).add(1, 'days'),
                        ) && <p className="text-rose-400 text-sm">Overdue</p>}
                    </td>

                    <td className="px-2 pr-4 hidden md:table-cell">
                      <div className="flex items-center justify-start ml-1 gap-2">
                        {priorityLoading[task?.id] ? (
                          <div className="w-full flex">
                            <Loader width="w-6" height="h-6" />
                          </div>
                        ) : (
                          <DropdownFull
                            noMinW
                            selected={task?.priority}
                            options={[
                              { id: 'high', name: 'High' },
                              { id: 'medium', name: 'Medium' },
                              { id: 'low', name: 'Low' },
                            ]}
                            name="name"
                            height="h-10 min-w-[120px]"
                            priorityDot
                            isTableDropdown
                            bottomOfTable
                            ids={ids}
                            transitionClass="min-w-fit"
                            setSelected={(value) =>
                              handleUpdateTask(
                                task?.id,
                                {
                                  ...task,
                                  priority: value,
                                },
                                'priority',
                              )
                            }
                          />
                        )}
                      </div>
                    </td>
                    <td className="px-2 pl-6">
                      {task?.assigneeId ? (
                        assigneeLoading[task?.id] ? (
                          <div className="w-full flex">
                            <Loader width="w-6" height="h-6" />
                          </div>
                        ) : (
                          <AssigneeDropdown
                            options={
                              showAccountantAssignee
                                ? [{ ...task?.assignee }]
                                : team?.users
                            }
                            selected={task?.assigneeId}
                            setSelected={(value, user) =>
                              showAccountantAssignee
                                ? handleUpdateTask(
                                    task?.id,
                                    {
                                      ...task,
                                      assigneeId: value,
                                      assignee: { ...user },
                                    },
                                    'assignee',
                                  )
                                : handleUpdateTask(
                                    task?.id,
                                    {
                                      ...task,
                                      assigneeId: value,
                                    },
                                    'assignee',
                                  )
                            }
                            users
                            isTableDropdown
                            bottomOfTable
                            ids={ids}
                            noMinW
                            height="!border-none !shadow-none hover:!shadow-none !h-fit !w-fit !p-0"
                            inbox
                            showAccountantAssignee={showAccountantAssignee}
                            teamId={task?.teamId}
                          />
                        )
                      ) : (
                        <p className="pl-2">--</p>
                      )}
                    </td>
                    <td className="px-2 hidden md:table-cell">
                      {statusLoading[task?.id] ? (
                        <div className="w-full flex">
                          <Loader width="w-6" height="h-6" />
                        </div>
                      ) : (
                        <TasksDropdown
                          statusOptions={statusOptions}
                          task={task}
                          toggleDropdown={toggleDropdown}
                          handleUpdateTask={handleUpdateTask}
                          statusDropdownOpen={statusDropdownOpen}
                          setStatusDropdownOpen={setStatusDropdownOpen}
                          isTableDropdown
                          bottomOfTable
                          ids={ids}
                        />
                      )}
                    </td>
                    <td className="pl-5 hidden md:table-cell">
                      <div className="flex items-center justify-start gap-2">
                        {accessType !== VIEWER && (
                          <div
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedTask(task);
                              setDeleteModalOpen('task');
                            }}
                            role="button"
                            tabIndex="0"
                          >
                            <Tooltip
                              content="Delete Task"
                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            >
                              <TrashSquare />
                            </Tooltip>
                          </div>
                        )}
                        <Tooltip
                          content="Copy Link"
                          contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                        >
                          <div
                            className={`w-[26px] h-[26px] border  rounded-[8px] inline-flex justify-center items-center `}
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(task?.customId);
                            }}
                            role="button"
                            tabIndex="0"
                          >
                            <svg
                              viewBox="0 0 48 48"
                              height="18px"
                              width="18px"
                              className={`max-h-4  max-w-4 text-[#667085] fill-[#667085] `}
                            >
                              <path fill="none" d="M0 0h48v48H0z" />
                              <path d="M32 2H8C5.79 2 4 3.79 4 6v28h4V6h24V2zm6 8H16c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 32H16V14h22v28z" />
                            </svg>
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </div>
      {!(!isLoading && tasks?.tasks?.length === 0 && !tableLoading) && (
        <div className=" w-full">
          <PaginationClassic
            setLimit={setLocalTasksLimit}
            onPageChange={loadTasks}
            pagination={{
              limit: localTasksLimit,
              page,
              totalRecords: totalCount,
            }}
          />
        </div>
      )}
    </>
  );
};

export default Tasks;
