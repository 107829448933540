import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import {
  addReceipt,
  getReceipt,
  updateReceipt,
  deleteReceipt,
} from '../../API/backend_helper';
import ReceiptModal from './ReceiptModal';
import receiptIcon from '../../images/receipts-icon.png';
import UploadFile from '../../components/UploadFile';
import Tooltip from '../../components/Tooltip';

const ReceiptPanel = ({
  transaction,
  setReceiptModalOpen,
  setActiveReceipt,
  reloadTransaction,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  const [localReceipts, setLocalReceipts] = useState([]);
  const [files, setFiles] = useState([]);
  const [newNotes, setNewNotes] = useState('');
  const [formOpen, setFormOpen] = useState(false);
  const [failedSubmit, setFailedSubmit] = useState(false);
  const [isDeleting, setIsDeleting] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (transaction?.id) {
      fetchReceipts();
    }
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [transaction]);

  const fetchReceipts = async () => {
    try {
      setIsLoading(true);
      const response = await getReceipt(transaction?.id);
      setLocalReceipts(response?.data?.receipts);
      setIsLoading(false);
    } catch (e) {
      console.log('error', e);
    }
  };

  const onCreateReceipt = async (event) => {
    setFailedSubmit(false);
    try {
      if (!newNotes && !files.length) {
        setFailedSubmit(true);
        return;
      }
      event.preventDefault();
      setIsLoading(true);
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('receipt', file);
      });
      formData.append('note', newNotes);
      await addReceipt(transaction?.id, formData);
      setFormOpen(false);
      setFiles([]);
      setNewNotes('');
      fetchReceipts();
      setIsLoading(false);
      await reloadTransaction({ scrollToTop: false });
      setFailedSubmit(false);
    } catch (e) {
      setFailedSubmit(false);
      console.log('error', e);
    }
  };

  const onClose = () => {
    setFiles([]);
    setNewNotes('');
    setFormOpen(false);
  };

  const handleDeleteReceipt = async (receiptId) => {
    try {
      setIsDeleting((prevState) => ({ ...prevState, [receiptId]: true }));
      await deleteReceipt(transaction?.id, receiptId);
      await fetchReceipts();
      setIsDeleting((prevState) => ({ ...prevState, [receiptId]: false }));
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleOpenModal = (receiptId) => {
    setActiveReceipt(localReceipts.find((receipt) => receipt.id === receiptId));
    setReceiptModalOpen(true);
  };

  const formatFileSize = (size) => {
    if (size < 1024) {
      return `${size} B`;
    }
    if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    }
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  };

  const removeFile = (targetIndex) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== targetIndex),
    );
  };

  const imgPreviews = files.map((file, index) => (
    <div
      key={index}
      className="w-full h-[9.5rem] rounded-xl border border-slate-600 mt-4 relative"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        className="absolute top-2 right-2 w-[1.063rem] h-[1.063rem] cursor-pointer"
        onClick={() => removeFile(index)}
      >
        <circle cx="8.5" cy="8.5" r="8.5" fill="black" fillOpacity="0.7" />
        <rect
          x="4.99951"
          y="5.73633"
          width="1.04201"
          height="8.85709"
          rx="0.521006"
          transform="rotate(-45 4.99951 5.73633)"
          fill="white"
        />
        <rect
          x="11.2627"
          y="5"
          width="1.04201"
          height="8.85709"
          rx="0.521006"
          transform="rotate(45 11.2627 5)"
          fill="white"
        />
      </svg>
      {file.type === 'application/pdf' ? (
        <iframe
          src={file.preview}
          className="w-full h-full object-cover rounded-lg"
        />
      ) : (
        <img
          src={file.preview}
          alt="receipt"
          className="w-full h-full object-cover rounded-lg"
        />
      )}
      <div className="absolute bottom-0 left-0 w-full h-[2.375rem] rounded-b-xl bg-black bg-opacity-60 flex justify-between items-center px-4">
        <p className="text-white text-xs leading-5 tracking-[-0.015rem]">
          {file.name.length > 47
            ? `${file.name.substring(0, 47)} ....`
            : file.name}
        </p>
        <p className="text-white text-xs leading-5 tracking-[-0.015rem]">
          {formatFileSize(file.size)}
        </p>
      </div>
    </div>
  ));

  return (
    <div className="mx-[1.563rem] mt-10 bg-white border border-black border-opacity-5 rounded-xl p-2.5 drop-shadow-sm ">
      <div className="flex justify-between items-center self-stretch">
        <p className="text-slate-600 leading-5 font-medium text-base">
          Your Attachments ({localReceipts?.length})
        </p>
        <Tooltip
          content="You do not have permission."
          contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
          size="sm"
          position="bottom"
          tooltipShow={
            !checkPermission(
              PermissionKeys.MANAGE_TRANSACTIONS,
              accessType,
              permissions,
            )
          }
        >
          <button
            className="shadow-sm bg-white border border-indigo-500 rounded-[0.313rem] px-6 py-2.5 text-indigo-500 leading-6 text-[0.938rem] disabled:cursor-not-allowed"
            disabled={
              !checkPermission(
                PermissionKeys.MANAGE_TRANSACTIONS,
                accessType,
                permissions,
              )
            }
            onClick={() => {
              setFormOpen(true);
            }}
            type="button"
          >
            Add an Attachment
          </button>
        </Tooltip>
      </div>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center">
          <svg
            width="32"
            height="32"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-8 h-8 fill-current shrink-0 mt-4"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <>
          {localReceipts.length < 1 && !formOpen && (
            <div className="w-full flex justify-center items-center mt-[1.875rem] gap-5">
              <img src={receiptIcon} alt="receipt icon" />
              <p>No attachment(s) to display here</p>
            </div>
          )}
          {localReceipts.length > 0 &&
            localReceipts.map((receipt) => (
              <div
                key={receipt.id}
                className="border border-slate-600 rounded-md w-full h-[3.125rem] flex justify-between items-center bg-gray-300 bg-opacity-10 pl-[0.313rem] pr-3 py-3 mb-2.5 mt-6"
              >
                <div className="flex items-center gap-2">
                  {receipt?.images[0]?.receiptUrl?.endsWith('.pdf') ? (
                    <div className="border border-slate-600 rounded-[0.313rem] w-[2.438rem] h-[2.438rem] flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="30"
                        width="30"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#e48642"
                          d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <img
                      src={receipt?.images[0]?.receiptUrl}
                      className="border border-slate-600 rounded-[0.313rem] w-[2.438rem] h-[2.438rem]"
                    />
                  )}
                  <p className="text-slate-600 leading-[1.625rem] tracking-[-0.02rem] p-0 m-0">
                    {receipt?.note?.length > 31
                      ? `${receipt?.note?.substring(0, 31)}...`
                      : receipt?.note}
                  </p>
                </div>
                <div className="flex justify-end items-center gap-4">
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                    onClick={() => handleOpenModal(receipt.id)}
                  >
                    <path
                      d="M12.4956 10.0019C12.4956 11.5315 11.2595 12.7675 9.72996 12.7675C8.20038 12.7675 6.96436 11.5315 6.96436 10.0019C6.96436 8.47235 8.20038 7.23633 9.72996 7.23633C11.2595 7.23633 12.4956 8.47235 12.4956 10.0019Z"
                      stroke="#667085"
                      strokeWidth="1.55"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.73046 16.3887C12.4574 16.3887 14.999 14.7818 16.7681 12.0008C17.4633 10.9115 17.4633 9.08065 16.7681 7.99141C14.999 5.21035 12.4574 3.60352 9.73046 3.60352C7.00347 3.60352 4.4619 5.21035 2.69284 7.99141C1.99757 9.08065 1.99757 10.9115 2.69284 12.0008C4.4619 14.7818 7.00347 16.3887 9.73046 16.3887Z"
                      stroke="#667085"
                      strokeWidth="1.55"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {!isDeleting[receipt.id] ? (
                    <button
                      type="button"
                      className="cursor-pointer disabled:cursor-not-allowed"
                      onClick={() => handleDeleteReceipt(receipt.id)}
                      disabled={
                        !checkPermission(
                          PermissionKeys.MANAGE_TRANSACTIONS,
                          accessType,
                          permissions,
                        )
                      }
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.25 4.5H3.75H15.75"
                          stroke="#FF4B4B"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5"
                          stroke="#FF4B4B"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.5 8.25V12.75"
                          stroke="#FF4B4B"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.5 8.25V12.75"
                          stroke="#FF4B4B"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  ) : (
                    <div className="flex items-center justify-center">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="animate-spin w-4 h-4 fill-current shrink-0"
                      >
                        <path
                          d="M14.5 2.41699V7.25033"
                          stroke="#E48642"
                          strokeWidth="2.55882"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.5 21.749V26.5824"
                          stroke="#E48642"
                          strokeWidth="2.55882"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.95703 5.95703L9.37662 9.37662"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.623 19.6211L23.0426 23.0407"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.41699 14.5H7.25033"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21.749 14.5H26.5824"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.95703 23.0407L9.37662 19.6211"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.623 9.37662L23.0426 5.95703"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            ))}
          {formOpen && (
            <form className="mt-[1.875rem]">
              {imgPreviews}
              <UploadFile
                title="Attachments - Upload an Image or a PDF"
                onFilesAdded={(files) =>
                  setFiles((prevFiles) => [...prevFiles, ...files])
                }
                name="receipt"
                id="receipt"
                pdf
              />
              {failedSubmit && (
                <p className="text-sm text-center text-red-500 mt-1">
                  Please provide an attachment or a note.
                </p>
              )}
              <div>
                <label
                  htmlFor="note"
                  className="text-sm font-medium leading-5 text-slate-600"
                >
                  Notes
                </label>
                <textarea
                  name="note"
                  type="text"
                  value={newNotes}
                  onChange={(e) => setNewNotes(e.target.value)}
                  id="note"
                  className="w-full px-4 py-3.5 mt-2.5 text-slate-600 text-base leading-[1.375rem] rounded-[0.313rem]"
                />
              </div>
              <div className="w-full flex flex-row gap-4 flex-end justify-end items-center mt-6">
                <button
                  className=" bg-white px-6 py-2.5 border border-slate-600 text-slate-600 text-base leading-6 rounded-[0.313rem]"
                  type="button"
                  onClick={onClose}
                >
                  Close
                </button>
                <button
                  className="bg-indigo-500 text-white leading-6 px-6 py-2.5 rounded-[0.313rem]"
                  type="button"
                  onClick={onCreateReceipt}
                >
                  Upload
                </button>
              </div>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default ReceiptPanel;
