import React, { useState } from 'react';
import Header from '../../partials/Header';
import Workspaces from './manage';
import InternalVendorFlyout from './internalVendors/InternalVendorFlyout';

const ManageWorkspaces = () => {
  document.title = 'COUNT | Manage Workspaces';
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeVendor, setActiveVendor] = useState(null);
  const [flyoutOpen, setFlyoutOpen] = useState(false);

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {flyoutOpen && (
        <InternalVendorFlyout
          vendor={activeVendor}
          setVendor={setActiveVendor}
          flyoutOpen={flyoutOpen}
          setFlyoutOpen={setFlyoutOpen}
        />
      )}
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          showHamburger={false}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <main className="grow">
          <Workspaces
            activeVendor={activeVendor}
            setActiveVendor={setActiveVendor}
            flyoutOpen={flyoutOpen}
            setFlyoutOpen={setFlyoutOpen}
          />
        </main>
      </div>
    </div>
  );
};

export default ManageWorkspaces;
