import React, { useState } from 'react';
import receiptIcon from '../../../images/receipts-icon.png';
import UploadFile from '../../../components/UploadFile';
import EditFileModal from './EditFileModal';
import ReceiptModal from '../../transactions/ReceiptModal';

const InvoiceDefaultAttachments = ({
  isLoading,
  files,
  handleDefaultAttachmentUpload,
  handleDefaultAttachmentDelete,
  handleDefaultAttachmentUpdate,
}) => {
  const [activeFile, setActiveFile] = useState(null);
  const [editFileModalOpen, setEditFileModalOpen] = useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [isDeletingFiles, setIsDeletingFiles] = useState({});

  const onDeleteFile = async (id) => {
    setIsDeletingFiles((prev) => ({ ...prev, [id]: true }));
    try {
      await handleDefaultAttachmentDelete(id);
    } catch (err) {
      console.log(err);
    } finally {
      setIsDeletingFiles((prev) => ({ ...prev, [id]: false }));
    }
  };

  return (
    <div className=" rounded-lg  border px-5 py-4 border-slate-200 ">
      <EditFileModal
        isOpen={editFileModalOpen}
        setIsOpen={setEditFileModalOpen}
        activeFile={activeFile}
        setActiveFile={setActiveFile}
        handleDefaultAttachmentUpload={handleDefaultAttachmentUpload}
        handleDefaultAttachmentUpdate={handleDefaultAttachmentUpdate}
      />
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt?.documentUrl || activeReceipt?.preview}
        isExpenseReporting
      />
      <h3 className="text-sm font-medium mb-6">Default Attachments</h3>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-full lg:col-span-6">
          <UploadFile
            title="Attachments - Upload an Image or a PDF"
            onFilesAdded={(_files) => {
              if (_files[0]) {
                setActiveFile(_files[0]);
                setEditFileModalOpen(true);
              }
            }}
            name="defaultAttachment"
            pdf
            id="defaultAttachment"
            pdf
          />
        </div>
        <div className="col-span-full lg:col-span-6">
          <div className="flex justify-between items-center self-stretch">
            <p className="text-slate-600 leading-5 font-medium text-base">
              Attachments ({files?.length || 0})
            </p>
          </div>
          {isLoading ? (
            <div className="flex flex-col items-center justify-center">
              <svg
                width="32"
                height="32"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-8 h-8 fill-current shrink-0 mt-4"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  stroke-width="2.55882"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  stroke-width="2.55882"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              {files?.length < 1 && (
                <div className="w-full flex justify-center items-center mt-[1.875rem] gap-5">
                  <img src={receiptIcon} alt="receipt icon" />
                  <p>No attachments</p>
                </div>
              )}
              {files?.length > 0 &&
                files?.map((receipt, index) => (
                  <div
                    key={receipt?.id}
                    className="border border-slate-600 rounded-md w-full min-h-[3.125rem] flex gap-4 justify-between items-center bg-gray-300 bg-opacity-10 pl-[0.313rem] pr-3 py-3 mb-2.5 mt-6"
                  >
                    <div className="flex items-center gap-2">
                      {(
                        receipt?.documentUrl
                          ? receipt?.documentUrl?.endsWith('.pdf')
                          : receipt?.type?.includes('pdf')
                      ) ? (
                        <div className="border border-slate-600 rounded-[0.313rem] w-[2.438rem] h-[2.438rem] flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="30"
                            width="30"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#e48642"
                              d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
                            />
                          </svg>
                        </div>
                      ) : (
                        <img
                          src={receipt?.documentUrl || receipt?.preview}
                          className="border border-slate-600 rounded-[0.313rem] w-[2.438rem] h-[2.438rem]"
                        />
                      )}
                      <p className="text-sm leading-tight break-all">
                        {receipt?.title || receipt?.name}
                      </p>
                    </div>
                    <div className="flex justify-end items-center gap-2">
                      {/* Edit Button */}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveFile(receipt);
                          setEditFileModalOpen(true);
                        }}
                        className=" disabled:cursor-not-allowed "
                      >
                        <svg
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-5"
                        >
                          <path
                            d="M6.98682 1.16699H5.82015C2.90348 1.16699 1.73682 2.33366 1.73682 5.25033V8.75033C1.73682 11.667 2.90348 12.8337 5.82015 12.8337H9.32015C12.2368 12.8337 13.4035 11.667 13.4035 8.75033V7.58366"
                            stroke="#667085"
                            stroke-opacity="0.8"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.92691 1.76206L5.33024 6.35872C5.15524 6.53372 4.98024 6.87789 4.94524 7.12872L4.69441 8.88456C4.60108 9.52039 5.05024 9.96372 5.68608 9.87622L7.44191 9.62539C7.68691 9.59039 8.03107 9.41539 8.21191 9.24039L12.8086 4.64372C13.6019 3.85039 13.9752 2.92872 12.8086 1.76206C11.6419 0.595392 10.7202 0.968725 9.92691 1.76206Z"
                            stroke="#667085"
                            stroke-opacity="0.8"
                            stroke-width="1.3"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.26807 2.4209C9.6589 3.81507 10.7497 4.9059 12.1497 5.30257"
                            stroke="#858D9D"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      {receipt?.documentUrl && (
                        // View Button
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => {
                            setActiveReceipt(receipt);
                            setReceiptModalOpen(true);
                          }}
                        >
                          <path
                            d="M12.4956 10.0019C12.4956 11.5315 11.2595 12.7675 9.72996 12.7675C8.20038 12.7675 6.96436 11.5315 6.96436 10.0019C6.96436 8.47235 8.20038 7.23633 9.72996 7.23633C11.2595 7.23633 12.4956 8.47235 12.4956 10.0019Z"
                            stroke="#667085"
                            stroke-width="1.55"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.73046 16.3887C12.4574 16.3887 14.999 14.7818 16.7681 12.0008C17.4633 10.9115 17.4633 9.08065 16.7681 7.99141C14.999 5.21035 12.4574 3.60352 9.73046 3.60352C7.00347 3.60352 4.4619 5.21035 2.69284 7.99141C1.99757 9.08065 1.99757 10.9115 2.69284 12.0008C4.4619 14.7818 7.00347 16.3887 9.73046 16.3887Z"
                            stroke="#667085"
                            stroke-width="1.55"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                      {!isDeletingFiles[receipt?.id] ? (
                        // Delete Button
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => onDeleteFile(receipt.id)}
                        >
                          <path
                            d="M2.25 4.5H3.75H15.75"
                            stroke="#FF4B4B"
                            stroke-width="1.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5"
                            stroke="#FF4B4B"
                            stroke-width="1.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.5 8.25V12.75"
                            stroke="#FF4B4B"
                            stroke-width="1.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.5 8.25V12.75"
                            stroke="#FF4B4B"
                            stroke-width="1.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        // Delete Loading
                        <div className="flex items-center justify-center">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 29 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="animate-spin w-4 h-4 fill-current shrink-0"
                          >
                            <path
                              d="M14.5 2.41699V7.25033"
                              stroke="#E48642"
                              stroke-width="2.55882"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.5 21.749V26.5824"
                              stroke="#E48642"
                              stroke-width="2.55882"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.95703 5.95703L9.37662 9.37662"
                              stroke="#E48642"
                              stroke-width="1.58955"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19.623 19.6211L23.0426 23.0407"
                              stroke="#E48642"
                              stroke-width="1.58955"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M2.41699 14.5H7.25033"
                              stroke="#E48642"
                              stroke-width="1.58955"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M21.749 14.5H26.5824"
                              stroke="#E48642"
                              stroke-width="1.58955"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.95703 23.0407L9.37662 19.6211"
                              stroke="#E48642"
                              stroke-width="1.58955"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19.623 9.37662L23.0426 5.95703"
                              stroke="#E48642"
                              stroke-width="1.58955"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceDefaultAttachments;
