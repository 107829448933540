import React, { useEffect, useState } from 'react';
import AlertBox from 'components/AlertBox';
import { useSelector } from 'react-redux';
import DropdownFull from '../../components/DropdownFull';
import { getAccountSubTypes, getAccountTypes } from '../../API/backend_helper';

const Step3 = ({
  tableRows,
  setTableRows,
  checkRowsError,
  getCheckedAccountsCount,
  deactivateExistingOnes,
  setDeactivateExistingOnes,
}) => {
  const { team } = useSelector((state) => state.Team);
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);

  const getAccountTypesApi = async () => {
    try {
      const typeResponse = await getAccountTypes();
      const subTypeResponse = await getAccountSubTypes();
      setTypes(typeResponse);
      setSubTypes(subTypeResponse);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    getAccountTypesApi();
  }, []);

  const handleUpdateValue = (index, field, value) => {
    const tempRows = [...tableRows];
    const tempObj = { ...tempRows[index] };
    if (field === 'subTypeId') {
      tempObj.subTypeId = {
        ...tempObj[field],
        value: value?.id,
        error: false,
      };
      tempObj.subTypeName = {
        ...tempObj[field],
        value: value?.name,
        error: false,
      };
    } else if (field === 'type') {
      tempObj.type = { ...tempObj[field], value };
      tempObj.subTypeId = {
        ...tempObj[field],
        value: null,
        error: !!tempObj.selected?.value,
      };
      tempObj.subTypeName = {
        ...tempObj[field],
        value: null,
        error: !!tempObj.selected?.value,
      };
    } else {
      tempObj[field] = { ...tempObj[field], value };
    }
    if (value) {
      tempObj[field] = { ...tempObj[field], error: false };
    } else {
      tempObj[field] = {
        ...tempObj[field],
        error: tempObj.selected?.value && true,
      };
    }
    if (field === 'selected' && value === false) {
      tempObj.type = { ...tempObj.type, error: false };
      tempObj.accountNumber = { ...tempObj.accountNumber, error: false };
      tempObj.name = { ...tempObj.name, error: false };
      tempObj.subTypeId = { ...tempObj.subTypeId, error: false };
      tempObj.subTypeName = { ...tempObj.subTypeName, error: false };
    }
    tempRows[index] = tempObj;
    setTableRows(tempRows);
  };

  const selectAll = (selected) => {
    const tempRows = [...tableRows];
    tableRows.forEach((d, i) => {
      const tempRow = { ...tableRows[i] };
      tempRow.selected.value = !selected;
    });
    setTableRows(tempRows);
  };

  return (
    <div>
      <h3 className="text-2xl mb-2 font-semibold">
        {getCheckedAccountsCount()} accounts are ready to be imported
      </h3>
      <label className="cursor-pointer text-base font-medium text-nowrap inline-flex justify-center items-center">
        <input
          type="checkbox"
          checked={deactivateExistingOnes}
          className="form-checkbox-orange  mr-2 h-5 w-5"
          onChange={() => setDeactivateExistingOnes(!deactivateExistingOnes)}
        />
        Enable this option to deactivate all default accounts and replace them
        with the imported chart of accounts.
      </label>
      <p className="pt-1 ml-7 text-slate-400">
        Note: Certain default accounts are required for COUNT's automation
        features and cannot be deactivated.
      </p>
      {deactivateExistingOnes && team?.featureExpenseReport && (
        <AlertBox
          type="error"
          heading="Warning"
          text="You have Expense Claim Types linked to existing Expense Accounts. If you choose to deactivate these accounts after importing your new Chart of Accounts, please ensure you update your Expense Claim Types to align with the newly imported accounts."
          hideActionButton
        />
      )}
      <div className="bg-transparent  max-w-full rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] flex-1 ">
        {/* Table */}
        <div
          className="overflow-x-auto h-full w-full max-w-full scrollbar"
          id="createBillTableContainer"
        >
          <table className="table-fixed w-full">
            {/* Table header */}
            <thead className="text-sm font-semibold text-[#667085] border-b border-[#D0D5DD] bg-[#A0CD850F]">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  w-[50px] min-w-[50px]">
                  <div className="flex items-center">
                    <label className="inline-flex ">
                      <span className="sr-only">Select</span>
                      <input
                        className="form-checkbox cursor-pointer h-5 w-5"
                        type="checkbox"
                        onChange={() =>
                          selectAll(
                            getCheckedAccountsCount() === tableRows?.length,
                          )
                        }
                        checked={
                          getCheckedAccountsCount() === tableRows?.length
                        }
                      />
                    </label>
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[170px] min-w-[170px]">
                  <div className="font-medium text-left">Account Number</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[350px] min-w-[350px]">
                  <div className="font-medium text-left">Account Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell  w-[300px] min-w-[300px] ">
                  <div className="font-medium text-left">Description</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[200px] min-w-[200px]">
                  <div className="font-medium text-left">Type</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell  w-[300px] min-w-[300px] ">
                  <div className="font-medium text-left">Sub Type</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-[#D0D5DD] ">
              {tableRows.map((b, i) => (
                <tr className="" key={i}>
                  <td className="px-2 first:pl-5 last:pr-5  pb-[2px] text-left">
                    <div className="flex h-[60px] pt-3">
                      <label className="inline-flex ">
                        <span className="sr-only">Select</span>
                        <input
                          className="form-checkbox cursor-pointer h-5 w-5"
                          type="checkbox"
                          onChange={() =>
                            handleUpdateValue(i, 'selected', !b.selected.value)
                          }
                          checked={b.selected.value}
                        />
                      </label>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="number"
                        placeholder="Account Number"
                        value={b.accountNumber.value}
                        className={`form-input placeholder:text-slate-300 w-[160px]
                        ${b.accountNumber.error ? '' : ''}
                        `}
                        onChange={(e) =>
                          handleUpdateValue(i, 'accountNumber', e.target.value)
                        }
                        onBlur={
                          b.accountNumber.error ? checkRowsError : () => {}
                        }
                      />
                      {b?.accountNumber?.error && (
                        <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                          {b?.accountNumber?.error || ''}
                        </span>
                      )}
                    </div>
                  </td>

                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="text"
                        placeholder="Account Name"
                        value={b?.name?.value}
                        className={`form-input placeholder:text-slate-300  ${
                          b.name.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'name', e.target.value)
                        }
                        onBlur={b.name.error ? checkRowsError : () => {}}
                      />
                      <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                        {b?.name?.error || ''}
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <input
                        type="text"
                        placeholder="Description"
                        value={b?.description?.value}
                        className={`form-input placeholder:text-slate-300  ${
                          b.description.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'description', e.target.value)
                        }
                        onBlur={b.description.error ? checkRowsError : () => {}}
                      />
                      <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                        {b?.description?.error || ''}
                      </span>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <DropdownFull
                        options={types.map((d) => ({
                          ...d,
                          name: d.name,
                          id: d.name,
                        }))}
                        className="!w-fit !h-fit"
                        setSelected={(name) =>
                          handleUpdateValue(i, 'type', name)
                        }
                        selected={b.type.value}
                        name="name"
                        bottomOfTable
                        ids={['importAccountsContainer']}
                        isTableDropdown
                        height="h-[38px]"
                        accounts
                        placeholder="Select"
                        error={b?.type?.error || ''}
                        buttonTextClass="max-w-[90%] overflow-hidden text-ellipsis"
                        buttonTextClassSub="max-w-[100%] overflow-hidden text-ellipsis"
                      />
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <DropdownFull
                        options={subTypes
                          .filter((d) => {
                            const selectedType = types.find(
                              (type) => type?.name === b.type.value,
                            );
                            return d.type === selectedType?.name;
                          })
                          .map((d) => ({
                            ...d,
                            subTypeId: d.name,
                            id: d.id,
                          }))}
                        height="h-[38px] min-w-[270px] max-w-[270px] text-nowrap overflow-hidden text-ellipsis"
                        setSelected={(option) => {
                          handleUpdateValue(i, 'subTypeId', option);
                        }}
                        selected={b.subTypeName.value}
                        error={b?.subTypeId?.error || ''}
                        accounts
                        subType
                        placeholder="Select"
                        disabled={!b.type.value}
                        className="!w-fit !h-fit"
                        name="name"
                        bottomOfTable
                        ids={['importAccountsContainer']}
                        isTableDropdown
                        selectFullObj
                        buttonTextClass="max-w-[90%] overflow-hidden text-ellipsis"
                        buttonTextClassSub="max-w-[100%] overflow-hidden text-ellipsis"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Step3;
