/* eslint-disable no-loop-func */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useMemo, useEffect } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { shallow } from 'zustand/shallow';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { boot, shutdown } from '@intercom/messenger-js-sdk';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Header from '../../partials/Header';
import {
  getMerchants,
  getCustomers,
  getAccounts,
  getTags,
  getAutomationById,
  updateAutomation,
  createAutomation,
  getAutomationTemplateById,
} from '../../API/backend_helper';
import 'reactflow/dist/style.css';
import '../../css/custom.css';
import { useStore } from './store';
import TriggerNode from './nodes/TriggerNode';
import ConditionNode from './nodes/ConditionNode';
import ActionNode from './nodes/ActionNode';
import PlaceholderNode from './nodes/PlaceholderNode';
import Canvas from './Canvas';
import { transformAccounts } from '../../utils/Utils';
import CustomEdge from './edges/CustomEdge';
import { Loader } from '../../components/Svg';

const selector = (store) => ({
  nodes: store.nodes,
  edges: store.edges,
  onNodesChange: store.onNodesChange,
  onNodesDelete: store.onNodesDelete,
  onEdgesChange: store.onEdgesChange,
  onEdgesDelete: store.onEdgesDelete,
  addEdge: store.addEdge,
  removeNodes: store.removeNodes,
  setActiveNodeId: store.setActiveNodeId,
  addTrigger: (x, y, values) => store.createNode('trigger', { x, y }, values),
  addCondition: (x, y, values) =>
    store.createNode('condition', { x, y }, values),
  addAction: (x, y, values) => store.createNode('action', { x, y }, values),
  createNewNode: (id, data, callback) =>
    store.createNewNode(id, data, callback),
  updateNode: (id, data) => store.updateNode(id, data),
  createNodeBetween: (sourceId, targetId, data) =>
    store.createNodeBetween(sourceId, targetId, data),
  deleteNode: (nodeId) => store.deleteNode(nodeId),
  startupCreateNewNode: (id, data) => store.createNewNode(id, data),
});

const AutomationBuilder = () => {
  const { localUser } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [searchParams] = useSearchParams();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [activeInstance, setActiveInstance] = useState(null);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelStep, setPanelStep] = useState('trigger1');
  const [vendors, setVendors] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [members, setMembers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isInBetween, setIsInBetween] = useState({});
  const [displayStartHere, setDisplayStartHere] = useState(true);
  const [localAutomation, setLocalAutomation] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const store = useStore(selector, shallow);
  const navigate = useNavigate();
  const [id] = useState(searchParams.get('id'));
  const [type] = useState(searchParams.get('type'));
  const [automationType, setAutomationType] = useState('');
  const [activeHoverNodeId, setActiveHoverNodeId] = useState(null);

  const edgeTypes = useMemo(
    () => ({
      custom: (props) => (
        <CustomEdge
          {...props}
          flyoutOpen={flyoutOpen}
          setFlyoutOpen={setFlyoutOpen}
          nodes={store.nodes}
          panelOpen={panelOpen}
          setPanelOpen={setPanelOpen}
          panelStep={panelStep}
          setPanelStep={setPanelStep}
          setActiveNodeId={store.setActiveNodeId}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setIsInBetween={setIsInBetween}
        />
      ),
    }),
    [],
  );

  const nodeTypes = useMemo(
    () => ({
      placeholder: (props) => (
        <PlaceholderNode
          {...props}
          createNewNode={store.createNewNode}
          nodes={store.nodes}
          panelOpen={panelOpen}
          setPanelOpen={setPanelOpen}
          panelStep={panelStep}
          setPanelStep={setPanelStep}
          setActiveNodeId={store.setActiveNodeId}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      ),
      trigger: (props) => (
        <TriggerNode
          {...props}
          createNewNode={store.createNewNode}
          nodes={store.nodes}
          panelOpen={panelOpen}
          setPanelOpen={setPanelOpen}
          panelStep={panelStep}
          setPanelStep={setPanelStep}
          setActiveNodeId={store.setActiveNodeId}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      ),
      condition: (props) => (
        <ConditionNode
          {...props}
          createNewNode={store.createNewNode}
          nodes={store.nodes}
          panelOpen={panelOpen}
          setPanelOpen={setPanelOpen}
          panelStep={panelStep}
          setPanelStep={setPanelStep}
          setActiveNodeId={store.setActiveNodeId}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          activeHoverNodeId={activeHoverNodeId}
          deleteNode={store.deleteNode}
        />
      ),
      action: (props) => (
        <ActionNode
          {...props}
          createNewNode={store.createNewNode}
          nodes={store.nodes}
          panelOpen={panelOpen}
          setPanelOpen={setPanelOpen}
          panelStep={panelStep}
          setPanelStep={setPanelStep}
          setActiveNodeId={store.setActiveNodeId}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          activeHoverNodeId={activeHoverNodeId}
          deleteNode={store.deleteNode}
        />
      ),
    }),
    [activeHoverNodeId],
  );

  const handleReset = () => {
    store.removeNodes();
    setPanelOpen(false);
    setIsEditing(false);
    setIsInBetween({});
    store.setActiveNodeId(null);
  };

  const assignNodeValues = (nodes) => {
    const filteredNodes = nodes.filter((node) => node.type !== 'placeholder');

    filteredNodes.sort((a, b) => a.position.y - b.position.y);

    filteredNodes.forEach((node, index) => {
      node.data.nodePos = index;
    });

    return filteredNodes;
  };

  const createConditionData = (node) => {
    if (automationType === 'transaction') {
      switch (node?.data?.conditionType) {
        case 'description':
          return {
            key: 'description',
            value: node?.data?.descriptionInput,
            position: node?.data?.nodePos,
            operator: node?.data?.stringCondition,
            caseSensitive: node?.data?.caseSensitive,
          };
        case 'vendor':
          return {
            key: 'vendorId',
            value: JSON.stringify(node?.data?.condition),
            position: node?.data?.nodePos,
            operator: node?.data?.stringCondition,
          };
        case 'customer':
          return {
            key: 'customerId',
            value: JSON.stringify(node?.data?.condition),
            position: node?.data?.nodePos,
            operator: node?.data?.stringCondition,
          };
        case 'amount':
          return {
            key: 'amount',
            value: node?.data?.amountInput,
            position: node?.data?.nodePos,
            operator: node?.data?.stringCondition,
          };
        default:
          return null;
      }
    } else if (automationType === 'estimateStatus') {
      return {
        key: 'status',
        value: node?.data?.conditionType,
        caseSensitive: false,
        operator: 'ct',
        position: 1,
      };
    } else if (automationType === 'stockOnHand') {
      return {
        key: 'amount',
        value: node?.data?.amountInput,
        caseSensitive: false,
        operator: node?.data?.conditionType,
        position: 1,
      };
    }
  };

  const createActionData = (node) => {
    if (automationType === 'transaction') {
      switch (node?.data?.actionType) {
        case 'category':
          return {
            key: 'categoryAccountId',
            value: node?.data?.selectedCategory,
            position: node?.data?.nodePos,
          };
        case 'tag':
          return {
            key: 'tags',
            value: node?.data?.actionTags,
            position: node?.data?.nodePos,
          };
        case 'vendor':
          return {
            key: 'vendorId',
            value: node?.data?.action,
            position: node?.data?.nodePos,
          };
        case 'customer':
          return {
            key: 'customerId',
            value: node?.data?.action,
            position: node?.data?.nodePos,
          };
        case 'excluded':
          return {
            key: 'excluded',
            value: true,
            position: node?.data?.nodePos,
          };
        case 'reviewed':
          return {
            key: 'reviewed',
            value: true,
            position: node?.data?.nodePos,
          };
        case 'description':
          return {
            key: 'description',
            value: node?.data?.descriptionText,
            position: node?.data?.nodePos,
          };
        default:
          return null;
      }
    } else if (automationType === 'estimateStatus') {
      return {
        key: 'email',
        value: node?.data?.email,
        position: 2,
      };
    } else if (automationType === 'stockOnHand') {
      return {
        key: 'email',
        value: node?.data?.email,
        position: 2,
      };
    }
  };

  const checkError = () => {
    let isError = false;
    if (store.nodes?.length > 0) {
      const flowNodes = store.nodes;

      // Check Conditions Error
      const conditionNodes = flowNodes.filter(
        (node) => node.type === 'condition',
      );
      conditionNodes?.forEach((node) => {
        if (automationType === 'transaction' && !node?.data?.conditionType) {
          isError = true;
        } else if (
          automationType === 'estimateStatus' &&
          !node?.data?.conditionType
        ) {
          isError = true;
        } else if (
          automationType === 'stockOnHand' &&
          (!node?.data?.amountInput || !node?.data?.conditionType)
        ) {
          isError = true;
        }
      });

      // Check Actions Error
      const actionNodes = flowNodes.filter((node) => node.type === 'action');
      actionNodes?.forEach((node) => {
        if (automationType === 'transaction' && !node?.data?.actionType) {
          isError = true;
        } else if (automationType === 'estimateStatus' && !node?.data?.email) {
          isError = true;
        } else if (automationType === 'stockOnHand' && !node?.data?.email) {
          isError = true;
        }
      });
    }
    return isError;
  };

  useEffect(() => {
    checkError();
  }, [store.nodes]);

  const handleSave = async (name, applyRetroactively) => {
    if (activeInstance) {
      const flow = activeInstance.toObject();
      const flowNodes = assignNodeValues(flow.nodes);
      const triggerNode = flowNodes.find((node) => node.type === 'trigger');
      const conditionNodes = flowNodes.filter(
        (node) => node.type === 'condition',
      );
      const actionNodes = flowNodes.filter((node) => node.type === 'action');
      const data = {
        name,
        applyRetroactively,
        trigger: {
          entity: automationType,
          is:
            automationType === 'estimateStatus'
              ? 'updated'
              : automationType === 'stockOnHand'
                ? 'created'
                : triggerNode?.data?.triggerType,
          position: 0,
        },
        filters: triggerNode?.data?.filters || [],
        conditions: conditionNodes.map((node) => createConditionData(node)),
        actions: actionNodes.map((node) => createActionData(node)),
      };

      if (type === 'template' || !localAutomation?.id) {
        try {
          const res = await createAutomation(data);
          if (res?.data?.automation) {
            setLocalAutomation(res?.data?.automation);
            navigate(`/automations`);
            toast.success('Automation created successfully');
          }
        } catch (error) {
          console.error('Error creating automation:', error);
        }
      } else if (localAutomation?.id) {
        try {
          await updateAutomation(localAutomation?.id, data);
          navigate(`/automations`);
          toast.success('Automation updated successfully');
        } catch (error) {
          console.error('Error updating automation:', error);
        }
      }
    }
  };

  const handleNavigate = () => {
    navigate('/automations');
  };

  const getMembers = () => {
    const mentionsList = team?.users
      ?.filter((member) => member.id !== localUser?.id)
      ?.map((member) => ({
        id: member.id,
        display: `${member.firstName} ${member.lastName}`,
        avatar: `member.avatarUrl`,
      }));
    setMembers(mentionsList);
  };

  const loadData = async () => {
    setIsLoading(true);

    try {
      const [accountResponse, customerResponse, vendorResponse, tagsResponse] =
        await Promise.all([
          getAccounts({}),
          getCustomers({}),
          getMerchants({}),
          getTags({}),
        ]);
      getMembers();
      const transformedAccounts = transformAccounts(
        accountResponse,
        'allAccounts',
      );
      const allCat = transformedAccounts.flatMap((item) => item.categories);

      setAllCategories(allCat);
      setAccounts(transformAccounts(accountResponse, 'PHYSICAL_ACCOUNT'));
      setCategories(transformedAccounts);
      setCustomers(customerResponse?.data?.records);
      setVendors(vendorResponse?.vendors);
      setTags(tagsResponse);
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setDataLoaded(true);
      if (!id) {
        setIsLoading(false);
      }
    }
  };

  const createTriggerData = (node, automation, automationEntity) => {
    if (automationEntity === 'transaction') {
      switch (node.is) {
        case 'created':
          return {
            name: 'Transaction is Added',
            triggerType: 'created',
            type: 'trigger',
            description: 'Rule is run when a transaction is added.',
            filters: automation?.filters,
          };
        case 'updated':
          return {
            name: 'Transaction is Updated',
            triggerType: 'updated',
            type: 'trigger',
            description: 'Rule is run when a transaction is updated.',
          };
        case 'deleted':
          return {
            name: 'Transaction is Deleted',
            triggerType: 'deleted',
            type: 'trigger',
            description: 'Rule is run when a transaction is deleted.',
          };
        default:
          return {};
      }
    } else if (automationEntity === 'estimateStatus') {
      return {
        name: 'Estimate Status Changes',
        triggerType: 'estimateStatus',
        type: 'trigger',
        description: 'Rule is run when an estimate status is changed.',
      };
    } else {
      return {
        name: 'Product Stock Changes',
        triggerType: 'stockOnHand',
        type: 'trigger',
        description: 'Rule is run when a stock is changed.',
      };
    }
  };

  const operatorConversion = (operator) => {
    // eslint-disable-next-line default-case
    switch (operator) {
      case 'eq':
        return 'Equals';
      case 'neq':
        return "Doesn't Equal";
      case 'ls':
        return 'Less Than';
      case 'lse':
        return 'Less Than or Equal To';
      case 'gt':
        return 'Greater Than';
      case 'gte':
        return 'Greater Than or Equal To';
      case 'ct':
        return 'Contains';
      case 'nct':
        return "Doesn't Contain";
    }
  };

  const createLoadingConditionData = (node, automationEntity) => {
    if (automationEntity === 'transaction') {
      switch (node.key) {
        case 'description':
          return {
            name: `If: ${
              node?.key.charAt(0).toUpperCase() + node?.key.slice(1)
            } ${operatorConversion(node?.operator)} ${node?.value}`,
            type: 'condition',
            conditionType: node?.key,
            condition: [],
            stringCondition: node?.operator,
            descriptionInput: node?.value,
            caseSensitive: node?.caseSensitive,
          };
        case 'vendorId':
          return {
            name: `If: Vendor is ${JSON.parse(node?.value)
              .map((id) => vendors.find((vendor) => vendor.id === id).name)
              .join(' or ')}`,
            type: 'condition',
            conditionType: 'vendor',
            condition: JSON.parse(node?.value),
            stringCondition: node?.operator,
            descriptionInput: '',
            caseSensitive: node?.caseSensitive,
          };
        case 'customerId':
          return {
            name: `If: Customer is ${JSON.parse(node?.value)
              .map(
                (id) =>
                  customers.find((customer) => customer.id === id).customer,
              )
              .join(' or ')}`,
            type: 'condition',
            conditionType: 'customer',
            condition: JSON.parse(node?.value),
            stringCondition: node?.operator,
            descriptionInput: '',
            caseSensitive: node?.caseSensitive,
          };
        case 'amount':
          return {
            name: `If: Amount ${operatorConversion(node?.operator)} ${
              node?.value
            }`,
            type: 'condition',
            conditionType: 'amount',
            amountInput: Number(node?.value),
            stringCondition: node?.operator,
            descriptionInput: '',
            caseSensitive: node?.caseSensitive,
          };
        default:
          return {};
      }
    } else if (automationEntity === 'estimateStatus') {
      return {
        name: `If: Estimate Status is ${node?.value?.toUpperCase()}`,
        conditionType: node?.value,
        type: 'condition',
        stringCondition: node?.operator,
        caseSensitive: node?.caseSensitive,
        descriptionInput: '',
      };
    } else {
      return {
        name: `If: Stock On Hand ${operatorConversion(node?.operator)}  ${node?.value}`,
        conditionType: node?.operator,
        amountInput: Number(node?.value),
        type: 'condition',
        stringCondition: node?.operator,
        caseSensitive: node?.caseSensitive,
        descriptionInput: '',
      };
    }
  };

  const createLoadingActionData = (node, automationEntity) => {
    if (automationEntity === 'transaction') {
      switch (node.key) {
        case 'categoryAccountId':
          return {
            name: `Then: Categorize Transaction as ${
              allCategories.find(
                (account) => account.id === Number(node?.value),
              )?.name
            }`,
            type: 'action',
            actionType: 'category',
            action: allCategories.find(
              (account) => account.id === Number(node?.value),
            )?.name,
            actionTags: [],
            comment: '',
            mentionIds: [],
            selectedCategory: Number(node?.value),
            descriptionText: '',
          };
        case 'tags':
          // eslint-disable-next-line no-case-declarations
          const data = JSON.parse(node?.value);
          // eslint-disable-next-line no-case-declarations
          const actionTags = tags
            .filter((tag) => data.includes(tag.id))
            .map((tag) => tag.name);
          return {
            name: `Then: Add Tag(s) to Transaction: ${actionTags.join(' and ')}`,
            type: 'action',
            actionType: 'tag',
            action: null,
            actionTags: data,
            comment: '',
            mentionIds: [],
            selectedCategory: null,
            descriptionText: '',
          };
        case 'vendorId':
          return {
            name: `Then: Assign Transaction to Vendor: ${
              vendors.find((vendor) => vendor.id === Number(node?.value))?.name
            }`,
            type: 'action',
            actionType: 'vendor',
            action: vendors.find((vendor) => vendor.id === Number(node?.value))
              ?.id,
            actionTags: [],
            comment: '',
            mentionIds: [],
            selectedCategory: null,
            descriptionText: '',
          };
        case 'customerId':
          return {
            name: `Then: Assign Transaction to Customer: ${
              customers.find((customer) => customer.id === Number(node?.value))
                ?.customer
            }`,
            type: 'action',
            actionType: 'customer',
            action: customers.find(
              (customer) => customer.id === Number(node?.value),
            )?.id,
            actionTags: [],
            comment: '',
            mentionIds: [],
            selectedCategory: null,
            descriptionText: '',
          };
        case 'excluded':
          return {
            name: `Then: Mark Transaction as Excluded`,
            type: 'action',
            actionType: 'excluded',
            action: null,
            actionTags: [],
            comment: '',
            mentionIds: [],
            selectedCategory: null,
            descriptionText: '',
          };
        case 'reviewed':
          return {
            name: `Then: Mark Transaction as Reviewed`,
            type: 'action',
            actionType: 'reviewed',
            action: null,
            actionTags: [],
            comment: '',
            mentionIds: [],
            selectedCategory: null,
            descriptionText: '',
          };
        case 'description':
          return {
            name: `Then: Update Transaction Description: ${node?.value}`,
            type: 'action',
            actionType: 'description',
            action: null,
            actionTags: [],
            comment: '',
            mentionIds: [],
            selectedCategory: null,
            descriptionText: node?.value,
          };
        default:
          return {};
      }
    } else if (automationEntity === 'estimateStatus') {
      return {
        name: `Then: Send Email to ${node?.value}`,
        email: node?.value,
        emailBody: '',
        type: 'action',
      };
    } else {
      return {
        name: `Then: Send Email to ${node?.value}`,
        email: node?.value,
        emailBody: '',
        type: 'action',
      };
    }
  };

  const loadAutomation = async (id) => {
    let res = {};
    if (type === 'template') {
      const templateRes = await getAutomationTemplateById(id);
      res = templateRes?.data?.automationTemplate;
    } else {
      const automationRes = await getAutomationById(id);
      res = automationRes?.data?.automation;
    }
    const { actions, trigger, conditions } = res;
    setLocalAutomation(res);
    const nodesToMap = [
      ...actions.map((action) => ({
        ...action,
        type: 'action',
        position: action.position ?? 2,
      })),
      {
        ...trigger,
        type: 'trigger',
        position: trigger.position ?? 0,
      },
      ...conditions.map((condition) => ({
        ...condition,
        type: 'condition',
        position: condition.position ?? 1,
      })),
    ].sort((a, b) => a.position - b.position);

    const automationEntity = nodesToMap?.[0]?.entity;
    setAutomationType(automationEntity);

    let newNodeId;
    // eslint-disable-next-line no-restricted-syntax
    for (const node of nodesToMap) {
      if (node?.type === 'trigger') {
        store.createNewNode(
          store.nodes[0].id,
          createTriggerData(node, res?.data?.automation, automationEntity),
          (id) => {
            newNodeId = id;
          },
        );
      } else if (node?.type === 'condition') {
        store.createNewNode(
          newNodeId,
          createLoadingConditionData(node, automationEntity),
          (id) => {
            newNodeId = id;
          },
        );
      } else if (node?.type === 'action') {
        store.createNewNode(
          newNodeId,
          createLoadingActionData(node, automationEntity),
          (id) => {
            newNodeId = id;
          },
        );
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const initializeFlow = async () => {
      setDataLoaded(false);
      await store.removeNodes();
      await loadData();
    };
    initializeFlow();
  }, [id]);

  useEffect(() => {
    if (dataLoaded && id) {
      loadAutomation(id);
    }
  }, [dataLoaded, id]);

  useEffect(() => {
    if (true) {
      shutdown();
    } else {
      boot();
    }
    return () => {
      boot();
    };
  }, []);

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/* Site area */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          workflows
        />
        <main className="grow w-full">
          <div className="w-full h-full">
            {isLoading ? (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <Loader />
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            ) : (
              <div className="h-full w-full">
                <ReactFlowProvider>
                  <Canvas
                    nodeTypes={nodeTypes}
                    nodes={store.nodes}
                    edges={store.edges}
                    edgeTypes={edgeTypes}
                    onNodesChange={store.onNodesChange}
                    onNodesDelete={store.onNodesDelete}
                    onEdgesChange={store.onEdgesChange}
                    onEdgesDelete={store.onEdgesDelete}
                    onConnect={store.addEdge}
                    addTrigger={store.addTrigger}
                    addCondition={store.addCondition}
                    addAction={store.addAction}
                    flyoutOpen={flyoutOpen}
                    setFlyoutOpen={setFlyoutOpen}
                    handleReset={handleReset}
                    handleSave={handleSave}
                    handleNavigate={handleNavigate}
                    setActiveInstance={setActiveInstance}
                    panelOpen={panelOpen}
                    setPanelOpen={setPanelOpen}
                    panelStep={panelStep}
                    setPanelStep={setPanelStep}
                    createNewNode={store.createNewNode}
                    vendors={vendors}
                    customers={customers}
                    categories={categories}
                    accounts={accounts}
                    members={members}
                    tags={tags}
                    updateNode={store.updateNode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    setActiveNodeId={store.setActiveNodeId}
                    isInBetween={isInBetween}
                    setIsInBetween={setIsInBetween}
                    createNodeBetween={store.createNodeBetween}
                    deleteNode={store.deleteNode}
                    currencies={currencies}
                    team={team}
                    displayStartHere={displayStartHere}
                    setDisplayStartHere={setDisplayStartHere}
                    localAutomation={localAutomation}
                    automationType={automationType}
                    setAutomationType={setAutomationType}
                    checkError={checkError}
                    type={type}
                    setActiveHoverNodeId={setActiveHoverNodeId}
                  />
                </ReactFlowProvider>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AutomationBuilder;
