/* eslint-disable default-case */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import DropdownFull from '../../../components/DropdownFull';
import TextInputDash from '../../../components/TextInputDash';
import BasicCheckboxDropdown from '../../component/BasicCheckboxDropdown';
import Transition from '../../../utils/Transition';
import { CloseBtn } from '../svg';
import useClickOutside from '../../../utils/useClickOutside';
import Tooltip from '../../../components/Tooltip';
import { capitalizeFirstChar, TEAM_CURRENCY } from '../../../utils/Utils';

const Condition1 = ({
  workingNodeId,
  vendors,
  customers,
  updateNode,
  activeNodeId,
  isEditing,
  localNode,
  setPanelOpen,
  currencies,
  automationType,
}) => {
  const { team } = useSelector((state) => state.Team);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const dropdownRef = useRef(null);
  const [conditionType, setConditionType] = useState('');
  const [conditionSelected, setConditionSelected] = useState(false);
  const [stringCondition, setStringCondition] = useState('');
  const [condition, setCondition] = useState([]);
  const [descriptionInput, setDescriptionInput] = useState('');
  const [caseSensitive, setCaseSensitive] = useState(false);
  const [amountInput, setAmountInput] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [localCurrency, setLocalCurrency] = useState(null);

  useEffect(() => {
    if (isEditing && localNode) {
      setConditionType(localNode?.data?.conditionType);
      if (
        localNode?.data?.conditionType === 'vendor' ||
        localNode?.data?.conditionType === 'customer'
      ) {
        setCondition([...localNode?.data?.condition]);
      } else {
        setStringCondition(localNode?.data?.stringCondition);
      }
      setDescriptionInput(localNode?.data?.descriptionInput || '');
      setAmountInput(localNode?.data?.amountInput || '');
      setCaseSensitive(localNode?.data?.caseSensitive || false);
    }
  }, [localNode, isEditing]);

  useEffect(() => {
    if (!isEditing) {
      setCondition([]);
      setStringCondition('');
    }
  }, [conditionType, isEditing]);

  useEffect(() => {
    if (conditionType === 'vendor' || conditionType === 'customer') {
      setStringCondition('eq');
    }
  }, [conditionType]);

  useEffect(() => {
    const isConditionValid = () => {
      if (!conditionType) return false;
      if (conditionType === 'vendor' || conditionType === 'customer') {
        return condition.length > 0;
      }
      if (stringCondition) return descriptionInput || amountInput;
    };
    setConditionSelected(isConditionValid());
  }, [
    conditionType,
    stringCondition,
    condition,
    descriptionInput,
    amountInput,
  ]);

  useClickOutside(dropdownRef, () => {
    setDropdownOpen(false);
  });

  const findTeamCurrency = () => {
    const matchingCurrency = currencies?.find?.(
      (currency) => currency?.isoCode === teamDefaultCurrency,
    );
    setLocalCurrency(matchingCurrency);
  };

  const operatorConversion = (operator) => {
    switch (operator) {
      case 'eq':
        return 'Equals';
      case 'neq':
        return "Doesn't Equal";
      case 'ls':
        return 'Less Than';
      case 'lse':
        return 'Less Than or Equal To';
      case 'gt':
        return 'Greater Than';
      case 'gte':
        return 'Greater Than or Equal To';
      case 'ct':
        return 'Contains';
      case 'nct':
        return "Doesn't Contain";
    }
  };

  const generateNodeName = () => {
    if (automationType === 'transaction') {
      switch (conditionType) {
        case 'description':
          return `If: ${capitalizeFirstChar(conditionType)} ${operatorConversion(
            stringCondition,
          )} ${descriptionInput}`;
        case 'vendor':
          return `If: ${capitalizeFirstChar(conditionType)} is ${condition
            .map((id) => vendors.find((vendor) => vendor.id === id).name)
            .join(' or ')}`;
        case 'customer':
          return `If: ${capitalizeFirstChar(conditionType)} is ${condition
            .map(
              (id) => customers.find((customer) => customer.id === id).customer,
            )
            .join(' or ')}`;
        case 'amount':
          return `If: ${capitalizeFirstChar(conditionType)} ${operatorConversion(
            stringCondition,
          )} ${amountInput}`;
      }
    } else if (automationType === 'stockOnHand') {
      return `If: Stock On Hand ${operatorConversion(conditionType)}  ${amountInput}`;
    } else if (automationType === 'estimateStatus') {
      return `If: Estimate Status is ${conditionType.toUpperCase()}`;
    }
  };

  const handleNextClick = () => {
    const nodeName = generateNodeName();
    if (automationType === 'transaction') {
      if (isEditing) {
        updateNode(activeNodeId, {
          name: nodeName,
          conditionType,
          condition,
          stringCondition,
          descriptionInput,
          amountInput,
          caseSensitive,
          currency: localCurrency?.isoCode,
        });
      } else {
        updateNode(workingNodeId, {
          name: nodeName,
          conditionType,
          condition,
          stringCondition,
          descriptionInput,
          amountInput,
          caseSensitive,
          currency: localCurrency?.isoCode,
        });
      }
    } else if (automationType === 'stockOnHand') {
      if (isEditing) {
        updateNode(activeNodeId, {
          name: nodeName,
          conditionType,
          amountInput,
        });
      } else {
        updateNode(workingNodeId, {
          name: nodeName,
          conditionType,
          amountInput,
        });
      }
    } else if (automationType === 'estimateStatus') {
      if (isEditing) {
        updateNode(activeNodeId, {
          name: nodeName,
          conditionType,
        });
      } else {
        updateNode(workingNodeId, {
          name: nodeName,
          conditionType,
        });
      }
    }
    setPanelOpen(false);
  };

  const findDropdownOptions = () => {
    switch (conditionType) {
      case 'description':
        return [
          { label: 'Contains', value: 'Contains', id: 'ct' },
          {
            label: "Doesn't Contain",
            value: "Doesn't Contain",
            id: 'nct',
          },
          { label: 'Equals', value: 'Equals', id: 'eq' },
        ];
      case 'vendor':
        return vendors.map((vendor) => ({
          label: vendor?.name,
          value: vendor?.id,
        }));
      case 'customer':
        const modifiedCustomers = customers.map((customer) => ({
          label: customer?.customer,
          value: customer?.id,
        }));
        return modifiedCustomers;
      case 'amount':
        return [
          { label: 'Equals', value: 'Equals', id: 'eq' },
          {
            label: "Doesn't Equal",
            value: "Doesn't Equal",
            id: 'neq',
          },
          { label: 'Is Less Than', value: 'Is Less Than', id: 'ls' },
          {
            label: 'Is Greater Than',
            value: 'Is Greater Than',
            id: 'gt',
          },
          {
            label: 'Is Less Than or Equal To',
            value: 'Is Less Than or Equal To',
            id: 'lse',
          },
          {
            label: 'Is Greater Than or Equal To',
            value: 'Is Greater Than or Equal To',
            id: 'gte',
          },
        ];
    }
  };

  useEffect(() => {
    findTeamCurrency();
  }, []);

  return (
    <>
      <div className="flex flex-col items-start gap-[0.938rem] self-stretch">
        <div className="w-full flex justify-between">
          <div className="flex flex-col items-start gap-px self-stretch">
            <p className="text-black text-[1.375rem] font-medium">
              Add a Condition
            </p>
            <p className="text-slate-600 text-[14px]">
              Compares two values using smart values and regular expressions.
            </p>
          </div>
          <CloseBtn
            onClick={() => setPanelOpen(false)}
            className="mt-[0.600rem]"
          />
        </div>
        {automationType === 'transaction' ? (
          <>
            {' '}
            <div className="w-full self-stretch flex items-center justify-center">
              <DropdownFull
                options={[
                  { name: 'Description', id: 'description' },
                  { name: 'Vendor', id: 'vendor' },
                  { name: 'Customer', id: 'customer' },
                  { name: 'Amount', id: 'amount' },
                ]}
                name="name"
                height="h-10"
                placeholder="Select Condition Type"
                selected={conditionType}
                setSelected={setConditionType}
                scrollIntoView={false}
              />
            </div>
            <div className="w-full self-stretch flex items-center justify-center relative">
              {conditionType === 'description' || conditionType === 'amount' ? (
                <DropdownFull
                  options={findDropdownOptions()}
                  name="label"
                  height="h-10"
                  placeholder="Select Condition"
                  selected={stringCondition}
                  setSelected={setStringCondition}
                  scrollIntoView={false}
                />
              ) : (
                <BasicCheckboxDropdown
                  options={findDropdownOptions()}
                  name="name"
                  height="h-10"
                  selectedOptions={condition}
                  onClear={() => setCondition([])}
                  setSelectedOptions={(condition) => setCondition(condition)}
                  keyValue="value"
                  apiFunc={() => {}}
                  label="Select Condition"
                  flyout
                  type="workflows"
                  disabled={!conditionType}
                />
              )}
            </div>
            {(conditionType === 'description' ||
              conditionType === 'amount') && (
              <div className="w-full self-stretch flex items-center justify-center">
                <TextInputDash
                  id="inputValue"
                  name="inputValue"
                  placeholder={
                    conditionType === 'description'
                      ? 'Enter Description'
                      : 'Enter Amount'
                  }
                  onChange={(e) => {
                    if (conditionType === 'description') {
                      setDescriptionInput(e.target.value);
                    } else {
                      setAmountInput(e.target.value);
                    }
                  }}
                  value={
                    conditionType === 'description'
                      ? descriptionInput
                      : amountInput
                  }
                  paddingBottom="pb-0"
                  inputClass="!h-10"
                  type={conditionType === 'amount' ? 'number' : 'text'}
                />
              </div>
            )}
            {conditionType === 'description' && (
              <div className="w-full flex items-center justify-start">
                <label className="inline-flex items-center text-slate-600">
                  <input
                    type="checkbox"
                    className="form-checkbox mr-[10px] h-[18px] w-[18px]"
                    checked={caseSensitive}
                    onChange={() => setCaseSensitive(!caseSensitive)}
                  />{' '}
                  Case Sensitive
                </label>
              </div>
            )}
            {conditionType === 'amount' && (
              <div className="w-full flex items-center justify-start">
                <p className="text-slate-600 text-sm">
                  {localCurrency?.isoCode === teamDefaultCurrency
                    ? `This condition applies to your workspace's default currency of ${teamDefaultCurrency}.`
                    : `This condition applies to your selected currency of ${localCurrency?.isoCode}.`}
                  <span
                    className="text-indigo-500 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdownOpen(!dropdownOpen);
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    {' '}
                    Change Currency
                  </span>
                </p>
              </div>
            )}
            <Transition
              show={dropdownOpen}
              tag="div"
              className="z-60 absolute bottom-10 left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
              enter="transition ease-out duration-100 transform"
              enterStart="opacity-0 -translate-y-2"
              enterEnd="opacity-100 translate-y-0"
              leave="transition ease-out duration-100"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"
            >
              {dropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
                  onFocus={() => setDropdownOpen(true)}
                  onBlur={() => setDropdownOpen(false)}
                >
                  <div className="max-h-48 overflow-auto scrollbar">
                    {currencies?.map((currency, index) => (
                      <button
                        type="button"
                        key={index}
                        className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                          currency.id === localCurrency?.id && 'text-indigo-500'
                        }`}
                        onClick={() => {
                          setLocalCurrency(currency);
                          setDropdownOpen(false);
                        }}
                      >
                        {`${currency?.symbol} ${currency?.name}`}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </Transition>
          </>
        ) : automationType === 'stockOnHand' ? (
          <div className="w-full self-stretch flex items-center justify-center">
            <div className="flex flex-col w-full gap-6">
              <div>
                <label className="text-sm font-medium text-slate-600 block mb-2">
                  Stock On Hand <span className="text-rose-500">*</span>
                </label>
                <DropdownFull
                  options={[
                    { name: 'Equals', id: 'eq' },
                    { name: 'Less Than or Equal To', id: 'lse' },
                  ]}
                  name="name"
                  height="h-10"
                  placeholder="Stock On Hand"
                  selected={conditionType}
                  setSelected={setConditionType}
                  scrollIntoView={false}
                />
              </div>
              {conditionType && (
                <TextInputDash
                  label="Amount"
                  required
                  id="amount"
                  name="amount"
                  placeholder="Enter Amount"
                  onChange={(e) => {
                    setAmountInput(e.target.value);
                  }}
                  value={amountInput}
                  paddingBottom="pb-0"
                  inputClass="!h-10"
                  type="number"
                  width="w-full"
                />
              )}
            </div>
          </div>
        ) : (
          <div className="w-full self-stretch flex items-center justify-center">
            <DropdownFull
              options={[
                { name: 'Accepted', id: 'accepted' },
                { name: 'Expired', id: 'expired' },
                { name: 'Pending', id: 'pending' },
              ]}
              name="name"
              height="h-10"
              placeholder="Estimate Status"
              selected={conditionType}
              setSelected={setConditionType}
              scrollIntoView={false}
            />
          </div>
        )}
      </div>
      <div className="flex items-start gap-5 self-stretch w-1/3">
        <Tooltip
          content={`Please ${!conditionType ? 'select a condition' : 'enter a value'}  to proceed`}
          tooltipShow={
            automationType === 'transaction'
              ? !conditionSelected
              : automationType === 'stockOnHand'
                ? !amountInput || !conditionType
                : !conditionType
          }
          position="top"
          contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown ml-20"
          className="w-full"
        >
          <button
            className="w-full py-[5px] flex items-center justify-center gap-2 rounded-[0.313rem] shadow text-white bg-indigo-500 leading-6 disabled:cursor-not-allowed"
            disabled={
              automationType === 'transaction'
                ? !conditionSelected
                : automationType === 'stockOnHand'
                  ? !amountInput || !conditionType
                  : !conditionType
            }
            onClick={() => handleNextClick()}
            type="button"
          >
            {isEditing ? 'Save' : 'Add'}
          </button>
        </Tooltip>
      </div>
    </>
  );
};

export default Condition1;
