import React, { useEffect, useState } from 'react';
import AppLayout from 'components/AppLayout';
import { getAllAccountantFirms } from 'API/backend_helper';
import PaginationClassic from 'components/PaginationClassic';
import BookkeeperBox from './BookkeeperBox';

const Bookkeepers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [bookkeepers, setBookkeepers] = useState({});
  const [localLimit, setLocalLimit] = useState(50);

  const loadAllFirms = async ({
    page = bookkeepers?.page,
    limit = localLimit,
  } = {}) => {
    try {
      const res = await getAllAccountantFirms({ page, limit });
      setBookkeepers(res?.data?.result);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadAllFirms({});
  }, []);

  return (
    <AppLayout>
      <main className="relative grow ">
        {/* Content */}
        <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
          {/* Page header */}
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between mb-10">
            <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
              Find a Bookkeeper
            </h1>
          </div>

          {isLoading && (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          )}
          {!isLoading && (
            <div>
              <div className="grid grid-cols-12 gap-x-8 gap-y-10">
                {bookkeepers?.accountingFirms?.map(
                  (bookkeeper) =>
                    bookkeeper?.certified && (
                      <div
                        className="col-span-full sm:col-span-6 xl:col-span-4 2xl:col-span-3"
                        key={bookkeeper?.id}
                      >
                        <BookkeeperBox bookkeeper={bookkeeper} />
                      </div>
                    ),
                )}
              </div>
              <div className="mt-10">
                <PaginationClassic
                  setLimit={setLocalLimit}
                  onPageChange={loadAllFirms}
                  pagination={{
                    limit: localLimit,
                    page: bookkeepers?.page,
                    totalRecords: bookkeepers?.totalCount,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </main>
    </AppLayout>
  );
};

export default Bookkeepers;
