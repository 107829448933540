import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

const CustomToast = () => (
  <div className="flex flex-col gap-1">
    <p className="text-white leading-5 text-[18px] font-medium ">
      A newer version is available. Please reload for a smoother experience.
    </p>
    <div className="flex justify-end gap-2 mt-2">
      {/* <button
        type="button"
        className="bg-indigo-500  text-white border border-white flex items-center justify-center h-[32px] w-[80px] rounded-[5px] gap-2 py-2.5 whitespace-nowrap"
        onClick={() => openModal(true)}
      >
        <span className="leading-5 text-[16px]">Later</span>
      </button> */}
      <button
        type="button"
        className="bg-white text-indigo-500 translate-x-5 border border-white flex items-center justify-center h-[32px] w-[80px] rounded-[5px] gap-2 py-2.5 whitespace-nowrap"
        onClick={() => window.location.reload()}
      >
        <span className="leading-5 text-[16px]"> Reload</span>
      </button>
    </div>
  </div>
);

const RefreshModal = ({ isOpen, setIsOpen }) => {
  useEffect(() => {
    if (isOpen) {
      const id = toast(<CustomToast />, {
        position: 'top-right',
        autoClose: false,
        onClose: () => setIsOpen(false),
        type: 'info',
        className: 'refreshToast',
        icon: (
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`mt-[2px] shrink-0 fill-white text-white   `}
          >
            <g clipPath="url(#clip0_569_18916)">
              <path
                d="M8.31157 0.0556164C6.86802 0.23374 5.46899 0.753271 4.26294 1.55483C2.39263 2.798 1.03071 4.61636 0.392432 6.73159C0.240283 7.22144 0.0918457 7.96733 0.0361816 8.46831C-0.0120605 8.93218 -0.0120605 10.0677 0.0361816 10.5316C0.436963 14.2945 3.0977 17.5082 6.73071 18.6066C7.22056 18.7587 7.96645 18.9072 8.46743 18.9628C8.9313 19.0111 10.0668 19.0111 10.5307 18.9628C12.7276 18.7291 14.7649 17.7308 16.3309 16.1203C17.4034 15.0107 18.1641 13.7267 18.6057 12.2683C18.8803 11.3666 18.9991 10.5316 18.9991 9.49995C18.9991 8.72437 18.962 8.29761 18.8284 7.62593C18.1567 4.21187 15.611 1.4064 12.2674 0.393311C11.7776 0.241163 11.0317 0.0927258 10.5307 0.0370617C10.0446 -0.0148926 8.79028 -0.00375938 8.31157 0.0556164ZM10.8907 0.998194C14.5942 1.62905 17.4145 4.48647 18.0231 8.21968C18.1159 8.79487 18.1159 10.205 18.0231 10.7802C17.4071 14.5542 14.5534 17.408 10.7793 18.024C10.2041 18.1167 8.79399 18.1167 8.2188 18.024C4.44478 17.408 1.59106 14.5542 0.975049 10.7802C0.882275 10.205 0.882275 8.79487 0.975049 8.21968C1.27935 6.3605 2.11431 4.71655 3.41313 3.41401C4.84556 1.9853 6.67134 1.12808 8.73833 0.912842C9.1354 0.872023 10.4602 0.923975 10.8907 0.998194Z"
                fill="#E48642"
                className="fill-current"
              />
              <path
                d="M10.0554 4.7834C9.84019 4.85391 9.64722 4.98008 9.48394 5.1582C8.9941 5.70742 9.16851 6.49414 9.85874 6.83555C10.0851 6.94688 10.1408 6.95801 10.4451 6.95801C10.7271 6.95801 10.8125 6.94316 10.9832 6.86152C11.3877 6.67227 11.6289 6.35313 11.6771 5.94863C11.7216 5.54043 11.4544 5.08398 11.0425 4.87246C10.883 4.78711 10.7976 4.77227 10.5007 4.76113C10.3078 4.75742 10.1074 4.76484 10.0554 4.7834Z"
                fill="#E48642"
                className="fill-current"
              />
              <path
                d="M8.99899 7.93801C8.70583 7.97883 8.54255 8.02336 7.95251 8.22746L7.46638 8.39817L7.38845 8.69875C7.34763 8.86203 7.31794 9.00305 7.32536 9.00676C7.32907 9.01047 7.46266 8.97336 7.61852 8.92512C8.12321 8.76184 8.55739 8.82121 8.70212 9.06613C8.75778 9.1552 8.77263 9.24426 8.76891 9.42981C8.75778 9.75266 8.6947 10.031 8.36442 11.1554C8.21598 11.6675 8.06755 12.2241 8.03786 12.3948C7.96364 12.7882 7.96364 13.189 8.03786 13.4116C8.16774 13.7939 8.63532 14.1464 9.14743 14.2503C9.40348 14.3023 9.94157 14.2763 10.2718 14.1984C10.4834 14.1464 11.2627 13.8755 11.3851 13.8087C11.4259 13.7864 11.4742 13.6751 11.515 13.5044C11.5521 13.3597 11.5744 13.2335 11.567 13.2261C11.5633 13.2187 11.4519 13.2484 11.322 13.2855C11.0363 13.3782 10.565 13.3857 10.3609 13.304C9.94157 13.137 9.97497 12.7585 10.5761 10.7064C10.9361 9.48547 10.9584 8.92883 10.6763 8.50949C10.3683 8.05676 9.74118 7.84153 8.99899 7.93801Z"
                fill="#E48642"
                className="fill-current"
              />
            </g>
            <defs>
              <clipPath id="clip0_569_18916">
                <rect width="19" height="19" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ),
      });

      return () => {
        toast.dismiss(id);
      };
    }
  }, [isOpen]);

  return null;
};

export default RefreshModal;
