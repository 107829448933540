import moment from 'moment';
import React, { useState } from 'react';

const ImportBox = ({
  title = '',
  text = '',
  buttonText = '',
  handleClick = () => {},
  lastSync,
  loading = false,
  toggleText = '',
}) => {
  const [mergeSameAccounts, setMergeSameAccounts] = useState(true);

  return (
    <div className="flex flex-col justify-between p-6 border border-[#D0D5DD] rounded-[12px] relative">
      <div>
        <h3 className="text-[20px] text-indigo-500 font-semibold mb-3 leading-tight">
          {title}
        </h3>
        <p className="text-[#667085] leading-tight mb-2">{text}</p>
        <div className="my-4 clear-start flex gap-4">
          {toggleText && (
            <div className="mt-1 form-switch min-w-11 inline-block form-switch-indigo">
              <input
                type="checkbox"
                id={title}
                className="sr-only"
                checked={mergeSameAccounts}
                onChange={() => setMergeSameAccounts((prev) => !prev)}
              />
              <label
                className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
                htmlFor={title}
              >
                <span className="bg-gray-300" aria-hidden="true" />
                <span className="sr-only">Switch Label</span>
              </label>
            </div>
          )}
          <label htmlFor={title} className="text-sm  cursor-pointer">
            {toggleText}
          </label>
        </div>
      </div>
      <div>
        <div className="mb-6">
          <span className="font-medium">Last Synced: </span>{' '}
          {lastSync ? moment(lastSync).format('MMM DD, h:mm a') : 'Never'}
        </div>
        <button
          className="shadow-button cursor-pointer inline-flex justify-center items-center h-10 w-full bg-indigo-500 text-white rounded-[5px] text-sm
      disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
          type="button"
          onClick={() => handleClick(mergeSameAccounts)}
          disabled={loading}
        >
          {loading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default ImportBox;