/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/Svg';
import DuplicateTransactions from './DuplicateTransactions';
import MatchingTransactions from './MatchingTransactions';
import {
  getAccounts,
  getCurrenciesData,
  getCustomers,
  getMatchingTransaction,
  getMerchants,
  getSingleTransaction,
  getTaxes,
  getTeamById,
} from '../../API/backend_helper';
import { TEAM_ID, transformAccounts } from '../../utils/Utils';
import TransactionDetails from '../transactions/TransactionPanel';
import AppLayout from '../../components/AppLayout';
import UndepositedTransfer from './UndepositedTransfer';
import InvoiceMatchingTransactions from './InvoiceMatchingTransactions';

const Suggestions = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [activeTab, setActiveTab] = useState('duplicates');
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [accountCategories, setAccountCategories] = useState([]);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [physicalAccounts, setPhysicalAccounts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [transaction, setTransaction] = useState(null);
  const [transactionFlyoutOpen, setTransactionFlyoutOpen] = useState(false);
  const [splitTransactionModalOpen, setSplitTransactionModalOpen] =
    useState(false);
  const [fromDropdown, setFromDropdown] = useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('details');
  const [duplicateMatchings, setDuplicateMatchings] = useState({});
  const [duplicateSelectedTransactions, setDuplicateSelectedTransactions] =
    useState({});
  const [transferMatchings, setTransferMatchings] = useState({});
  const [invoiceMatchings, setInvoiceMatchings] = useState({});
  const [undepositedTrans, setUndepositedTrans] = useState({});
  const [undepositedSelectedTransactions, setUndepositedSelectedTransactions] =
    useState({});
  const [transferSelectedTransactions, setTransferSelectedTransactions] =
    useState({});
  const [invoiceSelectedTransactions, setInvoiceSelectedTransactions] =
    useState({});
  const [localLimit, setLocalLimit] = useState(20);

  const teamId = localStorage.getItem(TEAM_ID);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (location?.state?.activeTab === 'Duplicate') {
      setActiveTab('duplicates');
    } else if (location?.state?.activeTab === 'Matching') {
      setActiveTab('matches');
    } else if (location?.state?.activeTab === 'Invoice Matching') {
      setActiveTab('invoice matches');
    }
  }, [location]);

  const loadVendors = async () => {
    try {
      const res = await getMerchants({});
      setVendors(res?.vendors);
    } catch (error) {
      console.error(error);
    }
  };

  const loadAccounts = async () => {
    try {
      const accountsRes = await getAccounts();
      setAllAccounts(transformAccounts(accountsRes, 'allAccounts'));
      setAccountCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
      );
      setExpenseCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
      setPhysicalAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadCustomers = async () => {
    try {
      const res = await getCustomers({});
      setCustomers(res?.data?.records);
    } catch (error) {
      console.error(error);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadSingleTransaction = async (id) => {
    try {
      const res = await getSingleTransaction(id);
      setTransaction(res);
      setTransactionFlyoutOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const loadDuplicateTransactionsApi = async ({
    page = duplicateMatchings?.page || 1,
    limit = localLimit,
    firstLoad = false,
  }) => {
    try {
      const response = await getMatchingTransaction({
        page,
        limit,
        matchingType: 'duplicate',
      });
      if (response) {
        const sortedData = response?.data?.matchings?.map((matching) => {
          if (matching?.duplicateTransactions?.length > 0) {
            matching.duplicateTransactions.sort((a, b) =>
              moment(b.createdAt).isAfter(a.createdAt) ? 1 : -1,
            );
          }
          return matching;
        });
        setDuplicateMatchings({ ...response?.data, matchings: sortedData });
        if (firstLoad) {
          const updatedSelectedTransactions = {};
          response?.data?.matchings?.forEach((matching) => {
            if (matching?.duplicateTransactions?.length > 0) {
              const latestTransaction = matching.duplicateTransactions.reduce(
                (latest, transaction) =>
                  moment(transaction.createdAt).isAfter(latest.createdAt)
                    ? transaction
                    : latest,
                matching.duplicateTransactions[0],
              );
              updatedSelectedTransactions[matching.id] = [latestTransaction.id];
            }
          });
          setDuplicateSelectedTransactions((prev) => ({
            ...prev,
            ...updatedSelectedTransactions,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadMatchingTransactionsApi = async ({
    page = transferMatchings?.page || 1,
    limit = localLimit,
    firstLoad = false,
  }) => {
    try {
      const response = await getMatchingTransaction({
        page,
        limit,
        matchingType: 'transfer',
      });
      if (response) {
        setTransferMatchings(response?.data || []);
        if (firstLoad) {
          const updatedSelectedTransactions = {};
          response?.data?.matchings?.forEach((matching) => {
            if (matching?.transferTransactions?.length === 1) {
              updatedSelectedTransactions[matching.id] = [
                matching.transferTransactions[0].id,
              ];
            }
          });
          setTransferSelectedTransactions((prev) => ({
            ...prev,
            ...updatedSelectedTransactions,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadInvoiceMatchingTransactionsApi = async ({
    page = invoiceMatchings?.page || 1,
    limit = localLimit,
    firstLoad = false,
  }) => {
    try {
      const response = await getMatchingTransaction({
        page,
        limit,
        matchingType: 'invoice',
      });
      if (response) {
        setInvoiceMatchings(response?.data || []);
        if (firstLoad) {
          const updatedSelectedTransactions = {};
          response?.data?.matchings?.forEach((matching) => {
            if (matching?.transferTransactions?.length === 1) {
              updatedSelectedTransactions[matching.id] = [
                matching.transferTransactions[0].id,
              ];
            }
          });
          setInvoiceSelectedTransactions((prev) => ({
            ...prev,
            ...updatedSelectedTransactions,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadUndepositedTransactionsApi = async ({
    page = undepositedTrans?.page || 1,
    limit = localLimit,
    firstLoad = false,
  }) => {
    try {
      const response = await getMatchingTransaction({
        page,
        limit,
        matchingType: 'undeposited_transfer',
      });
      if (response) {
        setUndepositedTrans(response?.data || []);
        if (firstLoad) {
          const updatedSelectedTransactions = {};
          response?.data?.matchings?.forEach((matching) => {
            if (matching?.transferTransactions?.length === 1) {
              updatedSelectedTransactions[matching.id] = [
                matching.transferTransactions[0].id,
              ];
            }
          });
          setUndepositedSelectedTransactions((prev) => ({
            ...prev,
            ...updatedSelectedTransactions,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadData = async () => {
    setIsLoading(true);
    await loadDuplicateTransactionsApi({ firstLoad: true });
    await loadMatchingTransactionsApi({ firstLoad: true });
    await loadUndepositedTransactionsApi({ firstLoad: true });
    await loadInvoiceMatchingTransactionsApi({ firstLoad: true });
    await loadVendors();
    await loadAccounts();
    await loadCustomers();
    loadTaxes();
    setIsLoading(false);
  };

  return (
    <AppLayout>
      <main className="relative grow ">
        {transaction && (
          <TransactionDetails
            transaction={transaction}
            setTransaction={setTransaction}
            transactionPanelOpen={transactionFlyoutOpen}
            setTransactionPanelOpen={setTransactionFlyoutOpen}
            vendors={vendors}
            setVendors={setVendors}
            accounts={physicalAccounts}
            allCategories={accountCategories}
            allExpenseCategories={expenseCategories}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            setFromDropdown={setFromDropdown}
            fromDropdown={fromDropdown}
            receiptModalOpen={receiptModalOpen}
            setReceiptModalOpen={setReceiptModalOpen}
            activeReceipt={activeReceipt}
            setActiveReceipt={setActiveReceipt}
            reloadTransaction={
              activeTab === 'duplicates'
                ? loadDuplicateTransactionsApi
                : activeTab === 'invoice matches'
                  ? loadInvoiceMatchingTransactionsApi
                  : loadMatchingTransactionsApi
            }
            customers={customers}
            setSplitTransactionModalOpen={setSplitTransactionModalOpen}
            splitTransactionModalOpen={splitTransactionModalOpen}
            taskModalOpen={modalOpen}
            setTaskModalOpen={setModalOpen}
            audit
            team={team}
            currencies={currencies}
            taxes={taxes}
            loadTaxes={loadTaxes}
          />
        )}
        <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
          <div className="flex gap-2.5 items-center mb-10">
            <button
              className="mr-2"
              type="button"
              onClick={() => navigate('/transactions')}
            >
              <svg
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="32"
                  height="33"
                  transform="translate(33) rotate(90)"
                  fill="#FBF3ED"
                />
                <path
                  d="M19 10.5L13 16.5L19 22.5"
                  stroke="#E48642"
                  strokeWidth="3.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
              Suggestions
            </h1>
          </div>
          {isLoading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <Loader />
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              <div className="flex items-start justify-between mt-2.5">
                <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-1">
                  <div
                    onClick={() => setActiveTab('duplicates')}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === 'duplicates' &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          } `}
                  >
                    Duplicate Transactions
                  </div>
                  <div
                    onClick={() => setActiveTab('matches')}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === 'matches' &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          } `}
                  >
                    Matching Transfers
                  </div>
                  <div
                    onClick={() => setActiveTab('invoice matches')}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === 'invoice matches' &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          } `}
                  >
                    Matching Invoice Transactions
                  </div>

                  <div
                    onClick={() => setActiveTab('undeposited')}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === 'undeposited' &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          } `}
                  >
                    Undeposited Transfer
                  </div>
                </div>
              </div>
              {activeTab === 'duplicates' && (
                <DuplicateTransactions
                  matchings={duplicateMatchings}
                  setMatchings={setDuplicateMatchings}
                  team={team}
                  currencies={currencies}
                  selectedTransactions={duplicateSelectedTransactions}
                  setSelectedTransactions={setDuplicateSelectedTransactions}
                  loadTransactionsApi={loadDuplicateTransactionsApi}
                  localLimit={localLimit}
                  setLocalLimit={setLocalLimit}
                  loadSingleTransaction={loadSingleTransaction}
                  setTransaction={setTransaction}
                  setTransactionFlyoutOpen={setTransactionFlyoutOpen}
                />
              )}
              {activeTab === 'matches' && (
                <MatchingTransactions
                  matchings={transferMatchings}
                  setMatchings={setTransferMatchings}
                  team={team}
                  currencies={currencies}
                  selectedTransactions={transferSelectedTransactions}
                  setSelectedTransactions={setTransferSelectedTransactions}
                  loadTransactionsApi={loadMatchingTransactionsApi}
                  localLimit={localLimit}
                  setLocalLimit={setLocalLimit}
                  loadSingleTransaction={loadSingleTransaction}
                  setTransaction={setTransaction}
                  setTransactionFlyoutOpen={setTransactionFlyoutOpen}
                />
              )}
              {activeTab === 'invoice matches' && (
                <InvoiceMatchingTransactions
                  matchings={invoiceMatchings}
                  setMatchings={setInvoiceMatchings}
                  team={team}
                  currencies={currencies}
                  selectedTransactions={invoiceSelectedTransactions}
                  setSelectedTransactions={setInvoiceSelectedTransactions}
                  loadTransactionsApi={loadInvoiceMatchingTransactionsApi}
                  localLimit={localLimit}
                  setLocalLimit={setLocalLimit}
                  loadSingleTransaction={loadSingleTransaction}
                  setTransaction={setTransaction}
                  setTransactionFlyoutOpen={setTransactionFlyoutOpen}
                />
              )}
              {activeTab === 'undeposited' && (
                <UndepositedTransfer
                  matchings={undepositedTrans}
                  setMatchings={setUndepositedTrans}
                  team={team}
                  currencies={currencies}
                  selectedTransactions={undepositedSelectedTransactions}
                  setSelectedTransactions={setUndepositedSelectedTransactions}
                  loadTransactionsApi={loadUndepositedTransactionsApi}
                  localLimit={localLimit}
                  setLocalLimit={setLocalLimit}
                  loadSingleTransaction={loadSingleTransaction}
                  setTransaction={setTransaction}
                  setTransactionFlyoutOpen={setTransactionFlyoutOpen}
                />
              )}
            </>
          )}
        </div>
      </main>
    </AppLayout>
  );
};

export default Suggestions;
