import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const CustomerStatementBox = ({
  team,
  customer,
  countryName,
  currency,
  data,
  isPdfView = false,
  type = 'all',
}) => (
  <div className="rounded-[10px] shadow-button py-12 px-10 text-slate-500">
    {/* Header  */}
    <header className="flex justify-between items-start">
      <div>
        <h4 className="font-semibold text-base text-slate-600">{team?.name}</h4>
        <p>
          {team?.businessAddress?.street}{' '}
          {team?.businessAddress?.street2
            ? `, ${team?.businessAddress?.street2}`
            : ''}
        </p>
        <p>
          {team?.businessAddress?.city}
          {team?.businessAddress?.city && ','} {team?.businessAddress?.state}{' '}
          {team?.businessAddress?.zipCode}
        </p>
        <p className="">{countryName}</p>
        {team?.businessTaxNumber && (
          <p className="!mt-4">Tax Number: {team?.businessTaxNumber}</p>
        )}
        <p className={!team?.businessTaxNumber ? '!mt-4' : ''}>{team?.email}</p>
        <p>{team?.phone}</p>
      </div>
      <div className="text-right">
        <h1 className="text-[36px] font-bold text-slate-600 leading-tight -mt-1.5">
          Statement of Account
        </h1>
        <h3 className="text-[24px] font-medium text-slate-600">
          {type === 'all' ? 'All' : 'Outstanding'} Invoices
        </h3>
      </div>
    </header>
    {/* Customer Info  */}
    <div className="mt-12 flex justify-between  ">
      <div>
        <p className="text-base text-[#667085CC] mb-1">Bill to</p>
        <p className="text-base font-semibold text-slate-600">
          {customer?.customer
            ? customer?.customer
            : `${customer?.contacts?.[0]?.firstName || ''} ${customer?.contacts?.[0]?.lastName || ''}`}
        </p>
        <p>
          {customer?.billingAddress?.street}{' '}
          {customer?.billingAddress?.street2
            ? `, ${customer?.billingAddress?.street2}`
            : ''}
        </p>
        <p>
          {customer?.billingAddress?.city}
          {customer?.billingAddress?.city && ','}{' '}
          {customer?.billingAddress?.state} {customer?.billingAddress?.zipCode}
        </p>
        <p>{customer?.billingAddress?.country}</p>
        {customer?.taxNumber && (
          <p className="!mt-4">Tax Number: {customer?.taxNumber}</p>
        )}
        <p className={!customer?.taxNumber ? '!mt-4' : ''}>
          {customer?.contacts?.[0]?.email}
        </p>
        <p>{customer?.contacts?.[0]?.phone}</p>
      </div>
      <div>
        <h3 className="text-right text-[18px] font-semibold mb-2 text-slate-600">
          As of {moment(data?.statment?.endDate).format('MMM DD, YYYY')}
        </h3>
        <div className="flex justify-end gap-4 border-y border-[#D0D5DD] p-2">
          <div className="text-right text-sm space-y-1">
            <p>Paid</p>
            <p>Overdue</p>
            <p>Not Yet Due</p>
          </div>
          <div className="text-right text-sm space-y-1 w-28">
            <p>
              {currency?.symbol}
              {parseFloat(data?.stats?.paidAmount)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
            <p>
              {currency?.symbol}
              {data?.stats?.overdue?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
            <p>
              {currency?.symbol}
              {data?.stats?.unpaid?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
        </div>
        <div className="text-[18px] font-semibold flex gap-4 p-2 text-slate-600">
          <p>Outstanding Balance</p>
          <p className="w-28 text-right">
            {currency?.symbol}
            {(
              parseFloat(data?.stats?.overdue) + parseFloat(data?.stats?.unpaid)
            )?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
      </div>
    </div>
    {/* Table  */}
    <div className="overflow-x-auto h-full mt-12 mb-2">
      <table className="table-fixed w-full dark:text-slate-300">
        {/* Table header */}
        <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
          <tr className="w-full py-[1.438rem] px-[1.375rem] h-12">
            <th className="px-4">
              <div className="text-left font-semibold">Invoice #</div>
            </th>
            <th>
              <div className="text-left font-semibold">Invoice Date</div>
            </th>
            <th>
              <div className="text-left font-semibold">Due Date</div>
            </th>
            <th>
              <div className="text-left font-semibold">Total</div>
            </th>

            <th className="">
              <div className="text-left font-semibold">Paid</div>
            </th>
            <th className="w-[7rem] max-w-[7rem]">
              <div className="text-left font-semibold">Due</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
          {data?.invoices?.map((invoice, index) => (
            <tr
              key={index}
              // onClick={() => onRowClick(transaction)}
              className="w-full pl-[1.375rem] pr-[1.125rem] h-16"
            >
              <td className="px-4">
                <Link
                  to={`/public/invoice-estimate/${invoice?.publicToken || invoice?.id}`}
                  className="w-fit inline-flex max-w-full"
                  target="_blank"
                >
                  <div
                    className={`break-words max-w-full leading-normal hover:underline w-fit font-medium text-left ${isPdfView ? '' : 'text-indigo-500 hover:text-indigo-600'} `}
                  >
                    {invoice?.invoiceNumber?.toString()?.padStart(4, '0')}
                  </div>
                </Link>
              </td>
              <td className="pr-4">
                <div className=" text-left leading-6 break-words">
                  {moment.utc(invoice?.date).format('MMM DD, YYYY')}
                </div>
              </td>
              <td className="whitespace-nowrap">
                <div className="text-left">
                  <p className="leading-tight">
                    {moment.utc(invoice?.dueDate).format('MMM DD, YYYY')}
                  </p>
                  {invoice?.paymentStatus === 'overdue' && (
                    <p className="font-semibold text-rose-600 leading-tight">
                      Overdue
                    </p>
                  )}
                </div>
              </td>
              <td className="whitespace-nowrap">
                <div className="text-left">
                  <p className="leading-6">
                    {currency?.symbol}
                    {parseFloat(invoice?.total ?? 0)?.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}
                  </p>
                </div>
              </td>
              <td className="whitespace-nowrap">
                <div className="text-left">
                  <p className="leading-6">
                    {currency?.symbol}

                    {parseFloat(invoice?.paidAmount)?.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}
                  </p>
                </div>
              </td>
              <td className="whitespace-nowrap">
                <div className="text-left">
                  <p className="leading-6">
                    {currency?.symbol}

                    {parseFloat(
                      invoice?.total - invoice?.paidAmount,
                    )?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    {type === 'all' && (
      <div className="text-[18px] font-semibold justify-end flex gap-4 p-2 text-slate-600">
        <p>Amount Paid</p>
        <p className="w-28 text-right">
          {currency?.symbol}
          {parseFloat(data?.stats?.paidAmount)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
    )}
    <div className="text-[18px] font-semibold justify-end flex gap-4 p-2 text-slate-600 mb-4">
      <p>Outstanding Balance</p>
      <p className="w-28 text-right">
        {currency?.symbol}
        {(
          parseFloat(data?.stats?.overdue) + parseFloat(data?.stats?.unpaid)
        )?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </p>
    </div>
    <h3 className="text-[18px] text-center font-semibold text-slate-600 mt-24 mb-4">
      Number of Days Overdue
    </h3>
    <div className="text-sm text-slate-500 grid grid-cols-5 border-y border-[#D0D5DD] divide-x divide-[#D0D5DD] ">
      <div className="text-center py-2">
        <h3 className="text-slate-600 font-semibold mb-2">91 or More Days</h3>
        <p>
          {currency?.symbol}
          {parseFloat(data?.stats?.ageStat?.upTo120)?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          )}
        </p>
      </div>
      <div className="text-center py-2">
        <h3 className="text-slate-600 font-semibold mb-2">61 to 90 Days</h3>
        <p>
          {currency?.symbol}
          {parseFloat(data?.stats?.ageStat?.upTo90)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
      <div className="text-center py-2">
        <h3 className="text-slate-600 font-semibold mb-2">31 to 60 Days</h3>
        <p>
          {currency?.symbol}
          {parseFloat(data?.stats?.ageStat?.upTo60)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
      <div className="text-center py-2">
        <h3 className="text-slate-600 font-semibold mb-2">30 or Less Days</h3>
        <p>
          {currency?.symbol}
          {parseFloat(data?.stats?.ageStat?.upTo30)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
      <div className="text-center py-2">
        <h3 className="text-slate-600 font-semibold mb-2">Not Yet Due</h3>
        <p>
          {currency?.symbol}
          {data?.stats?.unpaid?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
    </div>
  </div>
);

export default CustomerStatementBox;
