/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router-dom';

const FormBox = ({
  onRoleChange = () => {},
  checked = '',
  title = '',
  details = '',
  objKey = '',
  enabledExpenseReporting = false,
  setExpenseModal = () => {},
  hideAccounts = false,
  peopleSelectedExpAcc = [],
  getAccountNameById = () => {},
  componentId = '',
}) => (
  <div className="h-full">
    <div
      className={`w-full h-full border border-[#D0D5DD] rounded-[5px] p-4 shadow-md ${checked ? 'bg-[#FCF1E9] !border-indigo-500' : ''}`}
    >
      <div className="flex justify-between items-center pb-4 border-b border-[#D0D5DD]">
        <h3 className="font-medium text-base text-[#667085]">{title}:</h3>
        {objKey === 'expense reporting' && !enabledExpenseReporting ? (
          <Link to="/expense-claims">
            <button
              className="flex items-center justify-center text-sm py-1.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[4rem] h-8 text-white leading-6
          disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
              type="button"
            >
              Enable
            </button>
          </Link>
        ) : (
          <div
            className="form-switch form-switch-indigo"
            // onClick={setChecked}
            role="button"
            tabIndex="0"
          >
            <input
              type="checkbox"
              id={`${objKey || title}${componentId}`}
              className="sr-only"
              checked={checked}
              onChange={() => onRoleChange(objKey)}
              disabled={objKey === 'expense reporting' && hideAccounts}
            />
            <label
              className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
              htmlFor={`${objKey || title}${componentId}`}
            >
              <span className="bg-gray-300" aria-hidden="true" />
              <span className="sr-only">Switch label</span>
            </label>
          </div>
        )}
      </div>
      <p className="text-sm text-[#6D7585] mt-4">{details}</p>

      {objKey === 'expense reporting' && checked && !hideAccounts && (
        <div>
          <div
            className="text-indigo-500 underline text-sm cursor-pointer"
            onClick={() => setExpenseModal(true)}
          >
            Select Expense Claim Accounts
          </div>
          <div className="font-semibold text-sm">
            {peopleSelectedExpAcc?.map(
              (pAcc, i) =>
                `${getAccountNameById(pAcc)}${i === peopleSelectedExpAcc?.length - 1 ? '' : ', '}`,
            )}
          </div>
        </div>
      )}
    </div>
    {objKey === 'expense reporting' &&
      checked &&
      !hideAccounts &&
      peopleSelectedExpAcc?.length === 0 && (
        <div className="text-sm text-rose-400">
          Please Select Account(s) to Proceed
        </div>
      )}
  </div>
);

export default FormBox;
