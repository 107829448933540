import React, { useMemo, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from '../images/custom/count-logo.png';

import UserMenu from '../components/DropdownProfile';
import { IS_ACCOUNTANT, IS_PERSON } from '../utils/Utils';
import AIFlyout from './AIFlyout';

function Header({
  sidebarOpen,
  setSidebarOpen,
  showHamburger = true,
  showUserMenu = true,
  workflows = false,
}) {
  const IsPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;

  const IsAccountant = localStorage.getItem(IS_ACCOUNTANT)
    ? JSON.parse(localStorage.getItem(IS_ACCOUNTANT))
    : null;

  const location = useLocation();
  const navigate = useNavigate();

  const [aiFlyoutOpen, setAiFlyoutOpen] = useState(false);
  const { localUser } = useSelector((state) => state.User);
  const { subscription } = useSelector((state) => state.Subscription);
  const { team } = useSelector((state) => state.Team);

  const pendingInvitesCount = useMemo(() => {
    if (localUser?.invites?.length > 0) {
      const filteredInvites = localUser?.invites?.filter(
        (invite) => !invite?.declined,
      );
      return filteredInvites?.length;
    }
    return 0;
  }, [localUser?.invites]);

  const pendingFirmInvitesCount = useMemo(() => {
    if (localUser?.firmInvites?.length > 0) {
      const filteredInvites = localUser?.firmInvites?.filter(
        (invite) => invite?.status === 'active',
      );
      return filteredInvites?.length;
    }
    return 0;
  }, [localUser?.firmInvites]);

  const pendingOwnerInvitaionssCount = useMemo(() => {
    if (localUser?.ownerInvitaions?.length > 0) {
      const filteredInvites = localUser?.ownerInvitaions?.filter(
        (invite) => invite?.status === 'active',
      );
      return filteredInvites?.length;
    }
    return 0;
  }, [localUser?.ownerInvitaions]);

  return (
    <header className="sticky top-0 bg-white dark:bg-[#182235] border-b border-[#D0D5DD80] dark:border-slate-700 z-50">
      <div className="relative px-4 sm:px-6 lg:px-8 ">
        {aiFlyoutOpen && (
          <AIFlyout
            flyoutOpen={aiFlyoutOpen}
            setFlyoutOpen={setAiFlyoutOpen}
            team={team}
          />
        )}
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex w-full">
            {/* Hamburger button */}
            {workflows && (
              <div className="h-16 bg-white  inline-flex pl-2 items-center border-b border-slate-200">
                <NavLink
                  end
                  to={IsPerson ? '/person/invoices?tab=invoices' : '/'}
                  className="block"
                >
                  <img src={Logo} alt="logo" className="h-8" />
                </NavLink>
              </div>
            )}
            {!workflows &&
              (showHamburger ? (
                <button
                  className="text-slate-500 hover:text-slate-600 lg:hidden"
                  aria-controls="sidebar"
                  aria-expanded={sidebarOpen}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSidebarOpen(!sidebarOpen);
                  }}
                  type="button"
                >
                  <span className="sr-only">Open sidebar</span>
                  <svg
                    className="w-6 h-6 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect x="4" y="5" width="16" height="2" />
                    <rect x="4" y="11" width="16" height="2" />
                    <rect x="4" y="17" width="16" height="2" />
                  </svg>
                </button>
              ) : (
                <div className="pl-6 sm:pl-4 lg:pl-2 h-16 bg-white  inline-flex    items-center border-b border-slate-200">
                  <img src={Logo} alt="logo" className="h-8" />
                </div>
              ))}
          </div>
          <div
            className={`absolute top-[6px] ${
              workflows
                ? 'header-subscription-warning-workflows'
                : 'header-subscription-warning'
            }`}
          >
            {subscription?.teamActivePlan?.status !== 'expired' &&
              subscription?.teamActivePlan?.isTrial &&
              !subscription?.teamActivePlan?.hasSubscribed &&
              !subscription?.teamActivePlan?.isOnComplimentary && (
                <div className=" max-w-fit  bg-[#FBECE1] px-3 py-2 rounded-[5px] lg:flex items-center gap-3.5 hidden ">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.4894 24.9996H16.766C22.8299 24.9996 25.2554 22.574 25.2554 16.5102V9.23354C25.2554 3.16968 22.8299 0.744141 16.766 0.744141H9.4894C3.42554 0.744141 1 3.16968 1 9.23354V16.5102C1 22.574 3.42554 24.9996 9.4894 24.9996Z"
                      fill="white"
                      stroke="#E48642"
                      strokeWidth="0.932901"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g clipPath="url(#clip0_2926_3317)">
                      <path
                        d="M13.5963 6.57422L15.3979 10.2242L19.4269 10.8131L16.5116 13.6526L17.1996 17.6641L13.5963 15.7691L9.99293 17.6641L10.6809 13.6526L7.76562 10.8131L11.7946 10.2242L13.5963 6.57422Z"
                        stroke="#E48642"
                        strokeWidth="1.39935"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2926_3317">
                        <rect
                          width="13.9935"
                          height="13.9935"
                          fill="white"
                          transform="translate(6.59961 5.4082)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <h3 className="text-[#00000099] font-medium leading-tight w-auto">
                    {subscription?.teamActivePlan?.isTrial ? (
                      <>
                        You have {subscription?.teamActivePlan?.daysLeft} days
                        left in your trial.
                        {subscription?.teamActivePlan?.hasSubscribed &&
                          "You'll switch to the paid monthly plan afterwards"}
                      </>
                    ) : (
                      <>
                        You have {subscription?.teamActivePlan?.daysLeft} days
                        left in your{' '}
                        <span className="capitalize">
                          {subscription?.teamActivePlan?.plan?.name}
                        </span>{' '}
                        plan.
                      </>
                    )}
                  </h3>
                  {!subscription?.teamActivePlan?.isOnComplimentary &&
                    subscription?.teamActivePlan?.isTrial &&
                    !subscription?.teamActivePlan?.hasSubscribed && (
                      <NavLink to="/manage-workspace/billing">
                        <button
                          className="h-9 w-fit bg-indigo-500 text-white px-4 rounded-[5px] text-sm font-medium"
                          type="button"
                        >
                          Upgrade
                        </button>
                      </NavLink>
                    )}
                </div>
              )}
          </div>
          {/* Header: Right side */}
          <div className="flex items-center space-x-3 ">
            {(pendingInvitesCount > 0 ||
              pendingFirmInvitesCount > 0 ||
              pendingOwnerInvitaionssCount > 0) && (
              <button
                className={`relative w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ml-3 ${
                  aiFlyoutOpen && 'bg-slate-200'
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    IsAccountant
                      ? '/accountant/invites'
                      : pendingFirmInvitesCount || pendingOwnerInvitaionssCount
                        ? '/manage-workspaces/firm-invites'
                        : '/manage-workspaces/my-invites',
                  );
                }}
                aria-controls="search-modal"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  className="h-4 w-4 fill-[#667085]"
                >
                  <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                </svg>
                <div className="absolute -translate-y-[50%] translate-x-1/2 right-0 rounded-full w-[1rem] h-[1rem] bg-indigo-500 text-white p-2.5 flex items-center justify-center text-[11px]">
                  {pendingInvitesCount > 9 ||
                  pendingFirmInvitesCount > 9 ||
                  pendingOwnerInvitaionssCount > 9
                    ? '9+'
                    : pendingInvitesCount ||
                      pendingFirmInvitesCount ||
                      pendingOwnerInvitaionssCount}
                </div>
              </button>
            )}
            {/* <SearchModal id="search-modal" searchId="search" modalOpen={searchModalOpen} setModalOpen={setSearchModalOpen} /> */}
            {/* <Help align="right" /> */}
            {/* <ThemeToggle /> */}
            {/*  Divider */}

            {pendingInvitesCount > 0 && (
              <hr className="w-px h-6 bg-slate-200 dark:bg-slate-700 border-none" />
            )}
            {!IsPerson &&
              team?.id &&
              subscription?.teamActivePlan?.status !== 'expired' &&
              location.pathname !== '/manage-workspaces' &&
              !location.pathname?.includes('/accountant') && (
                <>
                  <button
                    className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ml-3 ${
                      aiFlyoutOpen && 'bg-slate-200'
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAiFlyoutOpen(true);
                    }}
                    aria-controls="search-modal"
                    type="button"
                  >
                    AI
                  </button>
                  {/* <SearchModal id="search-modal" searchId="search" modalOpen={searchModalOpen} setModalOpen={setSearchModalOpen} /> */}
                  {/* <Help align="right" /> */}
                  {/* <ThemeToggle /> */}
                  {/*  Divider */}

                  <hr className="w-px h-6 bg-slate-200 dark:bg-slate-700 border-none" />
                </>
              )}
            {showUserMenu && <UserMenu align="right" />}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
