import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition';
import TextInput from './TextInput';
import useClickOutside from '../utils/useClickOutside';

function DropdownInput({
  options,
  selected,
  setSelected,
  name,
  id,
  label = '',
  categories = false,
  error = false,
  countries = false,
  firmCountries = false,
  disabled = false,
  className = '',
  onBlur = () => {},
  searchable = false,
  required = false,
  crossIcon = false,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([...options]);
  const [isResultFound, setIsResultFound] = useState(true);

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const searchInput = useRef(null);

  useEffect(() => {
    if (dropdownOpen) {
      setSearchValue('');
      setFilteredData([...options]);
      if (options?.length) {
        setIsResultFound(true);
      }
      searchInput.current?.focus();
    }
  }, [dropdownOpen]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setFilteredData([...options]);
      if (options?.length) {
        setIsResultFound(true);
      }
      return;
    }
    setIsResultFound(false);
    const formattedValue = e.target.value.replace(/\s/g, '').toLowerCase();

    const filteredData = options?.filter((option) =>
      option[name]?.replace(/\s/g, '').toLowerCase().includes(formattedValue),
    );

    setFilteredData(filteredData);

    if (filteredData?.length > 0) {
      setIsResultFound(true);
    }
  };

  const OptionsToMap = ({ options }) =>
    options?.map((option) => (
      <button
        type="button"
        key={firmCountries ? option.code : option.id}
        tabIndex="0"
        className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
          firmCountries
            ? option.code === selected
            : option.id === selected && 'text-indigo-500'
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setSelected(firmCountries ? option?.code : option.id);
          setDropdownOpen(false);
        }}
      >
        <span>
          {(countries || firmCountries) && (
            <span className="text-lg mr-2 leading-none">
              {' '}
              {option.flagIcon || option.emoji}
            </span>
          )}
          {option[name]}
        </span>
        <svg
          className={`shrink-0 mr-2 fill-current text-indigo-500 ${
            (firmCountries
              ? option.code !== selected
              : option.id !== selected) && 'invisible'
          }`}
          width="12"
          height="9"
          viewBox="0 0 12 9"
        >
          <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
        </svg>
      </button>
    ));

  const getSelectedTransaction = () => {
    const selectedCategory = options
      ?.map((grp) => grp.categories)
      .flat()
      .find((cat) => cat.id === selected);
    return selectedCategory?.name ? `${selectedCategory?.name}` : 'Select...';
  };

  useClickOutside(dropdown, () => {
    setDropdownOpen(false);
  });

  return (
    <div className={`relative inline-flex w-full ${className}`}>
      <TextInput
        label={label}
        placeholder=" "
        id={id}
        onClick={(e) => {
          e.stopPropagation();
          setDropdownOpen(!dropdownOpen);
        }}
        // onBlur={onBlur}
        value={
          categories
            ? getSelectedTransaction()
            : firmCountries
              ? options?.find((opt) => opt.code === selected)?.[name]
              : options?.find((opt) => opt.id === selected)?.[name] || ''
        }
        ref={trigger}
        autofill="false"
        error={error}
        style={{
          color: 'transparent ',
          textShadow: '0 0 0 #232323CC',
          cursor: 'default',
        }}
        dropdown
        setDropdownOpen={setDropdownOpen}
        flag={
          firmCountries
            ? options?.find((opt) => opt.code === selected)?.emoji || ''
            : countries
              ? options?.find((opt) => opt.id === selected)?.flagIcon || ''
              : ''
        }
        readOnly
        dropdownOpen={dropdownOpen}
        required={required}
        disabled={disabled}
        crossIcon={crossIcon}
        handleClear={() => {
          setSelected('');
          const inputById = document.getElementById(id);
          if (inputById) {
            inputById.value = '';
          }
          setDropdownOpen(false);
        }}
      />

      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-20 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        {dropdownOpen && (
          <div
            ref={dropdown}
            className="font-medium 	 text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
            onFocus={() => (searchable ? null : setDropdownOpen(true))}
            onBlur={() => (searchable ? null : setDropdownOpen(false))}
          >
            {searchable && (
              <div className="relative flex-1 px-3 mb-1">
                <input
                  type="text"
                  placeholder="Search..."
                  className="form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600  my-2 pl-8 w-full inline-block flex-1 height-fit"
                  value={searchValue}
                  onChange={handleSearch}
                  ref={searchInput}
                />
                <svg
                  className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                    stroke="#E48642"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
            <div className="max-h-48 overflow-auto">
              {categories ? (
                // Special for categories
                options?.map(
                  (group) =>
                    group?.categories?.length > 0 && (
                      <div key={group.id}>
                        <span className=" cursor-default font-bold whitespace-nowrap flex items-center pr-4 text-sm text-slate-600 dark:text-slate-300 py-1 px-3">
                          {group?.name}
                        </span>

                        <OptionsToMap
                          options={group?.categories.map((cat) => ({
                            id: cat.id,
                            name: `${cat.name}`,
                          }))}
                        />
                      </div>
                    ),
                )
              ) : (
                // Special normal options
                <OptionsToMap options={filteredData} />
              )}
              {!isResultFound && (
                <span className="whitespace-nowrap inline-flex text-center  pr-4 text-sm  text-opacity-60 py-2 px-4">
                  No Option
                </span>
              )}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
}

export default DropdownInput;
