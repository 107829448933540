import React, { useState } from 'react';
import ModalBasic from '../../components/ModalBasic';
import AlertBox from 'components/AlertBox';

const DeActivateModal = ({
  isOpen,
  setIsOpen,
  deactivateAccount,
  account,
  loading,
  team,
}) => (
  <ModalBasic
    title={`${account?.status === 'inactive' ? 'Activate' : 'Deactivate'} ${account?.name}`}
    modalOpen={isOpen}
    setModalOpen={setIsOpen}
    smallModal
  >
    {account?.status === 'active' && team?.featureExpenseReport && (
      <div className="my-2 px-3">
        <AlertBox
          type="error"
          heading="Warning"
          text="You have Expense Claim Types linked to existing Expense Accounts. If you choose to deactivate these accounts after importing your new Chart of Accounts, please ensure you update your Expense Claim Types to align with the newly imported accounts."
          hideActionButton
        />
      </div>
    )}
    <div className="w-full px-5 py-[1.875rem] pt-2 flex items-center self-stretch bg-white">
      <p className="text-base leading-6 text-slate-600 text-opacity-80">
        {account?.status === 'inactive'
          ? 'You will regain full access to manage this account, including categorizing transactions and generating reports. All previous account settings and data will remain unchanged'
          : 'You will still be able to generate reports for this account, and all previously categorized transactions will remain unchanged.'}
      </p>
    </div>
    <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
      <button
        className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        type="button"
      >
        No
      </button>
      <button
        className="w-[5.625rem] h-11
          disabled:bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed
          flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
        onClick={deactivateAccount}
        type="button"
        disabled={loading}
      >
        {loading && (
          <svg
            className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
            viewBox="0 0 16 16"
          >
            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
          </svg>
        )}
        Yes
      </button>
    </div>
  </ModalBasic>
);

export default DeActivateModal;
